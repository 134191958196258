import styled from "styled-components";

export const LandingButton = styled.button`
  cursor: pointer;
  background: #0069ff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  min-width: 160px;
  height: 40px;
  padding: 11px 18px;
  border: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.6s ease 0s, background-color 0.4s ease 0s;
  :hover {
    background: #0060e8;
  }
  font-size: 0.95rem;
  font-weight: 700;
  line-height: 19px;
  font-family: "Montserrat", sans-serif;
  color: white;
  margin: 0;
  border-radius: 4px;
  text-align: center;
`;

export const LandingButtonSecondary = styled(LandingButton)`
  background-color: #fffefb;
  color: #0069ff;
  box-shadow: inset 0 0 0 1px #0069ff;
  :hover {
    box-shadow: inset 0 0 0 2px #0069ff;
    background-color: #fffefb;
  }
`;

export const LandingButtonCTA = styled(LandingButton)`
  background-color: #000a33;
  color: white;
  :hover {
    background-color: rgb(88, 88, 91);
  }
`;
