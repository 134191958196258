import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { LandingButton } from "components/Button";
import screenshot from "assets/images/revmo-screenshot.png";

const LandingScreenshotWrapper = styled.div`
  width: 100%;
  padding: 40px 0 40px 0;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  text-align: center;
  h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    color: white;
  }
  h4 {
    font-size: 25px;
    font-weight: normal;
    font-family: "Montserrat", sans-serif;
    color: white;
    margin-left: 10%;
    margin-right: 10%;
  }
  img {
    padding: 5rem 0 5rem 0;
  }
  @media (min-width: 871px) {
    img {
      width: 70%;
      height: auto;
    }
  }
  @media (max-width: 500px) {
    text-align: center;
    h2 {
      max-width: 80%;
    }
    h4 {
      max-width: 75%;
    }
    img {
      max-width: 95%;
    }
  }
  @media (max-width: 870px) {
    text-align: center;
    h2 {
      max-width: 80%;
    }
    h4 {
      max-width: 75%;
    }
    img {
      max-width: 85%;
    }
  }
`;

const LandingScreenshotButtonWrapper = styled.div`
  padding: 15px 0 15px 0;
`;

const LandingScreenshot = () => {
  return (
    <LandingScreenshotWrapper>
      <h2>Smart Introductions at Scale</h2>
      <h4>
        Revmo supercharges your network with the valuable connections you need.
      </h4>
      <img src={screenshot} alt={"Revmo dashboard screenshot"} />
      <LandingScreenshotButtonWrapper>
        <Link to="/sign-up">
          <LandingButton>GET STARTED</LandingButton>
        </Link>
      </LandingScreenshotButtonWrapper>
    </LandingScreenshotWrapper>
  );
};

export default LandingScreenshot;
