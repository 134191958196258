export const loadState = (key) => {
  try {
    const serializedState = sessionStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};

export const saveOnboardingFields = (onboardingFields) => {
  saveState("onboardingFields", onboardingFields);
};

export const saveOnboardingFieldsPersonalData = (onboardingFields) => {
  saveState("onboardingFields1", onboardingFields);
};

export const saveOnboardingFieldsPrivateData = (onboardingFields) => {
  saveState("onboardingFields2", onboardingFields);
};

export const saveOnboardingUserAddress = (userAddress) => {
  saveState("userAddress", userAddress);
};

export const loadOnboardingFields = () => {
  const personalData = loadState("onboardingFields1");
  const professionalData = loadState("onboardingFields2");
  return {
    personalData,
    professionalData,
  };
};
