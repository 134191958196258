import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SectionWrapperTransparent, ContentWrapper } from "../Layout";
import iconBusiness from "assets/icons/icon-business.png";
import iconFundraising from "assets/icons/icon-fundraising.png";
import iconRecruitment from "assets/icons/icon-recruiting.png";

const HowItWorksGrid = styled.div`
  border-top: 1px solid #efeffd;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 1.5rem 0;
`;

function HowItWorks() {
  return (
    <SectionWrapperTransparent>
      <ContentWrapper>
        <h1>If your job requires a Rolodex, Revmo is for you</h1>
        <HowItWorksGrid>
          <HowItWorksCard1 />
          <HowItWorksCard2 />
          <HowItWorksCard3 />
        </HowItWorksGrid>
        <span>
          Large business? Check out our{" "}
          <Link to="/enterprise">enterprise section.</Link>
        </span>
      </ContentWrapper>
    </SectionWrapperTransparent>
  );
}

const HowItWorksCardWrapper = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em;
  text-align: center;
  min-width: 100px;
  max-width: 300px;
`;

const HowItWorksCaption = styled.figcaption`
  h4 {
    font-size: 20px;
    margin: 0.5em 0px;
  }
  h5 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0.8rem;
  }
  span {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0.8rem;
  }
  p {
    margin-top: 0.8rem;
  }
`;

const HowItWorksIcon = styled.div`
  img {
    height: 80px;
    width: 80px;
  }
`;

function HowItWorksCard1() {
  return (
    <HowItWorksCardWrapper>
      <HowItWorksIcon>
        <img src={iconFundraising} />
      </HowItWorksIcon>
      <HowItWorksCaption>
        <h4>Fundraising</h4>
        <br />
        <h5>Looking to raise capital for a new venture or a fund?</h5>
        <p>
          With Revmo you can access a wide range of investors across industries,
          organisations and geographies.
        </p>
      </HowItWorksCaption>
    </HowItWorksCardWrapper>
  );
}

function HowItWorksCard2() {
  return (
    <HowItWorksCardWrapper>
      <HowItWorksIcon>
        <img src={iconBusiness} />
      </HowItWorksIcon>
      <HowItWorksCaption>
        <h4>Business Development & Origination</h4>
        <h5>Seeking new leads, or a specific person or company?</h5>
        <p>
          Together Revmo's advanced search and enhanced path discovery will help
          you find the right people.
        </p>
      </HowItWorksCaption>
    </HowItWorksCardWrapper>
  );
}

function HowItWorksCard3() {
  return (
    <HowItWorksCardWrapper>
      <HowItWorksIcon>
        <img src={iconRecruitment} />
      </HowItWorksIcon>
      <HowItWorksCaption>
        <h4>Recruitment</h4>
        <br />
        <h5>Need new talent on your team to grow your business?</h5>
        <p>
          Supercharge your search. Revmo enables teams to view each others
          contact's and automate introductions.
        </p>
      </HowItWorksCaption>
    </HowItWorksCardWrapper>
  );
}

export default HowItWorks;
