import { useState, useCallback } from "react";
import { postData, fetchData } from "utils";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { introductionRequestsState } from "state/recoil/requests";
import { walkthroughState } from "state/recoil/walkthrough";
import { walkthroughSearchResults } from "state/walkthrough";
import { revmoApiUrl } from "../config";

const useIntroductionRequest = () => {
  const introductionRequestsDefault = {
    personYouWouldLikeToViewIntroductionPathwayTo: [],
    peopleWhoCanIntroduceYou: [],
    personSelectedWhoCanIntroduceYou: [],
    introductionRequestHasBeenSent: false,
    introductionRequestError: false,
    displayRequestIntroductionPanel: false,
    userHasConfirmedTheyWouldLikeAnIntroductionRequest: false,
  };

  const [introductionRequests, setIntroductionRequests] = useState(
    introductionRequestsDefault
  );
  const [message, setMessage] = useState("");

  const setRequestsState = useSetRecoilState(introductionRequestsState);

  const walkthrough = useRecoilValue(walkthroughState);
  const walkthroughPeople = walkthroughSearchResults.people;
  const hideAndClearRequestIntroductionPanel = () => {
    setIntroductionRequests(introductionRequestsDefault);
  };
  const viewIntroductionPathwayToNetworkMember = useCallback(
    async (person) => {
      setIntroductionRequests((prevState) => ({
        ...prevState,
        displayRequestIntroductionPanel: true,
      }));
      try {
        if (walkthrough) {
          setMessage(
            `This is a test message, you can replace this text with anything you like.`
          );
          setIntroductionRequests((prevState) => ({
            ...prevState,
            personYouWouldLikeToViewIntroductionPathwayTo: walkthroughPeople[1],
            peopleWhoCanIntroduceYou: [walkthroughPeople[0]],
          }));
        } else {
          setMessage(
            `Please introduce me to ${person.first_name} so that I can share Revmo's story.`
          );
          try {
            const path = await fetchData(`/paths/${person.uuid}`);
            if (!person.profile_image) {
              person.profile_image = `${revmoApiUrl}/images/profile_images/${person.uuid}`;
            }
            setIntroductionRequests((prevState) => ({
              ...prevState,
              personYouWouldLikeToViewIntroductionPathwayTo: person,
              peopleWhoCanIntroduceYou: path,
            }));
          } catch (err) {
            console.log(err);
            setIntroductionRequests((prevState) => ({
              ...prevState,
              introductionRequestError: true,
            }));
          }
        }
      } catch (err) {
        console.log(err);
        setIntroductionRequests((prevState) => ({
          ...prevState,
          introductionRequestError: true,
        }));
      }
    },
    [walkthrough, walkthroughPeople]
  );

  async function handleIntroRequest(introRequestInfo, introductionUseCase) {
    await postData("/intros/", JSON.stringify(introRequestInfo));
    setIntroductionRequests({
      ...introductionRequests,
      introductionRequestHasBeenSent: true,
    });
    const requestInfo = { ...introRequestInfo };
    requestInfo.reason = introductionUseCase;
    requestInfo.target_person_node = {
      ...introductionRequests.personYouWouldLikeToViewIntroductionPathwayTo,
    };
    setRequestsState((prevRequests) => ({
      ...prevRequests,
      PENDING: [...prevRequests.PENDING, requestInfo],
    }));
  }

  const sendIntroductionRequestToNetworkMember = async (
    introductionUseCase,
    message,
    walkthrough
  ) => {
    try {
      let from_person_uuid, between_person_mask, to_person_uuid;
      if (walkthrough) {
        const introRequestInfo = {
          reason: introductionUseCase.toUpperCase(),
          intermediary_person_info: walkthroughPeople[0],
          target_person_node: walkthroughPeople[1],
          context: message,
        };
        setIntroductionRequests({
          ...introductionRequests,
          introductionRequestHasBeenSent: true,
        });
        setRequestsState((prevRequests) => ({
          ...prevRequests,
          PENDING: [...prevRequests.PENDING, introRequestInfo],
        }));
      } else {
        if (introductionRequests.personSelectedWhoCanIntroduceYou) {
          ({ from_person_uuid, between_person_mask, to_person_uuid } =
            introductionRequests.personSelectedWhoCanIntroduceYou);
          const introRequestInfo = {
            from_person_uuid,
            between_person_mask,
            to_person_uuid,
            reason: introductionUseCase.toUpperCase(),
            context: message,
          };
          handleIntroRequest(introRequestInfo, introductionUseCase);
        } else {
          ({ from_person_uuid, between_person_mask, to_person_uuid } =
            introductionRequests.peopleWhoCanIntroduceYou[0]);
          const introRequestInfo = {
            from_person_uuid,
            between_person_mask,
            to_person_uuid,
            reason: introductionUseCase.toUpperCase(),
            context: message,
          };
          handleIntroRequest(introRequestInfo, introductionUseCase);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const confirmIntroductionRequest = (confirm) => {
    if (confirm.every((element) => element === false)) {
      setIntroductionRequests({
        ...introductionRequests,
        userHasConfirmedTheyWouldLikeAnIntroductionRequest: false,
        personSelectedWhoCanIntroduceYou: [],
      });
    } else {
      confirm.forEach((value, index) => {
        if (value) {
          setIntroductionRequests({
            ...introductionRequests,
            userHasConfirmedTheyWouldLikeAnIntroductionRequest: true,
            personSelectedWhoCanIntroduceYou:
              introductionRequests.peopleWhoCanIntroduceYou[index],
          });
        }
      });
    }
  };

  return {
    introductionRequests,
    setIntroductionRequests,
    hideAndClearRequestIntroductionPanel,
    viewIntroductionPathwayToNetworkMember,
    sendIntroductionRequestToNetworkMember,
    confirmIntroductionRequest,
    message,
    setMessage,
  };
};

export default useIntroductionRequest;
