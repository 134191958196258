import React from "react";
import styled from "styled-components";
import chevronBlack from "assets/icons/chevron-black.svg";
import greenTick from "assets/icons/tick-green.svg";
import blackArrow from "assets/icons/arrow-black-to-right.svg";
import { ErrorMessage } from "../Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import { months, years } from "utils";

const WhiteLargeFormInputWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e3e6e9;
  border-radius: 5px;
  width: 100%;
  height: 60px;
  position: relative;
  margin-bottom: 20px;
  p {
    position: absolute;
    left: 19px;
    top: 13px;
  }
`;
const WhiteLargeFormInputWrapperOrg = styled.div`
  background: #ffffff;
  border: 1px solid #e3e6e9;
  border-radius: 5px;
  width: 100%;
  width: 250px;
  height: 60px;
  position: relative;
  margin-bottom: 20px;
  p {
    position: absolute;
    left: 19px;
    top: 13px;
  }
`;

const WhiteLargeFormInputWrapperGrey = styled(WhiteLargeFormInputWrapper)`
  background: #fcfcfc;
  box-shadow: 0px 2px 4px #efefef;
  input {
    background: #fcfcfc;
  }
`;

const SmallBlackStandardText = styled.p`
  font-size: 13px;
  line-height: 15px;
  color: ${(props) => props.theme.textColor};
  font-weight: ${(props) => props.bold && "bold"};
`;

const WhiteFormInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 24px 0 0 19px;
  background: white;
  color: ${(props) => props.theme.textColor};
  font-size: 15px;
  border-radius: 5px;
  border: unset;
  ${({ error }) => {
    if (error) {
      return `
      border: 1px solid #F9007A;
      `;
    }
  }}
  ::placeholder {
    color: ${(props) => props.theme.textColor};
  }
`;

const WhiteLargeFormInputHooksFormWrapper = styled.div`
  ${ErrorMessage} {
    margin-top: -22px;
    margin-bottom: 10px;
  }
`;

export function WhiteLargeFormInput({ fieldName, value, handleChange, error }) {
  return (
    <>
      <WhiteLargeFormInputWrapper>
        <SmallBlackStandardText>{fieldName}</SmallBlackStandardText>
        <WhiteFormInput
          value={value}
          onChange={handleChange}
          autoComplete="false"
          error={error}
        />
      </WhiteLargeFormInputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}

function WhiteFormInputWithoutError({
  bold,
  fieldName,
  error,
  register,
  type,
  name,
  onBlur,
  placeholder,
  onMouseLeave,
  formWrapper,
}) {
  if (formWrapper == "orgsTable") {
    return (
      <WhiteLargeFormInputWrapperOrg>
        <SmallBlackStandardText bold={bold}>{fieldName}</SmallBlackStandardText>
        <WhiteFormInput
          name={name}
          error={error[name]?.message}
          ref={register}
          type={type ? type : "text"}
          onBlur={onBlur}
          placeholder={placeholder}
          onMouseLeave={onMouseLeave}
        />
      </WhiteLargeFormInputWrapperOrg>
    );
  } else {
    return (
      <WhiteLargeFormInputWrapper>
        <SmallBlackStandardText bold={bold}>{fieldName}</SmallBlackStandardText>
        <WhiteFormInput
          name={name}
          error={error[name]?.message}
          ref={register}
          type={type ? type : "text"}
          onBlur={onBlur}
          placeholder={placeholder}
          onMouseLeave={onMouseLeave}
        />
      </WhiteLargeFormInputWrapper>
    );
  }
}

export function WhiteLargeFormInputHooksForm({
  fieldName,
  name,
  error,
  register,
  type,
  bold,
  onBlur,
  placeholder,
  onMouseLeave,
  formWrapper,
}) {
  return (
    <WhiteLargeFormInputHooksFormWrapper>
      <WhiteFormInputWithoutError
        bold={bold && bold}
        fieldName={fieldName}
        error={error}
        register={register}
        type={type}
        name={name}
        onBlur={onBlur}
        placeholder={placeholder}
        onMouseLeave={onMouseLeave}
        formWrapper={formWrapper}
      />
      {error[name]?.message && (
        <ErrorMessage>{error[name]?.message}</ErrorMessage>
      )}
    </WhiteLargeFormInputHooksFormWrapper>
  );
}

const WhiteLargeFormSelectWrapper = styled.div`
  border-radius: 5px;
  min-width: 124px;
  max-width: 124px;
  height: 60px;
  position: relative;
  margin-bottom: 20px;
  margin-right: 6px;
  label {
    position: absolute;
    left: 19px;
    top: 13px;
    font-size: 13px;
  }
  p {
    position: absolute;
    padding-left: 19px;
    line-height: 17.25px;
    height: 34px;
    top: 25px;
    display: flex;
    align-items: center;
  }
  ${({ error }) => {
    if (error) {
      return `
      border: 1px solid #F9007A;
      `;
    }
  }}
`;

const WhiteLargeFormSelect = styled.select`
  height: 100%;
  padding-left: 19px;
  top: 0;
  bottom: 0;
  border: 1px solid #e3e6e9;
  border-radius: 5px;
  min-width: unset!important;
  max-width: unset!important;
  width: 100%;
  color: transparent;
  background: white;
  option {
    color: black;
  };
  padding-left:
  //eslint-disable-next-line
  -moz-appearance: none;
  //eslint-disable-next-line
  -webkit-appearance: none;
`;

const InternationalDialingCodeFormWrapper = styled.div`
  display: flex;
`;

const ErrorMessageMargin = styled(ErrorMessage)`
  margin-top: -22px;
  margin-bottom: 10px;
`;

export function WhiteLargeFormInternationalDiallingCode({
  name,
  options,
  handleChangeDiallingCode,
  diallingCode,
  fieldName,
  error,
  register,
}) {
  return (
    <>
      <InternationalDialingCodeFormWrapper>
        <WhiteLargeFormSelectWrapper error={error[name]?.message}>
          <label>Dialling Code</label>
          <WhiteLargeFormSelect
            name={name}
            onChange={(event) => handleChangeDiallingCode(event, name)}
          >
            {options.map((option, index) => {
              return (
                <option
                  key={index}
                  value={JSON.stringify(option)}
                  selected={
                    option?.regionCode.toUpperCase() ===
                    diallingCode[name]?.regionCode.toUpperCase()
                  }
                >
                  {option.name} {option.countryCode}
                </option>
              );
            })}
          </WhiteLargeFormSelect>
          <p>{diallingCode[name]?.countryCode}</p>
        </WhiteLargeFormSelectWrapper>
        <WhiteFormInputWithoutError
          name={name}
          fieldName={fieldName}
          register={register}
          error={error}
        />
      </InternationalDialingCodeFormWrapper>
      {error[name]?.message && (
        <ErrorMessageMargin>{error[name]?.message}</ErrorMessageMargin>
      )}
    </>
  );
}

export function WhiteLargeFormInputHooksPlaceHolder({
  name,
  error,
  register,
  type,
  placeholder,
}) {
  return (
    <>
      {error[name]?.message && (
        <ErrorMessage>{error[name]?.message}</ErrorMessage>
      )}
      <WhiteLargeFormInputWrapper>
        <WhiteFormInput
          name={name}
          error={error[name]?.message}
          ref={register}
          type={type ? type : "text"}
          placeholder={placeholder}
        />
      </WhiteLargeFormInputWrapper>
    </>
  );
}

export function WhiteLargeFormInputHooksFormGrey({
  fieldName,
  name,
  error,
  register,
  type,
  bold,
}) {
  return (
    <>
      {error[name]?.message && (
        <ErrorMessage>{error[name]?.message}</ErrorMessage>
      )}
      <WhiteLargeFormInputWrapperGrey>
        <SmallBlackStandardText bold={bold}>{fieldName}</SmallBlackStandardText>
        <WhiteFormInput
          name={name}
          error={error[name]?.message}
          ref={register}
          type={type ? type : "text"}
        />
      </WhiteLargeFormInputWrapperGrey>
    </>
  );
}

const WhiteLargeFormTextAreaWrapper = styled(WhiteLargeFormInputWrapper)`
  height: 200px;
`;

const WhiteTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  resize: none;
  padding: 32px 0 0 19px;
  background: white;
  color: ${(props) => props.theme.textColor};
  font-size: 15px;
  border-radius: 5px;
  ${({ error }) => {
    if (error) {
      return `
      border: 1px solid #F9007A;
      `;
    } else {
      return `
      border: 1px solid #e3e6e9;
      border-radius: 5px;
      `;
    }
  }}
`;

export function WhiteLargeTextAreaHooksForm({
  fieldName,
  name,
  error,
  register,
  type,
}) {
  return (
    <>
      {error[name]?.message && (
        <ErrorMessage>{error[name]?.message}</ErrorMessage>
      )}
      <WhiteLargeFormTextAreaWrapper>
        <SmallBlackStandardText>{fieldName}</SmallBlackStandardText>
        <WhiteTextArea
          name={name}
          error={error[name]?.message}
          ref={register}
          type={type ? type : "text"}
        />
      </WhiteLargeFormTextAreaWrapper>
    </>
  );
}

export const SearchInputWrapper = styled.div`
  position: relative;
  background: white;
  border: 1px solid ${(props) => props.theme.borderColor};
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  ~ div {
    flex: 1;
  }
  flex: 1;
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    top: 10px;
    left: 24px;
    z-index: 10;
    color: ${(props) => props.theme.textColor};
    position: absolute;
  }
`;

export const SearchInputWrapperLocation = styled(SearchInputWrapper)`
  height: 35px;
  background-color: #fcfcfc;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 9px;
  input {
    padding-top: 0;
    padding-bottom: 0;
    ::placeholder {
      line-height: normal !important;
    }
  }
`;

const SearchInput = styled.input`
  padding: 24px 23px 12px 24px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  overflow: hidden;
  white-space: nowrap;
  height: 54px;
  width: 100%;
  text-overflow: ellipsis;
  :focus {
    outline: unset;
  }
  background: white;
  position: relative;
  border: unset;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;

  color: ${(props) => props.theme.textColor};
  ::placeholder {
    position: absolute;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    opacity: 1;
    bottom: 11px;
    color: #a5a5a5;
  }
`;

const ChevronContainer = styled.div`
  border-left: 1px solid ${(props) => props.theme.borderColor};
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60px;
  pointer-events: none;
`;

export const SearchBarIcon = styled.img`
  pointer-events: none;
  right: 26px;
  top: 26px;
  bottom: 26px;
  z-index: 0;
  width: 8px;
  height: 4px;
  position: absolute;
`;

const SearchSelect = styled.select`
  padding: 22px 23px 12px 24px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 56px;
  background: white;
  border: unset;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  position: relative;
  font-size: 17px;
  line-height: 20px;
  color: ${(props) => props.theme.textColor};
`;

export function SearchFormInput({
  fieldName,
  name,
  placeholder,
  register,
  onKeyDown,
}) {
  return (
    <SearchInputWrapper>
      <label>{fieldName}</label>
      <SearchInput
        placeholder={placeholder && placeholder}
        ref={register}
        type="text"
        name={name}
        autocomplete="false"
        onKeyDown={onKeyDown}
      />
    </SearchInputWrapper>
  );
}

export function SearchFormInputLocation({
  handleChange,
  value,
  optionIcon: OptionIcon,
}) {
  return (
    <SearchInputWrapperLocation>
      {OptionIcon && <OptionIcon />}
      <SearchInput
        placeholder={"Ex. New York, NY"}
        type="text"
        onChange={handleChange}
        value={value}
      />
    </SearchInputWrapperLocation>
  );
}

export function SearchFormSelect({ fieldName, name, options, register }) {
  return (
    <SearchInputWrapper>
      <label>{fieldName}</label>
      <SearchSelect name={name} ref={register}>
        {options.map((option) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </SearchSelect>
      <ChevronContainer>
        <SearchBarIcon searchBar src={chevronBlack} />
      </ChevronContainer>
    </SearchInputWrapper>
  );
}

const WhiteLargeFormSelectWrapperGrey = styled(WhiteLargeFormInputWrapperGrey)`
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: unset;
    flex: 1;
    position: absolute;
    left: 19px;
    bottom: 0px;
    padding-bottom: 8px;
    width: calc(100% - 19px);
    z-index: 2;
    color: rgb(49, 50, 55);
    background: unset;
    :focus {
      outline: unset;
    }
  }
`;

const VerticalLineGrey = styled.div`
  position: absolute;
  width: 58px;
  bottom: 0;
  top: 0;
  right: 0;
  border-left: 1px solid #e3e6e9;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    transform: rotate(90deg);
    height: 14px;
    width: 14px;
  }
`;

export function WhiteSelectHooksForm({ fieldName, register, options, name }) {
  return (
    <WhiteLargeFormSelectWrapperGrey>
      <SmallBlackStandardText>{fieldName}</SmallBlackStandardText>
      <select ref={register} name={name}>
        {options.map((option) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </select>
      <VerticalLineGrey>
        <img src={blackArrow} />
      </VerticalLineGrey>
    </WhiteLargeFormSelectWrapperGrey>
  );
}

export const TickboxContainer = styled.div`
  background: #ffffff;
  border: 1px solid #7c919e;
  box-sizing: border-box;
  border-radius: 3px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 10px;
    width: 10px;
  }
  margin-right: ${(props) => props.marginRight};
  ${(props) => {
    if (props.hide) {
      return `
        display: none;
      `;
    }
  }}
`;

export function Tickbox({ displayTickbox, setDisplayTickbox, tickbox }) {
  return (
    <TickboxContainer onClick={() => setDisplayTickbox(!displayTickbox)}>
      {displayTickbox && <img src={tickbox ? tickbox : greenTick} alt="" />}
    </TickboxContainer>
  );
}

function MonthSelect({ control, name }) {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          options={months}
          getOptionLabel={(option) => option.label}
          disablePortal
          renderInput={(params) => (
            <TextField {...params} label="Choose a month" variant="outlined" />
          )}
          onChange={(e, data) => onChange(data)}
          {...props}
        />
      )}
      onChange={([, data]) => data.value}
      defaultValue={null}
      name={name}
      control={control}
    />
  );
}

function YearSelect({ control, name }) {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          options={years}
          getOptionLabel={(option) => option}
          disablePortal
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a year"
              variant="outlined"
              sx={{ input: { fontWeight: "normal" } }}
            />
          )}
          onChange={(e, data) => onChange(data)}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={null}
      name={name}
      control={control}
    />
  );
}

export function DateSelect({ title, control, type }) {
  return (
    <Box sx={{ mb: "20px" }}>
      <Typography>{title}</Typography>
      <Grid container spacing={1} sx={{ mt: "5px" }}>
        <Grid item xs={12} sm={6}>
          <MonthSelect control={control} name={type + "Month"} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YearSelect control={control} name={type + "Year"} />
        </Grid>
      </Grid>
    </Box>
  );
}
