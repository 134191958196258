import React from "react";
import { useLocation } from "react-router-dom";
import { UserProfileInfoContainer } from "../Layout";
import { PositionTable, PositionTableSkeleton } from "./index";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as _ from "lodash";
import styled from "styled-components";

const NoCareerInformationText = styled.p`
  font-size: 16px;
  line-height: 28px;
  max-width: 480px;
`;

function ProfileInfo({
  userContact,
  fromSearchPage,
  handleSubmitUpdatePosition,
  handleOnDragEnd,
  profile,
  jobsAreDraggable,
  displayOptions,
  toggleDisplayOption,
  handleDeletePosition,
  toggleAddOrEditSingleContactModal,
  diallingCodesHook,
  customFormErrorsHook,
}) {
  let career = profile?.positions;
  const pageIsShowingUserContact = useLocation().pathname.includes("contact");
  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="user-position">
          {(provided) => (
            <UserProfileInfoContainer
              className="user-position"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {career?.length ? (
                career.map((job, index) => {
                  return (
                    <Draggable
                      key={job.role_uuid}
                      draggableId={`${index}`}
                      index={index}
                      isDragDisabled={!jobsAreDraggable}
                    >
                      {(provided, snapshot) => (
                        <div
                          id={index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <PositionTable
                            index={index}
                            job={job}
                            handleSubmitUpdatePosition={
                              handleSubmitUpdatePosition
                            }
                            profile={profile}
                            displayOption={displayOptions[index]}
                            toggleDisplayOption={toggleDisplayOption}
                            handleDeletePosition={handleDeletePosition}
                            userContact={userContact}
                            fromSearchPage={fromSearchPage}
                            toggleAddOrEditSingleContactModal={
                              toggleAddOrEditSingleContactModal
                            }
                            isDragging={snapshot.isDragging}
                            isDragDisabled={!jobsAreDraggable}
                            diallingCodesHook={diallingCodesHook}
                            customFormErrorsHook={customFormErrorsHook}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <>
                  {pageIsShowingUserContact ? (
                    <NoCareerInformationText>
                      There is no career information for this contact. Add
                      additional information to enrich your Revmo experience.
                    </NoCareerInformationText>
                  ) : (
                    <>
                      {_.times(3, (index) => {
                        index;
                        return <PositionTableSkeleton index={index + 1} />;
                      })}
                    </>
                  )}
                </>
              )}
              {provided.placeholder}
            </UserProfileInfoContainer>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default ProfileInfo;
