import React from "react";
import styled from "styled-components";
import { WhiteLargeFormInputHooksForm } from "components/Input";
import { AuthButton } from "components/Button";
import { HeaderText, AuthText } from "components/Typography";
import {
  AuthForm,
  AuthBackButton,
  AuthFormWrapperBackButton,
} from "components/Auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { joinWaitlistSchema } from "../utils";

const AuthFormWrapperRequestAccess = styled(AuthFormWrapperBackButton)`
  padding-bottom: 30px;
  h1 {
    margin-bottom: 20px;
    + p {
      margin-bottom: 5px;
    }
  }
`;

const HeaderTextRequestAccess = styled(HeaderText)`
  font-size: 23px;
`;

function RequestAccessCode({
  registerForAccessCode,
  alertMessage,
  returnToCheckReferralCode,
}) {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(joinWaitlistSchema),
  });

  if (alertMessage) {
    errors.email = {};
    errors.email.message = alertMessage;
  }

  return (
    <AuthFormWrapperRequestAccess>
      <AuthBackButton goBack={returnToCheckReferralCode} />
      <HeaderTextRequestAccess>Join the Revmo Waitlist</HeaderTextRequestAccess>
      <AuthText>
        The Revmo Beta is live, and we're slowly sending out invites. Join the
        waitlist and we’ll be in touch soon, so you can start making
        connections.
      </AuthText>
      <AuthForm>
        <WhiteLargeFormInputHooksForm
          fieldName="First Name"
          register={register}
          error={errors}
          name="firstName"
          bold
        />
        <WhiteLargeFormInputHooksForm
          fieldName="Last Name"
          register={register}
          error={errors}
          name="lastName"
          bold
        />
        <WhiteLargeFormInputHooksForm
          fieldName="Email Address"
          register={register}
          error={errors}
          name="email"
          bold
        />
        <WhiteLargeFormInputHooksForm
          fieldName="Company"
          register={register}
          error={errors}
          name="company"
          bold
        />
        <AuthButton onClick={handleSubmit(registerForAccessCode)}>
          Keep me posted
        </AuthButton>
      </AuthForm>
    </AuthFormWrapperRequestAccess>
  );
}

export default RequestAccessCode;
