import React from "react";
import styled from "styled-components";
import { RequestStatusTableHeader, RequestStatusTableRow } from "./components";

const RequestStatusTableWrapper = styled.div`
  background: #f9fafb;
  padding: 42px 14px 20px 19px;
  margin-top: 10px;
  width: 1100px;
  @media (max-width: 1126px) {
    width: 100%;
    background: white;
    padding: unset;
  }
`;

function RequestStatusTable({ requestType, requests }) {
  return (
    <>
      <RequestStatusTableHeader requestType={requestType} requests={requests} />
      {requests[requestType] && (
        <RequestStatusTableWrapper>
          {requests[requestType]?.map((request, index) => {
            return (
              <RequestStatusTableRow
                key={index}
                request={request}
                index={index}
                requestType={requestType}
              />
            );
          })}
        </RequestStatusTableWrapper>
      )}
    </>
  );
}

export default RequestStatusTable;
