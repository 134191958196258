import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { AuthButton } from "components/Button";
import {
  HeaderText,
  BlueLinkTextAdapative,
  AuthText,
} from "components/Typography";
import {
  AuthFormWrapper,
  AuthAlternateOptionsRowNoPadding,
} from "components/Auth";

const AuthFormWrapperResumeSignUp = styled(AuthFormWrapper)`
  padding-bottom: 25px;
  h1 {
    margin-bottom: 25px;
    + p {
      margin-bottom: 15px;
    }
  }
`;

function ResumeSignUp({ userEmail, signUpWithNewAccount, resumeSignUp }) {
  return (
    <AuthFormWrapperResumeSignUp>
      <HeaderText>Resume Sign Up</HeaderText>
      <AuthText>
        You previously began signing up to Revmo, with username {userEmail}.
        Would you like to continue where you left off, or sign up with a
        different account?
      </AuthText>
      <AuthButton onClick={async () => resumeSignUp(userEmail)}>
        Resume
      </AuthButton>
      <AuthAlternateOptionsRowNoPadding>
        <BlueLinkTextAdapative onClick={async () => signUpWithNewAccount()}>
          Create a new account
        </BlueLinkTextAdapative>
        <BlueLinkTextAdapative>|</BlueLinkTextAdapative>
        <Link to="/login" onClick={() => localStorage.clear()}>
          <BlueLinkTextAdapative>Log in to your account</BlueLinkTextAdapative>
        </Link>
      </AuthAlternateOptionsRowNoPadding>
    </AuthFormWrapperResumeSignUp>
  );
}

export default ResumeSignUp;
