import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { CareersPageWrapper, JobWrapper } from "../Layout";
import { LandingNavbar } from "components/Navbar";
import { LandingFooter } from "components/LandingPage";
import { LandingButton } from "components/Button";
import sanityClient from "../../../client.js";
import BlockContent from "@sanity/block-content-to-react";
import locationIcon from "assets/icons/location-icon.svg";
import websiteIcon from "assets/icons/website-icon.svg";

const DescriptionWrapper = styled.div`
  padding: 15px;
  font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  h1 {
    margin-top: 15px;
  }
`;

const CareerIconContainer = styled.div`
  position: relative;
  margin-right: 23px;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 100%;
  img {
    height: 16px;
    width: 16px;
    margin-right: 2px;
  }
  a,
  p {
    color: #31475f;
    font-weight: 500;
  }
`;

const SectionTitle = styled.h2`
  margin: 15px 0px 5px 0px;
`;

function JobDescription() {
  const [roleData, setRoleData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
         description,
       }`,
        { slug }
      )
      .then((data) => setRoleData(data[0]))
      .catch(console.error);
  }, [slug]);

  return (
    <>
      <LandingNavbar />
      <CareersPageWrapper>
        <JobWrapper>
          <DescriptionWrapper>
            {roleData?.title !== "CTO" ? (
              <h1>Revmo is hiring {roleData?.title}s!</h1>
            ) : (
              <h1>Revmo is hiring a {roleData?.title}!</h1>
            )}
            <CareerIconContainer>
              <img src={locationIcon} alt={"location icon"} />
              <p>51 Jermyn St, London, SW1Y 6NP, UK</p>
            </CareerIconContainer>
            <CareerIconContainer>
              <img src={websiteIcon} alt={"website icon"} />
              <Link to="/">Website</Link>
            </CareerIconContainer>
            <SectionTitle>About Us</SectionTitle>
            <p>
              Revmo is an ERA-backed startup focusing on transforming the way
              people meet, by unlocking the value of their potential
              connections. To do this, we are building a privacy-oriented social
              graph, enabling users to upload their contacts and expand their
              networks. We use graph data science and a range of data sources to
              map the universe and identify the best paths.
              <br />
              Whilst Revmo’s platform is general and sector agnostic, it is
              currently focused on the Financial Services and Startup sectors,
              with both a consumer and enterprise angle. Our clients range from
              VC funds, AdTech, Fitness and AI.
              <br />
              Fundamentally, we believe that user data belongs to users - and we
              are designing a platform for professional networking that respects
              the privacy of our users.
            </p>
            <SectionTitle>Why join Revmo?</SectionTitle>
            <p>
              We have a lot of the great tech startup qualities you'd expect,
              but we don't stop there. Our international scale and small team,
              emphasis on healthy life balance and kindness in all our
              interactions, and focus on ownership and personal growth make us a
              uniquely great place to work.
              <br />
              In the UK, we have offices in Central London, and in the US, we
              are in New York. We support remote-first employment, especially
              during the global pandemic.
            </p>
            <SectionTitle>
              Our tech stack <i>(but open to others!)</i>
            </SectionTitle>
            <p>
              <ul>
                <li>Python + FastAPI</li>
                <li>ReactJS + Recoil</li>
                <li>Docker</li>
                <li>AWS S3</li>
                <li>
                  Machine Learning (ML) + Natural Language Processing (NLP)
                </li>
                <li>
                  <i>Our future plans involve Go + GraphQL</i>
                </li>
              </ul>
            </p>
            {roleData && (
              <>
                <BlockContent blocks={roleData.description} />
              </>
            )}
            <SectionTitle>Benefits + perks:</SectionTitle>
            <ul>
              <li>Flexible work hours</li>
              <li>20 days paid vacation + Bank Holidays</li>
              <li>Yummy foods: coffee + snacks provided when in the office</li>
              <li>Weekly Deliveroo allowance</li>
              <li>Choice of workstation!</li>
              <li>Equity - as the company grows in value, you benefit</li>
              <li>Private medical care</li>
            </ul>
            <SectionTitle>How to apply</SectionTitle>
            <p>
              Think you might be interested? Send your CV to{" "}
              <a href="mailto:apply@revmo.app?subject=Job Application&body=Hi there, I am interested in applying for a job at Revmo.">
                apply@revmo.app
              </a>
              .
            </p>
          </DescriptionWrapper>
        </JobWrapper>
        <Link to="/careers">
          <LandingButton>Back to jobs</LandingButton>
        </Link>
      </CareersPageWrapper>
      <LandingFooter />
    </>
  );
}

export default JobDescription;
