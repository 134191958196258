import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = [
  "Personal Details",
  "Professional Details",
  "Location",
  "Complete Account",
];

export default function OnboardingProgress({ activeStep }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step
            key={label}
            sx={{
              "& .Mui-completed": {
                color: "#0069FF", // circle color (COMPLETED)
              },
              "& .Mui-active": {
                color: "#0069FF", // circle color (ACTIVE)
              },
              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: "inherit", // Just text label (ACTIVE)
                },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: "inherit", // Just text label (COMPLETED)
                },
            }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
