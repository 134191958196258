import { useContext } from "react";
import { ContactsContext } from "state";

const useContact = () => {
  const context = useContext(ContactsContext);
  if (context === undefined) {
    throw new Error(`useContact must be within a ContactProvider`);
  }
  return context;
};

export default useContact;
