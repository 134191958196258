import React from "react";
import styled from "styled-components";
import supporterLogos from "assets/images/supporter-logos.png";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const CarouselInnerText = styled.div`
  p {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const data = [
  {
    title: "Lately",
    description:
      "Lately is an AI platform the turns videos and podcasts into daily social posts",
    testimonial:
      "Revmo allowed us to identify over 635 health & wellness investors and automatically send them emails. We then received 75 automatic responses requesting introductions to the founder, and setup 35 meetings. This led to us raising almost $2million!",
  },
  {
    title: "FemPatch",
    description:
      "FemPatch is a wellness platform and marketplace for women's health",
    testimonial:
      "Revmo allowed us to identify over 900 health & wellness investors and automatically send them emails. We then received 52 automatic responses requesting introductions to the founder, and setup 12 meetings. This led to us raising almost $1million!",
  },
  {
    title: "VC Firm",
    testimonial:
      "“Revmo generates qualified leads more easily and faster than anything I’ve ever seen!”",
  },
];

function TestimonialsCarousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = data.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 700, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "transparent",
        }}
      ></Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {data.map((step, index) => (
          <div key={step.title}>
            {Math.abs(activeStep - index) <= 2 ? (
              <CarouselInnerText>
                <Typography variant="h4">{step.title}</Typography>
                <Typography fontStyle="italic">{step.description}</Typography>
                <Typography>{step.testimonial}</Typography>
              </CarouselInnerText>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        sx={{ backgroundColor: "transparent" }}
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
      />
    </Box>
  );
}

const TestimonialsWrapper = styled.section`
  background-color: #f8f9fa;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
  @media only screen and (min-width: 768px) {
    padding: 116px 96px 116px;
  }
  @media only screen and (min-width: 600px) {
    padding: 116px 80px 116px;
  }
`;

function Testimonials() {
  return (
    <TestimonialsWrapper>
      <h1>Why our customers trust Revmo</h1>
      <TestimonialsCarousel />
      <Supporters />
    </TestimonialsWrapper>
  );
}

const SupportersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  img {
    max-height: 200px;
    padding-top: 2rem;
  }
`;

function Supporters() {
  return (
    <SupportersWrapper>
      <h2>We are proud to partner with</h2>
      <img
        src={supporterLogos}
        alt={"Code First Girls and Entrepreneurs Roundtable Accelerator logos"}
      />
    </SupportersWrapper>
  );
}

export default Testimonials;
