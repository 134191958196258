import React, { useState } from "react";
import styled from "styled-components";
import plusIcon from "assets/icons/plus-solid.svg";
import minusIcon from "assets/icons/minus-solid.svg";
import { Fade } from "react-awesome-reveal";

const maxWidth = `900px`;

const AccordionSection = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  transition: height 0.1s ease-in;
  height: 650px;
  ${(props) => {
    if (props.clicked !== false) {
      return `
      height: 900px;
      transition: height 0.1s ease-out;
      `;
    }
  }}
  @media (min-width: 768px) {
    height: 500px;
    ${(props) => {
      if (props.clicked !== false) {
        return `
        height: 700px;
        transition: height 0.1s ease-out;
        `;
      }
    }}
  }
`;

const Container = styled.div`
  margin-top: 2rem;
  position: absolute;
  border-top: 1px solid rgb(175, 175, 175);
  overflow: hidden;
  width: 95%;
  @media (min-width: 992px) {
    width: ${maxWidth};
    max-width: ${maxWidth};
  }
`;

const Wrap = styled.div`
  background: #ffffff;
  color: #000a33;
  border-bottom: 1px solid rgb(175, 175, 175);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  cursor: pointer;
  h1 {
    padding: 1.8rem;
    font-size: 1.2rem;
    color: #000a33;
    margin-bottom: 0;
  }
  span {
    margin-right: 1.5rem;
  }
`;

const Dropdown = styled.div`
  background: #fff;
  color: #00ffb9;
  width: 100%;
  height: auto;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 0 2rem;
  border-bottom: 1px solid #aeaeb1;
  border-top: 1px solid #aeaeb1;
  p {
    font-size: 1.2rem;
    padding: 1rem 0;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const Accordion = ({ data }) => {
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(false);
    }

    setClicked(index);
  };

  return (
    <AccordionSection clicked={clicked}>
      <Container>
        {data.map((item, index) => {
          return (
            <>
              <Wrap onClick={() => toggle(index)} key={index}>
                <h1>{item.question}</h1>
                <span>
                  {clicked === index ? (
                    <Icon src={minusIcon} />
                  ) : (
                    <Icon src={plusIcon} />
                  )}
                </span>
              </Wrap>
              {clicked === index ? (
                <Fade>
                  <Dropdown>
                    <p>
                      {item.answer}
                      {item.email && (
                        <a href={`mailto:${item.email}`}>{item.email}</a>
                      )}
                    </p>
                  </Dropdown>
                </Fade>
              ) : null}
            </>
          );
        })}
      </Container>
    </AccordionSection>
  );
};

export default Accordion;
