import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "semantic-ui-css/semantic.min.css";
import {
  RegisterUser,
  ConfirmUser,
  ThankYouForRegistering,
  ResumeSignUp,
  EnterPasswordToLogin,
} from "./components";
import { useUser, useCognitoAuth } from "hooks";
import { AuthBackground } from "components/Auth";
import { PageWrapperFullWidthRelative } from "components/Layout";
import { registerUser, confirmUser, resendConfirmationCode } from "./utils";
import {
  saveState,
  checkIfUserHasStartedRegisteringAnAccount,
} from "state/localStorage";
import history from "../../history";
import { Sidebar } from "components/Onboarding";

const LoginContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 768px) {
    flex-basis: 66.66%;
  }
`;

function Signup(props) {
  const previousSignUpAttemptEmail =
    checkIfUserHasStartedRegisteringAnAccount();
  const {
    alertMessage,
    setAlertMessage,
    loginState,
    setLoginState,
    customErrorMessages,
  } = useCognitoAuth(
    previousSignUpAttemptEmail ? "resumeSignUp" : "registerUser"
  );
  const [userLogin, setUserLogin] = useState({});

  const { login } = useUser();

  useEffect(() => {
    if (previousSignUpAttemptEmail) {
      setUserLogin({ email: previousSignUpAttemptEmail });
    }
  }, [setUserLogin, previousSignUpAttemptEmail, props.location.pathname]);

  const registerUserForSignUp = async (data) => {
    try {
      const { email, password } = data;
      const response = await registerUser(
        email.toLowerCase(),
        password,
        setAlertMessage
      );
      if (response.user) {
        setUserLogin({ email, password });
        setLoginState("userCanConfirmAccount");
        saveState("signupEmail", email.toLowerCase());
      }
    } catch (err) {
      console.log(err);
    }
  };

  const confirmUserAndLogIn = async (data) => {
    const { email, password } = userLogin;
    const userHasBeenConfirmed = await confirmUser(
      userLogin.email.toLowerCase(),
      data.confirmationCode,
      setAlertMessage,
      customErrorMessages
    );
    if (userHasBeenConfirmed && password) {
      localStorage.clear();
      await login(email.toLowerCase(), password);
      history.push("/onboarding");
    } else if (userHasBeenConfirmed) {
      setLoginState("enterPasswordToSignUp");
    }
  };

  const signUpWithNewAccount = async () => {
    try {
      localStorage.clear();
      setLoginState("registerUser");
      history.push("/sign-up");
    } catch (err) {
      console.log(err);
    }
  };

  const resumeSignUp = async (userEmail) => {
    try {
      await resendConfirmationCode(userEmail.toLowerCase());
      setLoginState("userCanConfirmAccount");
    } catch (err) {
      console.log(err);
    }
  };

  const loginUserWhoHasResumedSignUp = async (data) => {
    const { password, email } = data;
    try {
      await login(email.toLowerCase(), password);
      saveState("signupEmail", "");
      history.push("/");
    } catch (error) {
      console.log(error);
      setAlertMessage(customErrorMessages[error.message]);
    }
  };

  const returnToRegisterUser = () => setLoginState("registerUser");

  return (
    <PageWrapperFullWidthRelative>
      <AuthBackground>
        <Sidebar />
        <LoginContent>
          {loginState === "registerUser" && (
            <RegisterUser
              registerUserForSignUp={registerUserForSignUp}
              alertMessage={alertMessage}
            />
          )}
          {loginState === "thankYouForRegistering" && (
            <ThankYouForRegistering />
          )}
          {loginState === "userCanConfirmAccount" && (
            <ConfirmUser
              confirmUserAndLogIn={confirmUserAndLogIn}
              alertMessage={alertMessage}
              returnToRegisterUser={returnToRegisterUser}
            />
          )}
          {loginState === "resumeSignUp" && (
            <ResumeSignUp
              userEmail={userLogin.email}
              signUpWithNewAccount={signUpWithNewAccount}
              resumeSignUp={resumeSignUp}
            />
          )}
          {loginState === "enterPasswordToSignUp" && (
            <EnterPasswordToLogin
              userEmail={userLogin.email}
              alertMessage={alertMessage}
              logInUser={loginUserWhoHasResumedSignUp}
            />
          )}
        </LoginContent>
      </AuthBackground>
    </PageWrapperFullWidthRelative>
  );
}

export default Signup;
