import React from "react";
import {
  HeaderText,
  MidLargeSpacedText,
  SmallBlackBoldText,
  SmallGreenText,
  ExtraSmallBlackText,
} from "components/Typography";
import { ButtonPrimaryWithArrow } from "components/Button";
import {
  WhiteLargeFormInputHooksForm,
  WhiteSelectHooksForm,
} from "components/Input";
import {
  OnboardingForm,
  FooterWrapper,
  InformationTitleContainerProfessionalData,
  HeaderContainerMarginTop,
} from "components/Onboarding";
import { industryOptions, companySizeOptions, companyTypeOptions } from "utils";
import { useForm } from "react-hook-form";

function OnboardingCompanyData({ handleChangeCompanyData }) {
  const { register, errors, watch, handleSubmit } = useForm();

  const { companyName, industries, companySize, companyType } = watch();
  const disabled =
    !companyName?.length ||
    !industries?.length ||
    !companySize?.length ||
    !companyType?.length;

  return (
    <>
      <HeaderContainerMarginTop>
        <HeaderText>Add company details</HeaderText>
        <SmallGreenText>1 / 3</SmallGreenText>
      </HeaderContainerMarginTop>
      <MidLargeSpacedText>
        Registering you company with Revmo allows you to create a single
        repository for all of your company's contact data; You can invite
        members of your team, assign role based access and share contact
        information with ease between team members.
      </MidLargeSpacedText>
      <InformationTitleContainerProfessionalData>
        <SmallBlackBoldText>Company Details</SmallBlackBoldText>
        <ExtraSmallBlackText>*Denotes required field</ExtraSmallBlackText>
      </InformationTitleContainerProfessionalData>
      <OnboardingForm>
        <WhiteLargeFormInputHooksForm
          name="companyName"
          fieldName={"Company Name*"}
          error={errors}
          register={register}
        />
        <WhiteSelectHooksForm
          fieldName="Industry*"
          register={register}
          options={industryOptions}
          name="industries"
        />
        <WhiteSelectHooksForm
          fieldName="Company Size*"
          register={register}
          options={companySizeOptions}
          name="companySize"
        />
        <WhiteSelectHooksForm
          fieldName="Company Type*"
          register={register}
          options={companyTypeOptions}
          name="companyType"
        />
        <WhiteLargeFormInputHooksForm
          name="website"
          fieldName={"Website"}
          error={errors}
          register={register}
          placeholder="Begin with http:// or https:// or www."
        />
      </OnboardingForm>
      <FooterWrapper>
        <ButtonPrimaryWithArrow
          action={handleSubmit(handleChangeCompanyData)}
          buttonText={"Next"}
          disabled={disabled}
          padding={"11px 30px"}
        />
      </FooterWrapper>
    </>
  );
}

export default OnboardingCompanyData;
