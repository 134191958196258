import styled from "styled-components";

export const Container996 = styled.div`
  position: relative;
  max-width: 62.25rem;
  margin-right: auto;
  margin-left: auto;
  padding: 4rem 5.625rem 5.25rem;
  margin-top: 5.3125rem;
  margin-bottom: 5.3125rem;
  @media screen and (max-width: 479px) {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
`;

export const HeroBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 2rem;
  background-color: #eee;
`;

export const HeroTextWrapper = styled.div`
  max-width: 38.5rem;
  margin-left: 2rem;
  h1 {
    max-width: 37.5rem;
    margin-bottom: 1.5rem;

    font-size: 2.5rem;
    line-height: 1.1em;
    font-weight: 600;
    letter-spacing: -0.075rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.45em;
    font-weight: 500;
    letter-spacing: -0.0375rem;
    color: #707070;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  a {
    max-width: 16.875rem;
    text-decoration: none;
    color: #2860e1;
  }
`;

export const HeroTag = styled.div`
  background-color: rgba(40, 96, 225, 0.1);
  color: #2860e1;
  display: inline-block;
  margin-right: 8px;
  padding: 2px 10px 3px;
  border-radius: 8px;
  font-size: 0.925rem;
  font-weight: 600;
  text-decoration: none;
`;

export const DividerSmallMargin = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  height: 1px;
  background-color: #e0e0e0;
`;

export const PostTextWrapper = styled.div`
  max-width: 38.5rem;
  margin-left: 2rem;
  @media screen and (max-width: 991px) {
    margin-left: 0;
  }
  p {
    font-size: 16px;
    line-height: 1.6em;
  }
  figure > img {
    max-width: 100%;
  }
`;

export const PostHeaderH1 = styled.h1`
  max-width: 36rem;
  margin-top: 0.37rem;
  margin-bottom: 1.05rem;
  font-size: 3rem;
  line-height: 1.15em;
  font-weight: 600;
  letter-spacing: -0.1rem;
`;

export const PostBackButton = styled.div`
  position: absolute;
  left: -10%;
  a {
    font-size: 1rem;
    line-height: 1.4em;
    font-weight: 500;
    letter-spacing: -0.018755rem;
    padding: 0.75rem 1rem;
    border-style: none;
    border-radius: 0.3rem;
    background-color: rgba(29, 30, 31, 0.05);
    color: #212121;
    :hover {
      background-color: rgba(29, 30, 31, 0.1);
    }
  }
  @media screen and (max-width: 1050px) {
    left: 5%;
  }
`;

export const PostHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PostAuthorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PostHeaderText = styled.div`
  font-size: 0.625rem;
  line-height: 135%;
  font-weight: 600;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: #707070;
`;

export const PostHeaderDot = styled(PostHeaderText)`
  margin-right: 0.25rem;
  margin-left: 0.25rem;
`;

export const PostImage = styled.img`
  margin-bottom: 2rem;
  max-width: 100%;
`;

export const PostItemWrapper = styled.div`
  max-width: 38.5rem;
  margin-left: 2rem;
  @media screen and (max-width: 991px) {
    margin-left: 0;
  }
  :hover {
    div > img {
      opacity: 1;
    }
  }
  a {
    max-width: 100%;
    div > img {
      opacity: 0;
      transition: color 0.2s ease;
      :hover {
        opacity: 1;
      }
    }
    h2 {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      font-size: 2rem;
      line-height: 1.2em;
      font-weight: 500;
      letter-spacing: -0.05rem;
      text-decoration: underline solid transparent;
      transition: text-decoration 0.3s ease;
      :hover {
        text-decoration: underline solid;
      }
    }
  }
`;

export const PostItemDate = styled.div`
  font-size: 0.8rem;
  line-height: 135%;
  font-weight: 600;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: #707070;
`;

export const PostItemPreviewText = styled.div`
  overflow: hidden;
  max-height: 5rem;
  margin-top: 0;
  margin-bottom: 1em;
  padding-top: 0;
  color: #3f3f3f;
  font-size: 1.2rem;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: -0.025rem;
`;

export const PostItemLinkText = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  p {
    color: #2860e1;
    font-size: 1rem;
    line-height: 1.4em;
    font-weight: 600;
    letter-spacing: -0.00625rem;
    margin-bottom: 0;
  }
`;

export const PostItemImage = styled.img`
  max-width: 100%;
`;
