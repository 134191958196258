import React from "react";
import styled from "styled-components";
import {
  SectionWrapperTransparent,
  ContentWrapper,
} from "containers/LandingPage/Layout";

const HowItWorksGrid = styled.div`
  border-top: 1px solid #efeffd;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

function GridThreeSection({ content }) {
  return (
    <SectionWrapperTransparent>
      <ContentWrapper>
        <h2>{content.title}</h2>
        <p>{content.text}</p>
        <HowItWorksGrid>
          {content.cards.map((card, index) => {
            return <GridCard card={card} key={index} />;
          })}
        </HowItWorksGrid>
        <span>{content.span}</span>
      </ContentWrapper>
    </SectionWrapperTransparent>
  );
}

const GridCardWrapper = styled.figure`
  display: flex;
  flex-direction: column;
  flex-basis: 33.3333%;
  max-width: 33.3333%;
  align-items: center;
  margin: 1.2em;
  text-align: center;
  line-height: 1.2em;
  min-width: 100px;
  max-width: 300px;
`;

const GridCaption = styled.figcaption`
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 20px;
    margin: 0.5em 0px;
  }
  span {
    font-weight: 600;
    font-size: 16px;
    height: 2.4em;
  }
`;

const GridDot = styled.div`
  height: 25px;
  width: 25px;
  background-color: #000a33;
  border-radius: 50%;
  display: inline-block;
  margin: 10px 0 20px 0;
`;

const GridIcon = styled.div`
  img {
    height: 80px;
    width: 80px;
  }
`;

function GridCard({ card }) {
  return (
    <GridCardWrapper>
      {card.icon ? (
        <GridIcon>
          <img src={card.icon} />
        </GridIcon>
      ) : (
        <GridDot />
      )}
      <GridCaption>
        <h4>{card.title}</h4>
        {card.tagline ? <span>{card.tagline} </span> : null}
        <br />
        <p>{card.text}</p>
      </GridCaption>
    </GridCardWrapper>
  );
}

export default GridThreeSection;
