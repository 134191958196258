import { atom, selector } from "recoil";
import { fetchData } from "utils";

export const searchesRemaining = atom({
  key: "searchesRemaining",
  default: selector({
    key: "searchesRemaining/Default",
    get: async () => {
      return await fetchData("/search/count");
    },
  }),
});
