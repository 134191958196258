import React from "react";
import { GreyLinkText } from "components/Typography";
import styled from "styled-components";
import authBackground from "assets/images/auth-background.png";
import { AuthButton } from "components/Button";
import { WhiteLargeFormInputHooksForm } from "components/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "utils";

export const AuthFormWrapper = styled.div`
  width: 450px;
  background: #f7f6f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px 40px 35px 40px;
  z-index: 50;
  margin-top: 100px;
  margin-bottom: 80px;
  box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
  background: #fffefb;
  border: 1px solid #f0f2f4;
  box-sizing: border-box;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.04),
    0px 6px 10px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  button {
    width: 100%;
  }
  h1 {
    word-wrap: break-word;
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 90%;
    padding: 40px 25px 29px 25px;
  }
  @media (max-width: 321px) {
    width: 90%;
    margin-top: 50px;
  }
`;

export const AuthForm = styled.form`
  margin-top: 18px;
  width: 100%;
`;

export const AuthAlternateOptionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 2px solid #e3e6e9;
  padding: 20px 20px 0 20px;
  margin-top: 25px;
  p {
    margin: 0 10px;
  }
  @media (max-width: 480px) {
    padding: 20px 10px 0 10px;
  }
  @media (max-width: 321px) {
    padding: 20px 0px 0 0px;
  }
`;

export const AuthAlternateOptionsRowMarginTop = styled(AuthAlternateOptionsRow)`
  margin-top: 25px;
`;

export const AuthAlternateOptionsRowNoPadding = styled(AuthAlternateOptionsRow)`
  padding: 20px 0;
  @media (max-width: 480px) {
    padding: 20px 0;
  }
  @media (max-width: 321px) {
    padding: 20px 0;
  }
`;

export const AuthBackground = styled.div`
  background: url(${authBackground});
  display: flex;
  position: absolute;
  align-items: stretch;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #cce2ff;
  background-size: cover;
  background-repeat: no-repeat;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  textarea {
    font-family: "Montserrat", sans-serif;
  }
`;

export function LogInToRevmo({ alertMessage, logInUser, children }) {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(loginSchema),
  });
  if (
    alertMessage?.includes("username") ||
    alertMessage?.includes("email") ||
    alertMessage?.includes("Email")
  ) {
    errors.email = {};
    errors.email.message = alertMessage;
  } else if (
    alertMessage?.includes("password") ||
    alertMessage?.includes("Password") ||
    alertMessage?.includes("exceeded")
  ) {
    errors.password = {};
    errors.password.message = alertMessage;
  }

  return (
    <AuthForm>
      <WhiteLargeFormInputHooksForm
        fieldName="Email"
        register={register}
        error={errors}
        name="email"
        bold
      />
      <WhiteLargeFormInputHooksForm
        fieldName="Password"
        register={register}
        error={errors}
        name="password"
        bold
        type="password"
      />
      {children}
      <AuthButton onClick={handleSubmit(logInUser)}>Log in</AuthButton>
    </AuthForm>
  );
}

export const AuthFormWrapperBackButton = styled(AuthFormWrapper)`
  padding-bottom: 50px;
  padding-top: 25px;
  h1 {
    margin-bottom: 20px;
    margin-top: 10px;
  }
`;

const AuthBackWrapper = styled.div`
  display: flex;
  width: 100%;
  span {
    display: flex;
    padding: 1rem 0;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export function AuthBackButton({ goBack }) {
  return (
    <AuthBackWrapper>
      <span onClick={goBack}>
        <GreyLinkText>Back</GreyLinkText>
      </span>
    </AuthBackWrapper>
  );
}
