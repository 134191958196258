import React from "react";
import styled from "styled-components";
import { SmallBlackNormalTextUnderlined } from "components/Typography";
import { AuthButton } from "./";
import arrowWhite from "../../assets/icons/arrow-white.svg";
import arrowBlue from "../../assets/icons/arrow-blue.svg";
import arrowBlueLeft from "assets/icons/arrow-blue-left.svg";

const AuthButtonContentContainer = styled.div`
  min-width: 76px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  gap: 0.5rem;
  img {
    margin-left: 8px;
    width: 12px;
    height: 11px;
  }
  p {
    margin: 0;
    color: white;
  }
  @media only screen and (max-width: 767.999px) {
    justify-content: center;
  }
`;

const SearchBarIcon = styled.img`
  pointer-events: none;
`;

export function AuthButtonNoArrow({
  buttonText,
  disabled,
  action,
  padding,
  border,
}) {
  return (
    <AuthButton
      disabled={disabled}
      onClick={async () => {
        action ? await action() : null;
      }}
      padding={padding}
      border={border}
    >
      <AuthButtonContentContainer>
        <p>{buttonText}</p>
      </AuthButtonContentContainer>
    </AuthButton>
  );
}

export function AuthButtonWithArrow({
  buttonText,
  disabled,
  action,
  padding,
  border,
}) {
  return (
    <AuthButton
      disabled={disabled}
      onClick={async () => {
        action ? await action() : null;
      }}
      padding={padding}
      border={border}
    >
      <AuthButtonContentContainer>
        <p>{buttonText}</p>
        <SearchBarIcon src={arrowWhite} />
      </AuthButtonContentContainer>
    </AuthButton>
  );
}

const AuthButtonSkipStyle = styled.button`
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 30px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  background: transparent;
  width: 100%;
  div > p {
    color: #000a33;
    text-decoration: underline;
  }
  @media only screen and (max-width: 767.999px) {
    background: rgb(239, 239, 239);
  }
`;

export function AuthButtonSkip({
  buttonText,
  disabled,
  action,
  padding,
  border,
}) {
  return (
    <AuthButtonSkipStyle
      disabled={disabled}
      onClick={async () => {
        action ? await action() : null;
      }}
      padding={padding}
      border={border}
    >
      <AuthButtonContentContainer>
        <SmallBlackNormalTextUnderlined>
          {buttonText}
        </SmallBlackNormalTextUnderlined>
        <SearchBarIcon src={arrowBlue} />
      </AuthButtonContentContainer>
    </AuthButtonSkipStyle>
  );
}

export function AuthButtonBack({ action, padding, border }) {
  return (
    <AuthButtonSkipStyle onClick={action} padding={padding} border={border}>
      <AuthButtonContentContainer>
        <SearchBarIcon src={arrowBlueLeft} />
        <SmallBlackNormalTextUnderlined> Back</SmallBlackNormalTextUnderlined>
      </AuthButtonContentContainer>
    </AuthButtonSkipStyle>
  );
}
