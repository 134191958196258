import React from "react";
import { PolicyBody } from "./Layout";
import { LandingNavbar } from "components/Navbar";
import styled from "styled-components";
import PrivacyPolicyTerms from "components/PrivacyPolicyTerms";
import { LandingFooter } from "components/LandingPage";

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  align-items: center;
`;

const PrivacyPageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
  padding: 0 0 2rem 0;
  background-color: #f7f6f8;
`;

function PrivacyPolicy() {
  return (
    <ContentWrapper>
      <LandingNavbar />
      <PrivacyPageWrapper>
        <PolicyBody>
          <PrivacyPolicyTerms />
        </PolicyBody>
      </PrivacyPageWrapper>
      <LandingFooter />
    </ContentWrapper>
  );
}

export default PrivacyPolicy;
