import React from "react";
import styled from "styled-components";
import blueChevron from "../../assets/icons/chevron-blue.svg";
import * as _ from "lodash";

const PaginationContainer = styled.div`
  display: ${(props) => (props.maxPage <= 1 ? "none" : "flex")};
  justify-content: center;
`;

const PaginationUnit = styled.button`
  height: 26px;
  min-width: 26px;
  border-radius: 5px;
  background: white;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: unset;
  outline: none;
  p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0;
    letter-spacing: 0.311111px;
    color: ${(props) => props.theme.brandColor};
  }
  ${(props) => {
    if (props.currentPage === props.index && props.currentPage !== undefined) {
      return `
            background: ${props.theme.brandColor};
            border-radius: 100%;
  
            p {
              color: #FFFFFF;
            }
          `;
    }
  }}
  ${(props) => {
    if (props.hide) {
      return `
            display: none;
          `;
    }
  }}
  ${(props) => {
    if (props.index === props.currentPage) {
      return `

      `;
    }
  }}
`;

const PaginationUnitMaxPage = styled(PaginationUnit)``;

const PaginationGoBack = styled(PaginationUnit)`
  margin-right: 10px;
`;

const PaginationGoForward = styled(PaginationUnit)`
  margin-left: 10px;
`;

const LeftFacingImage = styled.img`
  height: 10px;
  width: auto;
  margin-right: 8px;
`;

const RightFacingImage = styled.img`
  transform: rotate(-180deg);
  height: 10px;
  width: auto;
  margin-left: 8px;
`;

const InternalButtonBoxOuter = styled.div`
  max-width: 102px;
  overflow: hidden;
`;

const InternalButtonBoxInner = styled.div`
  width: 100%;
  display: flex;
  ${(props) => {
    if (
      props.currentPage > 2 &&
      (props.currentPage < props.maxPage - 1 ||
        props.currentPage !== props.maxPage)
    ) {
      return `
            transform: translateX(-${34 * (props.currentPage - 2)}px);
          `;
    } else if (props.currentPage === props.maxPage) {
      return `
          transform: translateX(-${34 * (props.maxPage - 3)}px);
          `;
    }
  }}
`;

function Pagination({ nextPage, prevPage, jumpPage, currentPage, maxPage }) {
  // Added nextPage id for testing
  return (
    <PaginationContainer maxPage={maxPage}>
      <PaginationGoBack onClick={() => prevPage()} currentPage={currentPage}>
        <LeftFacingImage src={blueChevron} />
        <p>Prev</p>
      </PaginationGoBack>
      <InternalButtonBoxOuter>
        <InternalButtonBoxInner currentPage={currentPage} maxPage={maxPage}>
          {_.times(maxPage, (index) => {
            index;
            return (
              <PaginationUnit
                currentPage={currentPage}
                key={index + 1}
                index={index + 1}
                onClick={() => jumpPage(index + 1)}
              >
                <p>{index + 1}</p>
              </PaginationUnit>
            );
          })}
        </InternalButtonBoxInner>
      </InternalButtonBoxOuter>
      <PaginationUnitMaxPage
        currentPage={currentPage}
        index={maxPage}
        hide={maxPage - currentPage > 1 && maxPage > 3 ? false : true}
        onClick={() => jumpPage(maxPage)}
      >
        <p>... {maxPage}</p>
      </PaginationUnitMaxPage>
      <PaginationGoForward id="nextPage" onClick={() => nextPage()}>
        <p>Next</p>
        <RightFacingImage src={blueChevron} />
      </PaginationGoForward>
    </PaginationContainer>
  );
}

export default Pagination;
export { default as PaginationWithHook } from "./PaginationWithHook";
