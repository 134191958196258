import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BookTwoToneIcon from "@mui/icons-material/BookTwoTone";
import NewspaperTwoToneIcon from "@mui/icons-material/NewspaperTwoTone";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";

const LandingNavbarDropdownWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const LandingNavbarDropdownContainer = styled.div`
  width: 100vw;
  top: 0px;
  position: absolute;
  transition: all ease-in-out 0.15s;
  transform: translateY(0);
  display: flex;
  justify-content: center;
  visibility: visible;

  z-index: 1001;

  a {
    font-family: "Montserrat", sans-serif !important;
  }

  ${(props) => {
    if (!props.showDropdown) {
      return `
            visibility: hidden;
            transition: all ease-in-out 0.15s;
            transform: translateY(-25px);
          `;
    }
  }}
  @media (max-width: 991.9px) {
    display: none;
  }
`;

const LandingNavbarDropdownInner = styled.div`
  max-width: 1200px;
  display: flex;
  background-color: #fff;
  opacity: 1;
  padding: 50px;
  box-shadow: 0 6px 20px 0 rgb(60 63 64 / 7%);
`;

const LandingNavbarDropdownLinks = styled.ul`
  padding-top: 0;
  @media (min-width: 992px) {
    flex: 1;
    padding: 0;
    list-style: none;
    width: 250px;
    margin-right: 100px;
  }
`;

const DropdownLinkItem = styled.div`
  display: flex;
  align-items: flex-start;
  li {
    font-weight: 600;
  }
  :hover {
    text-decoration: none;
    li {
      color: #0069ff;
    }
  }
  svg {
    width: 35px;
    height: auto;
    margin-right: 13px;
  }
  @media (min-width: 992px) {
    font-size: 14px;
    color: #3c3f40;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 40px;
    transition: color 0.1s ease-in-out;
  }
`;

const DropdownLinkItemContentText = styled.span`
  @media (min-width: 992px) {
    font-size: 14px;
    color: #b1b2b3;
    line-height: 21px;
  }
`;

const DropdownLinkItemContent = styled.div``;

export const LandingNavbarDropdown = ({ showDropdown }) => {
  return (
    <LandingNavbarDropdownWrapper>
      <LandingNavbarDropdownContainer showDropdown={showDropdown}>
        <LandingNavbarDropdownInner>
          <LandingNavbarDropdownLinks>
            <Link to="/blog">
              <DropdownLinkItem>
                <BookTwoToneIcon style={{ color: "#0069FF" }} />
                <DropdownLinkItemContent>
                  <li>Blog</li>
                  <DropdownLinkItemContentText>
                    News, stories and latest thinking on networking, data and
                    more{" "}
                  </DropdownLinkItemContentText>
                </DropdownLinkItemContent>
              </DropdownLinkItem>
            </Link>
          </LandingNavbarDropdownLinks>
          <LandingNavbarDropdownLinks>
            <Link to="/press">
              <DropdownLinkItem>
                <NewspaperTwoToneIcon style={{ color: "#0069FF" }} />
                <DropdownLinkItemContent>
                  <li>Press</li>
                  <DropdownLinkItemContentText>
                    The latest press releases from Revmo
                  </DropdownLinkItemContentText>
                </DropdownLinkItemContent>
              </DropdownLinkItem>
            </Link>
          </LandingNavbarDropdownLinks>
          <LandingNavbarDropdownLinks>
            <Link to="/updates">
              <DropdownLinkItem>
                <CategoryTwoToneIcon
                  style={{
                    color: "#0069FF",
                    fontWeight: "700",
                    fontFamily: "Montserrat",
                  }}
                />
                <DropdownLinkItemContent>
                  <li>Product Updates</li>
                  <DropdownLinkItemContentText>
                    Get updates on our product, best practices and tips{" "}
                  </DropdownLinkItemContentText>
                </DropdownLinkItemContent>
              </DropdownLinkItem>
            </Link>
          </LandingNavbarDropdownLinks>
        </LandingNavbarDropdownInner>
      </LandingNavbarDropdownContainer>
    </LandingNavbarDropdownWrapper>
  );
};

export default LandingNavbarDropdown;
