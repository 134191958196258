const formatManualContactUpload = (data, contactAddress) => {
  const {
    firstName,
    lastName,
    personalEmail,
    company,
    position,
    companyEmail,
    startYear,
    startMonth,
    endYear,
    endMonth,
    personUuid,
  } = data;

  const { location } = contactAddress;

  const contactData = {
    first_name: firstName,
    last_name: lastName,
    person_uuid: personUuid,
    positions: [
      {
        is_active: endMonth ? false : true,
        org: company,
        role: [
          {
            role_name: position,
          },
        ],
        role_description: position,
        company_email_addresses: [],
      },
    ],
    personal_email_addresses: [],
  };

  if (companyEmail) {
    const emailObject = { email_address: companyEmail };
    contactData.positions[0].company_email_addresses = [emailObject];
  }

  if (personalEmail) {
    const emailObject = { email_address: personalEmail };
    contactData.personal_email_addresses = [emailObject];
  }

  if (location) {
    contactData.positions[0].org = {
      ...contactData.positions[0].org,
      additional_locations: [location],
    };
  }

  if (startMonth || endMonth) {
    contactData.positions[0].start_date = startMonth
      ? `${startYear}-${startMonth.value}-01`
      : null;
    contactData.positions[0].end_date = endMonth
      ? `${endYear}-${endMonth.value}-01`
      : null;
  }

  if (personUuid) {
    contactData.person_uuid = personUuid;
  }

  return [contactData];
};

export default formatManualContactUpload;
