import React from "react";
import styled from "styled-components";
import {
  TableTextHeader,
  TableHeaderText,
  UserImage,
  UserImageContainer,
  RequestStatusTableColumUserInfo,
  RequestNumberContainer,
  RequestNumberButtonIcon,
  RequestBodyText,
  RequestStatusTableButtonWrapper,
  TableTextAboutPerson,
  TableTextReasonCode,
} from ".";
import { DarkGreySquareButtonRequest } from "components/Button";
import { AnonymousUserRequestsTable } from "components/Assets/Image";
import { TableTextAnonymousUser } from "components/Typography";
import { useProfileImage } from "hooks/useProfileImage";

const RequestStatusTableUserDetails = styled.div`
  margin-left: 20.5px;
  margin-top: 8px;
  ${(props) => {
    if (props.column === "introduced to") {
      return `
            width: 138px;
            margin-right: 30px;
        `;
    }
  }}
  @media (max-width: 480px) {
    margin: 6px 0 0 12.5px;
  }
`;

const PersonContentWrapper = styled.div`
  display: flex;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export function PersonInvolvedInIntro({
  person,
  index,
  requestType,
  request,
  column,
  children,
  width,
}) {
  const image = useProfileImage(person);

  return (
    <RequestStatusTableColumUserInfo width={width}>
      {column === "introduced by" ? (
        <TableHeaderText index={index}>
          {requestType === "COMPLETED" && "USER"}
          {requestType === "PENDING" && "WAITING ON"}
          {requestType === "REJECTED" && "LOOKS LIKE"}
        </TableHeaderText>
      ) : (
        <TableHeaderText index={index}>
          {requestType === "COMPLETED" && "TO"}
          {requestType === "PENDING" && "TO"}
          {requestType === "REJECTED" && "INTRODUCING YOU TO"}
        </TableHeaderText>
      )}
      {image ? (
        <UserImageContainer>
          <UserImage src={image} />
        </UserImageContainer>
      ) : (
        <AnonymousUserRequestsTable />
      )}
      <PersonContentWrapper>
        <RequestStatusTableUserDetails column={column}>
          <>
            {person ? (
              <>
                <TableTextHeader marginBottom>
                  {person?.first_name} {person?.last_name}
                </TableTextHeader>
                <span>
                  {column === "introduced by" || column === "introduced to" ? (
                    person.positions.length > 0 && (
                      <TableTextAboutPerson>
                        {person?.positions[0]?.role_description} @{" "}
                        {person?.positions[0]?.org?.org_name}
                      </TableTextAboutPerson>
                    )
                  ) : (
                    <TableTextAboutPerson>
                      For {request?.reason_code}
                    </TableTextAboutPerson>
                  )}
                </span>
              </>
            ) : (
              <TableTextAnonymousUser>
                This user has opted to remain anonymous
              </TableTextAnonymousUser>
            )}
          </>
        </RequestStatusTableUserDetails>
        {children}
      </PersonContentWrapper>
    </RequestStatusTableColumUserInfo>
  );
}

const ScoreAndTagContainer = styled.div`
  height: 100%;
  display: flex;
  padding-top: 8px;
  @media (max-width: 480px) {
    margin-left: 12.5px;
  }
`;

export function PersonYouWishToBeIntroducedTo({
  person,
  index,
  requestType,
  column,
  request,
}) {
  return (
    <PersonInvolvedInIntro
      person={person}
      column={column}
      index={index}
      requestType={requestType}
      width={450}
      request={request}
    >
      <ScoreAndTagContainer>
        <RequestNumberContainer>
          <RequestNumberButtonIcon>
            {person?.revmo_score}
          </RequestNumberButtonIcon>
          <RequestBodyText>of 800</RequestBodyText>
        </RequestNumberContainer>
        <RequestStatusTableButtonWrapper>
          <DarkGreySquareButtonRequest margin="0 0 0 53px">
            Contact
          </DarkGreySquareButtonRequest>
          <RequestStatusTableUserDetails>
            <TableTextReasonCode>{request?.reason_code}</TableTextReasonCode>
          </RequestStatusTableUserDetails>
        </RequestStatusTableButtonWrapper>
      </ScoreAndTagContainer>
    </PersonInvolvedInIntro>
  );
}
