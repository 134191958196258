import React from "react";
import styled from "styled-components";
import {
  SmallBlackBoldText,
  MidSizedGreyTextSpaced,
} from "components/Typography";
import {
  SearchResultsTableRow,
  SearchResultsTableSkeletonComponent,
} from "./index";
import Pagination from "components/Pagination";
import { PeopleYouMightWantToConnectWith } from "components/Card";
import { Link } from "react-router-dom";

const SearchAndPagination = styled.div`
  padding-bottom: 85px;
  @media (max-width: 870px) {
    margin-top: 10px;
  }
  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

const SearchResultsTableWrapper = styled.div`
  padding: 43px 0 15px;
  border-bottom: 1px solid #e3e6e9;
  margin-bottom: 31px;
  min-height: 116px;
  @media (max-width: 480px) {
    padding-top: 70px;
  }
`;

const DesktopTableWrapper = styled.div`
  @media (max-width: 870px) {
    display: none;
  }
`;

const MobileTableWrapper = styled.div`
  @media (min-width: 871px) {
    display: none;
  }
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 30px;
`;

function SearchResultsTable({
  nextPage,
  prevPage,
  jumpPage,
  currentPage,
  maxPage,
  searchResult,
  viewIntroductionPathwayToNetworkMember,
  searchRequestHistory,
  showAddContactModal,
  error,
}) {
  return (
    <SearchAndPagination className="walkthrough-step-3">
      <SearchResultsTableWrapper>
        <SearchResultsFound
          currentPage={currentPage}
          maxPage={maxPage}
          searchResult={searchResult}
          searchRequestHistory={searchRequestHistory}
          error={error}
        />
        <DesktopTableWrapper className="walkthrough-step-3">
          {searchResult?.people &&
            !searchResult?.loading &&
            searchResult.people.map((result, index) => {
              return (
                <SearchResultsTableRow
                  result={result}
                  key={result.uuid}
                  viewIntroductionPathwayToNetworkMember={
                    viewIntroductionPathwayToNetworkMember
                  }
                  index={index}
                  showAddContactModal={showAddContactModal}
                />
              );
            })}
        </DesktopTableWrapper>
        <MobileTableWrapper>
          {searchResult?.people && !searchResult?.loading && (
            <PeopleYouMightWantToConnectWith
              people={searchResult.people}
              viewIntroductionPathwayToNetworkMember={
                viewIntroductionPathwayToNetworkMember
              }
              showAddContactModal={showAddContactModal}
            />
          )}
        </MobileTableWrapper>
      </SearchResultsTableWrapper>
      {!searchResult?.loading && (
        <Pagination
          nextPage={nextPage}
          prevPage={prevPage}
          jumpPage={jumpPage}
          currentPage={currentPage}
          maxPage={maxPage}
        />
      )}
    </SearchAndPagination>
  );
}

const SearchResultsFoundWrapper = styled.div`
  margin-bottom: 38px;
`;

function SearchResultsFound({
  currentPage,
  maxPage,
  searchResult,
  searchRequestHistory,
  error,
}) {
  return (
    <>
      <SearchResultsFoundWrapper>
        <SearchResultsFoundNumberOfResults
          searchResult={searchResult}
          currentPage={currentPage}
          maxPage={maxPage}
        />
        <SearchResultsFoundEnterSearchCallToAction
          searchResult={searchResult}
          searchRequestHistory={searchRequestHistory}
        />

        <SearchResultExeceededFreeWeeklySearch error={error} />
        <SearchResultsFoundNoResults searchResult={searchResult} />
      </SearchResultsFoundWrapper>
      <SearchResultsFoundLoading searchResult={searchResult} error={error} />
    </>
  );
}

function SearchResultsFoundNumberOfResults({
  searchResult,
  currentPage,
  maxPage,
}) {
  return (
    <>
      {(searchResult?.results || searchResult?.loading) && (
        <>
          <SmallBlackBoldText>Search Results</SmallBlackBoldText>
          <MidSizedGreyTextSpaced>
            {!searchResult.loading
              ? `Showing page ${currentPage} of ${maxPage} `
              : "Searching Revmo..."}
            <br />
          </MidSizedGreyTextSpaced>
        </>
      )}
    </>
  );
}

function SearchResultsFoundEnterSearchCallToAction({
  searchResult,
  searchRequestHistory,
}) {
  return (
    <>
      {!searchResult?.results?.length && !searchRequestHistory && (
        <MidSizedGreyTextSpaced>
          Select from the parameters above and press SEARCH to get started
        </MidSizedGreyTextSpaced>
      )}
    </>
  );
}

function SearchResultsFoundLoading({ searchResult, error }) {
  const userHasCalledSearchWithoutAnErrorThrown = !searchResult.loading;
  error?.detail !== "Exceeded max search";
  return (
    <>
      {!userHasCalledSearchWithoutAnErrorThrown && (
        <DesktopTableWrapper>
          <SearchResultsTableSkeletonComponent />
        </DesktopTableWrapper>
      )}
    </>
  );
}

const ExceededUsageContainer = styled.div`
  display: flex;
  p,
  a {
    font-size: 14px;
    color: #313237;
    line-height: 26px;
    margin: 0;
  }
  a {
    color: #5374ff;
  }
`;

function SearchResultExeceededFreeWeeklySearch({ error }) {
  return (
    <>
      {error?.detail === "Exceeded max search" && (
        <ExceededUsageContainer>
          <MidSizedGreyTextSpaced>
            You have exceed your 10 free weekly searches.
          </MidSizedGreyTextSpaced>
          <Link to="/settings">&nbsp;Click here&nbsp;</Link>
          <MidSizedGreyTextSpaced>
            to upgrade to a subscription and enjoy unlimited searches.
          </MidSizedGreyTextSpaced>
        </ExceededUsageContainer>
      )}
    </>
  );
}

function SearchResultsFoundNoResults({ searchResult }) {
  return (
    <>
      {searchResult?.results === 0 && !searchResult.loading && (
        <MidSizedGreyTextSpaced>
          It appears no one in your network matches your search. Consider trying
          different parameters?
        </MidSizedGreyTextSpaced>
      )}
    </>
  );
}

export default SearchResultsTable;
