import React from "react";
import styled from "styled-components";
import revmoLaptopMockup from "assets/images/revmo-mockup-meeting.jpg";

const HeroWrapper = styled.div`
  min-width: 100%;
  height: 70vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 700px;
  background-image: url(${revmoLaptopMockup});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
`;

const Overlay = styled.div`
  min-height: 480px;
  height: 100%;
  width: 100%;
  font-size: 56px;
  font-weight: bold;
  color: white;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.9447129193474265) 12%,
    rgba(0, 0, 0, 0.2528361686471463) 100%
  );
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 500px) {
    min-height: 0px;
    img {
      padding: 20% 0 20% 0;
    }
  }
  @media (max-width: 870px) {
    min-height: 300px;
    line-height: normal;
    text-align: center;
  }
`;

const HeroImage = () => {
  return (
    <HeroWrapper>
      <Overlay>
        <span>Getting Started with Revmo</span>
      </Overlay>
    </HeroWrapper>
  );
};

export default HeroImage;
