import React from "react";
import { LandingPageContainer, LandingFooter } from "components/LandingPage";
import { LandingNavbar } from "components/Navbar";
import {
  GettingStartedSection,
  HeroImage,
  InitialSetup,
  HowTo,
  ContactUpload,
} from "./components";

const GettingStarted = () => {
  return (
    <LandingPageContainer>
      <LandingNavbar />
      <HeroImage />
      <GettingStartedSection />
      <InitialSetup />
      <HowTo />
      <ContactUpload />
      <LandingFooter />
    </LandingPageContainer>
  );
};

export default GettingStarted;
