import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// material-ui
import { styled, useTheme } from "@mui/material/styles";

import { useMediaQuery } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  mainListItems,
  secondaryListItems,
} from "containers/DashboardNew/components/listItems";
import AddIcon from "@mui/icons-material/Add";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

// project imports
import { drawerWidth } from "containers/DashboardNew/theme";
import LogoSection from "components/DashboardNew/Layout/LogoSection";
import OnboardingChecklist from "./OnboardingChecklist";
import { loadState } from "state/localStorage";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
    ...(open &&
      "@media(maxHeight: 1057px)" && {
        height: "100vh",
      }),
  },
}));

const Sidebar = ({
  drawerOpen,
  drawerToggle,
  window,
  toggleAdvancedSearch,
}) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const userHasCompletedOnboarding = loadState("completedOnboarding");

  const drawer = (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
          <LogoSection />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          {mainListItems}
          <Divider sx={{ my: 1 }} />
          {secondaryListItems}
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          {mainListItems}
          <Divider sx={{ my: 1 }} />
          {secondaryListItems}
        </Box>
      </MobileView>
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      {matchUpMd ? (
        <Drawer variant="permanent" open={drawerOpen}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
            }}
          >
            <IconButton onClick={toggleAdvancedSearch}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingLeft: 0,
              paddingRight: 0,
              margin: 0,
              px: [1],
            }}
          >
            <List component="nav">
              {mainListItems}
              <Divider sx={{ my: 1 }} />
              {secondaryListItems}
            </List>

            {!drawerOpen ? (
              <Tooltip placement="top" title="Add Contacts">
                <IconButton
                  component={Link}
                  to={{ pathname: "/contacts", state: { addContacts: true } }}
                  sx={{
                    height: "55px",
                    backgroundColor: "#0069ff",
                    marginBottom: "3px",
                    color: "white",
                    boxShadow:
                      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                    "&:hover": {
                      backgroundColor: "#00255a",
                    },
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            ) : (
              !userHasCompletedOnboarding && (
                <Grid container>
                  <Grid item xs={12}>
                    <OnboardingChecklist />
                  </Grid>
                </Grid>
              )
            )}
          </Box>
        </Drawer>
      ) : (
        <Box
          component="nav"
          sx={{
            flexShrink: { md: 0 },
            width: matchUpMd ? drawerWidth : "auto",
            display: { xs: "block", md: "none" },
          }}
          aria-label="mailbox folders"
        >
          <MuiDrawer
            container={container}
            variant={matchUpMd ? "persistent" : "temporary"}
            anchor="left"
            open={drawerOpen}
            onClose={drawerToggle}
            sx={{
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                background: theme.palette.background.default,
                color: theme.palette.text.primary,
                borderRight: "none",
                [theme.breakpoints.up("md")]: {
                  top: "88px",
                },
              },
            }}
            ModalProps={{ keepMounted: true }}
            color="inherit"
          >
            {drawer}
          </MuiDrawer>
        </Box>
      )}
    </>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
