import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useUser } from "hooks";

const PrivateRouteOnboarding = ({
  path,
  component: Component,
  exact,
  ...props
}) => {
  let { user } = useUser();
  let restricted = false;
  let exactPath = exact ? exact : false;

  if (
    user &&
    user?.attributes &&
    !user?.attributes["custom:onboarding_completed"] &&
    path !== "/onboarding"
  ) {
    return <Redirect to="/onboarding" />;
  } else if (
    user &&
    user?.attributes &&
    user?.attributes["custom:onboarding_completed"] &&
    path === "/onboarding"
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      exact={exactPath}
      path={path}
      render={() =>
        !restricted && user ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRouteOnboarding;
