import React, { useEffect } from "react";
import {
  LargeBoldText,
  MidLargeSpacedText,
  MidLargeSpacedTextMarginTop,
} from "../Typography";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  AddContactsContent,
  ProfileModalContainerSuccess,
  SuccessModalHeader,
  SuccessIconAndTextWrapper,
} from "./index";
import styled, { keyframes } from "styled-components";
import Button from "@mui/material/Button";
import closeIcon from "../../assets/icons/close-icon.svg";

export function CloudspongeUploadModal({
  displayModal,
  setDisplayModal,
  contactData,
}) {
  const { contactsWithNames, contactsWithoutNames } = contactData;

  useEffect(() => {
    window.plausible("Contact Upload", { props: { method: "Cloudsponge" } });
  }, []);

  return (
    <>
      {displayModal && (
        <ProfileModalPageWrapper>
          <DarkBlurredBackground onClick={() => setDisplayModal(null)} />
          <ProfileModalContainerSuccess>
            <CloseIconImage
              src={closeIcon}
              onClick={() => setDisplayModal(null)}
            />
            <AddContactsContent>
              <SuccessModalHeader>
                <SuccessIconAndTextWrapper>
                  <LargeBoldText>
                    Thank you for uploading your contacts
                  </LargeBoldText>
                </SuccessIconAndTextWrapper>
              </SuccessModalHeader>
              {!!contactsWithNames.length && !contactsWithoutNames.length && (
                <>
                  <EmailSentAnimation />
                </>
              )}
              {!!contactsWithNames.length && !!contactsWithoutNames.length && (
                <>
                  <MidLargeSpacedText>
                    You have succesfully uploaded {contactsWithNames.length}{" "}
                    contacts. Unfortunately, some of your contacts could not
                    uploaded, as they did not have a first name or a last name.
                    Please add a name to the following contacts and try again:
                  </MidLargeSpacedText>
                  <MidLargeSpacedTextMarginTop>
                    {contactsWithoutNames.join(", ")}
                  </MidLargeSpacedTextMarginTop>
                </>
              )}
              {!contactsWithNames.length && (
                <>
                  <MidLargeSpacedText>
                    Unfortunately, some of your contacts could not be uploaded,
                    as they did not have a first name or a last name. Please add
                    a name to the following contacts and try again:
                  </MidLargeSpacedText>
                  <MidLargeSpacedTextMarginTop>
                    {contactsWithoutNames.join(", ")}
                  </MidLargeSpacedTextMarginTop>
                </>
              )}
              <Button
                variant="contained"
                color="secondary"
                disabled={false}
                onClick={() => setDisplayModal(null)}
                sx={{ mt: 2 }}
              >
                Continue
              </Button>
            </AddContactsContent>
          </ProfileModalContainerSuccess>
        </ProfileModalPageWrapper>
      )}
    </>
  );
}

const transformB = keyframes`
  50% {
    transform: scale(1.5, 1.5);
    background: #0069ff;
  }
  100% {
    background: #0069ff;
  }
`;

const transformBA = keyframes`
  100% {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
  }
`;

const transformS = keyframes`
  50% {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
`;

const moveL = keyframes`
  100% {
    transform: translateX(220px) rotateY(90deg);
  }
`;

const moveR = keyframes`
  0% {
    transform: translateX(-220px) rotateY(90deg);
  }
  50% {
    transform: translateX(0) rotateY(0);
  }
`;

const Container = styled.div`
  position: relative;
  top: 50%;
  left: calc(50% - 240px);
  width: 540px;
`;

const Animation = styled.div`
  width: 540px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Mail = styled.div`
  width: 40px;
  height: 30px;
  border-radius: 5% 5%;
  border: 2px solid black;
  position: relative;
  animation: ${transformS} 0.3s linear;
  &:before {
    content: "";
    position: absolute;
    top: 5px;
    right: 5px;
    width: 7px;
    height: 6px;
    background: black;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: 15px;
    height: 4px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
  }
`;

const MailAnimation = styled.div`
  width: 40px;
  height: 30px;
  border-radius: 5% 5%;
  border: 2px solid black;
  position: relative;
  animation: ${transformS} 0.3s linear;
  margin: -2px 0 0 -2px;
  animation: ${moveL} 0.8s linear;
  &:before {
    content: "";
    position: absolute;
    top: 5px;
    right: 5px;
    width: 7px;
    height: 6px;
    background: black;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: 15px;
    height: 4px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
  }
`;

const Line = styled.div`
  padding: 1px 210px;
  background-image: linear-gradient(
    to right,
    #000 30%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 15px 2px;
  background-repeat: repeat-x;
`;

const Success = styled.div`
  width: 40px;
  height: 30px;
  border-radius: 5% 5%;
  border: 2px solid #0069ff;
  position: relative;
  animation: ${transformB} 0.3s 1.4s linear forwards;
  margin: -2px 0 0 -2px;
  &:after {
    content: "";
    position: absolute;
    bottom: 12px;
    left: 11px;
    width: 15px;
    height: 8px;
    border-bottom: 2px solid #0069ff;
    border-left: 2px solid #0069ff;
    transform: rotate(-45deg);
    animation: ${transformBA} 0.3s 1.4s linear forwards;
  }
`;

const SuccessAnim = styled.div`
  width: 40px;
  height: 30px;
  border-radius: 5% 5%;
  border: 2px solid #0069ff;
  position: relative;
  margin: -2px 0 0 -2px;
  animation: ${moveR} 0.8s 1s linear;
  &:after {
    content: "";
    position: absolute;
    bottom: 12px;
    left: 11px;
    width: 15px;
    height: 8px;
    border-bottom: 2px solid #0069ff;
    border-left: 2px solid #0069ff;
    transform: rotate(-45deg);
    animation: ${transformBA} 0.3s 1.4s linear forwards;
  }
`;

const Message = styled.div`
  text-align: center;
  margin-top: 10px;
`;

function EmailSentAnimation() {
  return (
    <Container>
      <Animation>
        <Mail>
          <MailAnimation />
        </Mail>
        <Line />
        <Success>
          <SuccessAnim />
        </Success>
      </Animation>
      <Message>
        We have received your contacts and they are being processed. We will
        send you an email to let you know when it's complete.
      </Message>
    </Container>
  );
}
