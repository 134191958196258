import React from "react";
import { styled, useTheme } from "@mui/material/styles";

// material-ui
import {
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  TableCell,
  TableRow,
  Typography,
  Avatar,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

// project imports
import { getContactByUUID } from "utils";

// assets
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ForumIcon from "@mui/icons-material/Forum";
import AddIcon from "@mui/icons-material/Add";
import { revmoApiUrl } from "../../../config";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: "none",
  },
}));

function SearchResultsListRow({
  row,
  viewIntroductionPathwayToNetworkMember,
  index,
  showAddContactModal,
}) {
  const theme = useTheme();
  const { first_name, last_name, uuid, revmo_score, is_contact, has_path } =
    row;

  return (
    <TableRow hover key={index} className="walkthrough-step-4">
      <TableCell sx={{ maxWidth: { lg: "400px", sm: "280px" } }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar
              size="lg"
              src={revmoApiUrl + `/images/profile_images/${uuid}` ?? ""}
              sx={{ width: { lg: 50, md: 40 }, height: { lg: 50, md: 40 } }}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography align="left" variant="subtitle1" component="div">
              {`${first_name} ${last_name}`}{" "}
            </Typography>
            <LightTooltip
              placement="bottom-start"
              title={
                row?.positions &&
                row?.positions[0]?.role_description +
                  (row?.positions[0]?.role_description ? " @ " : " ") +
                  row?.positions[0]?.org?.org_name
              }
            >
              <Typography
                align="left"
                variant="subtitle2"
                noWrap
                sx={{ minWidth: "none" }}
              >
                {row?.positions &&
                  row?.positions[0]?.role_description +
                    (row?.positions[0]?.role_description &&
                    row.positions[0]?.org?.org_name
                      ? " @ "
                      : " ") +
                    row.positions[0]?.org?.org_name}
              </Typography>
            </LightTooltip>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" align="center">
              {revmo_score} / 800
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LinearProgress
              variant="determinate"
              value={(100 * row.revmo_score) / 800}
              color="primary"
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="center">
        {is_contact && (
          <Tooltip
            placement="top"
            title="This user is already in your list of contacts"
          >
            <Chip
              label="Existing Contact"
              size="small"
              sx={{
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.dark.main
                    : theme.palette.success.light + 60,
                color: theme.palette.success.dark,
              }}
            />
          </Tooltip>
        )}
        {has_path === false && !is_contact && (
          <Tooltip
            placement="top"
            title="You do not have an introduction path with this user"
          >
            <Chip
              label="No Path"
              size="small"
              sx={{
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.dark.main
                    : theme.palette.tertiary.light,
                color: theme.palette.tertiary.dark,
              }}
            />
          </Tooltip>
        )}
        {has_path === true && !is_contact && (
          <Tooltip
            placement="top"
            title="You have an introduction path available with this user"
          >
            <Chip
              label="Path Available"
              size="small"
              sx={{
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.main
                    : theme.palette.secondary.light,
                color: theme.palette.primary.dark,
              }}
            />
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="center" sx={{ pr: 3 }}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          {is_contact ? (
            <Tooltip placement="top" title="View">
              <IconButton
                color="primary"
                aria-label="view"
                size="large"
                onClick={async () => getContactByUUID(uuid)}
              >
                <RemoveRedEyeIcon sx={{ fontSize: "1.1rem" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              {has_path && (
                <Tooltip placement="top" title="Request Intro">
                  <IconButton
                    color="primary"
                    aria-label="request"
                    size="large"
                    className="walkthrough-step-5"
                    onClick={() => {
                      has_path && viewIntroductionPathwayToNetworkMember(row);
                    }}
                  >
                    <ForumIcon sx={{ fontSize: "1.1rem" }} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip placement="top" title="Add Contact">
                <IconButton
                  color="primary"
                  aria-label="add"
                  size="large"
                  onClick={() => {
                    !is_contact && showAddContactModal(index, row);
                  }}
                >
                  <AddIcon sx={{ fontSize: "1.1rem" }} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
}

export default SearchResultsListRow;
