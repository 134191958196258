import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
        color: #313237;
        font-family: "Roboto", sans-serif !important;
        height: 100%;
        margin: 0;
      }
    body {
        height: 100%;
        margin: 0;
      }

    #root {
        height: 100%;
      }

    a {
        font-weight: 700;
        text-decoration: none;
        font-family: "Roboto", sans-serif;
      }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    textarea {
        color: #313237;
        font-family: "Roboto", sans-serif;
        font-weight: 700;
      }
    p {
        color: #313237;
        font-family: "Roboto", sans-serif;
      }
    select, option, input, button, label {
        font-family: "Roboto", sans-serif;
      }
`;

export const theme = {
  brandColor: "#5374ff",
  textColor: "#313237",
  titleTextColor: "#40404A",
  acceptedColor: "#18BFA1",
  pendingColor: "#7564c6",
  unsuccessfulColor: "#fe7066",
  borderColor: "#e3e6e9",
  lightGrey: "rgb(237, 237, 237) ",
  mediumGrey: "rgb(153, 153, 153)",
  darkGrey: "rgb(68, 68, 68)",
};

// moving forward, try and establish a range of font-size - sm, md, lg, that can form part of the theme
// include a greater range of text colors - primary, secondary, title, body, etc..
