import React from "react";
import styled from "styled-components";
import { PrivacyPolicyText } from "./PrivacyPolicyText";

const PrivacyPolicyWrapper = styled.div`
  background-color: inherit;
  display: flex;
  justify-content: center;
  div {
    max-width: 840px;
    background-color: #ffffff;
    padding: 2rem;
  }
`;

function PrivacyPolicyTerms() {
  return (
    <PrivacyPolicyWrapper>
      <PrivacyPolicyText />
    </PrivacyPolicyWrapper>
  );
}

export default PrivacyPolicyTerms;
