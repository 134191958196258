import { Auth } from "aws-amplify";
import { revmoApiUrl } from "../config";

const fetchData = async (url) => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  let res = await fetch(revmoApiUrl + url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + cognitoUser.signInUserSession.idToken.jwtToken,
    },
  });
  const json = await res.json();
  if (res.status >= 200 && res.status <= 299) {
    return json;
  } else {
    throw json;
  }
};

export default fetchData;
