import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { HowItWorks } from ".";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import heroBackground from "assets/images/hero-background-crop.png";
import revmoScreenshot from "assets/images/revmo_screenshot_fade.png";
import heroSemicircle from "assets/images/hero_semicircle.svg";
import history from "../../../history";

const HeroWrapper = styled.div`
  margin-bottom: -120px;
  margin-top: -120px;
  padding-bottom: 50px;
  background: radial-gradient(
      50% 125.2% at 100% 100%,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    ),
    url(${heroBackground});
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
`;

const StyledImage = styled.img`
  max-width: 100%;
  transition: 0.3s ease-out;
  :hover {
    filter: blur(3px);
  }
`;

const LandingHero = () => {
  const { control, handleSubmit } = useForm();
  const handleOnSubmit = (event) => {
    history.push({
      pathname: "/sign-up",
      state: { email_address: event.email_address },
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
      <HeroWrapper>
        <Container>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={3}
            sx={{ mt: { xs: 15, sm: 10, md: 18.75 }, mb: { xs: 2.5, md: 10 } }}
          >
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              spacing={3}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: "2.25rem", sm: "3rem", md: "4rem" },
                  fontWeight: 900,
                  lineHeight: 1.4,
                  textAlign: {
                    xs: "left",
                    sm: "center",
                  },
                }}
              >
                The fastest, simplest way to make meaningful connections.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              spacing={3}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 900,
                  lineHeight: 1.4,
                  maxWidth: {
                    xs: "100%",
                    sm: "80%",
                  },
                  textAlign: {
                    xs: "left",
                    sm: "center",
                  },
                }}
              >
                Make natural introductions to investors, find new hires, or add
                to your business development pipeline through the Revmo platform
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={3}
              >
                <Grid item xs={12} md={8} zeroMinWidth>
                  <Controller
                    control={control}
                    name={`email_address`}
                    defaultValue={""}
                    onChange={([event]) => event.target.value}
                    render={({ onChange, ...props }) => (
                      <TextField
                        {...props}
                        type="email"
                        fullWidth
                        placeholder="Email address"
                        onChange={(e) => onChange(e)}
                        sx={{ backgroundColor: "white" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    disableElevation
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{
                      px: 2.75,
                      py: 1.5,
                      width: "100%",
                      backgroundColor: "#0069FF",
                    }}
                  >
                    Create account
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundImage: `url(${heroSemicircle})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "auto",
                marginTop: "-35px",
                a: {
                  color: "#FFF",
                  fontWeight: "bold",
                  borderRadius: "60px",
                  "&.MuiButton-root": {
                    backgroundColor: "#0069FF",
                  },
                },
              }}
            >
              <Link
                to={{ pathname: "https://calendly.com/margot-57/30min" }}
                target="_blank"
              >
                <StyledImage
                  src={revmoScreenshot}
                  alt={"A screenshot of the Revmo platform"}
                  loading="lazy"
                />
              </Link>
              <Button
                disableElevation
                href="https://calendly.com/margot-57/30min"
                target="_blank"
                variant="contained"
                size="large"
                sx={{
                  px: 2.75,
                  py: 1.5,
                  position: "absolute",
                }}
              >
                Book a demo
              </Button>
            </Grid>
            <Grid item>
              <HowItWorks />
            </Grid>
          </Grid>
        </Container>
      </HeroWrapper>
    </Box>
  );
};

export default LandingHero;
