import React from "react";
import styled from "styled-components";
import filterIcon from "assets/icons/filter-icon.svg";

export const IconSVG = styled.svg.attrs({
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
})``;

const FilterIconImage = styled.img`
  height: 13px;
  width: 18px;
  margin-left: 38px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;

export function FilterIcon() {
  return <FilterIconImage src={filterIcon} />;
}

const FilterIconGreyWrapper = styled.div`
  svg {
    height: 11px;
    path {
      fill: #7c919e;
    }
  }
`;

export function FilterIconGrey() {
  return (
    <FilterIconGreyWrapper>
      <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V1.75H18V0H0ZM2.53521 5.5H15.4648V3.75H2.53521V5.5ZM5.07042 9.25H12.9296V7.5H5.07042V9.25ZM7.60563 13H10.3944V11.25H7.60563V13Z"
          fill="black"
        />
      </svg>
    </FilterIconGreyWrapper>
  );
}

const StyledSVG = styled(IconSVG)`
  width: 40px;
  height:: 40px;
`;

export const ExitIcon = ({ className }) => {
  return (
    <StyledSVG viewBox="0 0 512 512" className={className}>
      <path
        fill="currentColor"
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"
        opacity="0.4"
      ></path>
      <path
        fill="currentColor"
        d="M377.6 321.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"
      />
    </StyledSVG>
  );
};
