import React from "react";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";

const IntroductionSectionContentWrapper = styled.div`
  padding: calc(40vmax / 10) 4vw calc(40vmax / 10) 4vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IntroductionSectionContent = styled.div`
  max-width: 1000px;
  min-height: 150px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  /* Each second item will be reversed */
  @media (min-width: 800px) {
    :nth-child(even) {
      direction: rtl;
      div {
        direction: ltr;
      }
    }
  }
  gap: 1em 10em;
  padding-top: 2rem;
`;

const IntroductionSectionContentInner = styled.div`
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    height: 100%;
    width: 100%;
  }
  h2 {
    font-weight: bold;
  }
  a,
  p {
    line-height: 1.8;
    letter-spacing: 0;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
  }
  a {
    :hover {
      text-decoration: underline;
    }
  }
`;

function IntroductionSection({ content }) {
  return (
    <IntroductionSectionContentWrapper>
      {content.map((section, index) => {
        return (
          <IntroductionSectionText
            key={index}
            title={section.title}
            innerText={section.text}
            image={section.image}
          />
        );
      })}
    </IntroductionSectionContentWrapper>
  );
}

function IntroductionSectionText({ title, innerText, image }) {
  return (
    <IntroductionSectionContent>
      <Fade left>
        <IntroductionSectionContentInner>
          <h2>{title}</h2>
          <p>{innerText}</p>
        </IntroductionSectionContentInner>
      </Fade>
      <Fade right>
        <IntroductionSectionContentInner>
          <img src={image} />
        </IntroductionSectionContentInner>
      </Fade>
    </IntroductionSectionContent>
  );
}

export default IntroductionSection;
