import React from "react";
import {
  Container996,
  HeroBackground,
  HeroTextWrapper,
  HeroTag,
} from "components/Post/Layout";

function SupportHero() {
  return (
    <Container996>
      <HeroBackground />
      <HeroTextWrapper>
        <HeroTag>Support</HeroTag>
        <h1>Articles, help and advice on using Revmo.</h1>
        <p>
          Information to help you make the most of Revmo, if you can't find the
          answer to your question here, please reach out to{" "}
          <a href="mailto: support@revmo.app">support@revmo.app</a>
        </p>
      </HeroTextWrapper>
    </Container996>
  );
}

export default SupportHero;
