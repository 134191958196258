import React from "react";
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Button, Grid, LinearProgress } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

// project imports
import { gridSpacing } from "containers/DashboardNew/theme";
import { getContactByUUID } from "utils";

// assets
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ForumIcon from "@mui/icons-material/Forum";
import AddIcon from "@mui/icons-material/Add";
import { revmoApiUrl } from "../../config";

const SearchCard = ({
  row,
  index,
  viewIntroductionPathwayToNetworkMember,
  showAddContactModal,
}) => {
  const theme = useTheme();
  const { first_name, last_name, uuid, revmo_score, is_contact, has_path } =
    row;

  return (
    <Card
      sx={{
        m: 2,
        borderRadius: "10px",
        border: theme.palette.mode === "dark" ? "none" : "1px solid",
        borderColor: theme.palette.grey[100],
        boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
      }}
    >
      <CardHeader
        sx={{
          height: "100px",
          p: 2,
          overflow: "hidden",
          "& .MuiCardHeader-content": {
            overflow: "hidden",
          },
        }}
        disableTypography
        avatar={
          <Avatar
            aria-label="profile image"
            src={revmoApiUrl + `/images/profile_images/${uuid}` ?? ""}
            sx={{ width: 70, height: 70 }}
          />
        }
        f
        title={
          <Typography gutterBottom variant="h5" component="div">
            {first_name + " " + last_name}
          </Typography>
        }
        subheader={
          <Typography
            variant="body2"
            component="div"
            sx={{ fontSize: "1.15rem" }}
            noWrap
          >
            {row?.positions &&
              row?.positions[0]?.role_description +
                (!!row?.positions[0]?.role_description && " @ ") +
                row?.positions[0]?.org?.org_name}
          </Typography>
        }
      />
      <CardContent
        sx={{
          bgcolor: theme.palette.grey[100],
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: theme.palette.grey[300],
        }}
      >
        <Grid container spacing={gridSpacing}>
          <Grid item xs={6}>
            <Grid container spacing={1} sx={{ p: 1 }}>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ fontSize: "1.15rem" }}
                >
                  Revmo Score
                </Typography>
                <Typography
                  variant="subtitle2"
                  align="center"
                  sx={{ fontSize: "1.15rem" }}
                >
                  {revmo_score} / 800
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <LinearProgress
                  variant="determinate"
                  value={(100 * revmo_score) / 800}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-evenly"
              sx={{ height: "100%" }}
            >
              {is_contact && (
                <Chip
                  label="Existing Contact"
                  size="large"
                  sx={{
                    background:
                      theme.palette.mode === "dark"
                        ? theme.palette.dark.main
                        : theme.palette.success.light + 60,
                    color: theme.palette.success.dark,
                  }}
                />
              )}
              {has_path === false && !is_contact && (
                <Chip
                  label="No Path"
                  size="large"
                  sx={{
                    background:
                      theme.palette.mode === "dark"
                        ? theme.palette.dark.main
                        : theme.palette.tertiary.light,
                    color: theme.palette.tertiary.dark,
                  }}
                />
              )}
              {has_path === true && !is_contact && (
                <Chip
                  label="Path Available"
                  size="large"
                  sx={{
                    background:
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.main
                        : theme.palette.secondary.light,
                    color: theme.palette.primary.dark,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ p: 2 }}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {is_contact ? (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%" }}
                    onClick={async () => getContactByUUID(uuid)}
                    startIcon={<RemoveRedEyeIcon />}
                  >
                    View Contact
                  </Button>
                </Grid>
              ) : (
                <>
                  {has_path && (
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{ width: "100%", minHeight: "40px" }}
                        onClick={() => {
                          has_path &&
                            viewIntroductionPathwayToNetworkMember(row);
                        }}
                        startIcon={<ForumIcon />}
                      >
                        Request Intro
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={has_path ? 6 : 12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ width: "100%", minHeight: "40px" }}
                      onClick={() => {
                        !is_contact && showAddContactModal(index, row);
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add Contact
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

SearchCard.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  viewIntroductionPathwayToNetworkMember: PropTypes.func,
  showAddContactModal: PropTypes.func,
};

export default SearchCard;
