import React from "react";
import { Dropdown } from "semantic-ui-react";
import { DropdownWrapper } from ".";

export function CSVUploadDropdown({ handleChange, showError }) {
  const options = [
    { key: "REVMO", text: "Revmo", value: "REVMO" },
    { key: "GMAIL", text: "Gmail", value: "GMAIL" },
    { key: "LI_CONTACTS", text: "LinkedIn Contacts", value: "LI_CONTACTS" },
    {
      key: "LI_CONNECTIONS",
      text: "LinkedIn Connections",
      value: "LI_CONNECTIONS",
    },
    { key: "OUTLOOK", text: "Outlook", value: "OUTLOOK" },
  ];

  return (
    <DropdownWrapper showError={showError}>
      <label>I'm uploading contacts from: </label>
      <Dropdown
        placeholder="LinkedIn, Outloo..."
        search
        selection
        options={options}
        onChange={handleChange}
        defaultValue={"REVMO"}
      />
    </DropdownWrapper>
  );
}
