import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import blob from "assets/icons/blob.svg";
import blob2 from "assets/icons/blob2.svg";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const AnimationWrapper = styled.div`
  background: transparent;
  padding-top: 50px;
  z-index: 5;
`;

const Device = styled.div`
  margin: 0 auto;
  transition: all 0.55s;
  position: relative;
  width: 100%;
  max-width: 712px;
  background: #e0e0e0;
  padding: 40px 15px 45px 15px;
  border-radius: 5px;
  &:before {
    display: table;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: calc(-745px / 2);
    background: #999999;
    height: 20px;
    width: calc(712px + 30px);
    border-bottom-left-radius: 4% 100%;
    border-bottom-right-radius: 4% 100%;
  }
  &:after {
    background: #bbbbbb;
    display: table;
    content: "";
    position: absolute;
    left: 50%;
    bottom: 12px;
    margin-left: -25px;
    width: 50px;
    height: 8px;
    border-bottom-left-radius: 4% 100%;
    border-bottom-right-radius: 4% 100%;
  }
  &,
  &:before,
  &:after {
    transition: all 0.55s;
  }
  ${(props) => {
    if (props.mobile) {
      return css`
        max-width: 230px; //16:9 aspect ratio
        padding-left: 5px;
        padding-right: 5px;
        &:before {
          width: 22px;
          height: 22px;
          margin-left: -9px;
          bottom: 10px;
          border-radius: 50%;
          border: 4px solid #bbbbbb;
        }
        &:after {
          display: none;
        }
        .screen:before {
          width: 30px;
          height: 5px;
          margin-left: -15px;
          border-radius: 0;
        }
        .ScreenText {
          max-width: 50%;
        }
        div > div {
          flex-direction: column;
          div {
            width: 100%;
            font-size: 0.8rem;
          }
        }
      `;
    }
  }}
  @media(max-width: 1024px) {
    max-width: 230px; //16:9 aspect ratio
    padding-left: 5px;
    padding-right: 5px;
    &:before {
      width: 22px;
      height: 22px;
      margin-left: -9px;
      bottom: 10px;
      border-radius: 50%;
      border: 4px solid #bbbbbb;
    }
    &:after {
      display: none;
    }
    .screen:before {
      width: 30px;
      height: 5px;
      margin-left: -15px;
      border-radius: 0;
    }
    div > div {
      flex-direction: column;
      div {
        width: 100%;
        font-size: 0.8rem;
      }
    }
  }
`;

const Screen = styled.div`
  height: 400px; //16:9 aspect ratio
  width: 100%;
  position: relative;
  background: #ffffff;
  &:before {
    display: table;
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: -18px;
    left: 50%;
    margin-left: -4px;
    border-radius: 50%;
    background: #999999;
  }
  &,
  &:before,
  &:after {
    @extend %transition;
  }
`;

const ScreenDisplay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
`;

const ScreenText = styled.div`
  display: block;
  padding: 2rem;
  width: 50%;
  p {
    font-size: 1.3em;
    margin-bottom: 0;
    div {
      height: 2.6rem;
      p {
        text-decoration: underline;
      }
    }
  }
  ${(props) => {
    if (props.mobile) {
      return css`
        width: 100%;
        p > div {
          height: 1.6rem;
        }
      `;
    }
  }}
`;

const Blob = styled.img`
  position: absolute;
  width: 100%;
  z-index: -1;
  margin-left: -200px;
  left: 60%;
  top: -11%;
  width: 150%;
  transition: width 0.2s ease-in;
  @media (min-width: 1024px) {
    width: 100%;
    left: 16%;
    top: -22%;
    ${(props) => {
      if (props.mobile === true) {
        return `
          left: 60%;
          top: -11%;
          width: 150%;
          transition: width 0.2s ease;
        `;
      }
    }}
  }
`;

const Blob2 = styled(Blob)`
  width: 100%;
  height: 50%;
  top: 69%;
  left: 143%;
  @media (min-width: 1024px) {
    width: 50%;
    height: 50%;
    left: 118%;
    top: 77%;
    ${(props) => {
      if (props.mobile === true) {
        return `
          width: 100%;
          left: 143%;
        `;
      }
    }}
  }
`;

function TextCarousel({ data }) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 350, flexGrow: 1, minWidth: 200 }}>
      <AutoPlaySwipeableViews
        axis={"y"}
        index={activeStep}
        onChangeIndex={handleStepChange}
      >
        {data.map((step, index) => (
          <div key={step}>
            {Math.abs(activeStep - index) <= 2 ? (
              <div>
                <Typography>{step}</Typography>
              </div>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
    </Box>
  );
}

function Animation() {
  const [mobile, setMobile] = useState(false);
  const names = ["Katherine", "James", "Luke", "Sarah"];
  const companies = ["Blackrock", "Amazon", "Google", "a VC fund"];
  const discuss = [
    "fundraising",
    "an investment",
    "recruitment",
    "an opportunity",
  ];

  return (
    <AnimationWrapper>
      <Device mobile={mobile} onClick={() => setMobile(!mobile)}>
        <Screen>
          <ScreenDisplay>
            <ScreenText>
              <p>
                I'd like an introduction to <TextCarousel data={names} />
              </p>
              <p>
                at <TextCarousel data={companies} />
              </p>
              <p>
                to discuss <TextCarousel data={discuss} />
              </p>
            </ScreenText>
            <Counter
              id={"002"}
              label={"Number of Introductions"}
              number={"120"}
              duration={"200"}
            />
          </ScreenDisplay>
        </Screen>
        <Blob src={blob} mobile={mobile} />
        <Blob2 src={blob2} mobile={mobile} />
      </Device>
    </AnimationWrapper>
  );
}

const CounterWrapper = styled.div`
  padding: 2rem;
  width: 100%;
  height: 100%;
  background-color: #000a33;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d3b66;
  h3 {
    color: white;
    i {
      font-size: 4rem;
    }
  }
`;

const Counter = ({ number, duration }) => {
  // number displayed by component
  const [count, setCount] = useState("0");

  useEffect(() => {
    let start = 0;
    // first three numbers from props
    const end = parseInt(number);
    // if zero, return
    if (start === end) return;

    // find duration per increment
    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 1000;

    // timer increments start counter
    // then updates count
    // ends if start reaches end
    let timer = setInterval(() => {
      start += 1;
      setCount(String(start));
      if (start === end) clearInterval(timer);
    }, incrementTime);

    // dependency array
  }, [number, duration]);

  return (
    <CounterWrapper>
      <h3>
        <i>
          {count}
          <br />
        </i>
        introductions
      </h3>
    </CounterWrapper>
  );
};

export default Animation;
