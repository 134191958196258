import React, { Suspense } from "react";
import ErrorBoundary from "components/ErrorBoundary";
import styled from "styled-components";
import whiteTick from "../../assets/icons/tick-white.svg";
import curvedArrow from "../../assets/icons/arrow-curved-white.svg";
import whiteCross from "../../assets/icons/cross-sign-white.svg";
import { Link } from "react-router-dom";
import { findNumberOfRequests } from "containers/MyRequests/utils";
import { useRecoilValue } from "recoil";
import { introductionRequestsState } from "state/recoil/requests";

const RequestCardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: ${(props) => props.margin};
  @media (max-width: 870px) {
    flex-direction: column;
  }
`;

const RequestCardContainer = styled.div`
  min-width: 337px;
  background: #f7f7f8;
  border-top: 1px solid ${(props) => props.theme.borderColor};
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  padding: 28px 17px 33px 11px;
  a {
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
    color: ${(props) => props.theme.brandColor};
    text-decoration: underline;
    margin-left: auto;
  }
  @media (max-width: 1126px) {
    min-width: unset;
    margin-right: 8px;
    flex: 1;
    :last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 870px) {
    margin: 0 0 26px 0;
    :last-child {
      margin: 0;
    }
  }
`;

const HorizontalFlexContainer = styled.div`
  display: flex;
  width: 100%;
`;

const NumberOfIntroductionsIcon = styled.div`
  border-radius: 5px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 7px 0px 6px 0;
  width: 53px;
  height: 66px;
  background: ${(props) => props.background};
  @media (max-width: 1126px) and (min-width: 871px) {
    width: 50px;
    height: 64px;
  }
`;

const NumberOfIntroductionsImage = styled.img`
  height: 19px;
  width: 19px;
`;

const NumberOfIntroductionsText = styled.p`
  font-size: 30px;
  line-height: 34px;
  letter-spacing: 0.4px;
  color: #ffffff;
`;

const IntroductionCardBodyTextPrimary = styled.p`
  font-weight: 500;
  font-size: 21px;
  line-height: 23px;
  color: ${(props) => props.theme.textColor};
  margin: 0;
`;

const IntroductionCardBodyTextSecondary = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #7c919e;
  margin-top: 9px;
  width: max-content;
`;

export function RequestCards({ myRequestsPage }) {
  return (
    <RequestCardsWrapper>
      <RequestCardContainer>
        <HorizontalFlexContainer>
          <NumberOfIntroductionsIcon background="#18BFA1">
            <NumberOfIntroductionsImage src={whiteTick} />
            <ErrorBoundary
              fallback={
                <NumberOfIntroductionsText>0</NumberOfIntroductionsText>
              }
            >
              <Suspense
                fallback={
                  <NumberOfIntroductionsText>0</NumberOfIntroductionsText>
                }
              >
                <NumberOfIntroductions component={"accepted"} />
              </Suspense>
            </ErrorBoundary>
          </NumberOfIntroductionsIcon>
          <div>
            <IntroductionCardBodyTextPrimary>
              Accepted <br /> Intro Requests
            </IntroductionCardBodyTextPrimary>
            <IntroductionCardBodyTextSecondary>
              You have been connected!
            </IntroductionCardBodyTextSecondary>
          </div>
          {!myRequestsPage && <Link to="/requests">View</Link>}
        </HorizontalFlexContainer>
      </RequestCardContainer>
      <RequestCardContainer>
        <HorizontalFlexContainer>
          <NumberOfIntroductionsIcon background="#7564C6;">
            <NumberOfIntroductionsImage src={curvedArrow} />
            <ErrorBoundary
              fallback={
                <NumberOfIntroductionsText>0</NumberOfIntroductionsText>
              }
            >
              <Suspense
                fallback={
                  <NumberOfIntroductionsText>0</NumberOfIntroductionsText>
                }
              >
                <NumberOfIntroductions component={"pending"} />
              </Suspense>
            </ErrorBoundary>
          </NumberOfIntroductionsIcon>
          <div>
            <IntroductionCardBodyTextPrimary>
              Pending <br /> Introductions
            </IntroductionCardBodyTextPrimary>
            <IntroductionCardBodyTextSecondary>
              Waiting on an intermediary
            </IntroductionCardBodyTextSecondary>
          </div>
          {!myRequestsPage && <Link to="/requests">View</Link>}
        </HorizontalFlexContainer>
      </RequestCardContainer>
      <RequestCardContainer last>
        <HorizontalFlexContainer>
          <NumberOfIntroductionsIcon background="#FE7066;">
            <NumberOfIntroductionsImage src={whiteCross} />
            <ErrorBoundary
              fallback={
                <NumberOfIntroductionsText>0</NumberOfIntroductionsText>
              }
            >
              <Suspense
                fallback={
                  <NumberOfIntroductionsText>0</NumberOfIntroductionsText>
                }
              >
                <NumberOfIntroductions component={"declined"} />
              </Suspense>
            </ErrorBoundary>
          </NumberOfIntroductionsIcon>
          <div>
            <IntroductionCardBodyTextPrimary>
              Unsuccessful <br /> Introductions
            </IntroductionCardBodyTextPrimary>
            <IntroductionCardBodyTextSecondary>
              No available paths accepted
            </IntroductionCardBodyTextSecondary>
          </div>
          {!myRequestsPage && <Link to="/requests">View</Link>}
        </HorizontalFlexContainer>
      </RequestCardContainer>
    </RequestCardsWrapper>
  );
}

export function NumberOfIntroductions({ component }) {
  const requests = useRecoilValue(introductionRequestsState);
  let {
    numberOfIntroRequestsAccepted,
    numberOfIntroRequestsPending,
    numberOfIntroRequestsDeclined,
  } = findNumberOfRequests(requests);

  return (
    <>
      {component === "accepted" && (
        <NumberOfIntroductionsText>
          {numberOfIntroRequestsAccepted}
        </NumberOfIntroductionsText>
      )}
      {component === "pending" && (
        <NumberOfIntroductionsText>
          {numberOfIntroRequestsPending}
        </NumberOfIntroductionsText>
      )}
      {component === "declined" && (
        <NumberOfIntroductionsText>
          {numberOfIntroRequestsDeclined}
        </NumberOfIntroductionsText>
      )}
    </>
  );
}
