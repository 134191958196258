import React, { useState } from "react";
import styled from "styled-components";
import "semantic-ui-css/semantic.min.css";
import { useCognitoAuth } from "hooks";
import { LogInUser, RecoverPassword, SetNewPassword } from "./components";
import { PageWrapperFullWidthRelative } from "components/Layout";
import { AuthBackground } from "components/Auth";
import { Sidebar } from "components/Onboarding";
import { recoverUserPassword, setNewPassword } from "./utils";

const LoginContent = styled.div`
  width: 100%;
  display: flex;
  @media only screen and (min-width: 768px) {
    flex-basis: 66.66%;
  }
  align-items: center;
  justify-content: center;
`;

function Login(props) {
  const {
    alertMessage,
    setAlertMessage,
    loginState,
    setLoginState,
    customErrorMessages,
    logInUser,
  } = useCognitoAuth(
    props.location.pathname.includes("reset-password")
      ? "userCanRequestNewPassword"
      : "logInUser"
  );

  const [emailAddress, setEmailAddress] = useState("");

  const userCanRequestNewPassword = () => {
    setAlertMessage("");
    setLoginState("userCanRequestNewPassword");
  };

  const checkIfUserHasAnAccount = async (data) => {
    let { email } = data;
    email = email.toLowerCase();
    setEmailAddress(email);
    await recoverUserPassword(
      email,
      setAlertMessage,
      customErrorMessages,
      setLoginState
    );
  };

  const resetPassword = async (data) => {
    const { password, confirmationCode } = data;
    setNewPassword(
      password,
      setAlertMessage,
      emailAddress,
      confirmationCode,
      setLoginState,
      customErrorMessages
    );
  };

  const returnToLoginPage = () => setLoginState("logInUser");
  const returnToCheckPasswordForAccount = () =>
    setLoginState('userCanRequestNewPassword"');

  return (
    <PageWrapperFullWidthRelative>
      <AuthBackground>
        <Sidebar />
        <LoginContent>
          {loginState === "logInUser" && (
            <LogInUser
              userCanRequestNewPassword={userCanRequestNewPassword}
              logInUser={logInUser}
              alertMessage={alertMessage}
            />
          )}
          {loginState === "userCanRequestNewPassword" && (
            <RecoverPassword
              checkIfUserHasAnAccount={checkIfUserHasAnAccount}
              alertMessage={alertMessage}
              returnToLoginPage={returnToLoginPage}
            />
          )}
          {loginState === "userCanResetPassword" && (
            <SetNewPassword
              resetPassword={resetPassword}
              alertMessage={alertMessage}
              returnToCheckPasswordForAccount={returnToCheckPasswordForAccount}
            />
          )}
        </LoginContent>
      </AuthBackground>
    </PageWrapperFullWidthRelative>
  );
}

export default Login;
