import React, { useState } from "react";
import { useAdvancedSearch, useContact } from "hooks";
import Grid from "@mui/material/Grid";
import {
  NumberOfIntroductionsCard,
  HoverSocialCard,
  DashboardHeader,
} from "components/DashboardNew";
import UserContacts from "components/UserContacts";
import { useTheme } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import SearchIcon from "@mui/icons-material/Search";
import { searchesRemaining } from "state/settings";
import { useRecoilValueLoadable } from "recoil";

function Home() {
  const theme = useTheme();
  const advancedSearchHook = useAdvancedSearch();
  const { contactsContextState } = useContact();
  const { numberOfNodes } = contactsContextState;
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const usageData = useRecoilValueLoadable(searchesRemaining);
  const searches = usageData.contents;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <DashboardHeader
              showAdvancedSearch={showAdvancedSearch}
              setShowAdvancedSearch={setShowAdvancedSearch}
              advancedSearchHook={advancedSearchHook}
            />
          </Grid>
          <Grid item lg={8} xs={12} sm={12} md={12}>
            <NumberOfIntroductionsCard />
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Grid
              container
              spacing={3}
              alignItems="stretch"
              sx={{ height: "100%" }}
            >
              <Grid item sm={6} xs={12} md={6} lg={6}>
                <HoverSocialCard
                  primary="Total Contacts"
                  secondary={numberOfNodes?.toString() ?? "0"}
                  iconPrimary={PeopleIcon}
                  color={theme.palette.primary.main}
                />
              </Grid>
              <Grid item sm={6} xs={12} md={6} lg={6}>
                <HoverSocialCard
                  primary="Searches Remaining"
                  secondary={searches?.search_count ?? "0"}
                  iconPrimary={SearchIcon}
                  color={theme.palette.secondary.main}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Contact */}
      <Grid item xs={12}>
        <UserContacts />
      </Grid>
    </Grid>
  );
}

export default Home;
