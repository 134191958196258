import React from "react";
import styled from "styled-components";
import { MidLargeSpacedTextGrey } from "components/Typography";

const NoRequestsFoundContainer = styled.div`
  margin-top: 100px;
`;

function NoRequestsFound() {
  return (
    <NoRequestsFoundContainer>
      <MidLargeSpacedTextGrey>
        To start tracking your requests, make your first connection using the
        search above!
      </MidLargeSpacedTextGrey>
    </NoRequestsFoundContainer>
  );
}

export function NoRequestsLoaded() {
  return (
    <NoRequestsFoundContainer>
      <MidLargeSpacedTextGrey>
        It looks like we can't load your requests at the moment. Please check
        back later!
      </MidLargeSpacedTextGrey>
    </NoRequestsFoundContainer>
  );
}

export default NoRequestsFound;
