import React from "react";
import {
  AddContactsModal,
  AddOrEditContactModal,
  AddMultipleContactsModal,
  LinkedInConnectionsModal,
  SuccessModal,
  ErrorModal,
  CloudspongeUploadModal,
} from "components/Modal";

function ContactModals({
  modalsState,
  toggleAddContactsModal,
  toggleAddOrEditSingleContactModal,
  toggleAddMultipleContactsModal,
  toggleAddLinkedInConnectionsModal,
  closeDisplayModal,
  handleSubmitManuallyAddOrEditNewContact,
  contactDetails,
  setContactDetails,
  customFormErrorsHook,
  issuesWithReferralContacts,
  handleContactUploadsFromFile,
  fileUploadRef,
  submitUploadsFromFile,
  closeSuccessModal,
  setUploadStatus,
  diallingCodesHook,
  cloudspongeData,
  closeCloudspongeModal,
  handleChangeCSVDropdown,
  loading,
  failureMessage,
}) {
  const {
    showAddContactsModal,
    showAddMultipleContactsModal,
    showAddOrEditContactModal,
    uploadStatus,
    showLinkedInConnectionsModal,
  } = modalsState;

  return (
    <>
      <AddContactsModal
        displayModal={showAddContactsModal}
        setDisplayModal={toggleAddContactsModal}
        toggleAddOrEditSingleContactModal={toggleAddOrEditSingleContactModal}
        toggleAddLinkedInConnectionsModal={toggleAddLinkedInConnectionsModal}
      />
      {showAddOrEditContactModal && (
        <AddOrEditContactModal
          displayModal={showAddOrEditContactModal}
          setDisplayModal={closeDisplayModal}
          handleSubmitManuallyAddOrEditNewContact={
            handleSubmitManuallyAddOrEditNewContact
          }
          contactDetails={contactDetails}
          setContactDetails={setContactDetails}
          action={modalsState.addOrEditContact}
          customFormErrorsHook={customFormErrorsHook}
          diallingCodesHook={diallingCodesHook}
        />
      )}
      {showLinkedInConnectionsModal && (
        <LinkedInConnectionsModal
          setDisplayModal={toggleAddLinkedInConnectionsModal}
          setDiaplayParentModal={toggleAddContactsModal}
          fileUploadRef={fileUploadRef}
          handleContactUploadsFromFile={handleContactUploadsFromFile}
          submitUploadsFromFile={submitUploadsFromFile}
        ></LinkedInConnectionsModal>
      )}
      <CloudspongeUploadModal
        displayModal={cloudspongeData.showModal}
        contactData={cloudspongeData}
        setDisplayModal={closeCloudspongeModal}
      />
      <AddMultipleContactsModal
        displayModal={showAddMultipleContactsModal}
        setDisplayModal={toggleAddMultipleContactsModal}
        handleContactUploadsFromFile={handleContactUploadsFromFile}
        fileUploadRef={fileUploadRef}
        submitUploadsFromFile={submitUploadsFromFile}
        handleChangeCSVDropdown={handleChangeCSVDropdown}
        loading={loading}
        failureMessage={failureMessage}
      />
      <SuccessModal
        displayModal={
          uploadStatus?.status === "Success" ||
          issuesWithReferralContacts.issue === "referral sent"
        }
        setDisplayModal={closeSuccessModal}
        message={
          uploadStatus?.status === "Success"
            ? uploadStatus?.message
            : "Referral sent!"
        }
      />
      <ErrorModal
        displayModal={
          uploadStatus === "failedManualUpload" ||
          uploadStatus === "failedCSVUpload"
        }
        setDisplayModal={setUploadStatus}
        useCase={
          uploadStatus === "failedManualUpload" ? "manualUpload" : "csvUpload"
        }
      />
    </>
  );
}

export default ContactModals;
