import React from "react";
import { Dropdown } from "semantic-ui-react";
import styled from "styled-components";

const DropdownWrapper = styled.div`
  display: flex;
  width: 100%;
  width: max-content;
  align-items: center;
  border-style: ${(props) => (props.showError ? "solid" : "")};
  border-color: red;
  border-radius: 5px;
  border-width: thin;
  .divider.text {
    padding-left: 14px;
    font-size: 14px;
  }
  .ui.search.selection.dropdown {
    width: 172px;
    display: flex;
    align-items: center;
    min-height: 38px;
    max-height: 38px;
    font-family: "Roboto", sans-serif;
    padding: 0;
    border-radius: 0 5px 5px 0px;
    background: #fcfcfc;
    font-size: 14px;

    div {
      box-sizing: border-box;
    }
  }
  label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    max-height: 38px;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props.theme.brandColor};
    padding: 12px 16px;
    background: #fcfcfc;
    border: 1px solid ${(props) => props.theme.borderColor};
    border-right: unset;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    width: max-content;
  }
`;

export function UseCaseDropdown({
  handleChange,
  useCase,
  introductionRequestHasBeenSent,
  showError,
}) {
  const options = [
    { key: "Fundraising", text: "Fundraising", value: "Fundraising" },
    { key: "Recruiting", text: "Recruiting", value: "Recruiting" },
    { key: "Sales", text: "Sales", value: "Sales" },
  ];

  return (
    <DropdownWrapper showError={showError}>
      <label>I'm using this for</label>
      <Dropdown
        placeholder="Fundraising, Recrui..."
        search
        selection
        options={options}
        onChange={handleChange}
        defaultValue={useCase}
        disabled={introductionRequestHasBeenSent ? true : false}
      />
    </DropdownWrapper>
  );
}
