import React, { useEffect, useState, createContext, useMemo } from "react";
import { Auth } from "aws-amplify";
import history from "../history";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const checkForUser = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      if (cognitoUser) {
        setUser(cognitoUser);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkForUser();
  }, []);

  const login = async (usernameOrEmail, password) => {
    setUser(null);
    await Auth.signOut();

    const cognitoUser = await Auth.signIn(usernameOrEmail, password);
    setUser(cognitoUser);
  };

  const logout = async () => {
    setUser(null);
    await Auth.signOut();
    history.push("/");
  };

  const values = useMemo(() => ({ user, login, logout, checkForUser }), [user]);

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
