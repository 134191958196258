import React from "react";
import RequestStatusTableSkeleton from "./RequestStatusTableSkeleton";
import styled from "styled-components";

const RequestTablesWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
`;

function RequestTablesSkeleton() {
  return (
    <RequestTablesWrapper>
      <RequestStatusTableSkeleton />
    </RequestTablesWrapper>
  );
}

export default RequestTablesSkeleton;
