import React from "react";
import styled from "styled-components";
import { MidLargeSpacedText } from "components/Typography";
import { AuthButtonNoArrow } from "components/Button";
import { UserAnalytics } from "containers/Profile/components";
import { Slide } from "react-awesome-reveal";
import OnboardingProgress from "./OnboardingProgress";

const OnboardingProfileHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const OnboardingProfileFooterWrapper = styled.div`
  display: flex;
  max-width: 1100px;
  width: 100%;
  align-self: center;
  padding: 0;
  margin-top: 40px;
`;

const OnboardingProfile = ({ handleSubmitOnboarding, profile }) => {
  return (
    <Slide right>
      <OnboardingProfileHeaderWrapper>
        <OnboardingProgress activeStep={4} />
        <h1>Congratulations!</h1>
        <MidLargeSpacedText>
          Your Revmo dashboard is ready, and you're one step closer to making
          new connections.
        </MidLargeSpacedText>
      </OnboardingProfileHeaderWrapper>
      <UserAnalytics userAnalytics={0} profile={profile} onboarding={true} />
      <OnboardingProfileFooter
        handleSubmitOnboarding={handleSubmitOnboarding}
      />
    </Slide>
  );
};

function OnboardingProfileFooter({ handleSubmitOnboarding }) {
  return (
    <OnboardingProfileFooterWrapper>
      <AuthButtonNoArrow
        buttonText="Finish"
        padding={"11px 30px"}
        disabled={false}
        action={() => handleSubmitOnboarding()}
      />
    </OnboardingProfileFooterWrapper>
  );
}

export default OnboardingProfile;
