import { useState, useEffect, useMemo, useCallback } from "react";

const useDisplayOptions = (numberOfOptions) => {
  const [displayOptions, setDisplayOptions] = useState([]);

  useEffect(() => {
    if (numberOfOptions) {
      const displayOption = {
        displayModal: false,
        showMenu: false,
      };
      const displayOptions = new Array(numberOfOptions).fill(displayOption);
      setDisplayOptions(displayOptions);
    }
  }, [numberOfOptions]);

  const toggleDisplayOption = useCallback(
    (index, fieldName) => {
      let updatedDisplayOption = {
        [fieldName]: !displayOptions[index][fieldName],
      };
      const updatedDisplayOptions = [...displayOptions];
      updatedDisplayOptions.splice(index, 1, updatedDisplayOption);
      setDisplayOptions([...updatedDisplayOptions]);
    },
    [displayOptions]
  );

  const closeAllDisplayOptions = useCallback(() => {
    const updatedDisplayOptions = [...displayOptions];
    updatedDisplayOptions.forEach((option) => {
      option.displayModal = false;
      option.showMenu = false;
    });
    setDisplayOptions(updatedDisplayOptions);
  }, [displayOptions]);

  const values = useMemo(
    () => ({
      displayOptions,
      toggleDisplayOption,
      closeAllDisplayOptions,
    }),
    [displayOptions, toggleDisplayOption, closeAllDisplayOptions]
  );

  return values;
};

export default useDisplayOptions;
