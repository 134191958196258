import React from "react";
import styled from "styled-components";
import { WhiteLargeFormInputHooksPlaceHolder } from "components/Input";
import arrowBlack from "assets/icons/arrow-black-to-right.svg";
import { MidBlackBoldText, BlueLinkTextUnderlined } from "../../Typography";

export const ExistingTagsWrapper = styled.div`
  margin: 20px 0 12px 0;
  p {
    line-height: 26px;
    margin-bottom: 20px;
  }
`;

export function TagInputs({ register, error }) {
  return (
    <>
      <form>
        <WhiteLargeFormInputHooksPlaceHolder
          fieldName="Tag name"
          name="name"
          error={error}
          register={register}
          placeholder="Create a shareable tag"
        />
      </form>
    </>
  );
}

export function TagInputsWithoutPreLoadData({ error, register }) {
  return (
    <>
      <form>
        <WhiteLargeFormInputHooksPlaceHolder
          fieldName="Tag name"
          name="name"
          error={error}
          register={register}
          placeholder="Create a shareable tag"
        />
      </form>
    </>
  );
}

const RelationshipHistoryWrapper = styled.div`
  position: relative;
  width: 100%;
  p {
    position: absolute;
    font-size: 13px;
    line-height: 15px;
    color: #313237;
    z-index: 2;
    left: 19px;
    top: 13px;
    pointer-events: none;
  }
`;

const RelationshipHistorySelect = styled.select`
  background: #ffffff;
  border: 1px solid #e3e6e9;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 60px;
  -webkit-appearance: none;
  padding-left: 19px;
  padding-top: 24px;
  ${({ error }) => {
    if (error) {
      return `
      border: 1px solid #F9007A;
      `;
    }
  }}
`;

const DropdownArrowContainer = styled.div`
  position: absolute;
  right: 0;
  top: 1px;
  bottom: 1px;
  width: 58px;
  border-left: 1px solid #e3e6e9;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  img {
    transform: rotate(90deg);
  }
  ${({ error }) => {
    if (error) {
      return `
      border-left: 1px solid #F9007A;
      `;
    }
  }}
`;

const ExistingTagsDropdownWrapper = styled(RelationshipHistoryWrapper)`
  margin-top: 20px;
`;

export function SettingsDropdown({
  register,
  defaultValue,
  error,
  setMessage,
}) {
  return (
    <RelationshipHistoryWrapper>
      <p>Settings</p>
      <RelationshipHistorySelect
        onChange={(event) => setMessage(event, "newTagName")}
        name={"setting"}
        ref={register}
        error={error?.setting}
      >
        <option value="" disabled selected hidden>
          Please select an option below:
        </option>
        <option
          value={0}
          selected={defaultValue === 0 ? true : false}
          ref={register}
        >
          None
        </option>
        <option value={1} selected={defaultValue === 1 ? true : false}>
          Low
        </option>
        <option value={2} selected={defaultValue === 2 ? true : false}>
          Medium
        </option>
        <option value={3} selected={defaultValue === 3 ? true : false}>
          High
        </option>
      </RelationshipHistorySelect>
      <DropdownArrowContainer error={error?.setting}>
        <img src={arrowBlack} />
      </DropdownArrowContainer>
    </RelationshipHistoryWrapper>
  );
}

export function AskIfTheUserWouldLikeToSelectAnExistingTag({
  register,
  error,
  allTags,
  contactTag,
}) {
  const flattenedTagNames = contactTag?.map((tag) => tag.name);
  return (
    <ExistingTagsDropdownWrapper>
      <p>Tag name</p>
      <RelationshipHistorySelect
        name={"otherTags"}
        ref={register}
        error={error?.setting}
        defaultValue="select"
      >
        <option value="select" disabled hidden>
          Select an existing tag:
        </option>
        {allTags.map((tag) => {
          if (!flattenedTagNames?.includes(tag.name)) {
            return (
              <option key={tag.name} value={JSON.stringify(tag)}>
                {tag.name}
              </option>
            );
          }
        })}
      </RelationshipHistorySelect>
      <DropdownArrowContainer error={error?.otherTags}>
        <img src={arrowBlack} />
      </DropdownArrowContainer>
    </ExistingTagsDropdownWrapper>
  );
}

const TagCardWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const TagCard = styled.div`
  margin-top: 20px;
  background: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0 px;
  overflow: hidden;
  width: 100%;
`;
const TagCardSectionFirst = styled.div`
  background: white;
  padding: 37px 19px 15px 30px;
  p {
    font-size: 18px;
    display: block;
    margin: 0;
    :nth-child(2) {
      font-size: 15px;
      margin-top: 8px;
    }
    :nth-child(3) {
      margin-top: 5px;
      font-size: 13px;
    }
  }
`;

const TagCardSectionSecond = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f7f7f8;
  border-top: 1px solid #e3e6e9;
`;

const TagCardHalfContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px;
`;

const BlueLinkTextUnderlinedTag = styled(BlueLinkTextUnderlined)`
  font-size: 14px;
`;

export function ExistingTag({ contactTags, contact, deleteContactTag, org }) {
  // If contact returns is_contact, set this value as UUID. Else, check for org or contact UUID.
  let uuid = contact.is_contact
    ? contact.is_contact
    : !org
    ? contact.uuid
    : contact.org_uuid;
  return (
    <>
      {contactTags.map((contactTag) => {
        const { name } = contactTag;
        return (
          <TagCardWrapper key={name}>
            <TagCard>
              <TagCardSectionFirst>
                <MidBlackBoldText>{name}</MidBlackBoldText>
              </TagCardSectionFirst>
              <TagCardSectionSecond>
                <TagCardHalfContainer>
                  <BlueLinkTextUnderlinedTag
                    onClick={async () => deleteContactTag(contactTag, uuid)}
                  >
                    Delete tag
                  </BlueLinkTextUnderlinedTag>
                </TagCardHalfContainer>
              </TagCardSectionSecond>
            </TagCard>
          </TagCardWrapper>
        );
      })}
    </>
  );
}
