import { Auth } from "aws-amplify";
import { revmoApiUrl } from "../config";

const postFileData = async (url, payload) => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  let res = await fetch(revmoApiUrl + url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + cognitoUser.signInUserSession.idToken.jwtToken,
      "Permissions-Policy": "interest-cohort=()",
    },
    body: payload,
  });
  const json = await res.json();
  if (res.status >= 200 && res.status <= 299) {
    return json;
  } else {
    throw json;
  }
};

export default postFileData;
