import { atom } from "recoil";

export const searchAddressState = atom({
  key: "searchAddressState",
  default: {
    location: "",
    error: "",
  },
});

export const clearSearchAddressState = atom({
  key: "clearSearchAddressState",
  default: false,
});
