import React from "react";
import styled from "styled-components";
import { SectionWrapperDark } from "../Layout";
import { IntroductionSection } from ".";
import contactsDiagram from "assets/images/contacts-fragmented.png";
import revmoDiagram from "assets/images/revmo-diagram.png";

const HowItWorksContentWrapper = styled.div`
  padding: calc(40vmax / 10) 4vw calc(40vmax / 10) 4vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1,
  h2,
  h4,
  p {
    color: white;
  }
`;

function WhoIsItFor() {
  const content = [
    {
      title: "Networks are fragmented - who do you really know?",
      text: "",
      // text:
      // "A typical user has 1,000 + connections across many platforms. How do you search them? How do you know which ones are real?",
      image: contactsDiagram,
    },
    {
      title:
        "Looking to connect with specific people, industries or organisations?",
      text: "",
      // "Whether you’re looking for a natural introduction to an investor, a new opportunity (or a new hire), or a possible addition to your business development pipeline, Revmo is the simple, secure, and efficient way to connect people to the right people.",
      image: revmoDiagram,
    },
  ];
  return (
    <SectionWrapperDark>
      <HowItWorksContentWrapper>
        <h1>What problems do we solve?</h1>
        <IntroductionSection content={content} />
      </HowItWorksContentWrapper>
    </SectionWrapperDark>
  );
}

export default WhoIsItFor;
