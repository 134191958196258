import React from "react";
import { LandingNavbar } from "components/Navbar";
import { LandingPageContainer, LandingFooter } from "components/LandingPage";
import { AboutUsHero, AboutUsBios } from "./components";
import {
  LandingCallToAction,
  FAQ,
  TeamSection,
} from "../LandingPage/components";

function AboutUs() {
  return (
    <>
      <LandingPageContainer>
        <LandingNavbar />
        <AboutUsHero />
        <AboutUsBios />
        <FAQ />
        <TeamSection />
        <LandingCallToAction />
      </LandingPageContainer>
      <LandingFooter />
    </>
  );
}

export default AboutUs;
