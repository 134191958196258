import React from "react";
import {
  HeaderText,
  MidLargeSpacedText,
  ExtraSmallBlackText,
  GreyLinkText,
} from "components/Typography";
import { Slide } from "react-awesome-reveal";
import { AuthButtonNoArrow } from "components/Button";
import { WhiteLargeFormInputHooksForm } from "components/Input";
import OnboardingProgress from "./OnboardingProgress";
import {
  OnboardingForm,
  HeaderContainer,
  FooterWrapper,
  InformationTitleContainerProfessionalData,
  OnboardingPreviousPage,
} from "components/Onboarding";
import { OrgsAutocomplete } from "components/Autocomplete";
import { useResetFormData } from "../utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { professionalDataSchema } from "../utils";

function OnboardingProfessionalData({
  onboardingFields,
  handleChangeOnboardingProfessionalData,
  prevPage,
  currentOnboardingPage,
  diallingCodesHook,
}) {
  const { control, register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(professionalDataSchema),
  });

  // load in loadOnboardingFields[personalData] with a useEffectHook

  useResetFormData(
    reset,
    onboardingFields,
    currentOnboardingPage,
    diallingCodesHook
  );
  //has a useEffectHook in it

  return (
    <Slide right>
      <HeaderContainer>
        <OnboardingProgress activeStep={1} />
        <HeaderText>What type of work do you do?</HeaderText>
        <MidLargeSpacedText>
          We'll use this to help you connect with others in your organisation.
        </MidLargeSpacedText>
      </HeaderContainer>
      <InformationTitleContainerProfessionalData>
        <ExtraSmallBlackText>*Denotes required field</ExtraSmallBlackText>
      </InformationTitleContainerProfessionalData>
      <OnboardingForm
        onSubmit={handleSubmit(handleChangeOnboardingProfessionalData)}
      >
        <OrgsAutocomplete name="company" error={errors} control={control} />
        <WhiteLargeFormInputHooksForm
          name="position"
          fieldName={"Position*"}
          error={errors}
          register={register}
        />
        <WhiteLargeFormInputHooksForm
          name="companyEmail"
          fieldName={"Company Email*"}
          error={errors}
          register={register}
          type="email"
        />
        <FooterWrapper>
          <AuthButtonNoArrow
            buttonText={"Continue"}
            padding={"11px 30px"}
            type="submit"
          />
          <OnboardingPreviousPage onClick={prevPage}>
            <GreyLinkText>Back</GreyLinkText>
          </OnboardingPreviousPage>
        </FooterWrapper>
      </OnboardingForm>
    </Slide>
  );
}

export default OnboardingProfessionalData;
