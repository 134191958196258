import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import gettingStarted1 from "assets/images/GettingStarted/gettingstarted1.jpg";

const InitialSetupWrapper = styled.div`
  padding: 10rem 2rem;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 870.9px) {
    padding-bottom: 0;
    padding-top: 2rem;
  }
  h2 {
    font-size: 40px;
    color: black;
    line-height: 1.2em;
    font-weight: bold;
    font-style: normal;
    padding-bottom: 2rem;
  }
  p {
    font-size: 18px;
    width: 80%;
    max-width: 800px;
    padding-bottom: 2rem;
  }
`;

const InitialSetupContentWrapper = styled.div`
  width: 100%;
  max-width: 1224px;
  display: flex;
  align-items: center;
  @media (min-width: 871px) {
    flex-direction: row;
    /* margin-bottom: 10rem; */
  }
  @media (max-width: 870.9px) {
    display: flex;
    flex-direction: column;
  }
`;

const InitialSetupImage = styled.div`
  overflow: hidden;
  img {
    object-fit: contain;
    min-height: 100%;
  }
  @media (min-width: 871px) {
    flex: 0 0 auto;
    width: 60%;
    height: 35rem;
    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 0;
      vertical-align: middle;
    }
  }
  @media (max-width: 870.9px) {
    max-width: 870px;
    /* background-color: #242323; */
    /* min-height: 400px; */
    max-height: 500px;
    display: flex;
    justify-content: center;
    img {
      max-height: 500px;
      width: 100%;
    }
  }
`;

const InitialSetupText = styled.div`
  display: flex;
  align-items: center;
  div {
    p {
      color: black;
      font-size: 18px;
    }
    ol {
      text-align: left;
      font-size: 20px;
      font-family: "Montserrat", "sans-serif";
      line-height: 1.2em;
      li {
        padding-bottom: 1rem;
      }
    }
    a {
      font-family: "Montserrat", "sans-serif";
      font-style: normal;
    }
  }

  @media (min-width: 871px) {
    flex: 0 0 auto;
    width: 40%;
    justify-content: center;
    text-align: center;
    text-color: black;
    div {
      border-left: 0.5rem solid #5374ff;
    }
  }
  @media (max-width: 870.9px) {
    min-height: 400px;
    justify-content: center;
    text-align: center;
    width: 100%;
    div {
      max-width: 90%;
    }
  }
`;

const InitialSetup = () => {
  return (
    <InitialSetupWrapper>
      <h2>Initial Setup</h2>
      <InitialSetupContent />
      <p>
        <strong>Note:</strong> All data received is encrypted with bank-level
        256 bit encryption. Revmo employees cannot see your data, and you can
        choose which data can be shared across different networks under your
        Revmo settings.
      </p>
    </InitialSetupWrapper>
  );
};

const InitialSetupContent = () => {
  return (
    <InitialSetupContentWrapper>
      <InitialSetupImage>
        <img src={gettingStarted1} />
      </InitialSetupImage>
      <InitialSetupText>
        <div>
          <ol>
            <li>
              Register your account <NavLink to="/sign-up">here</NavLink>
            </li>
            <li>
              Follow the instructions below to download your contact list from
              Linkedin, Gmail, iCloud, Yahoo, and/or Outlook 365.
            </li>
            <li>
              Upload your contacts into Revmo at{" "}
              <NavLink to="/network">beta.revmo.app/network</NavLink>
            </li>
          </ol>
        </div>
      </InitialSetupText>
    </InitialSetupContentWrapper>
  );
};

export default InitialSetup;
