import React, { useState } from "react";
import { PageWrapperFullWidth } from "components/Layout";
import {
  SettingsHeader,
  // AccountPreferences,
  AccountSettings,
  SettingsModals,
} from "./components";
import { useUser, useProfile } from "hooks";
import { Auth } from "aws-amplify";
import { formatProfile } from "./utils";
import { usePaymentAndSubscriptionInfo } from "./hooks";
import { putData } from "utils";
import history from "../../history";
import { useLocation } from "react-router-dom";
import { useInternationalDiallingCodes } from "hooks";

function Settings() {
  const [settingsType, setSettingsType] = useState("Account settings");
  const [displayEditPersonalInfoModal, setDisplayEditPersonalInfoModal] =
    useState(false);
  const [displayEditPasswordModal, setDisplayEditPasswordModal] =
    useState(false);
  const [updatePasswordStatus, setUpdatePasswordStatus] = useState({
    status: null,
  });

  const { user } = useUser();
  const { profile, updateUserProfile, getUserProfile } = useProfile();
  const [address, setAddress] = useState({
    address1: "",
    personalLatLng: "",
  });

  const internationalDiallingCodesHook = useInternationalDiallingCodes();

  const showEditPersonalInfoModal = () => {
    if (profile?.telephone_number?.country_code) {
      let diallingCodeState = {};
      diallingCodeState.personalPhoneNumber = profile?.telephone_number;
      internationalDiallingCodesHook.setDiallingCodeToLiveData(
        diallingCodeState
      );
    }
    setDisplayEditPersonalInfoModal(true);
  };

  const location = useLocation();
  usePaymentAndSubscriptionInfo(location, profile);

  const handleSubmitUpdatePassword = async (data) => {
    try {
      const { oldPassword, newPassword } = data;
      if (oldPassword === newPassword) {
        setUpdatePasswordStatus({
          status: "Error",
          message: "Your previous password is not valid as a new password.",
        });
      } else {
        await Auth.changePassword(user, oldPassword, newPassword);
        setUpdatePasswordStatus({
          status: "Password has been updated",
          message: "Your password has been successfully updated",
        });
        setDisplayEditPasswordModal(false);
      }
    } catch (err) {
      const errorMessages = {
        InvalidParameterException: "Password must be at least 6 characters",
        NotAuthorizedException: "Incorrect username or password.",
      };
      setUpdatePasswordStatus({
        status: "Failed",
        message: errorMessages[err.code],
      });
    }
  };

  const handleSubmitUpdateConfirmationCode = async (data) => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit(
        "email",
        data.confirmationCode
      );
      // in here, once again, update user data
      setUpdatePasswordStatus({
        status: "Email has been updated",
        message: "Your email has been successfully updated",
      });
    } catch (err) {
      console.log(err);
      setUpdatePasswordStatus({
        status: "Confirmation Code",
        message: "Please enter a valid confirmation code",
      });
    }
  };

  const handleSubmitUpdatePersonalInfo = async (data) => {
    const formattedProfileData = formatProfile(
      data,
      profile,
      address,
      internationalDiallingCodesHook.diallingCode
    );
    const updatedProfile = { ...profile, ...formattedProfileData };
    // internationalDiallingCodesHook
    try {
      const { email } = data;
      if (email !== profile.email_address) {
        await Auth.updateUserAttributes(user, {
          email,
        });
        setUpdatePasswordStatus({
          status: "Confirmation Code",
        });
      }
      await putData(
        `/profile/${profile.cognito_user_id}`,
        JSON.stringify(updatedProfile)
      );
      updateUserProfile(updatedProfile);
      setDisplayEditPersonalInfoModal(false);
    } catch (err) {
      setUpdatePasswordStatus({ status: err.message });
      console.log(err);
    }
  };

  const closeSuccessModal = () => {
    if (location.pathname.includes("success")) {
      getUserProfile();
    }
    history.push("/settings");
  };

  return (
    <PageWrapperFullWidth>
      <SettingsHeader
        setSettingsType={setSettingsType}
        settingsType={settingsType}
      />
      <AccountSettings
        setDisplayEditPersonalInfoModal={setDisplayEditPersonalInfoModal}
        showEditPersonalInfoModal={showEditPersonalInfoModal}
        setDisplayEditPasswordModal={setDisplayEditPasswordModal}
        profile={profile}
      />
      <SettingsModals
        profile={profile}
        setDisplayModal={setDisplayEditPersonalInfoModal}
        handleSubmitUpdatePersonalInfo={handleSubmitUpdatePersonalInfo}
        displayEditPasswordModal={displayEditPasswordModal}
        handleSubmitUpdatePassword={handleSubmitUpdatePassword}
        setDisplayEditPasswordModal={setDisplayEditPasswordModal}
        setUpdatePasswordStatus={setUpdatePasswordStatus}
        updatePasswordStatus={updatePasswordStatus}
        displayEditPersonalInfoModal={displayEditPersonalInfoModal}
        setDisplayEditPersonalInfoModal={setDisplayEditPersonalInfoModal}
        address={address}
        setAddress={setAddress}
        handleSubmitUpdateConfirmationCode={handleSubmitUpdateConfirmationCode}
        location={location}
        closeSuccessModal={closeSuccessModal}
        internationalDiallingCodesHook={internationalDiallingCodesHook}
      />
    </PageWrapperFullWidth>
  );
}

export default Settings;
