import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import { ProfileInfo } from ".";
import { borderRadius } from "containers/DashboardNew/theme";

import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";

const ProfileDetails = ({
  setShowAddPositionModal,
  userContact,
  fromSearchPage,
  handleSubmitUpdatePosition,
  handleOnDragEnd,
  profile,
  jobsAreDraggable,
  setJobsAreDraggable,
  displayOptions,
  toggleDisplayOption,
  displayContactOptions,
  toggleDisplayContactOptions,
  handleDeletePosition,
  toggleAddOrEditSingleContactModal,
  diallingCodesHook,
  customFormErrorsHook,
}) => {
  return (
    <Card sx={{ borderRadius: borderRadius }}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <CardHeader
          subheader={`Adding your ${
            userContact ? "contact's " : ""
          }career information can help improve your search results.`}
          title="Experience"
          action={
            <Stack direction="row" justifyContent="center" alignItems="center">
              {!fromSearchPage && (
                <Tooltip placement="top" title="Add Experience">
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    size="large"
                    onClick={
                      !userContact
                        ? setShowAddPositionModal
                        : toggleAddOrEditSingleContactModal
                    }
                  >
                    <AddIcon sx={{ fontSize: "1.8rem" }} />
                  </IconButton>
                </Tooltip>
              )}
              {!userContact && (
                <Tooltip placement="top" title="Reorder Roles">
                  <span>
                    <IconButton
                      color="primary"
                      aria-label="reorder"
                      size="large"
                      onClick={() => setJobsAreDraggable(!jobsAreDraggable)}
                      disabled={profile?.positions?.length >= 2 ? false : true}
                    >
                      <ListIcon sx={{ fontSize: "1.8rem" }} />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Stack>
          }
        />
        <Divider />
        <CardContent>
          <ProfileInfo
            userContact={userContact}
            fromSearchPage={fromSearchPage}
            handleSubmitUpdatePosition={handleSubmitUpdatePosition}
            setShowAddPositionModal={setShowAddPositionModal}
            handleOnDragEnd={handleOnDragEnd}
            profile={profile}
            jobsAreDraggable={jobsAreDraggable}
            setJobsAreDraggable={setJobsAreDraggable}
            toggleDisplayOption={toggleDisplayOption}
            displayOptions={displayOptions}
            displayContactOptions={displayContactOptions}
            toggleDisplayContactOptions={toggleDisplayContactOptions}
            handleDeletePosition={handleDeletePosition}
            toggleAddOrEditSingleContactModal={
              toggleAddOrEditSingleContactModal
            }
            diallingCodesHook={diallingCodesHook}
            customFormErrorsHook={customFormErrorsHook}
          />
        </CardContent>
      </DragDropContext>
    </Card>
  );
};

export default ProfileDetails;
