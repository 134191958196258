import React from "react";
import {
  AdvancedSearchInputContainer,
  InputName,
  InputWrapperTickbox,
} from "./index";
import { Tickbox } from "components/Input";
import styled, { css } from "styled-components";
import showContactIcon from "assets/icons/show-contacts-icon.svg";
import showContactIconWhite from "assets/icons/show-contacts-white.svg";

const TickboxIncludeContactsText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #7c919e;
  margin: 0 auto 0 4px;
  ${(props) =>
    props.selected &&
    css`
      color: white;
    `}
`;

const ShowContactsIcon = styled.img`
  height: 22px;
  width: 20px;
`;

function AdvancedSearchTickbox({
  searchOptions,
  toggleIncludeInSearch,
  headerText,
  roles,
}) {
  const { includeContactsInSearch, includePreviousRolesInSearch } =
    searchOptions;
  let selectedField;
  if (roles) {
    selectedField = includePreviousRolesInSearch;
  } else {
    selectedField = includeContactsInSearch;
  }

  return (
    <AdvancedSearchInputContainer>
      <InputName>{headerText}</InputName>
      <InputWrapperTickbox selected={selectedField}>
        <ShowContactsIcon
          src={selectedField ? showContactIconWhite : showContactIcon}
        />
        <TickboxIncludeContactsText selected={selectedField}>
          Include in list
        </TickboxIncludeContactsText>
        <Tickbox
          displayTickbox={selectedField}
          setDisplayTickbox={toggleIncludeInSearch}
        />
      </InputWrapperTickbox>
    </AdvancedSearchInputContainer>
  );
}

export default AdvancedSearchTickbox;
