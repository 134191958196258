import React from "react";
import { AppTheme } from "./components";
import Dashboard from "./components/dashboard";

function DashboardNew() {
  return (
    <AppTheme>
      <Dashboard />
    </AppTheme>
  );
}

export default DashboardNew;

export { default as Home } from "./Home";
export { default as SettingsNew } from "./Profile";
