import React from "react";

// material-ui
import Grid from "@mui/material/Grid";

import { gridSpacing } from "containers/DashboardNew/theme";
import UserContactCard from "./UserContactCard";

function UserContactsCards({
  contactsAll,
  toggleAddOrEditSingleContactModal,
  deleteContact,
}) {
  const displayEditPositionModal = (index, contact) => {
    toggleAddOrEditSingleContactModal("edit", index, contact);
  };

  return (
    <Grid
      container
      alignItems="center"
      spacing={gridSpacing}
      sx={{ display: { xs: "block", md: "none" } }}
    >
      <Grid item xs={12}>
        <Grid container direction="row" spacing={gridSpacing}>
          {contactsAll &&
            contactsAll.map((contact, index) => (
              <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                <UserContactCard
                  contact={contact}
                  index={index}
                  displayEditPositionModal={displayEditPositionModal}
                  deleteContact={deleteContact}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UserContactsCards;
