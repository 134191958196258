import { useState, useCallback, useMemo } from "react";

const usePagination = (totalRequests = 1) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const memoizedCurrentPage = useMemo(() => {
    return currentPage;
  }, [currentPage]);
  const maxPage = Math.ceil(totalRequests / itemsPerPage);
  const memoizedMaxPage = useMemo(() => {
    return maxPage;
  }, [maxPage]);

  const nextPage = useCallback(() => {
    let followingPage = currentPage < maxPage ? currentPage + 1 : currentPage;
    setCurrentPage(followingPage);
  }, [currentPage, maxPage]);

  const prevPage = useCallback(() => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }, []);

  const jumpPage = useCallback((page) => {
    setCurrentPage(Math.min(page));
  }, []);

  const getPaginatedResults = useCallback(
    (allResults) => {
      let updatedResults = [...allResults];
      updatedResults = [
        ...allResults.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        ),
      ];
      return updatedResults;
    },
    [currentPage, itemsPerPage]
  );

  return {
    nextPage,
    prevPage,
    jumpPage,
    currentPage: memoizedCurrentPage,
    maxPage: memoizedMaxPage,
    getPaginatedResults,
    itemsPerPage,
    setItemsPerPage,
  };
};

export default usePagination;
