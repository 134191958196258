import React from "react";
import infoCircle from "assets/icons/info-icon-green.svg";
import {
  TableHeaderText,
  RequestStatusTableColumnCallToAction,
  StatusText,
} from ".";
import { IntroductionButton } from "components/Button";

function CallToAction({ index, requestType }) {
  return (
    <RequestStatusTableColumnCallToAction status>
      <TableHeaderText index={index}>YOU SHOULD</TableHeaderText>
      {requestType !== "REJECTED" ? (
        <>
          <img src={infoCircle} alt="" />
          <StatusText>
            Please wait to be <br /> contacted directly
          </StatusText>
        </>
      ) : (
        <IntroductionButton color="blue" table>
          Re-try the intro request
        </IntroductionButton>
      )}
    </RequestStatusTableColumnCallToAction>
  );
}

export default CallToAction;
