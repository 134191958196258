import styled from "styled-components";
import authBackground from "assets/images/auth-background.png";
import { ExitIcon } from "components/Assets/Icon";

export const OnboardingBody = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  textarea {
    font-family: "Montserrat", sans-serif;
    font-weight: normal;
  }
  min-height: 100vh;
  background: url(${authBackground});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
`;

export const InternalContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media only screen and (min-width: 768px) {
    flex-basis: 66.66%;
    height: unset;
  }
  align-items: center;
  justify-content: center;
`;

export const InternalContainer = styled.div`
  @media only screen and (min-width: 768px) {
    max-width: 550px;
    width: 80%;
  }
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fffefb;
  border: 1px solid #f0f2f4;
  box-sizing: border-box;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.04),
    0px 6px 10px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  overflow: hidden;
`;

export const OnboardingForm = styled.form`
  margin-top: 16px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 14px;
  position: relative;
  h1 {
    margin-top: 5px;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    h1 {
      order: 1;
      margin-top: 2px;
    }
    p {
      margin-left: auto;
    }
  }
`;

export const HeaderContainerMarginTop = styled(HeaderContainer)`
  padding-top: 42px;
`;

export const HeaderContainerPersonalData = styled(HeaderContainer)`
  text-align: center;
  h1 {
    width: 80%;
  }
`;

export const FooterWrapper = styled.div`
  margin-top: 1px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  div {
    p {
      margin-bottom: 0;
    }
  }
`;

export const InformationTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const InformationTitleContainerProfessionalData = styled(
  InformationTitleContainer
)``;

export const OnboardingPreviousPage = styled.div`
  display: flex;
  width: max-content;
  cursor: pointer;
  p {
    margin-bottom: 16px;
  }
`;

export const ExitOnboarding = styled(ExitIcon)`
  position: absolute;
  top: 4%;
  right: 20px;
  transform: translateY(-50%);
  color: #000a33;
  @media (max-width: 480px) {
    height: 25px;
    width: 25px;
  }
  :hover {
    color: grey;
    cursor: pointer;
  }
`;

export { default as Sidebar } from "./Sidebar";
