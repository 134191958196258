import styled from "styled-components";

export const NewModalPageWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  textarea {
    font-family: "Montserrat", sans-serif;
    font-weight: normal;
  }
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5000;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    padding-top: 10px;
  }
`;

export const NewModalContainerAddOrEditContact = styled.div`
  height: max-content;
  background: #fffefb;
  z-index: 50;
  padding: 54px 15px 61px 15px;
  margin-bottom: 100px;
  width: 100%;
  max-width: 747px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 54px;
  margin-bottom: 100px;
  border-radius: 4px;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.04),
    0px 6px 10px rgba(0, 0, 0, 0.02);
  border: 1px solid #f0f2f4;
  @media (max-width: 767px) {
    padding-top: 62px;
    padding-bottom: 42px;
  }
`;
