import React from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { searchAddressState } from "state";
import {
  SearchButtonDashboard,
  AdvancedSearchButtonDashboard,
} from "components/Button";
import { OrgsAutocompleteSearch } from "components/Autocomplete/OrgsAutocomplete";
import arrowWhite from "assets/icons/arrow-white.svg";
import chevronWhite from "assets/icons/chevron-white.svg";
import { SearchFormInput } from "components/Input";
import { useForm } from "react-hook-form";
import { handleSubmitDashboardSearch } from "containers/DashboardNew/utils";

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 870px) {
    flex-direction: column;
  }
`;

export const SearchFormContainer = styled.div`
  display: flex;
  flex: 1;
  div {
    :first-child {
      margin-right: 19px;
    }
    div {
    }
  }
  @media (max-width: 870px) {
    flex-direction: column;
    div {
      margin-bottom: 11px;
    }
  }
`;

export const SearchBarIcon = styled.img`
  pointer-events: none;
`;

export const DashboardButtonWrapper = styled.div`
  display: flex;
  @media (max-width: 480px) {
    a {
      margin-right: 9px;
      width: calc(50% - 4.5px);
    }
  }
`;

export function DashboardSearchForm({
  showAdvancedSearch,
  setShowAdvancedSearch,
  openAndCloseDropdowns,
  disableForm,
  advancedSearchHook,
}) {
  const { register, handleSubmit, watch } = useForm();
  const disabled = disableForm(watch());
  const [searchAddress] = useRecoilState(searchAddressState);
  const { searchOptions, setSearchOptions } = advancedSearchHook;

  return (
    <SearchContainer>
      <SearchFormContainer className="walkthrough-step-2">
        <SearchFormInput
          register={register}
          fieldName="Person"
          placeholder="Ex. Art Vandelay"
          name="person"
        />
        <OrgsAutocompleteSearch
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
        />
      </SearchFormContainer>
      <DashboardButtonWrapper>
        <SearchButtonDashboard
          disabled={disabled}
          type="submit"
          onClick={handleSubmit((data) =>
            handleSubmitDashboardSearch(
              data,
              openAndCloseDropdowns,
              searchAddress
            )
          )}
        >
          <p>Search</p>
          <SearchBarIcon searchButton src={arrowWhite} />
        </SearchButtonDashboard>
        <AdvancedSearchButtonDashboard
          onClick={() => {
            setShowAdvancedSearch(!showAdvancedSearch);
          }}
        >
          <p>Advanced Search</p>
          <SearchBarIcon advancedSearchButton src={chevronWhite} />
        </AdvancedSearchButtonDashboard>
      </DashboardButtonWrapper>
    </SearchContainer>
  );
}
