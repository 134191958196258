import React from "react";
import styled from "styled-components";
import { Zoom } from "react-awesome-reveal";
import revmoTeam from "assets/images/revmo-team-06-22.png";

const HeroWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const HeroContentWrapper = styled.div`
  min-width: 100%;
  min-height: 50vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 3rem 5rem;

  @media (max-width: 767px) {
    padding: 2rem;
    margin-top: 2em;
  }
`;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1300px;
  color: white;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
  gap: 0em 10em;
  align-items: center;
`;

const TextContainer = styled.div`
  text-align: left;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  color: #000a33;
  h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    max-width: 700px;
  }
  h1,
  h3 {
    margin-top: 0;
  }
  @media (max-width: 767px) {
    div {
      width: 100%;
    }
    h1 {
      font-size: 32.2px;
      line-height: 36px;
      letter-spacing: -0.04em;
      text-align: center;
      min-height: 140px;
      margin: 0;
    }
  }
  @media screen and (min-width: 768px) {
    h1 {
      font-size: calc(37px + 8 * ((100vw - 768px) / 432));
    }
    h3 {
      font-size: calc(13px + 3 * ((100vw - 768px) / 432));
    }
  }
  @media screen and (orientation: portrait) and (min-width: 768px) and (max-height: 1100px) {
    h1 {
      margin-top: 200px;
    }
  }

  button {
    width: 100%;
    margin-top: 2em;
  }
  @media screen and (min-width: 768px) {
    align-items: flex-start;
  }
`;

const IntroText = styled.div`
  text-align: center;
  line-height: 28px;
  mix-blend-mode: luminosity;

  p {
    font-size: 20px;
    max-width: 500px;
  }
  @media screen and (min-width: 768px) {
    text-align: left;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  img {
    max-width: 100%;
  }
  @media only screen and (min-width: 992px) {
  }
  @media only screen and (min-width: 768px) {
    width: 100%;
    align-items: flex-end;
  }
`;

const CareersHero = () => {
  return (
    <HeroWrapper>
      <HeroContentWrapper>
        <Overlay>
          <TextContainer>
            <Zoom>
              <h1>Join our team.</h1>
              <IntroText>
                <h3>Come join our quest.</h3>
                <p>
                  Help us build a platform for professional networking that
                  respects the privacy of our users.
                </p>
              </IntroText>
            </Zoom>
          </TextContainer>
          <ImageContainer>
            <Zoom>
              <img src={revmoTeam} />
            </Zoom>
          </ImageContainer>
        </Overlay>
      </HeroContentWrapper>
    </HeroWrapper>
  );
};

export default CareersHero;
