import { atom, selector } from "recoil";

export const industryListQuery = atom({
  key: "IndustryList",
  default: selector({
    key: "IndustryList/Default",
    get: async () => {
      const fetchIndustry = async (url) => {
        let res = await fetch(url, {
          method: "GET",
        });
        const json = await res.json();
        if (res.status >= 200 && res.status <= 299) {
          return json;
        } else {
          throw json;
        }
      };
      const industries = await fetchIndustry(
        `${process.env.REACT_APP_INDUSTRY_BUCKET}/industry/REVMO.json`
      );

      function sortArray(industries) {
        industries.forEach((a) => {
          if (a.sub_industry) {
            sortArray(a.sub_industry);
          }
        });
        industries.sort((a, b) => {
          let textA = a.name.toUpperCase();
          let textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        return industries;
      }
      return sortArray(industries);
    },
  }),
});
