import React from "react";
import styled from "styled-components";

const ShowMoreButtonContainer = styled.div`
  width: 19px;
  display: flex;
  justify-content: space-between;
  height: 4px;
  cursor: pointer;
  ${(props) => {
    if (props.dropdownIsOpen) {
      return `
        pointer-events: none;
      `;
    }
  }}
  ${(props) => {
    if (props.hide) {
      return `
        visibility: hidden;
      `;
    }
  }}
`;

const ShowMoreButtonContainerMargin = styled(ShowMoreButtonContainer)`
  margin-top: 5px;
`;

const RoundCircle = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 100%;
  background: black;
`;

export function ShowMoreButton({
  toggleMenu,
  menuState,
  dropdownIsOpen,
  hide,
}) {
  return (
    <ShowMoreButtonContainer
      className="showMore"
      onClick={() => toggleMenu(menuState)}
      dropdownIsOpen={dropdownIsOpen}
      hide={hide}
    >
      <RoundCircle />
      <RoundCircle />
      <RoundCircle />
    </ShowMoreButtonContainer>
  );
}

export function ShowMoreButtonReferAFriend({ action }) {
  return (
    <ShowMoreButtonContainerMargin className="showMore" onClick={action}>
      <RoundCircle />
      <RoundCircle />
      <RoundCircle />
    </ShowMoreButtonContainerMargin>
  );
}
