import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ErrorMessage } from "components/Typography";

const PrivacyPolicyCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PrivacyPolicyCheckboxContent = styled.div`
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  input {
    margin-right: 10px;
  }
`;

const PrivacyPolicyCheckbox = ({ register, errors, name, value }) => {
  return (
    <PrivacyPolicyCheckboxWrapper>
      <PrivacyPolicyCheckboxContent>
        <input
          ref={register({ required: "This is required" })}
          name={name}
          value={value}
          type="checkbox"
        />
        <p>
          By selecting this box, you agree to the terms of our{" "}
          <Link to="privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </Link>
        </p>
      </PrivacyPolicyCheckboxContent>
      {errors.privacyPolicy && (
        <ErrorMessage className="error">
          {errors.privacyPolicy.message}
        </ErrorMessage>
      )}
    </PrivacyPolicyCheckboxWrapper>
  );
};

export default PrivacyPolicyCheckbox;
