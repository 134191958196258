import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// material-ui
import { ButtonBase } from "@mui/material";

// ==============================|| MAIN LOGO ||============================== //

const StyledLogoHeading = styled.h1`
  margin-top: 0;
`;

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={"/"}>
    <StyledLogoHeading>Revmo</StyledLogoHeading>
  </ButtonBase>
);

export default LogoSection;
