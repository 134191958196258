import { useContext } from "react";
import { UserContext } from "../state";

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("`useUser` must be within a `UserProvider`");
  }
  return context;
};

export default useUser;
