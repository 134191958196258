import React from "react";
import styled from "styled-components";
import { SectionWrapper, ContentWrapper } from "containers/LandingPage/Layout";
import { Accordion } from "components/LandingPage";

const FAQHeader = styled.div`
  text-align: center;
`;

const dataEnterprise = [
  {
    question: "Can Revmo integrate with our CRM system?",
    answer:
      "We are working on integrations with top CRM systems - stay tuned for updates in 2022! If you use another system and you are interested in integrating Revmo, please write to us at ",
    email: "support@revmo.app",
  },
  {
    question: "Do you have an API?",
    answer:
      "Yes, our API platform allows Revmo to plug into your systems seamlessly and quickly.",
  },
  {
    question: "Which data sources do you integrate?",
    answer:
      "Our approach to data is universal: we can integrate any data from any source!",
  },
  {
    question: "Data privacy and encryption?",
    answer:
      "We adhere to the highest standards of encryption and privacy (GDPR + CCPA).  Settings default to complete privacy and anonymity but are customizable to enable teams to collaborate in a way that is appropriate for their business.",
  },
];

export function FAQEnterprise() {
  return (
    <SectionWrapper>
      <ContentWrapper>
        <FAQHeader>
          <h2>FAQ</h2>
          <p>
            If we haven't answered your question below, drop us a note at
            <a href="mailto:support@revmo.app"> support@revmo.app</a>.
          </p>
        </FAQHeader>
        <Accordion data={dataEnterprise} />
        <p>
          To learn more about our offering for enterprise, please write to
          <a href="mailto:support@revmo.app?subject=Learn More&body=Hi there, I would like to learn more about the product.">
            {" "}
            our enterprise team.
          </a>
        </p>
      </ContentWrapper>
    </SectionWrapper>
  );
}

export default FAQEnterprise;
