import React from "react";
import styled from "styled-components";
import { DashboardSearchForm } from "./DashboardForm";
import { AdvancedSearch } from "components/AdvancedSearch";

export const GreyHeaderBackground = styled.div`
  top: 50px;
  left: 0;
  right: 0;
  position: absolute;
  pointer-events: none;
  background: #f7f6f8;
  height: 174px;
  @media (max-width: 870px) {
    height: 388px;
  }
  @media (max-width: 480px) {
    height: 378px;
  }
`;

const DashboardHeaderContainer = styled.div`
  width: 100%;
  position: relative;
`;

const AdvancedSearchWrapper = styled.div`
  margin-top: 16px;
  transition: opacity 0.2s, margin 0.2s;
  margin-bottom: 14px;
  height: max-content;
  @media (max-width: 870px) {
    margin-top: 30px;
  }
  ${(props) => {
    if (!props.showAdvancedSearch) {
      return `
        visibility: hidden;
        margin-top: 0px;
        opacity: 0;
        margin-bottom: 0;
        height: 0;
      `;
    }
  }}
`;

function DashboardHeader({
  showAdvancedSearch,
  setShowAdvancedSearch,
  advancedSearchHook,
}) {
  const { openAndCloseDropdowns, disableForm } = advancedSearchHook;

  return (
    <DashboardHeaderContainer>
      <DashboardSearchForm
        showAdvancedSearch={showAdvancedSearch}
        setShowAdvancedSearch={setShowAdvancedSearch}
        openAndCloseDropdowns={openAndCloseDropdowns}
        disableForm={disableForm}
        advancedSearchHook={advancedSearchHook}
      />
      <AdvancedSearchWrapper showAdvancedSearch={showAdvancedSearch}>
        <AdvancedSearch
          showAdvancedSearch={showAdvancedSearch}
          advancedSearchHook={advancedSearchHook}
        />
      </AdvancedSearchWrapper>
    </DashboardHeaderContainer>
  );
}

export default DashboardHeader;
