import React from "react";
import styled from "styled-components";
import { HeaderText, MidLargeSpacedText } from "components/Typography";
import { Slide } from "react-awesome-reveal";
import { AuthButtonBack, AuthButtonSkip } from "components/Button";
import { HeaderContainer, FooterWrapper } from "components/Onboarding";
import { OnboardingProgress } from ".";
import EditImage from "components/EditImage";
import { useImageUpload } from "hooks";
import { FileTooLargeModal } from "components/Modal";
import { UserAnalytics } from "containers/Profile/components";

const AddUserPhotoIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e3e6e9;
  flex-direction: column;
  @media (max-width: 480px) {
    padding-bottom: 41px;
    margin-bottom: 36px;
  }
  img {
    width: 108px;
    height: 108px;
    ${(props) => {
      if (props.imageString) {
        return `
          width: 100%;
          height: unset;
          border-radius: 100%;
        `;
      }
    }}
  }
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 15;
  width: 100%;
`;

const SkipBackButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 5px;
`;

const FileInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  text-decoration: none;
  width: 100%;
  background: #0069ff;
  border-radius: 10px;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 30px;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  margin: 5px 0 0 0;
  backgorund div > p {
    color: #000a33;
    text-decoration: underline;
  }
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  :hover {
    background: #0062ee;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
`;

const PhotoFooterWrapper = styled(FooterWrapper)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

function AddUserPhoto({ handleChangeAddUserPhoto, prevPage, profile }) {
  const {
    selectImageToUpload,
    loaded,
    setLoaded,
    imageString,
    setImageString,
    clearImageUpload,
    fileIsTooLarge,
    setFileIsTooLarge,
  } = useImageUpload();

  const closeFileTooLargeModal = () => setFileIsTooLarge(null);
  const profileWithImage = { ...profile, profile_image: imageString };

  return (
    <>
      <FileTooLargeModal
        displayModal={fileIsTooLarge}
        setDisplayModal={closeFileTooLargeModal}
      />
      <Slide right>
        <EditImage
          loaded={loaded}
          setLoaded={setLoaded}
          imageString={imageString}
          setImageString={setImageString}
          clearImageUpload={clearImageUpload}
        />
        <HeaderContainer>
          <OnboardingProgress activeStep={3} />
          <HeaderText>Now, add a photo</HeaderText>
          <MidLargeSpacedText>
            That way, people will recognise you.
          </MidLargeSpacedText>
        </HeaderContainer>
        <UserAnalytics
          userAnalytics={0}
          profile={profileWithImage}
          onboarding={true}
        />
        <AddUserPhotoIconContainer imageString={imageString}>
          <FileInput
            type="file"
            id="file"
            name="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={selectImageToUpload}
          />
        </AddUserPhotoIconContainer>
        <PhotoFooterWrapper>
          <InputWrapper>
            <StyledLabel htmlFor="file" loaded={loaded}>
              Choose Photo
            </StyledLabel>
          </InputWrapper>
          <SkipBackButtons>
            <AuthButtonBack action={prevPage} padding={"11px 27px"} />
            {imageString ? (
              <AuthButtonSkip
                disabled={false}
                padding={"11px 27px"}
                buttonText={"Continue"}
                action={() =>
                  handleChangeAddUserPhoto({ profileImage: imageString })
                }
              />
            ) : (
              <AuthButtonSkip
                disabled={false}
                padding={"11px 27px"}
                buttonText={"Skip"}
                action={() =>
                  handleChangeAddUserPhoto({ profileImage: imageString })
                }
              />
            )}
          </SkipBackButtons>
        </PhotoFooterWrapper>
      </Slide>
    </>
  );
}

export default AddUserPhoto;
