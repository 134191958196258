import React from "react";
import styled from "styled-components";
import { LargeBoldText } from "../Typography";
import closeIcon from "../../assets/icons/close-icon.svg";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  ProfileModalContainerAddOrEditContact,
  HeaderAndExplainerTextContainerSingleContact,
} from "./index";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";

const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    padding-bottom: 1em;
  }
`;

const LikedInConnectionsForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export function LinkedInConnectionsModal({
  setDisplayModal,
  setDiaplayParentModal,
  fileUploadRef,
  handleContactUploadsFromFile,
  submitUploadsFromFile,
}) {
  return (
    <ProfileModalPageWrapper>
      <DarkBlurredBackground onClick={() => setDisplayModal(false)} />
      <ProfileModalContainerAddOrEditContact>
        <CloseIconImage
          src={closeIcon}
          onClick={async () => {
            setDisplayModal(false);
          }}
        />
        <HeaderAndExplainerTextContainerSingleContact>
          <LargeBoldText>Upload LinkedIn connections</LargeBoldText>
        </HeaderAndExplainerTextContainerSingleContact>
        <ModalFooterWrapper>
          <LikedInConnectionsForm>
            <Input
              type="file"
              onChange={handleContactUploadsFromFile}
              color="secondary"
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                submitUploadsFromFile();
                setDisplayModal(false);
                setDiaplayParentModal(false);
              }}
              disabled={fileUploadRef.current === null}
            >
              Upload connections
            </Button>
          </LikedInConnectionsForm>
        </ModalFooterWrapper>
      </ProfileModalContainerAddOrEditContact>
    </ProfileModalPageWrapper>
  );
}
