import React from "react";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import { InputName } from "components/AdvancedSearch/components";
import { companySizeOptions } from "utils";

export default function AdvancedSearchCompanySize({
  searchOptions,
  setSearchOptions,
}) {
  const value = searchOptions.companySize.name;
  const minDistance = 1;
  const numberOfOptions = companySizeOptions.length;

  const handleSliderChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setSearchOptions({
        ...searchOptions,
        companySize: {
          ...searchOptions.companySize,
          name: [Math.min(newValue[0], value[1] - minDistance), value[1]],
        },
      });
    } else {
      setSearchOptions({
        ...searchOptions,
        companySize: {
          ...searchOptions.companySize,
          name: [value[0], Math.max(newValue[1], value[0] + minDistance)],
        },
      });
    }
  };

  const valuetext = (value) => {
    return value === numberOfOptions - 1
      ? `${companySizeOptions[value]}+ employees`
      : `${companySizeOptions[value]} employees`;
  };

  const markText = (value) => {
    return value === numberOfOptions - 1
      ? `${companySizeOptions[value]}+`
      : `${companySizeOptions[value]}`;
  };

  const marks = [
    { value: 0, label: markText(value[0]) },
    { value: numberOfOptions - 1, label: markText(value[1]) },
  ];

  return (
    <Grid container sx={{ p: 2, pb: 0 }} xs={12} md={8} lg={6}>
      <InputName>Company size</InputName>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs sx={{ maxWidth: "300px" }}>
          <Slider
            value={typeof value === "object" ? value : [0, numberOfOptions - 1]}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            step={1}
            min={0}
            max={numberOfOptions - 1}
            scale={companySizeOptions[value]}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            valueLabelFormat={valuetext}
            name="companySize"
            marks={marks}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
