import { useState, useMemo, useCallback } from "react";

const useAdvancedSearch = (existingSearchRequest) => {
  let defaultSearchCriteria = {
    company: "",
    location: "",
    position: "",
    person: "",
    industries: {
      name: [],
      showDropdown: false,
    },
    history: {
      name: [],
      showDropdown: false,
    },
    revmoScore: {
      name: [],
      showDropdown: false,
    },
    companySize: {
      name: [0, 9],
    },
    searchRadius: {
      name: [],
      showDropdown: false,
    },
    includeContactsInSearch: false,
    includePreviousRolesInSearch: false,
    introductionUseCase: "",
  };

  const resetAdvancedSearch = () => setSearchOptions(defaultSearchCriteria);

  const defaultSearchState = existingSearchRequest
    ? existingSearchRequest
    : defaultSearchCriteria;
  const [searchOptions, setSearchOptions] = useState(defaultSearchState);

  const toggleIncludeContactsInSearch = () => {
    setSearchOptions({
      ...searchOptions,
      includeContactsInSearch: !searchOptions.includeContactsInSearch,
    });
  };

  const toggleIncludePreviousRolesInSearch = () => {
    setSearchOptions({
      ...searchOptions,
      includePreviousRolesInSearch: !searchOptions.includePreviousRolesInSearch,
    });
  };

  const formatDefaultText = useCallback((selectedOptions, optionName) => {
    if (optionName === "industries") {
      return selectedOptions.length > 1
        ? `${selectedOptions[0].name}...`
        : selectedOptions[0].name;
    } else {
      return selectedOptions.length > 1
        ? selectedOptions.join(", ")
        : selectedOptions[0];
    }
  }, []);

  const addOrRemoveSearchCriteriaSearchRadius = useCallback(
    (currentOption, optionName) => {
      if (
        optionName === "searchRadius" &&
        searchOptions.searchRadius.name[0] !== currentOption
      ) {
        setSearchOptions({
          ...searchOptions,
          searchRadius: {
            name: [currentOption],
            showDropdown: true,
          },
        });
      } else if (
        optionName === "searchRadius" &&
        searchOptions.searchRadius.name[0] == currentOption
      ) {
        setSearchOptions({
          ...searchOptions,
          searchRadius: {
            name: [],
            showDropdown: true,
          },
        });
      }
    },
    [searchOptions]
  );

  const addOrRemoveSearchCriteria = useCallback(
    (selectedOptions, currentOption, optionName) => {
      if (optionName === "industries") {
        setSearchOptions({
          ...searchOptions,
          [optionName]: {
            name: currentOption,
          },
        });
      } else {
        const indexOfCurrentOption = selectedOptions.indexOf(currentOption);
        if (indexOfCurrentOption > -1) {
          let updatedSelectedOptions = [
            ...selectedOptions.slice(0, indexOfCurrentOption),
            ...selectedOptions.slice(indexOfCurrentOption + 1),
          ];
          setSearchOptions({
            ...searchOptions,
            [optionName]: {
              name: updatedSelectedOptions,
              showDropdown: true,
            },
          });
        } else {
          setSearchOptions({
            ...searchOptions,
            [optionName]: {
              name: [...searchOptions[optionName].name, currentOption],
              showDropdown: true,
            },
          });
        }

        addOrRemoveSearchCriteriaSearchRadius(currentOption, optionName);
      }
    },
    [searchOptions, addOrRemoveSearchCriteriaSearchRadius]
  );

  const handleChangeUseCaseDropdown = (event, result) => {
    const { value } = result || event.target;
    setSearchOptions({
      ...searchOptions,
      introductionUseCase: value,
    });
  };

  const currentStateWithClosedDropdowns = useMemo(
    () => ({
      industries: {
        name: [...searchOptions.industries.name],
        showDropdown: false,
      },
      person: searchOptions.person,
      company: searchOptions.company,
      history: {
        name: [...searchOptions.history.name],
        showDropdown: false,
      },
      revmoScore: {
        name: [...searchOptions.revmoScore.name],
        showDropdown: false,
      },
      companySize: {
        name: [...searchOptions.companySize.name],
      },
      searchRadius: {
        name: [...searchOptions.searchRadius.name],
        showDropdown: false,
      },
      location: searchOptions.location,
      includeContactsInSearch: searchOptions.includeContactsInSearch,
      includePreviousRolesInSearch: searchOptions.includePreviousRolesInSearch,
      introductionUseCase: searchOptions.introductionUseCase,
    }),
    [
      searchOptions.industries.name,
      searchOptions.history.name,
      searchOptions.revmoScore.name,
      searchOptions.companySize.name,
      searchOptions.company,
      searchOptions.includeContactsInSearch,
      searchOptions.person,
      searchOptions.location,
      searchOptions.introductionUseCase,
      searchOptions.searchRadius.name,
      searchOptions.includePreviousRolesInSearch,
    ]
  );

  const openAndCloseDropdowns = useCallback(
    (searchCriteriaName) => {
      let currentSearchCriteria = currentStateWithClosedDropdowns;
      if (searchCriteriaName) {
        setSearchOptions({
          ...currentSearchCriteria,
          [searchCriteriaName]: {
            name: [...searchOptions[searchCriteriaName].name],
            showDropdown: !searchOptions[searchCriteriaName].showDropdown,
          },
        });
      } else {
        return currentSearchCriteria;
      }
    },
    [searchOptions, currentStateWithClosedDropdowns]
  );

  const closeAllDropdownOnPageBodyClick = () => {
    setSearchOptions(currentStateWithClosedDropdowns);
  };

  const disableForm = (data) => {
    const { industries, company, history, revmoScore, location, position } =
      searchOptions;
    let disabled =
      !industries.name.length &&
      !company &&
      !history.name.length &&
      !revmoScore.name.length &&
      !data.person &&
      !location &&
      !position;
    if ("person" in data) {
      disabled = disabled && !data.person;
    }
    return disabled;
  };

  return {
    formatDefaultText,
    addOrRemoveSearchCriteria,
    openAndCloseDropdowns,
    closeAllDropdownOnPageBodyClick,
    searchOptions,
    setSearchOptions,
    disableForm,
    toggleIncludeContactsInSearch,
    toggleIncludePreviousRolesInSearch,
    handleChangeUseCaseDropdown,
    resetAdvancedSearch,
  };
};

export default useAdvancedSearch;
