import React, { Suspense } from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, LinearProgress, Typography } from "@mui/material";

// project imports
import { MainCard } from ".";
import { borderRadius } from "containers/DashboardNew/theme";
import { findNumberOfRequests } from "containers/MyRequests/utils";
import { useRecoilValue } from "recoil";
import { introductionRequestsState } from "state/recoil/requests";
import ErrorBoundary from "components/ErrorBoundary";

// ===========================|| WIDGET STATISTICS - PROJECT TASK CARD ||=========================== //

const NumberOfIntroductionsCard = () => {
  const theme = useTheme();

  return (
    <MainCard
      sx={{
        borderRadius: { borderRadius },
        border: "none",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
      contentSX={{ width: "100%" }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-evenly"
        spacing={3}
      >
        <Grid
          item
          xs={12}
          lg={4}
          sm={6}
          sx={{
            height: "100%",
            // width: "100%",
          }}
        >
          <Grid
            container
            spacing={1}
            sx={{
              height: "100%",
            }}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="left">
                Successful Introductions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ErrorBoundary fallback={<NumberOfIntroductionsFallback />}>
                <Suspense fallback={<NumberOfIntroductionsFallback />}>
                  <NumberOfIntroductions type="accepted" />
                </Suspense>
              </ErrorBoundary>
            </Grid>
            <Grid item xs={12}>
              {/** had wrong colour, colour is an enum not string */}
              <LinearProgress
                variant="determinate"
                value={100}
                sx={{
                  bgcolor: theme.palette.success.light,
                  "& .MuiLinearProgress-bar": {
                    bgcolor: theme.palette.success.dark,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="left">
                Pending Introductions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ErrorBoundary fallback={<NumberOfIntroductionsFallback />}>
                <Suspense fallback={<NumberOfIntroductionsFallback />}>
                  <NumberOfIntroductions type="pending" />
                </Suspense>
              </ErrorBoundary>
            </Grid>
            <Grid item xs={12}>
              {/** had wrong colour, colour is an enum not string */}
              <LinearProgress
                variant="determinate"
                value={100}
                sx={{
                  bgcolor: theme.palette.warning.light,
                  "& .MuiLinearProgress-bar": {
                    bgcolor: theme.palette.warning.main,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="left">
                Unsuccessful Introductions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ErrorBoundary fallback={<NumberOfIntroductionsFallback />}>
                <Suspense fallback={<NumberOfIntroductionsFallback />}>
                  <NumberOfIntroductions type="declined" />
                </Suspense>
              </ErrorBoundary>
            </Grid>
            <Grid item xs={12}>
              <LinearProgress
                variant="determinate"
                value={100}
                sx={{
                  bgcolor: theme.palette.tertiary.light,
                  "& .MuiLinearProgress-bar": {
                    bgcolor: theme.palette.tertiary.main,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};

function NumberOfIntroductions({ type }) {
  const requests = useRecoilValue(introductionRequestsState);
  let {
    numberOfIntroRequestsAccepted,
    numberOfIntroRequestsPending,
    numberOfIntroRequestsDeclined,
  } = findNumberOfRequests(requests);

  return (
    <>
      {type === "accepted" && (
        <Typography variant="h3" align="left">
          {numberOfIntroRequestsAccepted}
        </Typography>
      )}
      {type === "pending" && (
        <Typography variant="h3" align="left">
          {numberOfIntroRequestsPending}
        </Typography>
      )}
      {type === "declined" && (
        <Typography variant="h3" align="left">
          {numberOfIntroRequestsDeclined}
        </Typography>
      )}
    </>
  );
}

function NumberOfIntroductionsFallback() {
  return (
    <Typography variant="h3" align="left">
      0
    </Typography>
  );
}

export default NumberOfIntroductionsCard;
