import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import history from "../../../history";

const RequestIntroductionPanelButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 22px 49px 28px 49px;
  margin-top: 33px;
  border-top: 1px solid #e3e6e9;
  span {
    display: flex;
    gap: 5px;
    @media (max-width: 870px) {
      flex-direction: row;
      align-items: flex-end;
      button {
        margin: 0 0 10px 0;
      }
    }
  }
  @media (max-width: 480px) {
    padding: 22px 15px 29px 15px;
  }
`;

function RequestIntroductionPanelButtons({
  userHasConfirmedTheyWouldLikeAnIntroductionRequest,
  sendIntroductionRequestToNetworkMember,
  introductionRequestHasBeenSent,
  hideAndClearRequestIntroductionPanel,
  introductionUseCase,
}) {
  return (
    <RequestIntroductionPanelButtonsWrapper>
      <span>
        <Button
          variant="contained"
          disabled={
            userHasConfirmedTheyWouldLikeAnIntroductionRequest ? false : true
          }
          color={
            userHasConfirmedTheyWouldLikeAnIntroductionRequest &&
            introductionUseCase
              ? "secondary"
              : "grey"
          }
          onClick={() => {
            !introductionRequestHasBeenSent
              ? sendIntroductionRequestToNetworkMember(introductionUseCase)
              : history.push("/introductions");
          }}
          className="walkthrough-step-10"
        >
          {!introductionRequestHasBeenSent
            ? "Confirm Intro Request"
            : "Track Progress"}
        </Button>
        <Button
          onClick={() => {
            hideAndClearRequestIntroductionPanel();
            introductionRequestHasBeenSent && history.push("/dashboard");
          }}
          variant="outlined"
        >
          {!introductionRequestHasBeenSent ? "Cancel" : "Back Home"}
        </Button>
      </span>
    </RequestIntroductionPanelButtonsWrapper>
  );
}

export default RequestIntroductionPanelButtons;
