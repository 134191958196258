import React from "react";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";

const BioWrapper = styled.div`
  margin-top: 3em;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BioImage = styled.img`
  max-width: 80%;
  max-height: 300px;
  border-radius: 100%;
`;

const BioHeader = styled.h3`
  margin-bottom: 0px;
  text-align: center;
`;

const BioTitle = styled(BioHeader)`
  margin-top: 0px;
  text-align: center;
  margin-bottom: calc(2rem - 0.14285714em);
`;

const BioText = styled.p`
  text-align: center;
  line-height: 26px;
  font-size: 1.15rem;
`;

function Bio({ name, title, bio, photo }) {
  return (
    <BioWrapper>
      {photo && <BioImage src={photo.asset.url} />}
      <BioHeader>{name}</BioHeader>
      <BioTitle>{title}</BioTitle>
      <BioText>
        <BlockContent blocks={bio} />
      </BioText>
    </BioWrapper>
  );
}

export default Bio;
