import React from "react";
import styled from "styled-components";
import arrowIcon from "assets/icons/arrow-white.svg";
import { Fade } from "react-awesome-reveal";

const ArrowSectionWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`;

const ArrowRow = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ArrowText = styled.div`
  p {
    font-size: 1.5rem;
  }
`;

const Arrow = styled.div`
  padding: 1rem 2rem 1rem 1rem;
  color: #0069ff;
  img {
    width: 30px;
  }
`;

function ArrowSection({ content }) {
  return (
    <ArrowSectionWrapper>
      {content.map((arrow, index) => {
        return (
          <Fade key={index}>
            <ArrowRow>
              <Arrow>
                <img src={arrowIcon} />
              </Arrow>
              <ArrowText>
                <h2>{arrow.title}</h2>
                <p>{arrow.text}</p>
              </ArrowText>
            </ArrowRow>
          </Fade>
        );
      })}
    </ArrowSectionWrapper>
  );
}

export default ArrowSection;
