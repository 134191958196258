import styled from "styled-components";

export const TableHeaderText = styled.p`
  position: absolute;
  top: -11px;
  left: 0;
  font-weight: bold;
  font-size: 10px;
  width: max-content;
  letter-spacing: 0.2px;
  line-height: 0;
  margin-bottom: 0;
  color: #7c919e;
  ${(props) => {
    if (props.index !== 0) {
      return `
        @media (min-width: 1127px) {
          display: none;
        }
        `;
    }
  }}
`;

export const UserImageContainer = styled.div`
  height: 60px;
  min-width: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #b3bcc6;
  flex-direction: column;
  p:last-child {
    font-size: 9px;
    color: #494661;
    line-height: 7px;
    margin-top: 4px;
    text-align: center;
  }
  @media (max-width: 480px) {
    height: 54px;
    width: 54px;
  }
`;

export const UserImage = styled.img`
  height: 52px;
  max-width: 52px;
  border-radius: 100%;
  @media (max-width: 480px) {
    height: 46px;
    width: 46px;
  }
`;

export const RequestStatusPrimaryTableColumn = styled.div`
  position: relative;
  height: 100%;
  margin-right: 5px;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 4px #efefef;
  min-height: 90px;
  span {
    display: flex;
    align-items: center;
  }
  @media (max-width: 870px) {
    width: 100%;
  }
`;

export const RequestStatusTableColumUserInfo = styled(
  RequestStatusPrimaryTableColumn
)`
  max-height: 90px;
  width: ${(props) => (props.width ? props.width : 269)}px;
  padding: 13.5px 12.5px 0 12.5px;
  @media (max-width: 1126px) and (min-width: 871px) {
    width: unset;
    flex: 1.5;
  }
  @media (max-width: 870px) {
    width: 100%;
    margin-bottom: 30px;
  }
  @media (max-width: 480px) {
    min-height: 86px;
    height: 86px;
    ${(props) => {
      if (props.width) {
        return `
      height: 109px; 
      min-height: 109px;
      `;
      }
    }}
  }
`;

export const RequestStatusTableColumnIntroduction = styled(
  RequestStatusPrimaryTableColumn
)`
  padding: 21px 0 0 16px;
  justify-content: flex-start;
  width: 132px;
  p {
    text-align: start;
    :last-child {
      margin-top: 3px;
      @media (max-width: 480px) {
        margin-top: 0;
      }
    }
  }
  @media (max-width: 1126px) and (min-width: 871px) {
    width: unset;
    flex: 1;
  }
  @media (max-width: 870px) {
    width: 100%;
    padding-top: 0;
    align-items: center;
  }

  @media (max-width: 480px) {
    padding: 10px 0 10px 16px;
    min-height: 57px;
  }
`;

export const RequestStatusTableColumnCallToAction = styled(
  RequestStatusPrimaryTableColumn
)`
  flex: 1;
  flex-direction: row;
  padding: 13.5px 0 0 15px;
  align-items: center;
  padding-top: 0;
  img {
    height: 18px;
    width: 18px;
  }
  @media (max-width: 480px) {
    min-height: 60px;
  }
`;

export const TableTextHeader = styled.p`
  font-size: 15px;
  line-height: 17px;
  color: #494661;
  font-weight: bold;
  margin-bottom: 0;
  ${(props) => {
    if (props.search) {
      return `
            font-size: 17px;
            margin-bottom: 2px;
          `;
    }
  }}
  ${(props) => {
    if (props.name) {
      return `
          @media (max-width: 950px) and (min-width: 871px) {
            width: 60px;
          }
          `;
    }
  }}
`;

export const TableTextAboutPerson = styled.p`
  font-size: ${(props) => (!props.smaller ? "12px" : "10px")};
  margin: 0;
  margin-top: ${(props) => (!props.smaller ? "2px!important" : "2px")};
  line-height: 14px;
  color: ${(props) => (props.blue ? "#5374FF" : props.theme.textColor)};
  ${(props) => {
    if (props.underline) {
      return `
        text-decoration: underline;
      `;
    } else if (props.larger) {
      return `
        font-size: 15px;
        margin-top: 0;
      `;
    }
  }}
`;
export const TableTextReasonCode = styled.p`
  font-size: ${(props) => (!props.smaller ? "12px" : "10px")};
  text-align: right;
  margin-top: ${(props) => (!props.smaller ? "2px!important" : "2px")};
  line-height: 14px;
  color: ${(props) => (props.blue ? "#5374FF" : props.theme.textColor)};
`;

export const IntroducedByIcon = styled.img`
  height: 21px;
  width: 21px;
  margin: 0 4px 1px 0;
  @media (max-width: 870px) {
    margin: 0;
  }
  @media (max-width: 870px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const RequestNumberContainer = styled.div`
  width: 41px;
  display: flex;
  flex-direction: column;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const RequestNumberButtonIcon = styled.div`
  background: ${(props) => props.theme.brandColor};
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 40px;
  height: 18px;
`;

export const RequestBodyText = styled.p`
  height: 20px;
  margin: 0;
  font-size: 15px;
  line-height: 18px;
  width: max-content;
  font-weight: bold;
  color: #898fb1;
  margin-top: 7px;
  ${(props) => {
    if (props.search) {
      return `
          color: #5374FF;
          margin: unset;
          `;
    }
  }}
  ${(props) => {
    if (props.bolder) {
      return `
          font-weight: 500;
          `;
    }
  }}
`;

export const StatusText = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: #7c919e;
  margin: 0;
  margin-left: 12.5px;
  width: max-content;
`;

export const RequestStatusTableButtonWrapper = styled.div`
  min-height: 45px;
  padding-top: 2px;
  @media (max-width: 480px) {
    button {
      margin: 0;
    }
  }
`;

export { default as RequestStatusTableRow } from "./RequestStatusTableRow";
export { default as RequestStatusTableHeader } from "./RequestStatusTableHeader";
export * from "./PeopleInvolvedInIntro";
export { default as ContactTypeAndStatus } from "./ContactTypeAndStatus";
export { default as CallToAction } from "./CallToAction";
