import React from "react";
import styled from "styled-components";
import { VariableColourSubTitleText } from "components/Typography";
import greenTickIcon from "assets/icons/tick-icon-green.svg";
import whiteArrow from "assets/icons/arrow-curved-white.svg";
import crossSign from "assets/icons/cross-sign-white.svg";
import { SkeletonPulse } from "components/Skeleton";

const RequestStatusTableTextAndIconWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 41px;
`;

const GreenTickIcon = styled.img`
  height: 23px;
  width: 23px;
  margin-right: 8px;
`;

const RequestStatusTableIconContainer = styled.div`
  height: 23px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 100%;
  background: ${(props) => props.background};
  img {
    width: 15px;
    height: 15px;
  }
`;

function RequestStatusTableHeader({ requestType }) {
  return (
    <RequestStatusTableTextAndIconWrapper>
      {requestType === "COMPLETED" && (
        <>
          <GreenTickIcon src={greenTickIcon} />
          <VariableColourSubTitleText introRequests color="#18bfa1">
            Completed&nbsp;
          </VariableColourSubTitleText>
        </>
      )}
      {requestType === "PENDING" && (
        <>
          <RequestStatusTableIconContainer background="#7564C6;">
            <img src={whiteArrow} alt="" />
          </RequestStatusTableIconContainer>
          <VariableColourSubTitleText introRequests color="#7564C6">
            Pending&nbsp;
          </VariableColourSubTitleText>
        </>
      )}
      {requestType === "REJECTED" && (
        <>
          <RequestStatusTableIconContainer background="#FEA666">
            <img src={crossSign} alt="" />
          </RequestStatusTableIconContainer>
          <VariableColourSubTitleText introRequests color="#FEA666">
            Failed&nbsp;
          </VariableColourSubTitleText>
        </>
      )}
    </RequestStatusTableTextAndIconWrapper>
  );
}

const SkeletonIcon = styled(SkeletonPulse)`
  height: 23px;
  width: 23px;
  border-radius: 100%;
  margin-right: 8px;
`;

const SkeletonHeaderText = styled(SkeletonPulse)`
  width: 92px;
  height: 20px;
`;

export function RequestStatusTableHeaderSkeleton() {
  return (
    <RequestStatusTableTextAndIconWrapper>
      <SkeletonIcon />
      <SkeletonHeaderText />
    </RequestStatusTableTextAndIconWrapper>
  );
}

export default RequestStatusTableHeader;
