import styled from "styled-components";

export const GreyHeaderPolicy = styled.div`
  background: #f7f6f8;
  position: absolute;
  top: 50px;
  left: 0;
  padding: 0px 20px 0px 0px;
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: flex-end;
  h1 {
    width: 1120px;
    margin-bottom: 15px;
    padding-left: 50px;
  }
`;
export const PolicyBody = styled.div`
  padding: 2rem 20px 0 20px;
  align-self: center;
  position: relative;
  background-color: #f7f6f8;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  @media (max-width: 870px) {
    padding-top: 0px;
  }
  div {
    background: #f7f6f8;
    width: 100%;
    div {
      max-height: 80vh;
      height: 80vh;
      width: 100%;
      overflow: hidden;
      background: white;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;
