import React from "react";
import styled from "styled-components";
import {
  MidBlackBoldText,
  ExtraSmallBlackTextEllipsis,
  SmallBlackBodyTextEllipsis,
} from "components/Typography";
import { PathScore, RevmoScore } from "components/Score";
import {
  IntroductionButton,
  SecondaryIntroductionButton,
} from "components/Button";
import { AnonymousUserSearch } from "components/Assets/Image";
import { SkeletonPulse } from "components/Skeleton";
import { getContactByUUID } from "utils";

const TableRowContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e3e6e9;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px #efefef;
  border-radius: 4px;
  height: 80px;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 20px;
`;

const TableRowSectionWhite = styled.div`
  flex: 30%;
  padding: 0 18px;
  display: flex;
  height: 100%;
`;

const TableRowSectionUserInfo = styled(TableRowSectionWhite)`
  padding: 10px 18px;
  min-width: 275px;
  p {
    margin: 0;
    :nth-child(2) {
      line-height: 14px;
      margin-top: 3px;
      margin-bottom: 4px;
    }
  }
`;

const TableRowSectionIntroOptions = styled(TableRowSectionWhite)`
  justify-content: space-evenly;
  align-items: center;
  min-width: 260px;
  button {
    font-weight: bold;
    font-size: 12px;
    margin-right: 5px;
  }
`;

const TableRowSectionGrey = styled.div`
  flex: 40%;
  background: #f7f7f8;
  display: flex;
  div:first-child {
    border-right: unset;
  }
`;

const TableRowScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 18px;
  border-right: 1px solid #e3e6e9;
  border-left: 1px solid #e3e6e9;
`;

const ProfileImage = styled.img`
  height: 45px;
  width: 45px;
  margin-top: 6px;
  border-radius: 100%;
`;

const CompanyText = styled(ExtraSmallBlackTextEllipsis)`
  line-height: normal;
`;

const RoleText = styled(ExtraSmallBlackTextEllipsis)`
  line-height: 20px;
  margin-top: 2px;
`;

const UserNameAndPosition = styled.div`
  margin-left: 29px;
`;

const UserNameAndShowIndustryButton = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
`;

const ShowMorePopUpWrapper = styled.div`
  position: absolute;
  z-index: 10;
  height: max-content;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  background: #f7f6f8;
  right: -13px;
  width: max-content;
  bottom: -60px;
  box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.15);
  p:first-child {
    font-weight: bold;
    padding-bottom: 2px;
  }
`;

function ShowMorePopUp({ industries }) {
  return (
    <ShowMorePopUpWrapper>
      <p>Industries</p>
      {industries.map((industry) => {
        return <p key={industry}>{industry}</p>;
      })}
    </ShowMorePopUpWrapper>
  );
}

const ShowMoreEllipsis = styled.span`
  font-size: 24px;
  margin-left: 10px;
  position: absolute;
  letter-spacing: 1px;
  color: #5374ff;
  cursor: pointer;
  right: -7px;
  top: -13px;
  &:hover + ${ShowMorePopUpWrapper} {
    opacity: 1;
  }
`;

export function SearchResultsTableRow({
  result,
  viewIntroductionPathwayToNetworkMember,
  index,
  showAddContactModal,
}) {
  let {
    first_name,
    last_name,
    career,
    path_score,
    revmo_score,
    profile_image,
    has_path,
    is_contact,
  } = result;

  let industries = [];
  if (career.length) {
    career.forEach((job) => {
      if (job.industry) {
        industries = [...industries, ...job.industry];
      }
    });
  }

  return (
    <TableRowContainer className="walkthrough-step-4">
      <TableRowSectionUserInfo>
        {profile_image ? (
          <ProfileImage src={profile_image} />
        ) : (
          <AnonymousUserSearch />
        )}
        <UserNameAndPosition>
          <ShowMorePopUp industries={industries} />
          <UserNameAndShowIndustryButton>
            <MidBlackBoldText>{`${first_name} ${last_name}`}</MidBlackBoldText>
            {industries[0] && (
              <>
                <ShowMoreEllipsis>...</ShowMoreEllipsis>
              </>
            )}
          </UserNameAndShowIndustryButton>
          {career[0] && (
            <>
              <RoleText>{career[0].role_desc}</RoleText>
              <CompanyText>@ {career[0].org_name}</CompanyText>
            </>
          )}
          <SmallBlackBodyTextEllipsis>
            {career[0]?.location}
          </SmallBlackBodyTextEllipsis>
        </UserNameAndPosition>
      </TableRowSectionUserInfo>
      <TableRowSectionGrey>
        <TableRowScoreWrapper>
          <RevmoScore score={revmo_score} />
        </TableRowScoreWrapper>
        <TableRowScoreWrapper>
          <PathScore score={path_score} />
        </TableRowScoreWrapper>
      </TableRowSectionGrey>
      <SearchResultsTableRowLastColumn
        has_path={has_path}
        is_contact={is_contact}
        result={result}
        index={index}
        viewIntroductionPathwayToNetworkMember={
          viewIntroductionPathwayToNetworkMember
        }
        contact={result}
        showAddContactModal={showAddContactModal}
      />
    </TableRowContainer>
  );
}

const ExistingContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

function SearchResultsTableRowLastColumn({
  result,
  index,
  viewIntroductionPathwayToNetworkMember,
  has_path,
  is_contact,
  contact,
  showAddContactModal,
}) {
  return (
    <TableRowSectionIntroOptions>
      {is_contact && (
        <ExistingContactWrapper>
          <IntroductionButton
            color={"blue"}
            size={"full"}
            onClick={async () => getContactByUUID(result.uuid)}
          >
            View Profile
          </IntroductionButton>
        </ExistingContactWrapper>
      )}
      {!result.is_contact && (
        <ExistingContactWrapper>
          <IntroductionButton
            color={has_path ? "blue" : "muted"}
            onClick={() => {
              has_path && viewIntroductionPathwayToNetworkMember(result);
            }}
            className="walkthrough-step-5"
          >
            Request Intro
          </IntroductionButton>
          <SecondaryIntroductionButton
            onClick={() => {
              !is_contact && showAddContactModal(index, contact);
            }}
          >
            Add Contact
          </SecondaryIntroductionButton>
        </ExistingContactWrapper>
      )}
    </TableRowSectionIntroOptions>
  );
}

const SkeletonSearchResultsIcon = styled(SkeletonPulse)`
  height: 45px;
  width: 45px;
  min-width: 45px;
  border-radius: 100%;
`;

const SkeletonInfoText = styled(SkeletonPulse)`
  width: 100%;
  height: 14px;
  line-height: 14px;
`;

const SkeletonScore = styled(SkeletonPulse)`
  width: 90px;
  margin: 7px 0;
  height: 20px;
  display: block;
`;

const SkeletonButton = styled(SkeletonPulse)`
  width: 120.3px;
  margin: 0 5px;
  height: 32px;
  border-radius: 5px;
`;

export function SearchResultsTableSkeletonComponent() {
  return (
    <TableRowContainer>
      <TableRowSectionUserInfo>
        <SkeletonSearchResultsIcon />
        <UserNameAndPosition>
          <MidBlackBoldText></MidBlackBoldText>
          <ExtraSmallBlackTextEllipsis>
            <SkeletonInfoText />
          </ExtraSmallBlackTextEllipsis>
          <SmallBlackBodyTextEllipsis>
            <SkeletonInfoText />
          </SmallBlackBodyTextEllipsis>
        </UserNameAndPosition>
      </TableRowSectionUserInfo>
      <TableRowSectionGrey>
        <TableRowScoreWrapper>
          <div>
            <SkeletonScore />
            <SkeletonScore />
          </div>
        </TableRowScoreWrapper>
        <TableRowScoreWrapper>
          <div>
            <SkeletonScore />
            <SkeletonScore />
          </div>
        </TableRowScoreWrapper>
      </TableRowSectionGrey>
      <TableRowSectionIntroOptions>
        <ExistingContactWrapper>
          <SkeletonButton />
          <SkeletonButton />
        </ExistingContactWrapper>
      </TableRowSectionIntroOptions>
    </TableRowContainer>
  );
}
