import * as yup from "yup";
import { Auth } from "aws-amplify";

export const recoverAccountSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email address is not properly formatted")
    .required("Please enter your email address"),
});

export const setNewPasswordSchema = yup.object().shape({
  confirmationCode: yup
    .string()
    .required("Please enter your confirmation code"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Please make sure your passwords match"
    ),
});

export const recoverUserPassword = async (
  username,
  setAlertMessage,
  customErrorMessages,
  setLoginState
) => {
  Auth.forgotPassword(username)
    .then(() => {
      setAlertMessage("");
      setLoginState("userCanResetPassword");
    })
    .catch((error) => {
      setAlertMessage(customErrorMessages[error.message]);
    });
};

export const setNewPassword = (
  password,
  setAlertMessage,
  username,
  confirmCode,
  setLoginState,
  customErrorMessages
) => {
  Auth.forgotPasswordSubmit(username, confirmCode, password)
    .then(() => {
      setLoginState("logInUser");
      setAlertMessage("");
    })
    .catch((error) => {
      console.log(error);
      setAlertMessage(customErrorMessages[error.message]);
    });
};
