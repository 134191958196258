import React from "react";
import { Link } from "react-router-dom";
import { DividerSmallMargin, PostItemWrapper } from "components/Post/Layout";

function SupportItem({ faq }) {
  return (
    <>
      <PostItemWrapper>
        <Link to={`support/${faq?.slug.current}`}>
          <h2>{faq?.title}</h2>
        </Link>
        <DividerSmallMargin />
      </PostItemWrapper>
    </>
  );
}

export default SupportItem;
