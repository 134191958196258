import styled from "styled-components";

export const AuthButton = styled.button`
  cursor: pointer;
  background: #0069ff;
  border-radius: 10px;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 30px;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  margin: 20px 0 0 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  :hover {
    background: #0062ee;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  @media only screen and (max-width: 767.999px) {
    width: 100%;
  }
`;

export const ButtonPrimary = styled.button`
  cursor: pointer;
  background: #40404a;
  border-radius: 5px;
  align-items: center;
  border: none;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  display: flex;
  padding: ${(props) => (props.padding ? props.padding : "11px 30px")};
  justify-content: space-between;
  text-align: left;
  p {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #ffffff;
    margin: 0;
    text-decoration: none;
  }
  &:disabled {
    background: #d4d4d6;
    p {
      color: #ffffff;
    }
  }
  ${(props) => {
    if (props.border) {
      return `
        border: 1px solid white;
      `;
    }
  }}
`;

export const SearchButtonDashboard = styled(ButtonPrimary)`
  width: 169px;
  height: 56px;
  margin-right: 19px;
  margin-left: 19px;
  padding: 0 18px 0 12px;
  @media (max-width: 870px) {
    margin-left: 0;
  }
  @media (max-width: 480px) {
    width: unset;
    flex: 1;
    margin-right: 9px;
    p {
      font-size: 15px;
      line-height: 18px;
    }
  }
`;

export const AdvancedSearchButton = styled(ButtonPrimary)`
  background: #7c919e;
  margin-left: 15px;
  padding: 0 22px 0 29px;
  p {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const AdvancedSearchButtonDashboard = styled(AdvancedSearchButton)`
  background: #7c919e;
  margin-left: 0;
  padding: 0 17px 0 12px;
  max-width: 173px;
  width: 173px;
  p {
    font-size: 15px;
  }
  @media (max-width: 480px) {
    width: unset;
    flex: 1;
    font-size: 15px;
  }
`;

export const SearchButtonSearchForm = styled(SearchButtonDashboard)`
  z-index: 5;
  margin-left: 0;
  background-color: #0069ff;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #0069ff;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  p {
    font-size: 15px;
  }

  border-bottom: ${(props) =>
    props.searchCriteriaName === "industry" && "unset"};
  ${(props) =>
    props.disabled === false &&
    `
  :hover {
    text-decoration: none;
    background-color: rgb(0, 37, 90);
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }
  `}
  @media (max-width: 870px) {
    order: 2;
  }
  @media (max-width: 480px) {
    min-height: 44px;
    position: relative;
    top: 9px;
  }
`;

export const LightGreySquareButton = styled.button`
  font-weight: bold;
  font-size: 15px;
  color: #494661;
  background: #ffffff;
  width: 132px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  border: none;
  cursor: pointer;
`;

export const DarkGreySquareButton = styled.button`
  background: #91a2af;
  border: none;
  color: white;
  height: 14px;
  font-size: 10px;
  padding: 0;
  margin: ${(props) => props.margin};
`;

export const DarkGreySquareButtonRequest = styled(DarkGreySquareButton)`
  padding: 0 9px;
  font-size: 11px;
  height: 20px;
`;

export const SideBarButton = styled.button`
  width: 118px;
  height: 32px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 0;
  border: unset;
  cursor: pointer;
  ${(props) => {
    if (props.color === "dark") {
      return `
        color: #FFFFFF;
        background: #40404A;
    `;
    } else if (props.color === "light") {
      return `
        color: BBBAC6;
        background:#FFFFFF;
        color: #BBBAC6;
    `;
    }
  }}
`;

export const ExpandButton = styled.button`
  background: #ffffff;
  opacity: 0.5;
  border: 1px solid #77768e;
  box-sizing: border-box;
  border-radius: 6px;
  width: 17px;
  height: 17px;
  max-width: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 1126px) and (min-width: 635px) {
    height: 25px;
    width: 25px;
    img {
      height: 15px;
      width: 12px;
    }
  }
`;

export const ExpandButtonCompany = styled(ExpandButton)`
  margin-left: auto;
`;

export const IntroductionButton = styled.button`
  height: 32px;
  padding: 0 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
  line-height: 12px;
  border: unset;
  width: ${(props) => (props.width ? props.width : "120.3px")};
  ${(props) => {
    if (props.color === "white") {
      return `
        color: ${(props) => props.theme.textColor};
        background: white;
        border: 1px solid #E3E6E9;
      `;
    } else if (props.color === "grey") {
      return `
        background: #B3BCC6;
        color: white;
        margin-right: 18px;
      `;
    } else if (props.color === "green") {
      return `
        margin-right: 18px;
        color: white;
        background: ${props.theme.acceptedColor};
      `;
    } else if (props.color === "blue") {
      return `
        background: ${props.theme.brandColor};
        margin-right: 18px;
        color: white;
      `;
    } else if (props.color === "muted") {
      return `
        background: #FFFFFF;
        color: #B3BCC6;
        border: 1px solid #E3E6E9;
        cursor: not-allowed;
      `;
    } else if (props.color === "navy") {
      return `
        background: #98acff;
        margin-right: 18px;
        color: white;
      `;
    }
  }}
  ${(props) => {
    if (props.table) {
      return `
        margin: unset;
        padding: 0 11px;
        font-size: 10px;
      `;
    }
  }}
  ${(props) => {
    if (props.size === "full") {
      return `
        width: 252px;
      `;
    }
  }}
`;

export const SecondaryIntroductionButton = styled(IntroductionButton)`
  background: white;
  color: #5374ff;
  margin-right: 18px;
  border: solid;
  border-color: #5374ff;
`;

export const ToggleTimeFrameButton = styled.button`
  background: #ffffff;
  border: 1px solid #91a2af;
  box-sizing: border-box;
  font-weight: 300;
  border-radius: 0;
  color: ${(props) => props.theme.textColor};
  font-size: 16px;
  cursor: pointer;
  z-index: 5;
  padding: 12px 30px;
  ${(props) => {
    if (props.timeFrame === "this month") {
      return `
        border-radius:  0px 5px 5px 0;
        border-left: unset;
    `;
    } else if (props.timeFrame === "today") {
      return `
        border-radius: 5px 0px 0px 5px;
        border-right: unset;
        max-width: 105px;
        min-width: 105px;
    `;
    }
  }}
  ${(props) => {
    if (props.toggleTimeFrame === props.timeFrame) {
      return `
    
        background: ${props.theme.brandColor};
        border: 1px solid ${props.theme.brandColor};
        box-sizing: border-box;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.4px;
        color: #ffffff;
        z-index: 10;
  `;
    }
  }}
  ${(props) => {
    if (props.maxWidth) {
      return `
        min-width: 125px;
        max-width: 125px;
        padding: 12px 0;
  `;
    }
  }}
`;

export const ContactTagButton = styled.button`
  cursor: pointer;
  background: ${(props) => props.theme.brandColor};
  color: white;
  border: unset;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  padding: 5px 9px;
`;

export * from "./ButtonPrimaryWithArrow";
export * from "./AuthButtonWithArrow";
export * from "./Toggle";
export * from "./ShowMoreButton";
export * from "./ToggleWithHook";
export * from "./LandingButton";
