import React from "react";
import styled from "styled-components";
import greyChevron from "assets/icons/chevron-grey.svg";
import { useToggleDropdown } from "hooks";

export const DropdownWrapper = styled.div`
  left: -1px;
  min-width: 204px;
  position: absolute;
  top: 37px;
  box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.15);
  z-index: 10;
  border-radius: 5px;
  border-top: 1px solid #e3e6e9;
  ${(props) => {
    if (props.lessWidth) {
      return `
        max-width: calc(14.6% - 7px);
      `;
    }
  }}
`;

export const DropdownContainer = styled.div`
  border: 1px solid #e3e6e9;
  border-top: unset;
  width: 100%;
  border-bottom: ${(props) =>
    props.searchCriteriaName === "industry" && "unset"};
  border-radius: ${(props) =>
    props.searchCriteriaName === "industry" ? "5px 5px 0 0" : "5px"};
  background: #fcfcfc;
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-y: scroll;
  max-height: 512px;
`;
export const CustomOption = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  background: ${(props) =>
    props.selected ? props.theme.brandColor : "#fcfcfc"};
  font-size: 12px;
  color: ${(props) => (props.selected ? "white" : "#7C919E")};
  border-bottom: ${(props) => (props.last ? "unset" : "1px solid #e3e6e9")};
  padding: 2px 8px;
  z-index: 10;
`;

const AdvancedSearchCustomOptions = ({
  closeAllDropdownOnPageBodyClick,
  searchOptions,
  searchCriteriaName,
  listOfOptions,
  addOrRemoveSearchCriteria,
  lessWidth,
}) => {
  const { dropdownRef } = useToggleDropdown(closeAllDropdownOnPageBodyClick);

  return (
    <DropdownWrapper
      lessWidth={lessWidth}
      showDropdown={searchOptions[searchCriteriaName].showDropdown}
      ref={dropdownRef}
    >
      <DropdownContainer searchCriteriaName={searchCriteriaName}>
        {listOfOptions.map((option, index) => {
          return (
            <CustomOption
              key={searchCriteriaName === "industries" ? index : option}
              onClick={() =>
                addOrRemoveSearchCriteria(
                  searchOptions[searchCriteriaName].name,
                  option,
                  searchCriteriaName
                )
              }
              selected={searchOptions[searchCriteriaName].name.includes(option)}
              last={index === listOfOptions.length - 1 ? true : false}
            >
              {searchCriteriaName === "industries" ? option.name : option}
            </CustomOption>
          );
        })}
      </DropdownContainer>
      <AdvancedSearchIndustryFooter searchCriteriaName={searchCriteriaName} />
    </DropdownWrapper>
  );
};

export const ArrowDropdownRow = styled.div`
  width: 100%;
  border-radius: 0 0 5px 5px;
  border: 1px solid #e3e6e9;
  height: 35px;
  background: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const UpwardArrow = styled.img`
  transform: rotate(180deg);
  margin: 0 1px;
  width: 8px;
  height: 6px;
`;

export const DownwardArrow = styled.img`
  margin: 0 1px;
  width: 8px;
  height: 6px;
`;

function AdvancedSearchIndustryFooter({ searchCriteriaName }) {
  return (
    <>
      {searchCriteriaName === "industry" && (
        <ArrowDropdownRow>
          <UpwardArrow src={greyChevron} />
          <DownwardArrow src={greyChevron} />
        </ArrowDropdownRow>
      )}
    </>
  );
}

export default AdvancedSearchCustomOptions;
