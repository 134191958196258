import React from "react";
import {
  LandingCallToAction,
  LandingHero,
  TeamSection,
  Testimonials,
  WhoIsItFor,
  HowDoesItWork,
  FAQ,
} from "./components";
import { LandingPageContainer } from "components/LandingPage";
import { LandingNavbar } from "components/Navbar";
import { LandingFooter } from "components/LandingPage";

function LandingPage() {
  return (
    <>
      <LandingPageContainer>
        <LandingNavbar />
        <LandingHero />
        <HowDoesItWork />
        <WhoIsItFor />
        <Testimonials />
        <TeamSection />
        <FAQ />
        <LandingCallToAction />
      </LandingPageContainer>
      <LandingFooter />
    </>
  );
}

export default LandingPage;
