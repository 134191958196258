import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const NavListContainer = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  list-style: none;
  ${(props) => {
    if (props.mobile) {
      return `
            width: 100%;
            align-items: flex-start;
            ul {
                padding: 0;
                font-size: 1.25rem;
                flex-direction: column;
                li {
                    margin: .25rem 0;
                    padding: .25rem 0 .5rem .75rem;
                    outline: 0;
                }
            }
            div > div > ul > li > a {
              font-weight: 400;
            }
            `;
    }
  }}

  ul {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    text-decoration: none;
    list-style-type: none;
    line-height: 1.4;
  }
  @media (min-width: 992px) and (max-width: 1325px) {
    padding-left: 0;
  }
`;

export const NavListItem = styled.li`
  padding: 0.8rem;
  font-weight: 700;
  @media (min-width: 992px) and (max-width: 1325px) {
    padding: 0.5rem;
    font-size: 1rem;
  }
  a {
    color: #1d1d1d !important;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    :hover {
      border-bottom: 1px solid #1d1d1d;
    }
    display: flex;
    align-items: center;
  }
`;

function NavList({
  mobile,
  setShowMobileMenu,
  setShowDropdown,
  showDropdown,
  user,
}) {
  return (
    <NavListContainer mobile={mobile}>
      <ul>
        <NavListItem>
          <Link
            to="/"
            onClick={() => {
              setShowMobileMenu(false);
            }}
          >
            Home
          </Link>
        </NavListItem>
        {!user && (
          <>
            <NavListItem>
              <Link
                to="/enterprise"
                onClick={() => {
                  setShowMobileMenu(false);
                }}
              >
                Enterprise
              </Link>
            </NavListItem>
            <NavListItem>
              <Link
                to="/about"
                onClick={() => {
                  setShowMobileMenu(false);
                }}
              >
                About
              </Link>
            </NavListItem>
          </>
        )}
        {mobile ? (
          <Accordion style={{ boxShadow: "none" }}>
            <AccordionSummary
              sx={{
                fontWeight: "700",
                fontFamily: "Montserrat",
                paddingLeft: "0.8rem",
                alignItems: "center",
              }}
            >
              News <ArrowDropDownIcon style={{ color: "#0069FF" }} />
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <NavListItem>
                  <Link
                    to="/blog"
                    onClick={() => {
                      setShowMobileMenu(false);
                    }}
                  >
                    Blog
                  </Link>
                </NavListItem>
                <NavListItem>
                  <Link
                    to="/press"
                    onClick={() => {
                      setShowMobileMenu(false);
                    }}
                  >
                    Press
                  </Link>
                </NavListItem>
                <NavListItem>
                  <Link
                    to="/updates"
                    onClick={() => {
                      setShowMobileMenu(false);
                    }}
                  >
                    Product Updates
                  </Link>
                </NavListItem>
              </ul>
            </AccordionDetails>
          </Accordion>
        ) : (
          <NavListItem>
            <a
              onClick={() => {
                setShowDropdown(!showDropdown);
              }}
            >
              News <ArrowDropDownIcon style={{ color: "#0069FF" }} />
            </a>
          </NavListItem>
        )}
        <NavListItem>
          <Link
            to="/careers"
            onClick={() => {
              setShowMobileMenu(false);
            }}
          >
            Careers
          </Link>
        </NavListItem>
        <NavListItem>
          <Link
            to="/support"
            onClick={() => {
              setShowMobileMenu(false);
            }}
          >
            Help Center
          </Link>
        </NavListItem>
      </ul>
    </NavListContainer>
  );
}

export default NavList;
