import React from "react";
import styled from "styled-components";
import { HeaderText, AuthText } from "components/Typography";
import { WhiteLargeFormInputHooksForm } from "components/Input";
import {
  AuthForm,
  AuthFormWrapperBackButton,
  AuthBackButton,
} from "components/Auth";
import { useForm } from "react-hook-form";
import { AuthButton } from "components/Button";

const AuthFormWrapperConfirmUser = styled(AuthFormWrapperBackButton)`
  h1 {
    margin-bottom: 20px;
  }
`;

function ConfirmUser({
  confirmUserAndLogIn,
  alertMessage,
  returnToRegisterUser,
}) {
  const { register, errors, handleSubmit } = useForm();
  errors.confirmationCode = {};
  errors.confirmationCode.message = alertMessage;

  return (
    <AuthFormWrapperConfirmUser>
      <HeaderText>
        Your confirmation code is waiting for you in your email inbox!
      </HeaderText>
      <AuthText>
        Enter the 6-digit confirmation code we’ve sent to confirm your email
        address
      </AuthText>
      <AuthForm>
        <WhiteLargeFormInputHooksForm
          fieldName="Confirmation Code"
          register={register}
          error={errors}
          name="confirmationCode"
          bold
        />
        <AuthButton onClick={handleSubmit(confirmUserAndLogIn)}>
          Continue
        </AuthButton>
        <AuthBackButton goBack={returnToRegisterUser} />
      </AuthForm>
    </AuthFormWrapperConfirmUser>
  );
}

export default ConfirmUser;
