import React, { useEffect, useCallback, useMemo, useState } from "react";
import { fetchData, postData } from "utils";
import styled from "styled-components";
import * as yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { SmallBlackBoldText } from "components/Typography";
import {
  Grid,
  Typography,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useAdvancedSearch, useProfile } from "hooks";
import { MainCard } from "components/DashboardNew";
import { borderRadius } from "containers/DashboardNew/theme";
import SearchIcon from "@mui/icons-material/Search";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import Button from "@mui/material/Button";
import { useAlert } from "hooks";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import closeIcon from "assets/icons/close-icon.svg";
import { WhiteLargeFormInputHooksForm } from "components/Input";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  ProfileModalContainerAddOrEditContact,
  AddOrEditContactPersonalDetailsWrapper,
  FormTypeHeaderAddContact,
} from "components/Modal/index";

export const OrganisationTableForm = styled.form`
  margin-top: 16px;
`;

export default function OrgsTable() {
  const [connectedOrgs, setConnectedOrgs] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [postOrgPayload, setPostOrgPayLoad] = useState({
    org_uuid: "",
    org_name: "",
    website: "",
  });
  const { addAlert } = useAlert();
  const history = useHistory();
  const { profile } = useProfile();
  const advancedSearchHook = useAdvancedSearch();
  const { openAndCloseDropdowns } = advancedSearchHook;
  const allowed_uuids = [
    { person: "freddie", uuid: "040811a0-cd7b-4137-912f-ce7a4bce1ef9" },
    { person: "sophie", uuid: "55e516ea-a6ed-4ab5-ba0a-d9ab564f0130" },
    { person: "margot", uuid: "09592648-3d9f-47eb-9f09-491cce831d71" },
    { person: "harry", uuid: "84eaa41c-e333-4028-999e-b92993e8e6e3" },
  ];
  const list_uuids = [];
  allowed_uuids.forEach((e) => list_uuids.push(e.uuid));

  const handleSubmitSearch = useCallback(
    (org) => () => {
      const searchResults = openAndCloseDropdowns();
      searchResults.company = org;
      history.push({ pathname: "/search", state: searchResults });
    },
    [history, openAndCloseDropdowns]
  );

  useEffect(() => {
    const getConnectedOrgs = async () => {
      try {
        const orgs = await fetchData(
          `/profile/connected_orgs/${profile.uuid}?org_name=${searchName}`
        );
        setConnectedOrgs(orgs);
      } catch (err) {
        console.log(err);
      }
    };
    getConnectedOrgs();
  }, [profile.uuid, searchName]);

  useEffect(() => {
    const postOrgWebsite = async () => {
      if (postOrgPayload.org_name) {
        try {
          addAlert(
            "processing",
            "We are processing your post request. Check back soon!",
            "info"
          );
          await postData(`/orgs/website`, JSON.stringify(postOrgPayload));
          setPostOrgPayLoad({
            org_uuid: "",
            website: "",
            org_name: "",
          });
          addAlert(
            "success",
            "Successfully updated Org, refresh the page to see your changes",
            "success"
          );
        } catch (err) {
          console.log(err);
          if (err.detail.includes("valid")) {
            addAlert(
              "failed",
              `There has been an error processing your post request, ${err.detail}`,
              "error"
            );
          } else {
            addAlert(
              "failed",
              "There has been an error processing your post request",
              "error"
            );
          }
        }
      }
    };
    postOrgWebsite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postOrgPayload]);

  const industriesDisplay = (params) => {
    if (params.value.length != 0) {
      let industries = "";
      params.value.forEach((industry) => {
        industries = industries + industry.name + " ";
      });
      return industries;
    }
  };
  const linkToWebsite = (params) => {
    if (params.value) {
      return (
        <Link
          to={{
            pathname: params.value.includes("http")
              ? params.value
              : `http://${params.value}`,
          }}
          target="_blank"
        >
          {params.value.includes("http")
            ? params.value.split("://")[1]
            : params.value}
        </Link>
      );
    }
  };
  const fetchButton = useCallback(
    function (org_name, org_uuid) {
      const handleSubmitFetchData = async () => {
        try {
          addAlert(
            "processing",
            "We are processing your fetch request. Check back soon!",
            "info"
          );
          await fetchData(`/data/fetch/org/${org_uuid}`);
        } catch (err) {
          console.log(err);
          addAlert(
            "processing",
            "There has been an error processing your fetch request",
            "error"
          );
        }
      };

      if (list_uuids.includes(profile.uuid)) {
        return (
          <GridActionsCellItem
            disableRipple={true}
            icon={<Button>{org_name}</Button>}
            onClick={handleSubmitFetchData}
            label="Search"
            key={2}
          />
        );
      } else {
        return (
          <GridActionsCellItem
            disabled={true}
            icon={<Button disabled={true}>{org_name}</Button>}
            label="Search"
            key={3}
          />
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile.uuid, addAlert]
  );
  const [displayModal, setDisplayModal] = useState({
    orgName: "",
    orgURL: "",
  });
  const [open, setOpen] = useState(false);
  const EditButton = (org_name, org_uuid, website) => {
    const orgValues = {
      orgUUID: org_uuid,
      orgName: org_name,
      orgURL: website,
    };
    const handleClickOpen = () => {
      setOpen(true);
      setDisplayModal(orgValues);
    };

    return (
      <div>
        <Tooltip placement="top" title="Edit">
          <IconButton onClick={handleClickOpen} label="Edit" key={12}>
            <EditTwoToneIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };
  const handleSubmitWebsiteData = async (data) => {
    setPostOrgPayLoad({
      ...postOrgPayload,
      org_uuid: displayModal.orgUUID,
      org_name: data.orgName,
      website: data.orgURL,
    });
    setOpen(false);
  };

  const EditOrgModal = () => {
    const addOrEditOrgSchema = yup.object().shape({
      orgName: yup.string().required("Please enter organisation name"),
      orgURL: yup.string().required("Please enter website"),
    });
    const { register, errors, handleSubmit, trigger } = useForm({
      defaultValues: displayModal,
      resolver: yupResolver(addOrEditOrgSchema),
    });
    return (
      <ProfileModalPageWrapper>
        <DarkBlurredBackground />
        <ProfileModalContainerAddOrEditContact>
          <CloseIconImage
            src={closeIcon}
            onClick={async () => {
              setOpen(false);
            }}
          />
          <FormTypeHeaderAddContact>
            <SmallBlackBoldText>Edit Organisation</SmallBlackBoldText>
          </FormTypeHeaderAddContact>
          <OrganisationTableForm>
            <AddOrEditContactPersonalDetailsWrapper>
              <WhiteLargeFormInputHooksForm
                fieldName="Organisation Name"
                name="orgName"
                error={errors}
                register={register}
                placeholder={""}
                formWrapper="orgsTable"
                onMouseLeave={(e) => {
                  setDisplayModal({
                    ...displayModal,
                    orgName: e.target.value,
                  });
                  trigger("orgName");
                }}
              />
              <WhiteLargeFormInputHooksForm
                fieldName="Website"
                name="orgURL"
                error={errors}
                register={register}
                placeholder={""}
                formWrapper="orgsTable"
                onMouseLeave={(e) => {
                  setDisplayModal({
                    ...displayModal,
                    orgURL: e.target.value,
                  });
                  trigger("orgURL");
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                sx={{ margin: 2, marginLeft: 8 }}
                onClick={handleSubmit((data) => handleSubmitWebsiteData(data))}
              >
                Edit Company
              </Button>
            </AddOrEditContactPersonalDetailsWrapper>
          </OrganisationTableForm>
        </ProfileModalContainerAddOrEditContact>
      </ProfileModalPageWrapper>
    );
  };

  const columns = useMemo(
    () => [
      {
        field: "org_name",
        headerName: "Company name",
        // width: 150,
        editable: false,
      },
      {
        field: "website",
        headerName: "Website",
        type: "string",
        renderCell: (params) => linkToWebsite(params),
      },
      {
        field: "industries",
        headerName: "Industries",
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => industriesDisplay(params),
      },
      {
        field: "num_employees",
        headerName: "# Employees",
        description: "Number of employees available on the Revmo graph",
        type: "number",
        width: 145,
        editable: false,
      },
      {
        field: "num_contacts",
        headerName: "# People Connected",
        description: "Number of people you have in your contact list",
        type: "number",
        width: 145,
        editable: false,
      },
      {
        field: "num_reachable_people",
        headerName: "# People Requestable",
        description:
          "Number of people to which you are able to request introductions",
        type: "number",
        width: 145,
        editable: false,
      },
      {
        field: "search",
        headerName: "Search",
        type: "actions",
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SearchIcon />}
            onClick={handleSubmitSearch(params.row.org_name)}
            label="Search"
            key={1}
          />,
        ],
      },
      {
        field: "fetch",
        headerName: "Fetch Org Data",
        description: "Calls fetch org api",
        type: "actions",
        width: 145,

        getActions: (params) => [
          fetchButton(params.row.org_name, params.row.org_uuid),
        ],
      },
    ],
    [handleSubmitSearch, fetchButton]
  );
  const { control } = useForm({
    defaultValues: { searchName: "" },
  });

  const handleKeyPress = function (e, value) {
    if (e.keyCode == 13) {
      setSearchName(value);
    }
  };
  if (list_uuids.includes(profile.uuid) && columns.length == 8) {
    columns.push({
      field: "edit",
      headerName: "Edit Organisation",
      description: "Calls fetch org api",
      type: "actions",
      width: 145,

      getActions: (params) => [
        EditButton(
          params.row.org_name,
          params.row.org_uuid,
          params.row.website
        ),
      ],
    });
  }
  return (
    <MainCard
      title={
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography variant="h4">Organisations</Typography>
          </Grid>
          <Grid item></Grid>
          <Grid item>
            <Controller
              control={control}
              name="searchName"
              defaultValue=""
              render={({ ...props }) => (
                <OutlinedInput
                  placeholder="Search Organisations"
                  onKeyDown={(event) => handleKeyPress(event, props.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSearchName(props.value);
                        }}
                      >
                        <SearchIcon stroke={1.5} size="1rem" />
                      </IconButton>
                    </InputAdornment>
                  }
                  size="small"
                  {...props}
                />
              )}
            />
          </Grid>
        </Grid>
      }
      content={false}
      sx={{
        borderRadius: { borderRadius },
        border: "none",
        mt: { xs: 3, lg: 0 },
      }}
    >
      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={connectedOrgs}
          columns={columns}
          autoHeight
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(row) => row.org_uuid}
        />
      </Box>
      {open && <EditOrgModal></EditOrgModal>}
    </MainCard>
  );
}
