import React from "react";
import { Link } from "react-router-dom";
// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

// project imports
import { AnimateButton } from "components/DashboardNew";

// styles
const CardStyle = styled(Card)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? theme.palette.dark[800]
      : theme.palette.tertiary.light,
  marginTop: "16px",
  marginBottom: "16px",
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "19px solid ",
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.tertiary.main
        : theme.palette.tertiary.main,
    borderRadius: "50%",
    top: "65px",
    right: "-150px",
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "3px solid ",
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.tertiary.main
        : theme.palette.tertiary.main,
    borderRadius: "50%",
    top: "145px",
    right: "-70px",
  },
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const UpgradePlanCard = () => {
  const theme = useTheme();

  return (
    <CardStyle>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h4">Go premium</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              color={
                theme.palette.mode === "dark" ? "textSecondary" : "grey.900"
              }
              sx={{ opacity: theme.palette.mode === "dark" ? 1 : 0.6 }}
            >
              Sign up for an annual subscription
              <br />
              and get 2 months free!
            </Typography>
          </Grid>
          <Grid item>
            <Stack direction="row">
              <AnimateButton>
                <Button
                  variant="contained"
                  color="tertiary"
                  sx={{ boxShadow: "none" }}
                  component={Link}
                  to="/settings"
                >
                  Go Premium
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </CardStyle>
  );
};

export default UpgradePlanCard;
