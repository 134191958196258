import React, { useState, useEffect } from "react";
import { EditPositionModal } from "components/Modal";
import { useTheme } from "@mui/material/styles";
import { Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import { getGeocode } from "use-places-autocomplete";

// icon imports
import BlockTwoToneIcon from "@mui/icons-material/BlockTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const ProfileInfoWrapper = styled.div`
  transition: box-shadow 0.2s ease;
  box-shadow: ${(props) =>
    props.isDragging ? "0px 0px 15px 1px rgba(0,0,0,0.31)" : "none"};
  border-left: ${(props) => (props.isDragging ? "5px solid #5374FF" : "none")};
`;

function PositionTable({
  index,
  job,
  handleSubmitUpdatePosition,
  profile,
  displayOption,
  toggleDisplayOption,
  handleDeletePosition,
  userContact,
  fromSearchPage,
  toggleAddOrEditSingleContactModal,
  isDragging,
  isDragDisabled,
  diallingCodesHook,
  customFormErrorsHook,
}) {
  const {
    company_email_addresses,
    location,
    org,
    telephone_number,
    is_active,
    start_date,
    end_date,
    role_description,
  } = job;

  const displayEditPositionModal = () => {
    if (telephone_number?.country_code) {
      let diallingCodeState = {};
      diallingCodeState.companyPhoneNumber = telephone_number;
      diallingCodesHook.setDiallingCodeToLiveData(diallingCodeState);
    }
    toggleDisplayOption(index, "displayModal");
  };

  const closeEditModal = () => {
    diallingCodesHook.clearInternationalDiallingCode();
    toggleDisplayOption(index, "displayModal");
  };

  const getLocationDetail = (components, type) => {
    const val = components.find((c) => c.types[0] === type);
    return val ? val.long_name : "";
  };

  const strLocationFromCoords = (coords) => async () => {
    if (coords == null) {
      return "";
    }
    const val = await getGeocode({
      location: { lat: coords[1], lng: coords[0] },
    });
    const components = val[0].address_components;
    const country = getLocationDetail(components, "country");
    const city = getLocationDetail(components, "postal_town");
    if (city && country) {
      return city + ", " + country;
    } else if (city) {
      return city;
    } else if (country) {
      return country;
    }
    return "";
  };
  const [strLocation, setStrLocation] = useState("");
  const coords = job?.primary_location?.centroid_lng_lat?.coordinates;

  useEffect(() => {
    const fetchDetails = async () => {
      const loc = await strLocationFromCoords(coords)();
      setStrLocation(loc);
    };
    fetchDetails();
  });

  return (
    <DragDropContext>
      <ProfileInfoWrapper
        isDragging={isDragging}
        isDragEnabled={!isDragDisabled}
      >
        {!userContact && displayOption?.displayModal && (
          <EditPositionModal
            setDisplayModal={closeEditModal}
            index={index}
            job={job}
            displayModal={displayOption?.displayModal}
            handleSubmitUpdatePosition={handleSubmitUpdatePosition}
            diallingCodesHook={diallingCodesHook}
            customFormErrorsHook={customFormErrorsHook}
          />
        )}
        <Grid container spacing={2} wrap="wrap" sx={{ padding: 1 }}>
          <Grid
            item
            md={5}
            sm={5}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            xs={12}
          >
            <Typography align="left" variant="subtitle1">
              {role_description}{" "}
            </Typography>
            <Typography
              align="left"
              variant="subtitle2"
              sx={{ whiteSpace: "break-spaces" }}
            >
              {org?.org_name} {location && "•"} {location}
            </Typography>
            <Typography
              align="left"
              variant="body2"
              sx={{ whiteSpace: "break-spaces" }}
            >
              {!is_active && end_date && start_date
                ? `${start_date.slice(0, 7)} - ${end_date.slice(0, 7)}`
                : !is_active
                ? "Previous Role"
                : is_active && start_date
                ? `${start_date.slice(0, 7)} - present`
                : `Current Role`}
            </Typography>
          </Grid>
          <Grid
            item
            md={5}
            sm={5}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            xs={12}
          >
            <Typography variant="caption">Email</Typography>
            <Typography variant="subtitle2">
              {company_email_addresses &&
                company_email_addresses[0]?.email_address}
            </Typography>
            {strLocation && (
              <div>
                <Typography variant="caption">Location</Typography>
                <Typography variant="subtitle2">{strLocation}</Typography>
              </div>
            )}
          </Grid>
          <Grid
            item
            lg={2}
            md={12}
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
            xs={12}
          >
            {isDragDisabled ? (
              <PositionTableShowMoreButtonAndModal
                userContact={userContact}
                fromSearchPage={fromSearchPage}
                displayOption={displayOption}
                handleDeletePosition={handleDeletePosition}
                job={job}
                profile={profile}
                toggleAddOrEditSingleContactModal={
                  toggleAddOrEditSingleContactModal
                }
                displayEditPositionModal={displayEditPositionModal}
              />
            ) : (
              <DragIndicatorIcon />
            )}
          </Grid>
        </Grid>
      </ProfileInfoWrapper>
    </DragDropContext>
  );
}

function PositionTableShowMoreButtonAndModal({
  userContact,
  fromSearchPage,
  handleDeletePosition,
  job,
  profile,
  toggleAddOrEditSingleContactModal,
  displayEditPositionModal,
}) {
  const theme = useTheme();

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      {!fromSearchPage && (
        <>
          <Tooltip placement="top" title="Edit">
            <IconButton
              color="primary"
              aria-label="edit"
              size="large"
              onClick={() =>
                !userContact
                  ? displayEditPositionModal()
                  : toggleAddOrEditSingleContactModal()
              }
            >
              <EditTwoToneIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title="Delete">
            <span>
              <IconButton
                color="primary"
                sx={{
                  color: theme.palette.tertiary.dark,
                  borderColor: theme.palette.tertiary.main,
                  "&:hover ": {
                    background: theme.palette.tertiary.light,
                  },
                }}
                size="large"
                onClick={() => handleDeletePosition(job)}
                disabled={profile?.positions?.length < 2 ? true : false}
              >
                <BlockTwoToneIcon sx={{ fontSize: "1.1rem" }} />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )}
    </Stack>
  );
}

export default PositionTable;
