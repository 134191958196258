import React, { Suspense } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ErrorBoundary from "components/ErrorBoundary";
import { HeaderText, IntroCountText } from "components/Typography";
import { findNumberOfRequests } from "../utils";
import { RequestHeaderContainer, IntroductionDropDown } from "../Layout";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  introductionRequestsState,
  introductionDateLimit,
} from "state/recoil/requests";

function MyRequestsHeader() {
  const todaysDate = new Date().toDateString();
  return (
    <RequestHeaderContainer>
      <div>
        <HeaderText marginBottom="7">Introductions</HeaderText>
        <ErrorBoundary
          fallback={
            <IntroCountText>
              Showing a total of 0 introduction requests as of {todaysDate}
            </IntroCountText>
          }
        >
          <Suspense
            fallback={
              <IntroCountText>
                Showing a total of 0 introduction requests as of {todaysDate}
              </IntroCountText>
            }
          >
            <IntroText />
          </Suspense>
        </ErrorBoundary>
      </div>
      <IntroDateDropdown></IntroDateDropdown>
    </RequestHeaderContainer>
  );
}

function IntroDateDropdown() {
  const [days, setDays] = useRecoilState(introductionDateLimit);

  const handleChange = (event) => {
    setDays(event.target.value);
  };
  return (
    <IntroductionDropDown>
      <Box sx={{ minWidth: 140 }}>
        <FormControl fullWidth>
          <InputLabel id="introductions-limit">Introductions</InputLabel>
          <Select
            labelId="within days"
            id="simple-select"
            value={days}
            label="Within 15 days"
            onChange={handleChange}
          >
            <MenuItem value={15}>Within 15 days</MenuItem>
            <MenuItem value={30}>Within 30 days</MenuItem>
            <MenuItem value={60}>Within 60 days</MenuItem>
            <MenuItem value={90}>Within 90 days</MenuItem>
            <MenuItem value={9999}>No limit</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </IntroductionDropDown>
  );
}

export function IntroText() {
  const requests = useRecoilValue(introductionRequestsState);
  let {
    numberOfIntroRequestsAccepted,
    numberOfIntroRequestsPending,
    numberOfIntroRequestsDeclined,
  } = findNumberOfRequests(requests);

  const todaysDate = new Date().toDateString();

  return (
    <IntroCountText>
      Showing a total of{" "}
      {numberOfIntroRequestsAccepted +
        numberOfIntroRequestsPending +
        numberOfIntroRequestsDeclined}{" "}
      introduction requests as of {todaysDate}
    </IntroCountText>
  );
}

export default MyRequestsHeader;
