import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, MemoryRouter } from "react-router-dom";
import { StaticRouter } from "react-router";
import { createTheme } from "@mui/material";

// theme constant
export const gridSpacing = 3;
export const borderRadius = "8px";
export const drawerWidth = 260;
export const appDrawerWidth = 320;

const LinkBehavior = forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

LinkBehavior.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
};

LinkBehavior.displayName = "LinkBehavior";

function Router(props) {
  const { children } = props;
  if (typeof window === "undefined") {
    return <StaticRouter location="/">{children}</StaticRouter>;
  }

  return <MemoryRouter>{children}</MemoryRouter>;
}

Router.propTypes = {
  children: PropTypes.node,
};

export const mdTheme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#000A33",
      light: "#000A33",
      dark: "#000A33",
      contrastText: "#fff",
    },
    secondary: {
      main: "#0069FF",
      200: "#d2e4ff",
      800: "#0069FF",
      light: "#d2e4ff",
      dark: "#00255a",
      contrastText: "#fff",
    },
    tertiary: {
      main: "#ff3e6e",
      200: "#ffbacb",
      800: "#5e0017",
      light: "#ffbacb",
      dark: "#5e0017",
      contrastText: "#fff",
    },
    dark: {
      main: "#000A33",
      light: "#000A33",
      dark: "#000A33",
      contrastText: "#fff",
    },
    grey: {
      main: "#B3BCC6",
      contrastText: "#fff",
    },
  },
});
