import React from "react";
import { usePagination } from "hooks";
import Pagination from "./index.js";

function PaginationWithHook({ totalNumberOfResults }) {
  const { nextPage, prevPage, jumpPage, currentPage, maxPage } = usePagination(
    totalNumberOfResults,
    10
  );
  return (
    <Pagination
      nextPage={nextPage}
      currentPage={currentPage}
      prevPage={prevPage}
      jumpPage={jumpPage}
      maxPage={maxPage}
    />
  );
}

export default PaginationWithHook;
