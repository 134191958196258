import React from "react";

// material-ui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import SearchResultsListRow from "./SearchResultsListRow";

function SearchResultsList({
  searchResult,
  viewIntroductionPathwayToNetworkMember,
  showAddContactModal,
}) {
  return (
    <TableContainer sx={{ display: { xs: "none", md: "block" } }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="center">Revmo Score</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center" sx={{ pr: 3 }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchResult &&
            searchResult.people.map((row, index) => (
              <SearchResultsListRow
                row={row}
                index={index}
                key={index}
                viewIntroductionPathwayToNetworkMember={
                  viewIntroductionPathwayToNetworkMember
                }
                showAddContactModal={showAddContactModal}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SearchResultsList;
