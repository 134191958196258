import React from "react";
import { OrgsTable } from "./components";
import { Grid } from "@mui/material";

function Organisations() {
  return (
    <Grid item xs={12} sx={{ p: 3, bgColor: "white" }}>
      <OrgsTable />
    </Grid>
  );
}

export default Organisations;
