import React from "react";
import styled from "styled-components";
import { SmallBlackBoldText } from "../Typography";
import Switch from "react-switch";

const ToggleWrapper = styled.div`
  margin: 0 84px 0 26px;
  display: flex;
  align-items: center;
`;

const AccountIsActiveToggleContainer = styled.div`
  display: flex;
  margin: 38px 0 21px 0;
  padding-left: 19px;
  padding-bottom: 30px;
  justify-content: space-between;
  border-bottom: 1px solid #e3e6e9;
  @media only screen and (min-width: 768px) {
    align-items: center;
  }
  span {
    display: flex;
    align-items: center;
    @media (max-width: 480) {
      margin-bottom: 22px;
    }
  }
  @media (max-width: 480px) {
    flex-direction: column;
    margin: 35px 0 23px 0;
    padding: 0 0 19px 0;
  }
`;

export function Toggle({ handleChange, children, checked }) {
  return (
    <AccountIsActiveToggleContainer>
      <span>
        <SmallBlackBoldText>Active</SmallBlackBoldText>
        <ToggleWrapper>
          <Switch
            onChange={() => handleChange()}
            checkedIcon={false}
            uncheckedIcon={false}
            checked={checked}
            height={32}
            width={68}
            onColor="#18BFA1"
          />
        </ToggleWrapper>
      </span>
      {children}
    </AccountIsActiveToggleContainer>
  );
}

export const ToggleWrapperProfile = styled.div`
  display: flex;
  align-items: center;
  padding: 38px 0;
  p {
    margin-right: 35px;
  }
  @media (max-width: 480px) {
    padding: 21px;
  }
`;

export function ToggleProfile({ handleChange, checked, disabled }) {
  return (
    <ToggleWrapperProfile>
      <SmallBlackBoldText>Active</SmallBlackBoldText>
      <Switch
        onChange={async () => (handleChange ? await handleChange() : null)}
        checkedIcon={false}
        uncheckedIcon={false}
        checked={checked}
        height={32}
        width={68}
        onColor="#18BFA1"
        disabled={disabled}
      />
    </ToggleWrapperProfile>
  );
}
