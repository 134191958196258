import { useState } from "react";

const useCustomFormErrors = () => {
  const [customFormErrors, setCustomFormErrors] = useState({});
  const clearCustomFormErrors = () => {
    setCustomFormErrors({});
  };
  return { customFormErrors, setCustomFormErrors, clearCustomFormErrors };
};

export default useCustomFormErrors;
