import styled from "styled-components";

export const RequestHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 36px;
  width: 100%;
  @media (max-width: 1126px) {
    margin-bottom: 65px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const TimeFilterContainer = styled.div`
  display: flex;
`;

export const ToggleTimeFrameContainer = styled.div`
  display: flex;
  z-index: 5;
`;

export const TableAndCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 53px;
`;

export const LoadingTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  @media (max-width: 1126px) {
    width: 100%;
  }
`;

export const IntroductionDropDown = styled.div`
  display: flex;

  justify-content: flex-end;
  flex-direction: row;
  margin-top: 0px;
  width: 300px;
  @media (max-width: 1126px) {
    width: 100%;
  }
`;
