import React from "react";
import {
  HeaderText,
  BlueLinkTextAdapative,
  ExtraSmallBlackTextUnderlineLogin,
} from "components/Typography";
import {
  AuthFormWrapper,
  AuthAlternateOptionsRow,
  LogInToRevmo,
} from "components/Auth";
import styled from "styled-components";
import { Link } from "react-router-dom";

const AuthFormWrapperLogin = styled(AuthFormWrapper)`
  button {
    margin: 5px 0 30px 0;
  }
`;

function LogInUser({ userCanRequestNewPassword, logInUser, alertMessage }) {
  return (
    <AuthFormWrapperLogin>
      <HeaderText>Log in to Revmo</HeaderText>
      <LogInToRevmo logInUser={logInUser} alertMessage={alertMessage}>
        <ExtraSmallBlackTextUnderlineLogin onClick={userCanRequestNewPassword}>
          Forgot your password?
        </ExtraSmallBlackTextUnderlineLogin>
      </LogInToRevmo>
      <AuthAlternateOptionsRow>
        <Link to="/contact">Can't log in?</Link>
        <BlueLinkTextAdapative>|</BlueLinkTextAdapative>
        <Link to="sign-up">Sign up for an account</Link>
      </AuthAlternateOptionsRow>
    </AuthFormWrapperLogin>
  );
}

export default LogInUser;
