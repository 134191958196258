import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import revmoTeam from "assets/images/revmo-team-06-22.png";
import pastCompanies from "assets/images/past-companies.png";

const IntroductionSectionWrapper = styled.section`
  min-height: 40vh;
  background-color: #fff;
`;

const IntroductionSectionContentWrapper = styled.div`
  padding: calc(40vmax / 10) 4vw calc(40vmax / 10) 4vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IntroductionSectionContent = styled.div`
  max-width: 1000px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 1em 10em;
  border-top: 1px solid #efeffd;
  padding-top: 2rem;
`;

const IntroductionSectionText = styled.div`
  h2 {
    font-weight: normal;
  }
  a,
  p {
    line-height: 1.8;
    letter-spacing: 0;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
  }
  a {
    font-weight: bold;
    :hover {
      text-decoration: underline;
    }
  }
`;

const TeamSectionImage = styled.div`
  img {
    max-width: 100%;
  }
`;

const PastCompaniesImageWrapper = styled.div`
  img {
    max-width: 100%;
  }
`;

function TeamSection() {
  return (
    <IntroductionSectionWrapper>
      <IntroductionSectionContentWrapper>
        <IntroductionSectionContent>
          <Fade left>
            <IntroductionSectionText>
              <h2>We're here to solve your problems.</h2>
              <p>
                We are a diverse team of former bankers, consultants,
                entrepreneurs, data analysts, software developers, and
                cybersecurity experts. Our team members and advisors have worked
                at and are affiliated with some of the leading institutions in
                the world.
              </p>
              <PastCompaniesImageWrapper>
                <img src={pastCompanies} />
              </PastCompaniesImageWrapper>
              <p></p>
              <p>
                We are always on the lookout for talented people to join our
                growing teams in London and New York.
              </p>
              <Link to="/careers">See our open positions</Link>
            </IntroductionSectionText>
          </Fade>
          <Fade right>
            <TeamSectionImage>
              <img src={revmoTeam} />
            </TeamSectionImage>
          </Fade>
        </IntroductionSectionContent>
      </IntroductionSectionContentWrapper>
    </IntroductionSectionWrapper>
  );
}

export default TeamSection;
