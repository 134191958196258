import * as yup from "yup";
import { fetchData, getSessionToken } from "utils";

export const editPersonalInfoDataSchema = yup.object().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your position"),
  email: yup.string().email("Email is not properly formatted"),
});

export const closeAndClearEditPasswordModal = (
  setDisplayEditPasswordModal,
  setUpdatePasswordStatus
) => {
  setUpdatePasswordStatus({ status: null });
  setDisplayEditPasswordModal(false);
};

export const formatProfile = (data) => {
  const { first_name, last_name } = data;

  const capitalizeWord = (word) => {
    return word ? word[0].toUpperCase() + word.slice(1) : null;
  };

  const formattedProfile = {
    first_name: capitalizeWord(first_name),
    last_name: capitalizeWord(last_name),
  };

  Object.keys(formattedProfile).forEach((key) =>
    formattedProfile[key] ? {} : delete formattedProfile[key]
  );

  return formattedProfile;
};

export const payWithGoCardless = async () => {
  try {
    const jwtToken = await getSessionToken();
    const redirectUrl = await fetchData(
      `/gocardless/?session_id=${jwtToken.slice(0, 5)}`
    );
    window.location.replace(redirectUrl);
  } catch (err) {
    console.log(err);
  }
};
