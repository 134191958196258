import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";

import { borderRadius } from "containers/DashboardNew/theme";

const ProfileCard = ({
  userContact,
  profile,
  onboarding,
  setShowUpdateProfileImageModal,
  numberOfContactsInNetwork,
  numberOfIntroductions,
}) => (
  <Card sx={{ borderRadius: borderRadius }}>
    <CardContent>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Avatar
          src={profile.profile_image}
          sx={{
            height: 96,
            mb: 2,
            width: 96,
          }}
        />
        <Typography color="textPrimary" gutterBottom variant="h4">
          {profile.first_name ? (
            `${profile?.first_name} ${profile?.last_name}`
          ) : (
            <Skeleton variant="text" width={290} />
          )}
        </Typography>
        {!!profile?.positions?.length && (
          <Typography color="textSecondary" variant="body2">
            <strong>{`${profile.positions[0]?.role_description}`}</strong>
            {` at ${profile.positions[0]?.org?.org_name}`}
          </Typography>
        )}
        <Typography variant="subtitle2" align="center" sx={{ paddingTop: 1 }}>
          {profile?.revmo_network_score || 458} / 800
        </Typography>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            paddingBottom: 1,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={(100 * (profile.revmo_score || 458)) / 800}
            color="primary"
            sx={{ width: "90%" }}
          />
        </Box>
      </Box>
    </CardContent>
    {!userContact && <Divider />}
    {!userContact && (
      <Grid container>
        <Grid
          item
          xs
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Typography fontWeight="bold">
            {numberOfContactsInNetwork ?? 0}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Contacts
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Tooltip title="This includes your successful, pending and unsuccessful introductions">
          <Grid
            item
            xs
            display="flex"
            flexDirection="column"
            textAlign="center"
            alignItems="center"
            sx={{ p: 2 }}
          >
            <Typography fontWeight="bold">
              {numberOfIntroductions ?? 0}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Total Introductions
            </Typography>
          </Grid>
        </Tooltip>
      </Grid>
    )}
    <Divider />
    {!onboarding && !userContact && (
      <CardActions>
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={() => setShowUpdateProfileImageModal(true)}
        >
          Change picture
        </Button>
      </CardActions>
    )}
  </Card>
);

export default ProfileCard;
