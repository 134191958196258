import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { LandingButtonCTA } from "components/Button";

const LandingCallToActionWrapper = styled.section`
  width: 100%;
  min-height: 400px;
  position: relative;
  background-color: #0069ff;
  img {
    overflow: hidden;
    object-fit: cover;
    min-width: 100%;
  }
  @media (max-width: 992px) {
    padding-left: 8.333%;
    padding-right: 8.333%;
  }
  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const LandingCallToActionOverlay = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 576.1px) {
    align-items: center;
  }
  @media (max-width: 576px) {
    flex-flow: row wrap;
  }
`;

const LandingCallToActionContent = styled.div`
  padding: 20px 0px 40px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h2 {
    color: white;
    font-size: 32px;
    line-height: 1.2em;
    font-family: montserrat, sans-serif;
    font-style: normal;
    padding-bottom: 2rem;
  }
  p {
    color: white;
    font-size: 18px;
    line-height: 1.2em;
    text-align: center;
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    max-width: 600px;
  }
  @media (min-width: 471px) {
    div {
      max-width: 40%;
    }
  }
  @media (max-width: 470px) {
    flex-direction: column;
    div {
      width: 100%;
      text-align: center;
      padding: 0 2rem 1rem 2rem;
      h2 {
        font-size: 10vw;
      }
      p {
        text-align: center;
        font-size: 5vw;
      }
    }
  }
`;

const LandingCallToAction = () => {
  return (
    <LandingCallToActionWrapper>
      <LandingCallToActionOverlay>
        <LandingCallToActionContent>
          <h2>Do you know how to reach the right people?</h2>
          <p>Start making connections today.</p>
        </LandingCallToActionContent>
        <Link to="/sign-up">
          <LandingButtonCTA>START CONNECTING</LandingButtonCTA>
        </Link>
      </LandingCallToActionOverlay>
    </LandingCallToActionWrapper>
  );
};

export default LandingCallToAction;
