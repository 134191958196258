import { useState } from "react";
import countryTelData from "country-telephone-data";

const useInternationalDiallingCodes = () => {
  const defaultDiallingCode = {
    countryCode: "+1",
    regionCode: "us",
    nationalNumber: "",
  };
  const [diallingCode, setDiallingCode] = useState({
    personalPhoneNumber: defaultDiallingCode,
    companyPhoneNumber: defaultDiallingCode,
  });
  const handleChangeDiallingCode = (event, phoneType) => {
    const diallingCodeData = JSON.parse(event.target.value);
    setDiallingCode({ ...diallingCode, [phoneType]: diallingCodeData });
  };

  const clearInternationalDiallingCode = () => {
    setDiallingCode({
      personalPhoneNumber: defaultDiallingCode,
      companyPhoneNumber: defaultDiallingCode,
    });
  };

  const setDiallingCodeToLiveData = (diallingCodeData) => {
    const updatedDiallingCodeState = {};
    if (diallingCodeData.personalPhoneNumber?.national_number?.length) {
      updatedDiallingCodeState.personalPhoneNumber = {
        countryCode: diallingCodeData.personalPhoneNumber.country_code,
        nationalNumber: diallingCodeData.personalPhoneNumber.national_number,
        regionCode: diallingCodeData.personalPhoneNumber.region_code,
      };
    }
    if (diallingCodeData?.companyPhoneNumber?.national_number?.length) {
      updatedDiallingCodeState.companyPhoneNumber = {
        countryCode: diallingCodeData.companyPhoneNumber.country_code,
        nationalNumber: diallingCodeData.companyPhoneNumber.national_number,
        regionCode: diallingCodeData.companyPhoneNumber.region_code,
      };
    }
    setDiallingCode({ ...diallingCode, ...updatedDiallingCodeState });
  };

  const countryDiallingCodes = () => {
    const refinedDiallingCodeData = [];
    countryTelData.allCountries.forEach((country) => {
      const countryData = {
        name: country.name,
        countryCode: `+${country.dialCode}`,
        regionCode: country.iso2,
      };
      if (country.name === "United Kingdom") {
        refinedDiallingCodeData.unshift(countryData);
      } else if (country.name === "United States") {
        refinedDiallingCodeData.unshift(countryData);
      } else {
        refinedDiallingCodeData.push(countryData);
      }
    });
    return refinedDiallingCodeData;
  };

  return {
    diallingCode,
    handleChangeDiallingCode,
    countryDiallingCodes: countryDiallingCodes(),
    setDiallingCodeToLiveData,
    clearInternationalDiallingCode,
    setDiallingCode,
  };
};

export default useInternationalDiallingCodes;
