import React, { useEffect, useState } from "react";
import sanityClient from "../../client";
import { LandingNavbar } from "components/Navbar";
import { CareersHero, CareersValues, JobRow } from "./components";
import { CareersPageWrapper, RolesWrapper } from "./Layout";
import { LandingFooter } from "components/LandingPage";

function Careers() {
  return (
    <>
      <LandingNavbar />
      <CareersPageWrapper>
        <CareersHero />
        <CareersValues />
        <h2>Roles Available</h2>
        <AllRoles />
      </CareersPageWrapper>
      <LandingFooter />
    </>
  );
}

function AllRoles() {
  const [allRolesData, setAllRoles] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "role"]{
        title,
        slug,
        tags,
    }`
      )
      .then((data) => setAllRoles(data))
      .catch(console.error);
  }, []);

  return (
    <RolesWrapper>
      {allRolesData &&
        allRolesData.map((role, index) => {
          return (
            <JobRow
              role={role.title}
              key={index}
              tags={role.tags}
              link={role.slug.current}
            />
          );
        })}
    </RolesWrapper>
  );
}

export default Careers;
