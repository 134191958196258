import React, { useEffect, useState } from "react";
import sanityClient from "../../../client";
import { Container996 } from "components/Post/Layout";
import SupportItem from "./SupportItem";

function SupportItems() {
  const [crmData, setCRMData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'faq'] 
              {
              title,
              slug,
              body[]{
                ..., 
                asset->{
                  ...,
                  "_key": _id
                }
              }
          }`
      )
      .then((data) => setCRMData(data))
      .catch(console.error);
  }, []);

  return (
    <Container996>
      {crmData &&
        crmData.map((faq, index) => {
          return <SupportItem faq={faq} key={index} />;
        })}
    </Container996>
  );
}

export default SupportItems;
