import { useContext } from "react";
import { ProfileContext } from "../state";

const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error("`useProfile` must be within a `ProfileProvider`");
  }
  return context;
};

export default useProfile;
