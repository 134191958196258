import React from "react";
import UserContacts from "components/UserContacts";
import { Grid } from "@mui/material";

function Contacts() {
  return (
    <Grid item xs={12}>
      <UserContacts />
    </Grid>
  );
}

export default Contacts;
