import React from "react";
import styled from "styled-components";
import {
  LargeBoldText,
  MidLargeSpacedText,
  MidSizedText,
} from "../../Typography";
import closeIcon from "assets/icons/close-icon.svg";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  ProfileModalContainerAddContacts,
  AddContactsContent,
  HeaderAndExplainerTextContainerTags,
} from "../index";
import {
  ExistingTagsWrapper,
  AskIfTheUserWouldLikeToSelectAnExistingTag,
} from "./index";
import { useForm } from "react-hook-form";
import { ButtonPrimaryWithArrow } from "components/Button";

const ViewTagButtons = styled.div`
  display: flex;
  margin-top: 20px;
  button {
    margin-top: 10px;
    height: 50px;
    margin-right: 10px;
    margin-bottom: 7px;
  }
`;

export function TagMultipleContacts({
  setDisplayModal,
  tagMultipleContactsHook,
  allTags,
  setAllTags,
}) {
  const { register, errors, watch, handleSubmit } = useForm();
  const { name, otherTags } = watch();
  const disableCreateNewTag = !name?.length;
  const disableOtherTag = !otherTags?.length;
  const {
    selectTagHideModalAndTagUsersExistingTag,
    selectTagHideModalAndTagUsersNewTag,
  } = tagMultipleContactsHook;

  const addAndSubmitTagNewTag = async (data) => {
    selectTagHideModalAndTagUsersNewTag(data);
    setAllTags([...allTags, { name: data.name }]);
  };

  return (
    <>
      <ProfileModalPageWrapper>
        <DarkBlurredBackground onClick={setDisplayModal} />
        <ProfileModalContainerAddContacts>
          <CloseIconImage src={closeIcon} onClick={setDisplayModal} />
          <AddContactsContent>
            <TagMultipleContactsBodyText allTags={allTags} />
            <>
              {allTags[0] && (
                <ExistingTagsWrapper>
                  <MidSizedText>
                    Or reuse a tag you created previously. Select an existing
                    tag from the list below. You will be able to sort and filter
                    your contacts by tag.
                  </MidSizedText>
                  <AskIfTheUserWouldLikeToSelectAnExistingTag
                    register={register}
                    error={errors}
                    allTags={allTags}
                  />
                </ExistingTagsWrapper>
              )}
              <TagMultipleContactsButton
                handleSubmit={handleSubmit}
                addAndSubmitTagNewTag={addAndSubmitTagNewTag}
                selectTagHideModalAndTagUsersExistingTag={
                  selectTagHideModalAndTagUsersExistingTag
                }
                disableCreateNewTag={disableCreateNewTag}
                disableOtherTag={disableOtherTag}
                allTags={allTags}
              />
            </>
          </AddContactsContent>
        </ProfileModalContainerAddContacts>
      </ProfileModalPageWrapper>
    </>
  );
}

export default TagMultipleContacts;

function TagMultipleContactsBodyText() {
  return (
    <>
      <HeaderAndExplainerTextContainerTags>
        <LargeBoldText>Tags</LargeBoldText>
        <MidLargeSpacedText>
          Select from the list of tags below, to add context to your
          relationship. Revmo allows you to form groupings with tags, giving you
          the ability to search and filter by tag name.
        </MidLargeSpacedText>
      </HeaderAndExplainerTextContainerTags>
    </>
  );
}

function TagMultipleContactsButton({
  handleSubmit,
  selectTagHideModalAndTagUsersExistingTag,
  disableOtherTag,
  allTags,
}) {
  return (
    <ViewTagButtons>
      {allTags[0] && (
        <ButtonPrimaryWithArrow
          buttonText="Add existing tag"
          action={handleSubmit(selectTagHideModalAndTagUsersExistingTag)}
          disabled={disableOtherTag}
        />
      )}
    </ViewTagButtons>
  );
}
