import { LandingFooter, LandingPageContainer } from "components/LandingPage";
import { LandingNavbar } from "components/Navbar";
import { usePageTitle } from "hooks";
import React from "react";
import { BlogHero, BlogListItems } from "./components";

function Blog() {
  usePageTitle("Blog | Revmo");
  return (
    <>
      <LandingPageContainer>
        <LandingNavbar />
        <BlogHero />
        <BlogListItems />
      </LandingPageContainer>
      <LandingFooter />
    </>
  );
}

export default Blog;
