import React from "react";

// material-ui
import Grid from "@mui/material/Grid";

import { gridSpacing } from "containers/DashboardNew/theme";
import { SearchCard } from "components/DashboardNew";

function SearchResultsCards({
  searchResult,
  viewIntroductionPathwayToNetworkMember,
  showAddContactModal,
}) {
  return (
    <Grid
      container
      alignItems="center"
      spacing={gridSpacing}
      sx={{ display: { xs: "block", md: "none" } }}
    >
      <Grid item xs={12}>
        <Grid container direction="row" spacing={gridSpacing}>
          {searchResult &&
            searchResult.people.map((row, index) => (
              <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                <SearchCard
                  row={row}
                  index={index}
                  viewIntroductionPathwayToNetworkMember={
                    viewIntroductionPathwayToNetworkMember
                  }
                  showAddContactModal={showAddContactModal}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SearchResultsCards;
