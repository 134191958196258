import React from "react";
import styled from "styled-components";
import revmoMockup from "assets/images/revmo-laptop-mockup.jpeg";
import revmoWarpSpeed from "assets/images/revmo-work-together.jpeg";
import revmoNetwork from "assets/images/revmo-network-work.jpeg";

const LandingGridSectionWrapper = styled.div`
  padding: 10rem 2rem;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 870.9px) {
    padding: 0;
  }
`;

const LandingImageContentMainWrapper = styled.div`
  width: 100%;
  max-width: 1224px;
  display: flex;
  align-items: center;
  @media (min-width: 871px) {
    flex-direction: row;
    margin-bottom: 10rem;
  }
  @media (max-width: 870.9px) {
    display: flex;
    flex-direction: column;
  }
`;

const LandingGridImageMain = styled.div`
  overflow: hidden;
  img {
    object-fit: cover;
    min-height: 100%;
  }
  @media (min-width: 871px) {
    flex: 0 0 auto;
    width: 66.77777%;
    height: 35rem;
    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 0;
      vertical-align: middle;
    }
  }
  @media (max-width: 870.9px) {
    max-width: 870px;
    background-color: #242323;
    min-height: 400px;
    max-height: 500px;
    display: flex;
    width: 100%;
    justify-content: center;
    img {
      max-height: 500px;
      height: 100%;
    }
  }
`;

const LandingGridTextMain = styled.div`
  display: flex;
  align-items: center;
  div {
    h2 {
      font-size: 40px;
      color: black;
      line-height: 1.2em;
      font-weight: bold;
      font-style: normal;
    }
    p {
      color: black;
    }
  }

  @media (min-width: 871px) {
    flex: 0 0 auto;
    width: 33.3333%;
    justify-content: center;
    text-align: center;
    text-color: black;
    div {
      border-left: 0.5rem solid #5374ff;
    }
  }
  @media (max-width: 870.9px) {
    min-height: 400px;
    justify-content: center;
    text-align: center;
    width: 100%;
    div {
      max-width: 90%;
    }
  }
`;
const LandingGrid = styled.div`
  max-width: 1224px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 2rem 0rem 2rem 0rem;
`;

const LandingGridRow = styled.div`
  width: 100%;
  max-width: 1224px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  @media (min-width: 871px) {
    max-height: 420px;
    height: calc(50vh - 24px);
  }
  @media (max-width: 870.9px) {
    height: 100vh;
    flex-direction: column;
    div {
      flex: 1;
    }
  }
`;

const LandingGridImage = styled.div`
  flex: 0 0 auto;
  overflow: hidden;
  @media (min-width: 871px) {
    width: 50%;
    align-self: stretch;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 870.9px) {
    max-width: 870px;
    order: -1;
    background-color: black;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
`;

const LandingGridText = styled.div`
  background-color: black;
  flex: 0 0 auto;
  display: flex;
  width: 50%;
  max-height: 420px;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  div {
    text-align: center;
    max-width: 90%;
    h2 {
      font-size: min(2.7vw, 40px);
      color: white;
      line-height: 1.2em;
      font-weight: bold;
      font-style: normal;
    }
    p {
      color: white;
    }
  }
  @media (max-width: 870.9px) {
    justify-content: center;
    width: 100%;
    background-color: #f8f9fa;
    div {
      max-width: 90%;
      text-align: center;
      h2 {
        color: black;
        font-size: 40px;
      }
      p {
        color: black;
      }
    }
  }
  @media (max-width: 470px) {
    div {
      h2 {
        font-size: 7vw;
      }
      p {
        font-size: 3.2vw;
      }
    }
  }
`;

const LandingPhotosSection = () => {
  return (
    <LandingGridSectionWrapper>
      <LandingImageContentSectionMain />
      <LandingPhotosSectionGrid />
    </LandingGridSectionWrapper>
  );
};

const LandingImageContentSectionMain = () => {
  return (
    <LandingImageContentMainWrapper>
      <LandingGridImageMain>
        <img src={revmoMockup} />
      </LandingGridImageMain>
      <LandingGridTextMain>
        <div>
          <h2>Connections In Seconds</h2>
          <p>
            Connect to the right person the first time, every time, using our
            patent-pending secure search.
          </p>
        </div>
      </LandingGridTextMain>
    </LandingImageContentMainWrapper>
  );
};

const LandingPhotosSectionGrid = () => {
  return (
    <LandingGrid>
      <LandingGridRow>
        <LandingGridImage>
          <img src={revmoWarpSpeed} />
        </LandingGridImage>
        <LandingGridText>
          <div>
            <h2>Personalised Networking at Warp Speed</h2>
            <p>
              Revmo automatically finds and connects to professionals across
              networks, acting as a your personal assistant for making warm,
              natural introductions.
            </p>
            <p>No cold emails.</p>
            <p>No awkward form letters.</p>
          </div>
        </LandingGridText>
      </LandingGridRow>
      <LandingGridRow>
        <LandingGridText>
          <div>
            <h2>Put Your Network to Work</h2>
            <p>
              Leverage your existing data to find optimal connections you never
              knew you had.
            </p>
            <p>
              Always safe and secure. Always with an infinite combination of
              privacy options.
            </p>
          </div>
        </LandingGridText>
        <LandingGridImage>
          <img src={revmoNetwork} />
        </LandingGridImage>
      </LandingGridRow>
    </LandingGrid>
  );
};

export default LandingPhotosSection;
