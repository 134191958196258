import React from "react";

export function PrivacyPolicyText() {
  return (
    <div>
      <p>
        <strong>Privacy Policy&nbsp;</strong>
      </p>
      <p>
        <strong>Last updated February 11, 2021&nbsp;</strong>
      </p>
      <p>
        Thank you for choosing to be part of our community at Revmo, Inc. (
        <strong>"Company", "we", "us", "our"</strong>). We are committed to
        protecting your personal information and your right to privacy. If you
        have any questions or concerns about this privacy notice, or our
        practices with regards to your personal information, please contact us
        at <strong>info@revmo.app</strong>
      </p>
      <p>
        When you visit our website{" "}
        <a href="http://www.revmo.app">http://www.revmo.app</a> (the "
        <strong>Website</strong>"), and more generally, use any of our services
        (the "<strong>Services</strong>", which include the Website), we
        appreciate that you are trusting us with your personal information. We
        take your privacy very seriously. In this privacy notice, we seek to
        explain to you in the clearest way possible what information we collect,
        how we use it and what rights you have in relation to it. We hope you
        take some time to read through it carefully, as it is important. If
        there are any terms in this privacy notice that you do not agree with,
        please discontinue use of our Services immediately.
      </p>
      <p>
        This privacy notice applies to all information collected through our
        Services (which, as described above, includes our Website), as well as,
        any related services, sales, marketing or events.
      </p>
      <p>
        <strong>
          Please read this privacy notice carefully as it will help you
          understand what we do with the information that we collect.
        </strong>
      </p>
      <p>
        <strong>TABLE OF CONTENTS&nbsp;</strong>
      </p>
      <ol>
        <li>
          <a href="#section1"> WHAT INFORMATION DO WE COLLECT?</a>
        </li>
        <li>
          <a href="#section2"> HOW DO WE USE YOUR INFORMATION?</a>
        </li>
        <li>
          <a href="#section3"> WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
        </li>
        <li>
          <a href="#section4"> HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
        </li>
        <li>
          <a href="#section5"> HOW LONG DO WE KEEP YOUR INFORMATION?</a>
        </li>
        <li>
          <a href="#section6"> HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
        </li>
        <li>
          <a href="#section7"> DO WE COLLECT INFORMATION FROM MINORS?</a>
        </li>
        <li>
          <a href="#section8"> WHAT ARE YOUR PRIVACY RIGHTS?</a>
        </li>
        <li>
          <a href="#section9"> CONTROLS FOR DO-NOT-TRACK FEATURES</a>
        </li>
        <li>
          <a href="#section10">
            {" "}
            DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
        </li>
        <li>
          <a href="#section11"> DO WE MAKE UPDATES TO THIS NOTICE?</a>
        </li>
        <li>
          <a href="#section12"> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
        </li>
        <li>
          <a href="#section13">
            {" "}
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </a>
        </li>
      </ol>
      <p>
        <br />
        <br />
      </p>
      <h2 id="section1">1. WHAT INFORMATION DO WE COLLECT?</h2>
      <p>
        <strong>Personal information you disclose to us</strong>
      </p>
      <p>
        <strong>
          <em>In Short:</em>
        </strong>
        <em> We collect personal information that you provide to us.&nbsp;</em>
      </p>
      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Website, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Website (such as by posting messages in our online
        forums or entering competitions, contests or giveaways) or otherwise
        when you contact us.
      </p>
      <p>
        The personal information that we collect depends on the context of your
        interactions with us and the Website, the choices you make and the
        products and features you use. The personal information we collect may
        include the following:
      </p>
      <p>
        <strong>Personal Information Provided by You.</strong> We collect names;
        phone numbers; email addresses; job titles; mailing addresses; contact
        preferences; contact or authentication data; billing addresses;
        debit/credit card numbers; and other similar information.
      </p>
      <p>
        <strong>Social Media Login Data. </strong>We may provide you with the
        option to register with us using your existing social media account
        details, like your Facebook, Twitter or other social media account. If
        you choose to register in this way, we will collect the information
        described in the section called{" "}
        <strong>"HOW DO WE HANDLE YOUR SOCIAL LOGINS?"</strong> below.
      </p>
      <p>
        All personal information that you provide to us must be true, complete
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>
      <p>
        <strong>Information collected from other sources</strong>
      </p>
      <p>
        <strong>
          <em>In Short:</em>
        </strong>
        <em>
          {" "}
          We may collect limited data from public databases, marketing partners,
          social media platforms, and other outside sources.
        </em>
      </p>
      <p>
        In order to enhance our ability to provide relevant marketing, offers
        and affiliate programs, data providers, social media platforms, as well
        as from other third parties. This information includes mailing
        addresses, job titles, email addresses, phone numbers, intent data (or
        user behavior data), Internet Protocol (IP) addresses, social media
        profiles, social media URLs and custom profiles, for purposes of
        targeted advertising and event promotion. If you interact with us on a
        social media platform using your social media account (e.g. Facebook or
        Twitter), we receive personal information about you such as your name,
        email address, and gender. Any personal information that we collect from
        your social media account depends on your social media account's privacy
        settings.
      </p>
      <h2 id="section2">2. HOW DO WE USE YOUR INFORMATION?</h2>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We process your information for purposes based on legitimate business
          interests, the fulfillment of our contract with you, compliance with
          our legal obligations, and/or your consent.
        </em>
      </p>
      <p>
        We use personal information collected via our Website for a variety of
        business purposes described below. We process your personal information
        for these purposes in reliance on our legitimate business interests, in
        order to enter into or perform a contract with you, with your consent,
        and/or for compliance with our legal obligations. We indicate the
        specific processing grounds we rely on next to each purpose listed
        below.
      </p>
      <p>
        In legal terms, we are generally the "data controller" under European
        data protection laws since we determine the means and/or purposes of the
        data processing we perform. However, if you are a business customer with
        whom we have entered into a data processing agreement for the provision
        of corporate services to you, then you would be the "data controller"
        and we would be the "data processor" under European data protection laws
        since we would be processing data on your behalf in accordance with your
        instructions.
      </p>
      <p>We use the information we collect or receive:</p>
      <ul>
        <ul>
          <li aria-level="1">
            <strong>To facilitate account creation and logon process.</strong>{" "}
            If you choose to link your account with us to a third-party account
            (such as your Google or Facebook account), we use the information
            you allowed us to collect from those third parties to facilitate
            account creation and logon process for the performance of the
            contract. See the section below headed{" "}
            <strong>"HOW DO WE HANDLE YOUR SOCIAL LOGINS?" </strong>for further
            information.&nbsp;
          </li>
          <li aria-level="1">
            testimonial. If you wish to update, or delete your testimonial,
            please contact us at <strong>privacy@revmo.app</strong> and be sure
            to include your name, testimonial location, and contact information.
          </li>
          <li aria-level="1">
            <strong>Request feedback. </strong>We may use your information to
            request feedback and to contact you about your use of our Website.
          </li>
          <li aria-level="1">
            <strong>To enable user-to-user communications. </strong>We may use
            your information in order to enable user-to-user communications with
            each user's consent.
          </li>
          <li aria-level="1">
            <strong>To manage user accounts.</strong> We may use your
            information for the purposes of managing our account and keeping it
            in working order.
          </li>
          <li aria-level="1">
            <strong>To send administrative information to you.</strong> We may
            use your personal information to send you product, service and new
            feature information and/or information about changes to our terms,
            conditions, and policies.
          </li>
          <li aria-level="1">
            <strong>To protect our Services.</strong> We may use your
            information as part of our efforts to keep our Website safe and
            secure (for example, for fraud monitoring and prevention).
          </li>
        </ul>
      </ul>
      <ul>
        <li aria-level="1">
          <strong>
            To enforce our terms, conditions and policies for business purposes,
            to comply with legal and regulatory requirements or in connection
            with our contract.
          </strong>
        </li>
      </ul>
      <ul>
        <li aria-level="1">
          <strong>To respond to legal requests and prevent harm.</strong> If we
          receive a subpoena or other legal request, we may need to inspect the
          data we hold to determine how to respond.&nbsp;
        </li>
      </ul>
      <p>&nbsp;</p>
      <h2 id="section3">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations.
        </em>
      </p>
      <p>
        We may process or share your data that we hold based on the following
        legal basis:
      </p>
      <ul>
        <li aria-level="1">
          <strong>Consent:</strong> We may process your data if you have given
          us specific consent to use your personal information for a specific
          purpose.&nbsp;
        </li>
        <li aria-level="1">
          &nbsp;Performance of a Contract: Where we have entered into a contract
          with you, we may process your personal information to fulfill the
          terms of our contract.
        </li>
        <li aria-level="1">
          <strong>Legal Obligations: </strong>We may disclose your information
          where we are legally required to do so in order to comply with
          applicable law, governmental requests, a judicial proceeding, court
          order, or legal process, such as in response to a court order or a
          subpoena (including in response to public authorities to meet national
          security or law enforcement requirements).
        </li>
        <li aria-level="1">
          <strong>Vital Interests:</strong> We may disclose your information
          where we believe it is necessary to investigate, prevent, or take
          action regarding potential violations of our policies, suspected
          fraud, situations involving potential threats to the safety of any
          person and illegal activities, or as evidence in litigation in which
          we are involved.
        </li>
      </ul>
      <p>
        More specifically, we may need to process your data or share your
        personal information in the following situations:
      </p>
      <ul>
        <li aria-level="1">
          <strong>Business Transfers. </strong>We may share or transfer your
          information in connection with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company.&nbsp;
        </li>
      </ul>
      <h2 id="section4">4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          If you choose to register or log in to our services using a social
          media account, we may have access to certain information about you.
        </em>
      </p>
      <p>
        Our Website offers you the ability to register and login using your
        third-party social media account details (like your Facebook or Twitter
        logins). Where you choose to do this, we will receive certain profile
        information about you from your social media provider. The profile
        information we receive may vary depending on the social media provider
        concerned, but will often include your name, email address, friends
        list, profile picture as well as other information you choose to make
        public on such social media platform.
      </p>
      <p>
        We will use the information we receive only for the purposes that are
        described in this privacy notice or that are otherwise made clear to you
        on the relevant Website. Please note that we do not control, and are not
        responsible
      </p>
      <p>
        how they collect, use and share your personal information, and how you
        can set your privacy preferences on their sites and apps.
      </p>
      <h2 id="section5">5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this privacy notice unless otherwise required by
          law.
        </em>
      </p>
      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than the period
        of time in which users have an account with us.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>
      <h2 id="section6">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We aim to protect your personal information through a system of
          organizational and technical security measures.
        </em>
      </p>
      <p>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, despite our safeguards and efforts to secure your
        information, no electronic transmission over the Internet or information
        storage technology can be guaranteed to be 100% secure, so we cannot
        promise or guarantee that hackers, cybercriminals, or other unauthorized
        third parties will not be able to defeat our security, and improperly
        collect, access, steal, or modify your information. Although we will do
        our best to protect your personal information, transmission of personal
        information to and from our Website is at your own risk. You should only
        access the Website within a secure environment.
      </p>
      <h2 id="section7">7. DO WE COLLECT INFORMATION FROM MINORS?</h2>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We do not knowingly collect data from or market to children under 18
          years of age
        </em>
      </p>
      <p>&nbsp;</p>
      <p>
        We do not knowingly solicit data from our market or to children under 18
        years of age. By using the Website, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent&rsquo;s use of the Website. If we learn that
        personal information from users less than 18 years of age has been
        collected, we will deactivate the account and take reasonable measures
        to promptly delete such data from our records. If you become aware of
        any data we may have collected from children under age 18, please
        contact us at <strong>privacy@revmo.app</strong>
      </p>
      <h2 id="section8">8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          In some regions, such as the European Economic Area (EEA) and United
          Kingdom (UK), you have rights that allow you greater access to and
          control over your personal information. You may review, change, or
          terminate your account at any time.
        </em>
      </p>
      <p>
        In some regions (like the EEA and UK), you have certain rights under
        applicable data protection laws. These may include the right (i) to
        request access and obtain a copy of your personal information, (ii) to
        request rectification or erasure; (iii) to restrict the processing of
        your personal information; and (iv) if applicable, to data portability.
        In certain circumstances, you may also have the right to object to the
        processing of your personal information. To make such a request, please
        use the contact details provided below. We will consider and act upon
        any request in accordance with applicable data protection laws.
      </p>
      <p>
        If we are relying on your consent to process your personal information,
        you have the right to withdraw your consent at any time. Please note
        however that this will not affect the lawfulness of the processing
        before its withdrawal, nor will it affect the processing of your
        personal information conducted in reliance on lawful processing grounds
        other than consent.
      </p>
      <p>
        If you are a resident in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your local data protection supervisory authority. You can
        find their contact details here:{" "}
        <a href="http://ec.europa.eu/justice/data-">
          http://ec.europa.eu/justice/data-
          protection/bodies/authorities/index_en.htm
        </a>
        .
      </p>
      <p>
        If you are a resident in Switzerland, the contact details for the data
        protection authorities are available here:{" "}
        <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
          https://www.edoeb.admin.ch/edoeb/en/home.html
        </a>
        .&nbsp;
      </p>
      <p>
        <strong>Account Information</strong>
      </p>
      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>
      <ul>
        <li aria-level="1">
          Log in to your account settings and update your user account.
        </li>
      </ul>
      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our Terms
        of Use and/or comply with applicable legal requirements.
      </p>
      <p>
        <strong>Opting out of email marketing:</strong> You can unsubscribe from
        our marketing email list at any time by clicking on the unsubscribe link
        in the emails that we send or by contacting us using the details
        provided below. You will then be removed from the marketing email list
        &mdash; however, we may still communicate with you, for example to send
        you service-related emails that are necessary for the administration and
        use of your account, to respond to service requests, or for other
        non-marketing purposes. To otherwise opt-out, you may:
      </p>
      <ul>
        <li aria-level="1">
          Access your account settings and update your preferences.&nbsp;
        </li>
      </ul>
      <h2 id="section9">9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>
      <h2 id="section10">
        10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </h2>
      <p>
        <strong>
          <em>In Short:</em>
        </strong>
        <em>
          {" "}
          Yes, if you are a resident of California, you are granted
          specific&nbsp;
        </em>
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the &ldquo;Shine
        the Light&rdquo; law, permits our users who are California residents to
        request and obtain from us, once a year and free of charge, information
        about categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with the Website, you have the right to request
        removal of unwanted data that you publicly post on the Website. To
        request removal of such data, please contact us using the contact
        information provided below, and include the email address associated
        with your account and a statement that you reside in California. We will
        make sure the data is not publicly displayed on the Website, but please
        be aware that the data may not be completely or comprehensively removed
        from all our systems (e.g. backups, etc.).
      </p>
      <p>
        <strong>CCPA Privacy Notice</strong>
      </p>
      <p>The California Code of Regulations defines a "resident" as:&nbsp;</p>
      <ol>
        <li aria-level="1">
          every individual who is in the State of California for other than a
          temporary or transitory purpose and&nbsp;
        </li>
        <li aria-level="1">
          every individual who is domiciled in the State of California who is
          outside the State of California for a temporary or transitory purpose
        </li>
      </ol>
      <p>All other individuals are defined as "non-residents."&nbsp;</p>
      <p>
        If this definition of "resident" applies to you, we must adhere to
        certain rights and obligations regarding your personal information.
      </p>
      <p>
        <strong>
          What categories of personal information do we collect?&nbsp;
        </strong>
      </p>
      <p>
        We have collected the following categories of personal information in
        the past twelve (12) months:&nbsp;
      </p>
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>Category</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Examples</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Collected&nbsp;</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>A. Identifiers</p>
            </td>
            <td>
              <p>
                Contact details, such as real name, alias, postal address,
                telephone or mobile contact number, unique personal identifier,
                online identifier, Internet Protocol address, email address and
                account name&nbsp;
              </p>
            </td>
            <td>
              <p>YES</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                B. Personal information categories listed in the California
                Customer Records statute
              </p>
            </td>
            <td>
              <p>
                Name, contact information, education, employment, employment
                history and financial information&nbsp;
              </p>
            </td>
            <td>
              <p>YES</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                C. Protected classification characteristics under California or
                federal law&nbsp;
              </p>
            </td>
            <td>
              <p>Gender and date of birth&nbsp;</p>
            </td>
            <td>
              <p>NO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>D. Commercial information&nbsp;</p>
            </td>
            <td>
              <p>
                Transaction information, purchase history, financial details and
                payment information
              </p>
            </td>
            <td>
              <p>NO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>E. Biometric information</p>
            </td>
            <td>
              <p>Fingerprints and voiceprints</p>
            </td>
            <td>
              <p>NO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>F. Internet or other similar network activity</p>
            </td>
            <td>
              <p>
                Browsing history, search history, online behavior, internet
                data, and interactions with our and other websites,
                applications, systems and advertisements
              </p>
            </td>
            <td>
              <p>NO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>G. Geolocation data</p>
            </td>
            <td>
              <p>Device location</p>
            </td>
            <td>
              <p>NO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                H. Audio, electronic, visual, thermal, olfactory, or similar
                information
              </p>
            </td>
            <td>
              <p>
                Imagines and audio, video or call recordings created in
                connection with our business activities
              </p>
            </td>
            <td>
              <p>NO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>I. Professional or employment-related information</p>
            </td>
            <td>
              <p>
                Business contact details in order to provide you our services at
                a business level, job title as well as work history and
                professional qualifications if you apply for a job with us
              </p>
            </td>
            <td>
              <p>YES</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>J. Educational Information</p>
            </td>
            <td>
              <p>Student records and directory information</p>
            </td>
            <td>
              <p>NO</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>K. Inferences drawn from other personal information</p>
            </td>
            <td>
              <p>
                Inferences drawn from any of the collected personal information
                listed above to create a profile or summary about, for example,
                an individual&rsquo;s preferences and characteristics
              </p>
            </td>
            <td>
              <p>NO</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>&nbsp;</h2>
      <p>
        We may also collect other personal information outside of these
        categories instances where you interact with us in-person, online, or by
        phone or mail in the context of:
      </p>
      <ul>
        <li aria-level="1">
          Receiving help through our customer support channels;
        </li>
        <li aria-level="1">
          Participation in customer surveys or contests; and
        </li>
        <li aria-level="1">
          Facilitation in the delivery of our Services and to respond to your
          inquiries.
        </li>
      </ul>
      <p>
        <strong>How do we use and share your personal information?</strong>
      </p>
      <p>
        More information about our data collection and sharing practices can be
        found in this privacy notice.
      </p>
      <p>
        You may contact us by email at privacy@revmo.app, or by referring to the
        contact details at the bottom of this document.
      </p>
      <p>
        If you are using an authorized agent to exercise your right to opt-out
        we may deny a request if the authorized agent does not submit proof that
        they have been validly authorized to act on your behalf.
      </p>
      <p>
        <strong>Will your information be shared with anyone else?</strong>
      </p>
      <p>
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for-profit entity that processes the
        information on our behalf.
      </p>
      <p>
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be "selling" of your personal
        data.
      </p>
      <p>
        Revmo, Inc. has not disclosed or sold any personal information to third
        parties for a business or commercial purpose in the preceding 12 months.
        Revmo, Inc. will not sell personal information in the future belonging
        to website visitors, users and other consumers.
      </p>
      <p>
        <strong>Your rights with respect to your personal data</strong>
      </p>
      <p>Right to request deletion of the data - Request to delete</p>
      <p>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation or any processing that may be required to
        protect against illegal activities.
      </p>
      <p>Right to be informed - Request to know</p>
      <p>Depending on the circumstances, you have a right to know:</p>
      <ul>
        <li aria-level="1">
          whether we collect and use your personal information;
        </li>
        <li aria-level="1">
          the categories of personal information that we collect;
        </li>
        <li aria-level="1">
          the purposes for which the collected personal information is used;
        </li>
        <li aria-level="1">
          whether we sell your personal information to third parties;
        </li>
        <li aria-level="1">
          the categories of personal information that we sold or disclosed for a
          business purpose;
        </li>
        <li aria-level="1">
          the categories of third parties to whom the personal information was
          sold or disclosed for a business purpose; and
        </li>
        <li aria-level="1">
          the business or commercial purpose for collecting or selling personal
          information.
        </li>
      </ul>
      <p>
        In accordance with applicable law, we are not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </p>
      <p>
        Right to Non-Discrimination for the Exercise of a Consumer&rsquo;s
        Privacy Rights
      </p>
      <p>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>
      <p>Verification process</p>
      <p>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance depending on the type of request you submit we
        may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g. phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. If, however, we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity, and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>
      <p>Other privacy rights</p>
      <ul>
        <li aria-level="1">
          you may object to the processing of your personal data
        </li>
        <li aria-level="1">
          you may request correction of your personal data if it is incorrect or
          no longer relevant, or ask to restrict the processing of the data
        </li>
        <li aria-level="1">
          you can designate an authorized agent to make a request under the CCPA
          on your behalf. We may deny a request from an authorized agent that
          does not submit proof that they have been validly authorized to act on
          your behalf in accordance with the CCPA.
        </li>
        <li aria-level="1">
          you may request to opt-out from future selling of your personal
          information to third parties. Upon receiving a request to opt-out, we
          will act upon the request as soon as feasibly possible, but no later
          than 15 days from the date of the request submission.
        </li>
      </ul>
      <p>
        To exercise these rights, you can contact us by email at{" "}
        <strong>privacy@revmo.app</strong>, or by referring to the contact
        details at the bottom of this document. If you have a complaint about
        how we handle your data, we would like to hear from you.
      </p>
      <h2 id="section11">11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
      <p>
        <strong>
          <em>In Short:</em>
        </strong>
        <em>
          {" "}
          Yes, we will update this notice as necessary to stay compliant with
          relevant laws.
        </em>
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>
      <h2 id="section12">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
      <p>
        If you have questions or comments about this notice, you may contact our
        Data Protection Officer (DPO), Ben Colman, by email at{" "}
        <strong>privacy@revmo.app</strong>, or by post to:
      </p>
      <p>Revmo, Inc.</p>
      <p>Ben Colman</p>
      <p>11 Park Pl</p>
      <p>New York, NY 10007</p>
      <p>United States</p>
      <p>
        If you are a resident in the European Economic Area or the United
        Kingdom, the "data controller" of your personal information is Revmo,
        Inc. Revmo, Inc. has appointed Revmo Europe Limited to be its
        representative in the EEA. You can contact them directly regarding the
        processing of your information by Revmo, Inc., by email at{" "}
        <strong>privacy_europe@revmo.app</strong>, or by post to:
      </p>
      <p>50 Jermyn Street</p>
      <p>London SW1Y 6LX</p>
      <h2 id="section13">
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h2>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it in some circumstances. To request review,
        update, or delete your personal information, please contact{" "}
        <strong>privacy@revmo.app</strong>
      </p>
    </div>
  );
}
