import React from "react";
import {
  LandingCallToAction,
  TeamSection,
  Testimonials,
} from "../LandingPage/components";
import { LandingPageContainer } from "components/LandingPage";
import { LandingNavbar } from "components/Navbar";
import { LandingFooter } from "components/LandingPage";
import { FAQEnterprise, ProblemsWeSolve, EnterpriseHero } from "./components";

function Enterprise() {
  return (
    <>
      <LandingPageContainer>
        <LandingNavbar />
        <EnterpriseHero />
        <ProblemsWeSolve />
        <Testimonials />
        <TeamSection />
        <FAQEnterprise />
        <LandingCallToAction />
      </LandingPageContainer>
      <LandingFooter />
    </>
  );
}

export default Enterprise;
