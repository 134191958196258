import React from "react";
import styled from "styled-components";
import { PageWrapperMaxContentWidth } from "components/Layout";
import ContactFrequency from "./ContactFrequency";
import TrustedContactInteractions from "./TrustedContactInteractions";
import ReferralPreferences from "./ReferralPreferences";
import IntroductionPreferences from "./IntroductionPreferences";

const PageWrapperAccounterPreferences = styled(PageWrapperMaxContentWidth)`
  margin-top: 71px;
  padding-bottom: 78px;
`;

function AccountPreferences({
  updateContactTypePreference,
  contactTypePreference,
  contactFrequency,
  setContactFrequency,
  updateContactInteractions,
  contactInteractions,
  referralPreference,
  setReferralPreference,
  allowUsersToRequestIntroduction,
  setAllowUsersToRequestIntroduction,
}) {
  return (
    <PageWrapperAccounterPreferences>
      <ContactFrequency
        updateContactTypePreference={updateContactTypePreference}
        contactTypePreference={contactTypePreference}
        contactFrequency={contactFrequency}
        setContactFrequency={setContactFrequency}
      />
      <TrustedContactInteractions
        updateContactInteractions={updateContactInteractions}
        contactInteractions={contactInteractions}
      />
      <ReferralPreferences
        referralPreference={referralPreference}
        setReferralPreference={setReferralPreference}
      />
      <IntroductionPreferences
        allowUsersToRequestIntroduction={allowUsersToRequestIntroduction}
        setAllowUsersToRequestIntroduction={setAllowUsersToRequestIntroduction}
      />
    </PageWrapperAccounterPreferences>
  );
}

export default AccountPreferences;
