import React, { useEffect, useState } from "react";
import sanityClient from "../../../client";
import { Link, useParams } from "react-router-dom";
import {
  Container996,
  DividerSmallMargin,
  PostTextWrapper,
  PostHeaderH1,
  PostBackButton,
  PostHeader,
  PostAuthorContainer,
  PostHeaderText,
  PostHeaderDot,
  PostImage,
} from "components/Post/Layout";
import { LandingNavbar } from "components/Navbar";
import { LandingPageContainer, LandingFooter } from "components/LandingPage";
import BlockContent from "@sanity/block-content-to-react";

function UpdatesPost() {
  const [data, setData] = useState(null);
  const { slug = "" } = useParams();
  const options = { year: "numeric", month: "long", day: "numeric" };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug][0] 
              {
              title,
              slug,
              publishedAt,
              body,
              "name": author->name,
              "role": author->role,
              mainImage{
                asset->{
                _id,
                url
              }
            }
          }`,
        { slug }
      )
      .then((data) => setData(data))
      .catch(console.error);
  }, [slug]);

  return (
    <>
      <LandingPageContainer>
        <LandingNavbar />
        <Container996>
          {data && (
            <PostTextWrapper>
              <PostBackButton>
                <Link to="/updates">← Back</Link>
              </PostBackButton>
              <PostImage src={data?.mainImage?.asset?.url} />
              <PostHeader>
                <PostAuthorContainer>
                  <PostHeaderText>{data?.name}</PostHeaderText>
                  <PostHeaderDot>·</PostHeaderDot>
                  <PostHeaderText>{data?.role}</PostHeaderText>
                </PostAuthorContainer>
                <PostHeaderText>
                  {new Date(data?.publishedAt).toLocaleDateString(
                    "en-US",
                    options
                  )}
                </PostHeaderText>
              </PostHeader>
              <PostHeaderH1>{data?.title}</PostHeaderH1>
              <DividerSmallMargin />
              <BlockContent blocks={data?.body} />
            </PostTextWrapper>
          )}
        </Container996>
      </LandingPageContainer>
      <LandingFooter />
    </>
  );
}

export default UpdatesPost;
