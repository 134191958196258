import styled from "styled-components";

export const ProfilePageContentBody = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 0px 118px 00px;
  z-index: 0;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 480px) {
    padding-top: 0;
  }
`;

export const UserProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const UserGroupInfoContainer = styled(UserProfileInfoContainer)`
  margin-top: 50px;
`;

export const ProfileInfoColumn = styled.div`
  width: 100%;
`;

export const HeaderTextFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 33px;
  height: 40px;
  position: relative;
  @media (max-width: 480px) {
    margin-bottom: 24px;
  }
`;

export const JobInfoTableContainer = styled.div`
  border: 1px solid ${(props) => props.theme.borderColor};
  padding: 5px 0px 0px 19px;
  position: relative;
  margin-bottom: 40px;
  @media (max-width: 480px) {
    padding-left: 15px;
    padding-top: 21px;
  }
  @media (max-width: 480px) {
    margin-bottom: 20px;
  }
`;

export const JobInfoRow = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  width: calc(100% - 54px);
  @media (max-width: 480px) {
    width: calc(100% - 15px);
  }
`;

export const ShowMoreButtonWrapper = styled.div`
  position: absolute;
  top: 29px;
  right: 23px;
`;

export const ProfilePageColumn = styled.div`
  width: 100%;
`;
