import styled from "styled-components";

export const SectionWrapper = styled.section`
  min-height: 40vh;
  background-color: #fff;
`;

export const SectionWrapperDark = styled(SectionWrapper)`
  background-color: #000a33;
`;

export const SectionWrapperTransparent = styled(SectionWrapper)`
  background-color: transparent;
`;

export const ContentWrapper = styled.div`
  padding: calc(40vmax / 10) 4vw calc(40vmax / 10) 4vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
