import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const JobRowContainer = styled.div`
  font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 840px;
  padding: 1.5rem 2rem;
  height: 80px;
  border: 1px solid #e3e6e9;
  border-left: 5px solid #5374ff;
  box-shadow: 0px 4px 4px #efefef;
  position: relative;
  margin: 1.3rem 0;
  @media (max-width: 767px) {
    height: unset;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }
`;

const JobRowImageContainer = styled.div``;

const JobRowText = styled.div`
  color: #7b8e8e;
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  h2,
  h3 {
    margin-bottom: 0;
  }
  a > h2:hover {
    color: #5374ff;
  }
`;

const JobRowTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 870px) {
    justify-content: flex-end;
  }
`;

const JobRowTag = styled.span`
  background-color: #ebefff;
  color: #5374ff;
  font-weight: 700;
  padding: 0.5rem;
  border-radius: 5px;
  @media (min-width: 870.1px) {
    :not(:last-child) {
      margin-right: 1rem;
    }
  }
  @media (max-width: 870px) {
    margin: 0.25rem 0.25rem;
  }
  :hover {
    background-color: #5374ff;
    color: #ffffff;
  }
`;

export function JobTags({ tags }) {
  return (
    <JobRowTagsContainer>
      {tags.map((tag, index) => {
        return <JobRowTag key={index}>{tag}</JobRowTag>;
      })}
    </JobRowTagsContainer>
  );
}

export function JobRow({ role, tags, link }) {
  return (
    <JobRowContainer>
      <JobRowImageContainer></JobRowImageContainer>
      <JobRowText>
        <Link to={`careers/${link}`}>
          <h2>{role}</h2>
        </Link>
      </JobRowText>
      <JobTags tags={tags} />
    </JobRowContainer>
  );
}

export default JobRow;
