import React from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import MessageIcon from "@mui/icons-material/Message";
import Tooltip from "@mui/material/Tooltip";

const SidebarListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "open",
})(() => ({
  borderRadius: "8px",
  margin: "3px",
}));

const SidebarTooltip = styled(Tooltip, {
  shouldForwardProp: (prop) => prop !== "open",
})(() => ({
  ".MuiTooltip-tooltip": {
    display: "none",
  },
}));

export const mainListItems = (
  <React.Fragment>
    <SidebarTooltip placement="top" title="Dashboard">
      <SidebarListItemButton component={Link} to="/dashboard">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </SidebarListItemButton>
    </SidebarTooltip>
    <Tooltip placement="top" title="Search">
      <SidebarListItemButton
        component={Link}
        to={{ pathname: "/search", state: null }}
      >
        <ListItemIcon>
          <PersonSearchIcon />
        </ListItemIcon>
        <ListItemText primary="Search" />
      </SidebarListItemButton>
    </Tooltip>
    <Tooltip placement="top" title="Contacts">
      <SidebarListItemButton component={Link} to="/contacts">
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Contacts" />
      </SidebarListItemButton>
    </Tooltip>
    <Tooltip placement="top" title="Organizations">
      <SidebarListItemButton component={Link} to="/organisations">
        <ListItemIcon>
          <LocationCityIcon />
        </ListItemIcon>
        <ListItemText primary="Organizations" />
      </SidebarListItemButton>
    </Tooltip>
    <Tooltip placement="top" title="Introductions">
      <SidebarListItemButton component={Link} to="/introductions">
        <ListItemIcon>
          <MessageIcon />
        </ListItemIcon>
        <ListItemText primary="Introductions" />
      </SidebarListItemButton>
    </Tooltip>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <Tooltip placement="top" title="Profile">
      <SidebarListItemButton component={Link} to="/profile">
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </SidebarListItemButton>
    </Tooltip>
    <Tooltip placement="top" title="Settings">
      <SidebarListItemButton component={Link} to="/settings">
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </SidebarListItemButton>
    </Tooltip>
    <Tooltip placement="top" title="Help Center">
      <SidebarListItemButton component={Link} to="/support" target="_blank">
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary="Help Center" />
      </SidebarListItemButton>
    </Tooltip>
  </React.Fragment>
);
