import React from "react";
import styled from "styled-components";
import { RequestStatusTableHeaderSkeleton } from "components/RequestStatusTable/components/RequestStatusTableHeader";
import { SkeletonPulse } from "components/Skeleton";
import {
  TableHeaderText,
  RequestStatusTableColumnCallToAction,
  RequestStatusTableColumUserInfo,
  RequestStatusTableColumnIntroduction,
} from "components/RequestStatusTable/components";

const RequestStatusTableWrapper = styled.div`
  padding: 42px 14px 20px 19px;
  background-color: #f9fafb;
  margin-top: 10px;
  width: 1100px;
  @media (max-width: 1126px) {
    width: 100%;
    padding: unset;
  }
`;

function RequestStatusTableSkeleton() {
  return (
    <>
      <RequestStatusTableHeaderSkeleton />
      <RequestStatusTableWrapper>
        <RequestStatusTableRowSkeleton />
      </RequestStatusTableWrapper>
    </>
  );
}

const MultiColumnWrapper = styled.div`
  display: flex;
  @media (max-width: 1126px) {
    padding: 32px 17px 22px 26px;
  }
  @media (max-width: 870px) {
    flex-direction: column;
    padding: 40px 20px 0px 20px;
  }
`;

const MultiColumnWrapperSecond = styled(MultiColumnWrapper)`
  flex: 1;
  @media (max-width: 870px) {
    flex-direction: column;
    padding: 28px 20px 30px 20px;
  }
`;

const RequestStatusTableRowContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 11px;
  @media (max-width: 1126px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
  @media (max-width: 870px) {
    border-top: 1px solid #e3e6e9;
    border-bottom: 1px solid #e3e6e9;
  }
`;

function RequestStatusTableRowSkeleton() {
  return (
    <RequestStatusTableRowContainer>
      <MultiColumnWrapper>
        <PersonInvolvedInIntroSkeleton column={"Introduced By"} />
        <RequestStatusTableColumnIntroduction>
          <TableHeaderText index={0}>INTRODUCED YOU BY</TableHeaderText>
          <span></span>
          <TwoLineTextSkeleton>
            <TextSkeleton />
            <TextSkeleton />
          </TwoLineTextSkeleton>
        </RequestStatusTableColumnIntroduction>
      </MultiColumnWrapper>
      <MultiColumnWrapperSecond>
        <PersonToBeIntroducedToSkeleton column={"Introduced To"} />
        <RequestStatusTableColumnCallToAction status>
          <TableHeaderText index={0}>YOU SHOULD</TableHeaderText>
          <TwoLineTextSkeleton>
            <TextSkeleton />
            <TextSkeleton />
          </TwoLineTextSkeleton>
        </RequestStatusTableColumnCallToAction>
      </MultiColumnWrapperSecond>
    </RequestStatusTableRowContainer>
  );
}

const RequestsStatusTableUserDetails = styled.div`
  margin-left: 20.5px;
  margin-top: 8px;
  ${(props) => {
    if (props.column === "Introduced To") {
      return `
            width: 138px;
            margin-right: 30px;
        `;
    }
  }}
  @media (max-width: 480px) {
    margin: 6px 0 0 12.5px;
  }
`;

const PersonContentWrapper = styled.div`
  display: flex;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const SkeletonLineUserImageWrapper = styled.div`
  height: 60px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 52px;
    width: 52px;
    fill: white;
  }
  @media (max-width: 480px) {
    height: 54px;
    min-width: 54px;
    svg {
      height: 38px;
      width: 38px;
    }
  }
`;

const SkeletonLineUserImage = styled(SkeletonPulse)`
  height: 60px;
  width: 60px;
  border-radius: 100%;
`;

const TextSkeleton = styled(SkeletonPulse)`
  height: 17px;
  width: 80%;
  min-width: 60px;
`;

const TwoLineTextSkeleton = styled.div`
  line-height: 1.15;
  display: block;
`;

const PersonInvolvedInIntroSkeleton = ({ column, width }) => {
  return (
    <RequestStatusTableColumUserInfo width={width}>
      {column === "Introduced By" ? (
        <TableHeaderText index={0}>USER</TableHeaderText>
      ) : (
        <TableHeaderText index={0}>TO</TableHeaderText>
      )}
      <SkeletonLineUserImageWrapper>
        <SkeletonLineUserImage />
      </SkeletonLineUserImageWrapper>
      <PersonContentWrapper>
        <RequestsStatusTableUserDetails column={column}>
          <>
            <>
              <TextSkeleton />
              <TextSkeleton />
            </>
          </>
        </RequestsStatusTableUserDetails>
      </PersonContentWrapper>
    </RequestStatusTableColumUserInfo>
  );
};

const ScoreAndTagContainer = styled.div`
  height: 100%;
  display: flex;
  padding-top: 8px;
  @media (max-width: 480px) {
    margin-left: 12.5px;
  }
`;

const PersonToBeIntroducedToSkeleton = ({ column }) => {
  return (
    <PersonInvolvedInIntroSkeleton width={450} column={column}>
      <ScoreAndTagContainer />
    </PersonInvolvedInIntroSkeleton>
  );
};

export default RequestStatusTableSkeleton;
