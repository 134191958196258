import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthButton } from "components/Button";
import { registerUserSchema } from "../utils";
import { HeaderContainer } from "components/Onboarding";
import {
  HeaderText,
  MidLargeSpacedText,
  SmallBlackBodyText,
} from "components/Typography";
import { WhiteLargeFormInputHooksForm } from "components/Input";
import { PrivacyPolicyCheckbox } from ".";
import {
  AuthForm,
  AuthAlternateOptionsRowMarginTop,
  AuthFormWrapperBackButton,
} from "components/Auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";

const AuthFormWrapperRegisterUser = styled(AuthFormWrapperBackButton)`
  padding-bottom: 35px;
`;

const PasswordReminder = styled(SmallBlackBodyText)`
  position: absolute;
  margin-top: -14px;
  margin-left: 2px;
`;

function RegisterUser({ registerUserForSignUp, alertMessage }) {
  const [placeholderEmail, setPlaceholderEmail] = useState(null);
  const { register, errors, handleSubmit, watch, trigger } = useForm({
    resolver: yupResolver(registerUserSchema),
  });

  if (alertMessage.includes("email")) {
    errors.email = {};
    errors.email.message = alertMessage;
  }

  const location = useLocation();
  const { password } = watch();

  useEffect(() => {
    if (location?.state?.email_address) {
      setPlaceholderEmail(location?.state?.email_address);
    }
  }, [location?.state?.email_address]);

  return (
    <AuthFormWrapperRegisterUser>
      <HeaderContainer>
        <HeaderText>Welcome to Revmo!</HeaderText>
        <MidLargeSpacedText>Start making connections today</MidLargeSpacedText>
      </HeaderContainer>
      <AuthForm onSubmit={handleSubmit(registerUserForSignUp)}>
        <WhiteLargeFormInputHooksForm
          fieldName="Email"
          register={register}
          error={errors}
          name="email"
          type="email"
          onBlur={() => {
            trigger("email");
          }}
          bold
          placeholder={placeholderEmail}
        />
        <WhiteLargeFormInputHooksForm
          fieldName="Password"
          register={register}
          error={errors}
          name="password"
          bold
          type="password"
          onBlur={() => {
            trigger("password");
          }}
        />
        {!errors?.password?.message && password?.length < 6 && (
          <PasswordReminder>
            Your password must be at least 6 characters.
          </PasswordReminder>
        )}
        <WhiteLargeFormInputHooksForm
          fieldName="Confirm Password"
          register={register}
          error={errors}
          name="confirmPassword"
          bold
          type="password"
          onBlur={() => {
            trigger("password");
          }}
        />
        <PrivacyPolicyCheckbox
          register={register}
          errors={errors}
          name="privacyPolicy"
          value={true}
          type="checkbox"
        />
        <AuthButton type="submit">Sign up</AuthButton>
        <AuthAlternateOptionsRowMarginTop>
          <p>
            Already a member?
            <Link to="login"> Login here</Link>
          </p>
        </AuthAlternateOptionsRowMarginTop>
      </AuthForm>
    </AuthFormWrapperRegisterUser>
  );
}

export default RegisterUser;
