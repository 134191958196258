import styled, { css } from "styled-components";

export const landingTheme = {
  colors: {
    text: "#000A33",
    background: "#fff",
    primary: "#0069FF",
    secondary: "#000A33",
    muted: "#f6f6f9",
    gray: "#D3D7DA",
    highlight: "hsla(205, 100%, 40%, 0.125)",
    white: "#FFF",
    black: "#111212",
  },
  // Typography
  fonts: {
    heading: "Montserrat, sans-serif",
    body: "Source Sans Pro, sans-serif",
  },
  fontSizes: [12, 14, 16, 20, 24, 30, 36, 48, 64],
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  // Text Variants
  text: {
    h1: {
      lineHeight: "1.25",
      fontSize: [6, 6, 6, 6],
      marginBottom: 3,
    },
  },
};

landingTheme.text.h1.fontFamily = landingTheme.fonts.heading;

export const LandingPageContainer = styled.div`
  button {
    height: 40px;
  }
  a {
    text-decoration: none;
    color: ${landingTheme.colors.primary};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${landingTheme.fonts.heading};
    font-weight: 500;
  }
  p,
  span,
  a {
    font-family: ${landingTheme.fonts.body};
  }
`;

export const HeroImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  opacity: 0.2;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const HorizontalHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 495px;
  margin-top: 10px;
  justify-content: space-between;
  @media (max-width: 480px) {
    width: unset;
  }
`;

export const HalfWidthHeaderContainer = styled.div`
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    width: 240px;
    line-height: 24px;
    font-size: 16px;
  }
  ${(props) =>
    props.flex &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const NetworkImage = styled.img`
  height: 175px;
  width: 247px;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const HorizontalBodyContainer = styled.div`
  display: flex;
  height: max-content;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
export const HalfWidthBodyContainer = styled.div`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.text &&
    css`
      box-sizing: border-box;
      padding: 50px 70px;
      height: min-content;
      width: 56%;
      min-width: 56%;
      @media (max-width: 480px) {
        padding: 40px;
      }
      h2 {
        font-size: 28px;
        font-family: Roboto;
        line-height: 36px;
        margin-top: 10px;
        :first-child {
          margin-top: 0;
        }
      }
      p {
        font-size: 18px;
        line-height: 27px;
      }
    `}
  ${(props) =>
    props.image &&
    css`
      width: 54%;
    `}
      @media (max-width: 767px) {
    width: 100%;
  }
`;

export const LandingPageBodyImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export { default as Accordion } from "./Accordion";
export { default as Animation } from "./Animation";
export { default as ArrowSection } from "./ArrowSection";
export { default as GridThreeSection } from "./GridThreeSection";
export { default as LandingFooter } from "./LandingFooter";
export { default as Table } from "./Table";
