import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  DarkBlurredBackground,
  CloseIconImage,
  AddContactsContent,
  FormTypeHeader,
  AddOrEditContactPersonalDetailsWrapper,
} from "./index";
import { NewModalPageWrapper, NewModalContainerAddOrEditContact } from ".";
import { WhiteLargeFormInputHooksForm } from "../Input";
import closeIcon from "../../assets/icons/close-icon.svg";
import { useForm } from "react-hook-form";

export function ConfirmUserEmailAddressModal({
  emailConfirmationData,
  confirmEmailFunction,
  closeModal,
}) {
  const { register, handleSubmit, errors, watch } = useForm();
  const { confirmationCode } = watch();
  const disabled = !confirmationCode?.length;

  if (emailConfirmationData.errorMessage) {
    errors.confirmationCode = {};
    errors.confirmationCode.message = emailConfirmationData.errorMessage;
  }

  return (
    <>
      {emailConfirmationData.displayModal && (
        <NewModalPageWrapper>
          <DarkBlurredBackground onClick={() => closeModal()} />
          <NewModalContainerAddOrEditContact>
            <CloseIconImage src={closeIcon} onClick={() => closeModal()} />
            <AddContactsContent>
              <FormTypeHeader>
                <h2>Your confirmation code is in your email inbox!</h2>
              </FormTypeHeader>
              <AddOrEditContactPersonalDetailsWrapper>
                <WhiteLargeFormInputHooksForm
                  fieldName="Please enter your confirmation code*"
                  name="confirmationCode"
                  error={errors}
                  register={register}
                />
              </AddOrEditContactPersonalDetailsWrapper>
              <Button
                variant="contained"
                disabled={disabled}
                onClick={handleSubmit((code) => confirmEmailFunction(code))}
                sx={{ bgcolor: "#0069ff" }}
              >
                Confirm Email
              </Button>
            </AddContactsContent>
          </NewModalContainerAddOrEditContact>
        </NewModalPageWrapper>
      )}
    </>
  );
}
