import React from "react";
import {
  HeaderText,
  MidLargeSpacedText,
  ExtraSmallBlackText,
} from "../../../components/Typography";
import { AuthButtonNoArrow } from "components/Button";
import { WhiteLargeFormInputHooksForm } from "components/Input";
import {
  OnboardingForm,
  HeaderContainerPersonalData,
  InformationTitleContainer,
  FooterWrapper,
} from "components/Onboarding";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { personalDataSchema, useResetFormData } from "../utils";
import { Slide } from "react-awesome-reveal";
import OnboardingProgress from "./OnboardingProgress";

function OnboardingPersonalData({
  onboardingFields,
  currentOnboardingPage,
  diallingCodesHook,
  handleChangeOnboardingPersonalData,
}) {
  const { register, handleSubmit, errors, reset, trigger } = useForm({
    resolver: yupResolver(personalDataSchema),
  });

  useResetFormData(
    reset,
    onboardingFields,
    currentOnboardingPage,
    diallingCodesHook
  );

  return (
    <Slide>
      <HeaderContainerPersonalData>
        <OnboardingProgress activeStep={0} />
        <HeaderText>What can we call you?</HeaderText>
        <MidLargeSpacedText>
          The next few questions will help us bring you the most relevant data
          and relationships.
        </MidLargeSpacedText>
      </HeaderContainerPersonalData>
      <InformationTitleContainer>
        <ExtraSmallBlackText>*Denotes required field</ExtraSmallBlackText>
      </InformationTitleContainer>
      <OnboardingForm
        autocomplete="false"
        onSubmit={handleSubmit(handleChangeOnboardingPersonalData)}
      >
        <WhiteLargeFormInputHooksForm
          name="firstName"
          fieldName="First Name*"
          error={errors}
          register={register}
          onBlur={() => {
            trigger("firstName");
          }}
        />
        <WhiteLargeFormInputHooksForm
          name="lastName"
          fieldName="Last Name*"
          error={errors}
          register={register}
          onBlur={() => {
            trigger("lastName");
          }}
        />
        <FooterWrapper>
          <AuthButtonNoArrow
            buttonText="Continue"
            padding={"11px 30px"}
            type="submit"
          />
        </FooterWrapper>
      </OnboardingForm>
    </Slide>
  );
}

export default OnboardingPersonalData;
