import React from "react";
import { GridThreeSection } from "components/LandingPage";
import iconCollaboration from "assets/icons/icon-collaboration.png";
import iconDiscovery from "assets/icons/icon-discovery.png";
import iconPrivacy from "assets/icons/icon-privacy.png";

function WhyRevmo() {
  const content = {
    title: "Why Revmo?",
    cards: [
      {
        title: "DISCOVERY",
        tagline: "Best paths with Smart Weighting",
        text: "Revmo maps relationships across all networks, including personal and business, to connect you to the people you need to reach. AI driven approach quantifies strength of relationships, which enables high quality, useful introductions.",
        icon: iconDiscovery,
      },
      {
        title: "COLLABORATION",
        tagline: "No matter where you keep your contacts",
        text: "Request introductions from colleauges.  Work as one team, or one organisation, no matter how large.   Selectively share your  network with colleauges, clients and contacts. We take an universal approach to data - any platform, we can upload and analyse it.",
        icon: iconCollaboration,
      },
      {
        title: "COMPLETE PRIVACY",
        tagline: "And always-on encryption",
        text: "Connections are private and introductions are requested anonymously by default. If denied, it's never known. Privacy settings can be customised to suit your team.",
        icon: iconPrivacy,
      },
    ],
  };
  return <GridThreeSection content={content} />;
}

export default WhyRevmo;
