import React from "react";
import {
  UserGroupInfoContainer,
  HeaderTextFlexContainer,
  ProfileInfoColumn,
} from "../Layout";
import { HeaderText } from "components/Typography";
import { GroupTable } from "./index";

function GroupsInfo() {
  return (
    <ProfileInfoColumn>
      <UserGroupInfoContainer>
        <HeaderTextFlexContainer>
          <HeaderText>Groupings</HeaderText>
        </HeaderTextFlexContainer>
      </UserGroupInfoContainer>
      <GroupTable />
    </ProfileInfoColumn>
  );
}

export default GroupsInfo;
