import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  AddNewPositionModal,
  AddOrEditContactModal,
  UpdateProfileImageModal,
  ConfirmUserEmailAddressModal,
} from "components/Modal";
import { Box, Container, Grid } from "@mui/material";
import {
  useAlert,
  useContact,
  useInternationalDiallingCodes,
  useProfile,
  useCustomFormErrors,
  useDisplayOptions,
  useEmailConfirmationCode,
} from "hooks";
import { putData } from "utils";
import { useRecoilValueLoadable } from "recoil";
import { introductionRequestsTotal } from "state/recoil/requests";
import { ProfileDetails, ProfileCard } from "./components";
import {
  updateUserPosition,
  updateUserProfilePicture,
  createAndUpdatePosition,
  deleteAndUpdateUserRoles,
} from "./utils";
import { editSingleContact } from "containers/Contacts/utils";

function Profile({ ...props }) {
  const [showAddPositionModal, setShowAddPositionModal] = useState(false);
  const [showUpdateProfileImageModal, setShowUpdateProfileImageModal] =
    useState(false);
  const history = useHistory();
  const { addAlert } = useAlert();
  const { contactsContextState } = useContact();
  const { numberOfNodes } = contactsContextState;

  const numberOfIntroductions = useRecoilValueLoadable(
    introductionRequestsTotal
  ).contents;

  const diallingCodesHook = useInternationalDiallingCodes();
  const { diallingCode } = diallingCodesHook;
  const [contactProfile, setContactProfile] = useState({});
  const { profile, getUserProfile, updateUserProfile } = useProfile();
  const userContact = props?.location?.state?.contact
    ? props.location.state.contact
    : null;
  const fromSearchPage = props?.location?.state?.search ? true : false;

  useEffect(() => {
    setContactProfile(userContact);
  }, [userContact]);

  const [jobsAreDraggable, setJobsAreDraggable] = useState(false);
  const { displayOptions, toggleDisplayOption } = useDisplayOptions(
    profile?.positions?.length
  );
  const [showAddOrEditContactModal, setShowAddOrEditContactModal] =
    useState(false);
  const [displayContactOptions, setDisplayContactOptions] = useState(false);
  const emailConfirmationCodeHook = useEmailConfirmationCode();
  const customFormErrorsHook = useCustomFormErrors();
  const {
    emailConfirmationData,
    displayEmailConfirmModal,
    closeEmailConfirmModal,
    confirmEmail,
    resetEmailConfirmationData,
    getListOfConfirmedEmailAddresses,
  } = emailConfirmationCodeHook;

  const handleSubmitUpdatePosition = async (
    data,
    job,
    setDisplayModal,
    diallingCode,
    contactAddress
  ) => {
    // Refactor closure
    const updateUserCareer = updateUserPosition(
      profile,
      updateUserProfile,
      getUserProfile
    );
    const userHasAlreadyConfirmedEmailAddress =
      getListOfConfirmedEmailAddresses(profile, data.email);
    const userHasEnteredEmail = Boolean(data.email);
    if (
      userHasAlreadyConfirmedEmailAddress ||
      !userHasEnteredEmail ||
      emailConfirmationData.confirmed
    ) {
      updateUserCareer(
        data,
        job,
        setDisplayModal,
        diallingCode,
        contactAddress,
        addAlert
      );
      resetEmailConfirmationData();
    } else {
      displayEmailConfirmModal(data.email);
    }
  };

  const handleSubmitAddPosition = async (data, contactAddress) => {
    const userHasAlreadyConfirmedEmailAddress =
      getListOfConfirmedEmailAddresses(profile, data.email);
    const userHasEnteredEmail = Boolean(data.email);
    if (
      userHasAlreadyConfirmedEmailAddress ||
      !userHasEnteredEmail ||
      emailConfirmationData.confirmed
    ) {
      const updatedProfile = await createAndUpdatePosition(
        data,
        contactAddress,
        profile,
        getUserProfile
      );

      updateUserProfile({ ...updatedProfile });
      resetEmailConfirmationData();
      setShowAddPositionModal(false);
      diallingCodesHook.clearInternationalDiallingCode();
    } else {
      displayEmailConfirmModal(data.email);
    }
  };

  const handleSubmitUpdateUserProfilePicture = updateUserProfilePicture(
    profile,
    updateUserProfile
  );

  const handleEditContact = async (data, contactAddress, action) => {
    try {
      if (action === "edit") {
        setShowAddOrEditContactModal(false);
        const userData = await editSingleContact(
          data,
          contactAddress,
          contactProfile,
          diallingCode
        );
        const contact = userData[0];
        setContactProfile(userData);
        history.replace({
          state: { contact },
        });
        return userData;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeletePosition = async (job) => {
    let roleUUID = job.uuid;
    const updatedProfile = await deleteAndUpdateUserRoles(
      profile,
      roleUUID,
      job,
      getUserProfile
    );
    updateUserProfile({ ...profile, positions: updatedProfile.positions });
  };

  const toggleAddOrEditSingleContactModal = () => {
    setShowAddOrEditContactModal(!showAddOrEditContactModal);
    setDisplayContactOptions(!displayContactOptions);
  };

  const toggleDisplayContactOptions = () => {
    setDisplayContactOptions(!displayContactOptions);
  };

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const reorderedJobs = [...profile.positions];
    const [reorderedJob] = reorderedJobs.splice(result.source.index, 1);
    reorderedJobs.splice(result.destination.index, 0, reorderedJob);
    const jobsWithUpdatedSeqNo = reorderedJobs.map((job, index) => {
      job.seq_no = profile.positions.length - index;
      return job;
    });
    const updatedProfile = { ...profile, career: jobsWithUpdatedSeqNo };
    delete updatedProfile.cognito_user_id;
    updateUserProfile(updatedProfile);
    await putData(
      `/profile/${profile.cognito_user_id}`,
      JSON.stringify(updatedProfile)
    );
    setJobsAreDraggable(false);
  };

  const closeAddNewPositionModal = () => {
    setShowAddPositionModal(false);
    diallingCodesHook.clearInternationalDiallingCode();
  };

  return (
    <>
      <AddNewPositionModal
        displayModal={showAddPositionModal}
        setDisplayModal={closeAddNewPositionModal}
        handleSubmitAddPosition={handleSubmitAddPosition}
        diallingCodesHook={diallingCodesHook}
        customFormErrorsHook={customFormErrorsHook}
      />
      {showUpdateProfileImageModal && (
        <UpdateProfileImageModal
          handleSubmitUpdateUserProfilePicture={
            handleSubmitUpdateUserProfilePicture
          }
          profile={userContact ? contactProfile : profile}
          displayModal={showUpdateProfileImageModal}
          setDisplayModal={setShowUpdateProfileImageModal}
        />
      )}
      <ConfirmUserEmailAddressModal
        emailConfirmationData={emailConfirmationData}
        confirmEmailFunction={confirmEmail}
        closeModal={closeEmailConfirmModal}
      />
      {showAddOrEditContactModal && (
        <AddOrEditContactModal
          displayModal={showAddOrEditContactModal}
          setDisplayModal={setShowAddOrEditContactModal}
          handleSubmitManuallyAddOrEditNewContact={handleEditContact}
          contactDetails={contactProfile}
          setContactDetails={setContactProfile}
          action={"edit"}
          customFormErrorsHook={customFormErrorsHook}
          diallingCodesHook={diallingCodesHook}
        />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <ProfileCard
                userContact={userContact}
                profile={userContact ? contactProfile : profile}
                setShowUpdateProfileImageModal={setShowUpdateProfileImageModal}
                numberOfContactsInNetwork={numberOfNodes}
                numberOfIntroductions={numberOfIntroductions?.totalUserRequests?.toString()}
              />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <ProfileDetails
                userContact={userContact}
                fromSearchPage={fromSearchPage}
                handleSubmitUpdatePosition={handleSubmitUpdatePosition}
                setShowAddPositionModal={setShowAddPositionModal}
                handleOnDragEnd={handleOnDragEnd}
                profile={userContact ? contactProfile : profile}
                jobsAreDraggable={jobsAreDraggable}
                setJobsAreDraggable={setJobsAreDraggable}
                toggleDisplayOption={toggleDisplayOption}
                displayOptions={displayOptions}
                displayContactOptions={displayContactOptions}
                toggleDisplayContactOptions={toggleDisplayContactOptions}
                handleDeletePosition={handleDeletePosition}
                toggleAddOrEditSingleContactModal={
                  toggleAddOrEditSingleContactModal
                }
                diallingCodesHook={diallingCodesHook}
                customFormErrorsHook={customFormErrorsHook}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Profile;
