import styled from "styled-components";

export const BlueTransparentBackground = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1200;
  opacity: 0;
  right: 0;
  position: fixed;
  width: 100%;
  transition: opacity 0.1s;
  pointer-events: none;
  ${(props) => {
    if (props.displayRequestIntroductionPanel) {
      return `
      background: #bedae2;
      opacity: 0.7;
      pointer-events: auto;
      `;
    }
  }}
`;

export const RequestIntroductionPanelWrapper = styled.div`
  right: 0;
  top: 69px;
  position: fixed;
  bottom: 0;
  transition: transform 0.5s ease;
  background: white;
  z-index: 1200;
  width: 862px;
  overflow: scroll;
  min-height: calc(100% - 69px);
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (max-width: 870px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    top: 56px;
  }
  ${(props) => {
    if (props.displayRequestIntroductionPanel) {
      return `
        transform: translate3d(0,0,0);
        box-shadow: 0 0 20px rgba(34,36,38,.15);
        visibility: visible; 
      `;
    } else {
      return `
        transform: translate3d(100%,0,0);
      `;
    }
  }}
  a {
    text-decoration: none;
  }
`;

export const RequestIntroductionPanelBody = styled.div`
  padding: 23px 49px 0 49px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 480px) {
    padding: 0 15px 0 15px;
  }
`;

export const RequestRowWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 18px;
  background: #f9fafb;
  padding: 42px 14px 20px 19px;
  @media (max-width: 870px) {
    flex-direction: column;
  }
`;

export const ContactTypeTextWrapper = styled.div`
  position: absolute;
  top: -24px;
  left: 0;
  display: ${(props) =>
    props.introductionRequestHasBeenSent ? "none" : "flex"};
`;

export const ContactTypeTextWrapperIntroduceTo = styled(ContactTypeTextWrapper)`
  left: 50%;
  width: max-content;
  transform: translateX(-50%);
  @media (max-width: 449px) {
    left: 0;
    transform: unset;
  }
`;

export const ContactTypeTextWrapperDesiredConnection = styled(
  ContactTypeTextWrapper
)`
  left: 14px;
  width: max-content;
  @media (max-width: 449px) {
    left: 0;
  }
`;

export const RequestedConnectionContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
  height: 90px;
  width: 100%;
  background: white;
  box-shadow: 0px 4px 4px #efefef;
  margin-right: 5px;
  flex-grow: 1;
  span {
    display: flex;
    margin-bottom: 3px;
  }
  @media (max-width: 870px) {
    margin-right: 0;
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const PathChanceContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 139px;
  align-items: center;
  position: relative;
  padding: 0 10px;
  flex-direction: column;
  background: white;
  height: 90px;
  box-shadow: 0px 4px 4px #efefef;
  margin-right: 5px;
  @media (max-width: 870px) {
    margin-bottom: 55px;
  }
`;

export const DesiredConnectionContainer = styled.div`
  position: relative;
  padding: 0 11px;
  height: 90px;
  align-items: center;
  width: 100%;
  display: flex;
  background: white;
  box-shadow: 0px 4px 4px #efefef;
  flex-grow: 1;
  span {
    display: flex;
    margin-bottom: 3px;
  }
`;

export const CheckBoxContainer = styled.div`
  position: relative;
  display: flex;
  min-width: 59px;
  align-items: center;
  justify-content: center;
  background: white;
  height: 90px;
  box-shadow: 0px 4px 4px #efefef;
  margin-right: 5px;
  @media (max-width: 870px) {
    width: 139px;
  }
  @media (max-width: 480px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 55px;
  }
`;

export const RowSectionWrap = styled.div`
  display: flex;
  @media (max-width: 870px) {
    margin-bottom: 55px;
    flex-grow: 1;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const RowSectionWrapFlexGrow = styled.div`
  display: flex;
  flex-grow: 1;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
