import React from "react";
import {
  Container996,
  HeroBackground,
  HeroTextWrapper,
  HeroTag,
} from "components/Post/Layout";

function BlogHero() {
  return (
    <Container996>
      <HeroBackground />
      <HeroTextWrapper>
        <HeroTag>Content</HeroTag>
        <h1>News, stories and latest thinking on networking, data and more.</h1>
        <p>
          A rundown of the latest Revmo thought pieces, articles, advice, and
          important use cases.
        </p>
      </HeroTextWrapper>
    </Container996>
  );
}

export default BlogHero;
