import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material/styles";
import { CircularProgress, Typography } from "@mui/material";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import {
  BlueLinkTextUnderlined,
  LargeBoldText,
  MidLargeSpacedText,
  MidSizedGreyTextSpaced,
  ExtraSmallBlackText,
  SmallBlackNormalText,
} from "../Typography";
import closeIcon from "../../assets/icons/close-icon.svg";
import { ButtonPrimaryWithArrow } from "../Button";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  AddContactsContent,
  HeaderAndExplainerTextContainerMultipleContacts,
  ProfileModalContainerAddMultipleContacts,
} from "./index";
import { CSVLink } from "react-csv";
import { CSVUploadDropdown } from "components/Dropdown";

const UploadFileContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px dashed #7c919e;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  position: relative;
  input {
    position: absolute;
    width: 100%;
    cursor: pointer;
    opacity: 0;
  }
  margin-bottom: 20px;
`;

const FileUploadPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UploadFileLinkAndText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${(props) => {
    if (props.fileHasBeenUploaded) {
      return `
      `;
    }
  }}
`;

const UploadIcon = styled(BackupOutlinedIcon)`
  && {
    height: 2em;
    width: 2em;
  }
`;

export function AddMultipleContactsModal({
  displayModal,
  setDisplayModal,
  handleContactUploadsFromFile,
  fileUploadRef,
  submitUploadsFromFile,
  handleChangeCSVDropdown,
  loading,
  failureMessage,
}) {
  const theme = useTheme();

  const headers = [
    { label: "first_name", key: "firstName" },
    { label: "last_name", key: "lastName" },
    { label: "email_personal", key: "emailPersonal" },
    { label: "telephone_personal", key: "telephonePersonal" },
    { label: "address_personal", key: "addressPersonal" },
    { label: "org_1_name", key: "org1Name" },
    { label: "org_1_industry", key: "industry1" },
    { label: "org_1_location", key: "role1Location" },
    { label: "org_1_email", key: "role1Email" },
    { label: "org_1_telephone", key: "phone1Work" },
    { label: "org_1_role_1_description", key: "role1Description1" },
    { label: "org_1_role_2_description", key: "role1Description2" },
    { label: "org_1_role_2_location", key: "role1Location2" },
    { label: "org_2_name", key: "org2name" },
    { label: "org_2_industry", key: "industry2" },
    { label: "org_2_location", key: "role2Location" },
    { label: "org_2_email", key: "role2Email" },
    { label: "org_2_telephone", key: "phone2Work" },
    { label: "org_2_role_1_description", key: "role2Description1" },
  ];

  const data = [
    {
      firstName: "Jacqui",
      lastName: "Douglis",
      fullName: "Jacquenetta Douglis",
      emailPersonal: "jdougliss8y@home.pl",
      telephonePersonal: "(710) 1989931",
      telephoneOther: "+63 (543) 248-1644",
      addressPersonal: "51965 Longview Street",
      org1Name: "Edgeclub",
      industry1: "Major Pharmaceuticals",
      role1Description1: "CFO",
      role1Location: "London, UK",
      role1Description2: "Board Member",
      role1Email: "jdougliss8y@google.it",
      phone1Work: "615-903-0066",
      org2Name: "Bubblebox",
      industry2: "Accident & Health Insurance",
      role2Description1: "VP",
    },
  ];

  return (
    <>
      {displayModal && (
        <ProfileModalPageWrapper>
          <DarkBlurredBackground onClick={() => setDisplayModal()} />
          <ProfileModalContainerAddMultipleContacts>
            <CloseIconImage src={closeIcon} onClick={setDisplayModal} />
            <AddContactsContent>
              <HeaderAndExplainerTextContainerMultipleContacts>
                <LargeBoldText>
                  {displayModal === "csv" ? "Upload a CSV" : "Upload a VCF"}
                </LargeBoldText>
                <MidLargeSpacedText>
                  Simply drag a {displayModal === "csv" ? "CSV" : "VCF"} contact
                  file to the area below, or click below to locate the file on
                  your device.
                </MidLargeSpacedText>
                <ExtraSmallBlackText>
                  *Please note, you will not be able to make introductions to
                  contacts who are uploaded without email addresses
                </ExtraSmallBlackText>
              </HeaderAndExplainerTextContainerMultipleContacts>
              <UploadFileContainer>
                {!loading ? (
                  <UploadFileLinkAndText
                    fileHasBeenUploaded={fileUploadRef.current}
                  >
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept=".csv, text/csv, text/x-vcard"
                      onChange={handleContactUploadsFromFile}
                    />
                    {fileUploadRef.current && (
                      <p>Currently selected: {fileUploadRef.current}</p>
                    )}
                    <UploadIcon />
                    <FileUploadPlaceholder>
                      <SmallBlackNormalText>
                        Drag & drop or&nbsp;
                      </SmallBlackNormalText>
                      <BlueLinkTextUnderlined as="label" htmlFor="file">
                        {!fileUploadRef.current
                          ? " browse"
                          : "select a different file to upload,"}
                      </BlueLinkTextUnderlined>
                    </FileUploadPlaceholder>
                  </UploadFileLinkAndText>
                ) : (
                  <CircularProgress />
                )}
              </UploadFileContainer>
              {displayModal === "csv" && (
                <DownloadCSVWrapper>
                  <CSVUploadDropdown handleChange={handleChangeCSVDropdown} />
                  {failureMessage && (
                    <Typography
                      variant="subtitle1"
                      color={theme.palette.warning.main}
                    >
                      {failureMessage}
                    </Typography>
                  )}
                </DownloadCSVWrapper>
              )}
              <ButtonPrimaryWithArrow
                padding={"11px 27px"}
                buttonText={"Submit"}
                disabled={!fileUploadRef.current}
                action={submitUploadsFromFile}
              />
            </AddContactsContent>
          </ProfileModalContainerAddMultipleContacts>
        </ProfileModalPageWrapper>
      )}
    </>
  );
}

const DownloadCSVWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const DownloadCSVLInkTextWrapper = styled.div`
  display: flex;
  p {
    display: inline;
  }
`;

const LargeBoldUnderlinedText = styled.p`
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.311111px;
  text-decoration-line: underline;
  color: #5374ff;
  cursor: pointer;
  font-weight: bold;
`;
