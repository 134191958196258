import React from "react";
import { useRecoilValue } from "recoil";
import { showMobileMenuState } from "state";
import styled from "styled-components";

export const PageWrapperFullWidth = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  align-items: center;
  border-radius: 8px;
  @media (max-width: 870px) {
    padding-top: 50px;
    ${(props) => {
      if (props.showMobileMenu) {
        return `
          position: fixed;
        `;
      }
    }}
  }
`;

export const PageWrapperMaxContentWidth = styled.div`
  padding: 0 20px;
  max-width: 1140px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 480px) {
    padding: 0 15px;
  }
`;

export const PageWrapperFullWidthRelative = styled(PageWrapperFullWidth)`
  position: relative;
  min-height: 100vh;
  @media (max-width: 870px) {
    padding-top: 0px;
  }
`;

export function PageWrapper({ children }) {
  const showMobileMenu = useRecoilValue(showMobileMenuState);
  return (
    <PageWrapperFullWidth showMobileMenu={showMobileMenu}>
      <PageWrapperMaxContentWidth>{children}</PageWrapperMaxContentWidth>
    </PageWrapperFullWidth>
  );
}

export const DarkBlurredBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #5c6480;
  z-index: 45;
  opacity: 0.65;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
`;

export { default as PageHeader } from "./PageHeader";
