import React from "react";
import styled from "styled-components";
import { PageWrapperMaxContentWidth } from "components/Layout";
import {
  MidLargeSpacedText,
  SmallBlackBoldText,
  BlueLinkTextUnderlined,
} from "components/Typography";
import Payment from "./Payment";

const PageWrapperAccounterPreferences = styled(PageWrapperMaxContentWidth)`
  margin-top: 71px;
  padding-bottom: 78px;
`;

const AccountSettingsContainerInner = styled.div`
  max-width: 632px;
  width: 100%;
  margin-top: 15px;
  @media (max-width: 480px) {
    margin-top: 36px;
  }
`;

const AccountSettingsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 33px;
  align-items: center;
`;

const AccountSettingsUserInformationRowWrapper = styled.div`
  padding-bottom: 18px;
  width: 100%;
  border-bottom: 1px solid #e3e6e9;
  padding-top: 24px;
`;

const AccountSettingsLocationDetails = styled.div`
  margin-top: 50px;
`;

const AccountSettingsPasswordContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 81px;
  margin-bottom: 39px;
`;

const MidLargeSpacedTextOpacity = styled(MidLargeSpacedText)`
  opacity: 0.3;
`;

function AccountSettings({
  setDisplayEditPasswordModal,
  profile,
  showEditPersonalInfoModal,
  subscriptionData,
}) {
  const {
    first_name,
    last_name,
    address_1,
    email_address,
    address_2,
    zip_code,
    city,
    telephone_number,
  } = profile;

  return (
    <PageWrapperAccounterPreferences>
      <Payment profile={profile} subscriptionData={subscriptionData} />
      <AccountSettingsContainerInner>
        <AccountSettingsHeader>
          <SmallBlackBoldText>Personal details</SmallBlackBoldText>
          <BlueLinkTextUnderlined onClick={showEditPersonalInfoModal}>
            Edit
          </BlueLinkTextUnderlined>
        </AccountSettingsHeader>
        <AccountSettingsInformationRow
          field={first_name}
          fieldName="First name"
        />
        <AccountSettingsInformationRow
          field={last_name}
          fieldName="Last name"
        />
        <AccountSettingsInformationRow
          field={email_address}
          fieldName="Email address"
        />
        <AccountSettingsInformationRow
          field={
            telephone_number?.country_code &&
            `${telephone_number?.country_code}${telephone_number?.national_number}`
          }
          fieldName="Telephone number"
        />
        <AccountSettingsLocationDetails>
          <AccountSettingsInformationRow
            field={address_1}
            fieldName="Address 1"
          />
          <AccountSettingsInformationRow
            field={address_2}
            fieldName="Address 2"
          />
          <AccountSettingsInformationRow
            field={zip_code}
            fieldName="Zip/Post Code"
          />
          <AccountSettingsInformationRow field={city} fieldName="City" />
        </AccountSettingsLocationDetails>
        <AccountSettingsPasswordContainer>
          <SmallBlackBoldText>Password</SmallBlackBoldText>
          <BlueLinkTextUnderlined
            onClick={() => setDisplayEditPasswordModal(true)}
          >
            Edit
          </BlueLinkTextUnderlined>
        </AccountSettingsPasswordContainer>
        <AccountSettingsUserInformationRowWrapper>
          <MidLargeSpacedText>*******************</MidLargeSpacedText>
        </AccountSettingsUserInformationRowWrapper>
      </AccountSettingsContainerInner>
    </PageWrapperAccounterPreferences>
  );
}

function AccountSettingsInformationRow({ field, fieldName }) {
  return (
    <AccountSettingsUserInformationRowWrapper>
      {field?.length ? (
        <MidLargeSpacedText>{field}</MidLargeSpacedText>
      ) : (
        <MidLargeSpacedTextOpacity>{fieldName}</MidLargeSpacedTextOpacity>
      )}
    </AccountSettingsUserInformationRowWrapper>
  );
}

export default AccountSettings;
