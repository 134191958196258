import React from "react";
import {
  Container996,
  HeroBackground,
  HeroTextWrapper,
  HeroTag,
} from "components/Post/Layout";

function UpdatesHero() {
  return (
    <Container996>
      <HeroBackground />
      <HeroTextWrapper>
        <HeroTag>Content</HeroTag>
        <h1>Insights and updates from across the team</h1>
        <p>
          A rundown of the latest Revmo feature releases, product enhancements,
          design updates, and important bug fixes.
        </p>
      </HeroTextWrapper>
    </Container996>
  );
}

export default UpdatesHero;
