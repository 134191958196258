import { Auth } from "aws-amplify";
import { revmoApiUrl } from "../config";

const putData = async (url, payload) => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  let res = await fetch(revmoApiUrl + url, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + cognitoUser.signInUserSession.idToken.jwtToken,
      "Permissions-Policy": "interest-cohort=()",
      "Content-Type": "application/json",
    },
    body: payload,
  });
  if (res.status >= 200 && res.status <= 299) {
    return res.status;
  } else {
    const json = await res.json();
    throw json;
  }
};

export default putData;
