import React from "react";
import { Link } from "react-router-dom";
import { AuthFormWrapper, LogInToRevmo } from "components/Auth";
import {
  HeaderText,
  ExtraSmallBlackTextUnderlineLogin,
} from "components/Typography";

// enter password to login
// offer opporunity for not remembering password

// Render part of the regular login page
// Thank you for signing up, please Login to Revmo to use your account
// the login functionality needs to lead to onboaridng

function EnterPasswordToLogin({ alertMessage, logInUser }) {
  return (
    <AuthFormWrapper>
      <HeaderText>Thank you for signing up</HeaderText>
      <LogInToRevmo alertMessage={alertMessage} logInUser={logInUser}>
        <Link to="/login/reset-password">
          <ExtraSmallBlackTextUnderlineLogin>
            Forgot your password?
          </ExtraSmallBlackTextUnderlineLogin>
        </Link>
      </LogInToRevmo>
    </AuthFormWrapper>
  );
}

export default EnterPasswordToLogin;
