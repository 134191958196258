import React, { useEffect, useState, useRef } from "react";
import { useSetRecoilState } from "recoil";
import { showRequestIntroPanelState } from "state/recoil/walkthrough";

import Typography from "@mui/material/Typography";
import {
  RequestIntroductionTableRow,
  RequestIntroductionPanelHeader,
  RequestIntroductionPanelButtons,
  RequestIntroductionUseCaseDropdown,
} from "./components";

import {
  BlueTransparentBackground,
  RequestIntroductionPanelBody,
  RequestIntroductionPanelWrapper,
} from "./Layout";
import { useCustomFormErrors } from "hooks";
import styled from "styled-components";
import TextField from "@mui/material/TextField";

export function RequestIntroductionPanel({
  introductionRequestHook,
  useCase,
  profile,
  walkthrough,
}) {
  const [introductionUseCase, setIntroductionUseCase] = useState(useCase);
  const {
    sendIntroductionRequestToNetworkMember,
    introductionRequests,
    hideAndClearRequestIntroductionPanel,
    confirmIntroductionRequest,
    message,
    setMessage,
  } = introductionRequestHook;

  const {
    personYouWouldLikeToViewIntroductionPathwayTo,
    peopleWhoCanIntroduceYou,
    introductionRequestHasBeenSent,
    introductionRequestError,
    displayRequestIntroductionPanel,
    userHasConfirmedTheyWouldLikeAnIntroductionRequest,
  } = introductionRequests;
  const customFormErrorsHook = useCustomFormErrors();
  const [showError, setShowError] = useState(false);
  const messageRef = useRef("");

  const setShowRequestIntroPanelState = useSetRecoilState(
    showRequestIntroPanelState
  );

  useEffect(() => {
    if (walkthrough) {
      setIntroductionUseCase("Fundraising");
    }
  }, [walkthrough]);

  useEffect(() => {
    if (walkthrough && displayRequestIntroductionPanel) {
      setShowRequestIntroPanelState(true);
    }
  }, [
    walkthrough,
    displayRequestIntroductionPanel,
    setShowRequestIntroPanelState,
  ]);

  const handleChangeIntroductionUseCase = (event, result) => {
    const { value } = result || event.target;
    setIntroductionUseCase(value);
    setShowError(false);
  };

  const handleSendIntroductionRequestToNetworkMember = (
    introductionUseCase = null
  ) => {
    if (!introductionUseCase) {
      customFormErrorsHook.setCustomFormErrors({
        introductionUseCase: "Please select a reason from the dropdown",
      });
      setShowError(true);
    } else if (messageRef.current.value) {
      setMessage(messageRef.current.value);
      sendIntroductionRequestToNetworkMember(
        introductionUseCase,
        messageRef.current.value,
        walkthrough,
        profile
      );
    } else {
      sendIntroductionRequestToNetworkMember(
        introductionUseCase,
        message,
        walkthrough,
        profile
      );
    }
  };

  const clearPanelAndResetMessage = () => {
    hideAndClearRequestIntroductionPanel();
  };

  return (
    <>
      <BlueTransparentBackground
        displayRequestIntroductionPanel={displayRequestIntroductionPanel}
        onClick={clearPanelAndResetMessage}
      />
      <RequestIntroductionPanelWrapper
        displayRequestIntroductionPanel={displayRequestIntroductionPanel}
        className="walkthrough-step-6"
      >
        <RequestIntroductionPanelHeader
          introductionRequestHasBeenSent={introductionRequestHasBeenSent}
          introductionRequestError={introductionRequestError}
        />
        <RequestIntroductionPanelBody>
          <RequestIntroductionUseCaseDropdown
            handleChange={handleChangeIntroductionUseCase}
            useCase={introductionUseCase}
            introductionRequestHasBeenSent={introductionRequestHasBeenSent}
            showError={showError}
          />
          <RequestIntroductionTableRow
            personYouWouldLikeToViewIntroductionPathwayTo={
              personYouWouldLikeToViewIntroductionPathwayTo
            }
            introductionRequestHasBeenSent={introductionRequestHasBeenSent}
            confirmIntroductionRequest={confirmIntroductionRequest}
            userHasConfirmedTheyWouldLikeAnIntroductionRequest={
              userHasConfirmedTheyWouldLikeAnIntroductionRequest
            }
            peopleWhoCanIntroduceYou={peopleWhoCanIntroduceYou}
          />
          <IntroductionCustomMesssageWrapper className="walkthrough-step-9">
            {!introductionRequestHasBeenSent ? (
              <>
                <Typography sx={{ marginBottom: 1 }}>
                  You can add a custom message to your introduction request.
                  Please note, any HTML will be automatically stripped out.
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  placeholder={message}
                  label="Personalise your introduction request"
                  id="customMessage"
                  inputRef={messageRef}
                />
              </>
            ) : (
              <IntroductionCustomMessageTitle>
                This is the message you sent:
                <br />
                <i>{message}</i>
              </IntroductionCustomMessageTitle>
            )}
          </IntroductionCustomMesssageWrapper>
        </RequestIntroductionPanelBody>
        <RequestIntroductionPanelButtons
          userHasConfirmedTheyWouldLikeAnIntroductionRequest={
            userHasConfirmedTheyWouldLikeAnIntroductionRequest
          }
          sendIntroductionRequestToNetworkMember={
            handleSendIntroductionRequestToNetworkMember
          }
          introductionUseCase={introductionUseCase}
          introductionRequestHasBeenSent={introductionRequestHasBeenSent}
          hideAndClearRequestIntroductionPanel={
            hideAndClearRequestIntroductionPanel
          }
        />
      </RequestIntroductionPanelWrapper>
    </>
  );
}

const IntroductionCustomMesssageWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
`;

const IntroductionCustomMessageTitle = styled.p`
  line-height: 23px;
  font-size: 14px;
`;
