import React from "react";
import styled from "styled-components";
import { useUser } from "hooks";
import revmoLogoTransparent from "assets/images/revmo-logo-new-white.png";
import shieldIcon from "assets/icons/shield-icon.png";
import networkIcon from "assets/icons/network-icon.png";
import collaborationIcon from "assets/icons/collaboration-icon.png";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const data = [
  {
    title: "Complete Privacy",
    description:
      "Connections are private and introductions are requested anonymously by default.",
    imgPath: shieldIcon,
  },
  {
    title: "Collaboration",
    description:
      "Request introductions from colleagues. Work as one team, or one organisation, no matter how large. Selectively share your network with colleagues, clients and contacts.",
    imgPath: collaborationIcon,
  },
  {
    title: "Discovery & Smart Weighting",
    description:
      "Revmo maps relationships across all networks, including personal and business, to connect you to the people you need to reach.",
    imgPath: networkIcon,
  },
];

const SidebarContent = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  h4,
  p {
    color: white;
  }
`;

const CarouselInnerText = styled.div`
  p {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const SidebarWrapper = styled.div`
  @media only screen and (min-width: 768px) {
    min-height: auto;
    background-color: #000a33;
    flex-basis: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 33vw;
  }
  display: none;
`;

function SidebarCarousel() {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = data.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 340, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "transparent",
        }}
      ></Paper>
      <AutoPlaySwipeableViews
        axis={"x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {data.map((step, index) => (
          <div key={step.title}>
            {Math.abs(activeStep - index) <= 2 ? (
              <SidebarContent>
                <Box
                  component="img"
                  sx={{
                    height: 150,
                    display: "block",
                    maxWidth: 150,
                    overflow: "hidden",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  src={step.imgPath}
                  alt={step.title}
                />
                <CarouselInnerText>
                  <Typography variant="h4" fontWeight="bold">
                    {step.title}
                  </Typography>
                  <Typography variant="body2" fontSize="1.2rem">
                    {step.description}
                  </Typography>
                </CarouselInnerText>
              </SidebarContent>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        sx={{
          backgroundColor: "transparent",
          justifyContent: "center",
          div: {
            div: {
              height: "13px",
              width: "13px",
              border: "1px solid white",
              backgroundColor: "transparent",
              "&.MuiMobileStepper-dotActive": {
                backgroundColor: "white",
              },
            },
          },
        }}
        activeStep={activeStep}
      />
    </Box>
  );
}

const SidebarLogo = styled.div`
  position: absolute;
  top: 2%;
  left: 2%;
  img {
    height: 30px;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  align-items: center;
`;

function Sidebar() {
  const { logout } = useUser();
  return (
    <SidebarWrapper>
      <SidebarLogo onClick={logout}>
        <img src={revmoLogoTransparent} alt="Revmo logo" />
      </SidebarLogo>
      <CarouselWrapper>
        <SidebarCarousel />
      </CarouselWrapper>
    </SidebarWrapper>
  );
}

export default Sidebar;
