import React from "react";
import { LandingFooter, LandingPageContainer } from "components/LandingPage";
import { LandingNavbar } from "components/Navbar";
import { usePageTitle } from "hooks";
import { SupportHero, SupportItems } from "./components";

function Support() {
  usePageTitle("Support | Revmo");
  return (
    <>
      <LandingPageContainer>
        <LandingNavbar />
        <SupportHero />
        <SupportItems />
      </LandingPageContainer>
      <LandingFooter />
    </>
  );
}

export default Support;
