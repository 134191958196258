import React from "react";
import styled from "styled-components";
import {
  MidBlackBoldText,
  MidBlueBoldText,
  ExtraSmallBlackBoldTextUpperCase,
} from "../Typography";

export const PathScoreDisplayOuter = styled.div`
  width: ${(props) => (!props.width ? "100%" : props.width)};
  height: ${(props) => (props.height ? props.height : 5)}px;
  min-height: 5px;
  overflow: hidden;
  position: relative;
  background: #d6d5e3;
  box-sizing: border-box;
  border-radius: 5px;
  align-items: center;
  margin-top: 0;
  min-width: 101px;
  margin-bottom: 4px;
`;

export const PathScoreDisplayInner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.brandColor};
  width: ${(props) => props.score}%;
  transition: 0.5s ease-in width;
`;

const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    margin: 0;
  }
`;

export function PathScore({ score }) {
  return (
    <ScoreWrapper>
      <MidBlackBoldText> {score}%</MidBlackBoldText>
      <PathScoreDisplayOuter>
        <PathScoreDisplayInner score={score} />
      </PathScoreDisplayOuter>
      <ExtraSmallBlackBoldTextUpperCase>
        PATH SCORE
      </ExtraSmallBlackBoldTextUpperCase>
    </ScoreWrapper>
  );
}

const RevmoScoreHorizontalContainer = styled.div`
  display: flex;
  margin-bottom: 7px;
`;

export function RevmoScore({ score }) {
  return (
    <ScoreWrapper>
      <RevmoScoreHorizontalContainer>
        <MidBlueBoldText>{score}&nbsp;</MidBlueBoldText>
        <MidBlackBoldText>/ 800</MidBlackBoldText>
      </RevmoScoreHorizontalContainer>
      <ExtraSmallBlackBoldTextUpperCase>
        REVMO SCORE
      </ExtraSmallBlackBoldTextUpperCase>
    </ScoreWrapper>
  );
}
