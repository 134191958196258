import { atom } from "recoil";

export const walkthroughState = atom({
  key: "walkthrough",
  default: false,
});

export const showRequestIntroPanelState = atom({
  key: "showRequestIntroPanel",
  default: false,
});
