import React from "react";
import { LargeBoldText, MidLargeSpacedText } from "../Typography";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  AddContactsContent,
  ProfileModalContainerSuccess,
  SuccessModalHeader,
  SuccessIconAndTextWrapper,
  GreenCircleTickIcon,
} from "./index";
import { ButtonPrimaryWithArrow } from "../Button";
import closeIcon from "../../assets/icons/close-icon.svg";
import tickGreen from "../../assets/icons/tick-green-flatter.svg";

export function SuccessModal({
  displayModal,
  setDisplayModal,
  message,
  report,
}) {
  return (
    <>
      {displayModal && (
        <ProfileModalPageWrapper>
          <DarkBlurredBackground onClick={() => setDisplayModal(null)} />
          <ProfileModalContainerSuccess>
            <CloseIconImage
              src={closeIcon}
              onClick={() => setDisplayModal(null)}
            />
            <AddContactsContent>
              <SuccessModalHeader>
                <SuccessIconAndTextWrapper>
                  <GreenCircleTickIcon>
                    <img src={tickGreen} />
                  </GreenCircleTickIcon>
                  {report ? (
                    <LargeBoldText>Thank you</LargeBoldText>
                  ) : (
                    <LargeBoldText>Success</LargeBoldText>
                  )}
                </SuccessIconAndTextWrapper>
                <MidLargeSpacedText>{message}</MidLargeSpacedText>
              </SuccessModalHeader>
              <ButtonPrimaryWithArrow
                disabled={false}
                padding={"11px 27px"}
                buttonText={"Continue"}
                action={() => setDisplayModal(null)}
              />
            </AddContactsContent>
          </ProfileModalContainerSuccess>
        </ProfileModalPageWrapper>
      )}
    </>
  );
}
