import React from "react";
import { JobInfoRow } from "../Layout";
import { MidLargeSpacedText } from "components/Typography";
import styled from "styled-components";

const MidLargeSpacedTextOpacity = styled(MidLargeSpacedText)`
  opacity: 0.3;
`;

function PositionTableRow({ content, fieldName, children }) {
  return (
    <JobInfoRow>
      {content ? (
        <MidLargeSpacedText>{content}</MidLargeSpacedText>
      ) : (
        <MidLargeSpacedTextOpacity>{fieldName}</MidLargeSpacedTextOpacity>
      )}
      {children}
    </JobInfoRow>
  );
}

export default PositionTableRow;
