import { useState } from "react";
import { postData } from "utils";

const useEmailConfirmation = () => {
  const [emailConfirmationData, setEmailConfirmationData] = useState({
    email: "",
    displayModal: false,
    confirmed: false,
  });

  const emailConfirmationRequest = async (email, code = null) => {
    const attempt = code ? code.confirmationCode : "";
    const address = email.replace("+", "%2B");
    const confirmationEndpoint = `/utils/verify-email-code?address=${address}&attempt=${attempt}`;
    try {
      return await postData(confirmationEndpoint);
    } catch (err) {
      console.log(err);
    }
    return {
      result: "ERROR",
    };
  };

  const displayEmailConfirmModal = (email) => {
    setEmailConfirmationData({
      ...emailConfirmationData,
      email: email,
      displayModal: true,
    });
    emailConfirmationRequest(email);
  };

  const closeEmailConfirmModal = () => {
    setEmailConfirmationData({
      ...emailConfirmationData,
      displayModal: false,
    });
  };

  const confirmEmail = async (code) => {
    const confirmationResult = await emailConfirmationRequest(
      emailConfirmationData.email,
      code
    );
    console.log(confirmationResult);
    const confirmationSuccess = confirmationResult.result == "SUCCESS";
    const errorMessage = confirmationSuccess
      ? ""
      : "The confirmation code you have entered is incorrect";

    setEmailConfirmationData({
      ...emailConfirmationData,
      displayModal: !confirmationSuccess,
      confirmed: confirmationSuccess,
      errorMessage: errorMessage,
    });
  };

  const resetEmailConfirmationData = () => {
    setEmailConfirmationData({
      email: "",
      displayModal: false,
      confirmed: false,
      errorMessage: "",
    });
  };

  const getListOfConfirmedEmailAddresses = (profile, newEmail) => {
    const careerEmailAddresses = profile.positions.reduce(
      (a, currentValue) => [
        ...a,
        ...currentValue.company_email_addresses.reduce(
          (a, curVal) => [...a, curVal.email_address],
          []
        ),
      ],
      []
    );
    const allEmailAddresses = profile.email_addresses.reduce(
      (acc, curVal) => acc.concat(curVal.email_address || null),
      []
    );
    return [...allEmailAddresses, ...careerEmailAddresses].includes(newEmail);
  };

  return {
    emailConfirmationData,
    setEmailConfirmationData,
    displayEmailConfirmModal,
    closeEmailConfirmModal,
    confirmEmail,
    resetEmailConfirmationData,
    getListOfConfirmedEmailAddresses,
  };
};

export default useEmailConfirmation;
