import React from "react";
import styled from "styled-components";
import infoIcon from "assets/icons/info-icon-black.svg";
import { TableTextName, TableTextJob } from "components/Typography";
import user3 from "assets/images/user3.png";

const AlternatePathsDropDownContainer = styled.div`
  position: absolute;
  top: 80px;
  left: -1px;
  z-index: 10;
  right: -1px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 11px rgba(168, 175, 199, 0.4);
  border: 1px solid ${(props) => props.theme.borderColor};
  ${(props) => {
    if (props.showAlternatePaths) {
      return `
        display: block
      `;
    } else {
      return `
        display: none;
      `;
    }
  }}
`;

const AlternateOptionHeaderRow = styled.div`
  display: flex;
  width: 100%;
  padding: 13px 0 3px 15px;
  p {
    font-size: 11px;
    letter-spacing: 0.2px;
    color: #3e3f52;
  }
  img {
    height: 11px;
    margin-right: 2px;
    width: 11px;
    margin-bottom: 1px;
  }
`;

const AlternatePathRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border-top: 1px solid ${(props) => props.theme.borderColor};
  p {
    font-size: 11px;
    line-height: 13px;
  }
  span {
    display: flex;
  }
`;

const DropDownUserImage = styled.img`
  height: 45px;
  width: 45px;
  margin: 0 8px 0 0;
`;

function AlternatePathsDropdown({ showAlternatePaths }) {
  const alternateOptions = [
    {
      id: 1,
      name: "Alice Stanton",
      profileImage: user3,
      path: { type: "best path" },
      company: "Whole Foods",
      position: "CFO",
      location: "London, UK",
      anonymous: false,
    },
    {
      id: 2,
      name: "Former Colleague @ Stanford Graduate School",
      anonymous: true,
      path: { type: "alternate paths available", numberOfAlternatives: 1 },
      company: "Monzo",
      position: "CTO",
      location: "New York, NY",
    },
  ];

  return (
    <AlternatePathsDropDownContainer showAlternatePaths={showAlternatePaths}>
      <AlternateOptionHeaderRow>
        <img src={infoIcon} alt="information icon" />
        <p>Please select one path</p>
      </AlternateOptionHeaderRow>
      {alternateOptions.map((alternateOption) => {
        return (
          <AlternatePathRow key={alternateOption.name}>
            <DropDownUserImage
              src={alternateOption.profileImage}
              alt="person who can introduce you"
            />
            <div>
              <TableTextName anonymous={alternateOption.anonymous}>
                {alternateOption.name}
              </TableTextName>
              <span>
                <TableTextJob>{alternateOption.position}</TableTextJob>
                <TableTextJob color="#5374FF">&nbsp;@&nbsp;</TableTextJob>
                <TableTextJob>{alternateOption.company}</TableTextJob>
              </span>
            </div>
          </AlternatePathRow>
        );
      })}
    </AlternatePathsDropDownContainer>
  );
}

export default AlternatePathsDropdown;
