import React, { useState } from "react";
import Button from "@mui/material/Button";
import { SmallBlackBoldText, ExtraSmallBlackText } from "../Typography";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  EditProfileContent,
  AddNewPositionHeader,
  EditPositionModalInputWrapper,
  ProfileModalContainerAddPosition,
} from "./index";
import { OrgsAutocomplete } from "components/Autocomplete";
import PlacesAutocomplete from "components/PlacesAutocomplete";
import {
  DateSelect,
  WhiteLargeFormInputHooksForm,
  WhiteLargeFormInput,
} from "../Input";
import closeIcon from "../../assets/icons/close-icon.svg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addOrEditPositionDataSchema } from "containers/Profile/utils";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export function AddNewPositionModal({
  displayModal,
  setDisplayModal,
  handleSubmitAddPosition,
  customFormErrorsHook,
}) {
  const { control, register, handleSubmit, errors, watch } = useForm({
    resolver: yupResolver(addOrEditPositionDataSchema),
  });

  const [contactAddress, setContactAddress] = useState({});
  const { isActive } = watch();

  const handleCloseModal = () => {
    setDisplayModal(false);
    setContactAddress({});
  };

  return (
    <>
      {displayModal && (
        <ProfileModalPageWrapper>
          <DarkBlurredBackground onClick={() => handleCloseModal()} />
          <ProfileModalContainerAddPosition>
            <CloseIconImage
              src={closeIcon}
              onClick={() => handleCloseModal()}
            />
            <EditProfileContent>
              <AddNewPositionHeader>
                <SmallBlackBoldText>Add experience</SmallBlackBoldText>
                <ExtraSmallBlackText>*Indicates required</ExtraSmallBlackText>
              </AddNewPositionHeader>
              <EditPositionModalInputWrapper>
                <WhiteLargeFormInputHooksForm
                  fieldName="Position*"
                  name={"position"}
                  error={errors}
                  register={register}
                />
                <OrgsAutocomplete
                  name="company"
                  control={control}
                  errors={errors}
                />
                <PlacesAutocomplete
                  component={WhiteLargeFormInput}
                  useCase="userProfessional"
                  fieldName="Location"
                  placesAddress={contactAddress}
                  setPlacesAddress={setContactAddress}
                  customFormErrorsHook={customFormErrorsHook}
                  outsideSearch={true}
                />
                <WhiteLargeFormInputHooksForm
                  fieldName="Email"
                  name={"email"}
                  error={errors}
                  register={register}
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="isActive"
                      control={control}
                      defaultValue={true}
                      render={(props) => (
                        <Checkbox
                          {...props}
                          checked={!!props.value}
                          onChange={(e) => props.onChange(e.target.checked)}
                          color="secondary"
                          size="large"
                        />
                      )}
                    />
                  }
                  label="I am currently working in this role"
                />
                <DateSelect
                  control={control}
                  type={"start"}
                  title={"Start Date"}
                />
                {isActive == false && (
                  <DateSelect control={control} type={"end"} title="End date" />
                )}
              </EditPositionModalInputWrapper>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit((data) =>
                  handleSubmitAddPosition(data, contactAddress)
                )}
                sx={{ mt: "20px" }}
              >
                Add position
              </Button>
            </EditProfileContent>
          </ProfileModalContainerAddPosition>
        </ProfileModalPageWrapper>
      )}
    </>
  );
}
