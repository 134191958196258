import React from "react";
import styled from "styled-components";

const LandingSectionWrapper = styled.div`
  width: 100%;
  padding: 40px 0 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .paragraph {
    padding-top: 30px;
    max-width: 690px;
    text-align: justify;
  }
  div {
    max-width: 820px;
    h1 {
      span {
        font-size: 40px;
        text-align: justify;
        font-weight: bold;
        font-style: normal;
        color: #0d2130;
        padding: 0px 0px 20px 0px;
      }
    }
    p {
      padding: 20px 0 20px 0;
      span {
        font-size: 24px;
        letter-spacing: 0em;
        color: #0d2130;
        span {
          font-style: italic;
        }
      }
    }
  }
  @media (max-width: 870px) {
    justify-content: center;
    align-items: center;
    div {
      max-width: 85%;
      text-align: center;
    }
    .paragraph {
      max-width: 75%;
      text-align: justify;
    }
  }
`;

const LandingSection = () => {
  return (
    <LandingSectionWrapper>
      <div>
        <h1>
          <span>Automate Connections with Revmo</span>
        </h1>
      </div>
      <div className="paragraph">
        <p>
          <span>
            Grow your B2B capabilities with a simple search across corporate,
            professional and personal networks.
          </span>
        </p>
        <p>
          <span>
            Automate secure, curated introductions by leveraging your network
            (and your network's network) to originate new opportunities{" "}
            <span>today</span>.
          </span>
        </p>
      </div>
    </LandingSectionWrapper>
  );
};

export default LandingSection;
