import React from "react";
import styled from "styled-components";
import { WhiteLargeFormInputHooksForm } from "components/Input";
import { AuthButton } from "components/Button";
import { HeaderText, AuthText } from "components/Typography";
import { AuthForm, AuthFormWrapper, AuthBackButton } from "components/Auth";
import { recoverAccountSchema } from "../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const AuthFormWrapperRequestAccess = styled(AuthFormWrapper)`
  padding-bottom: 50px;
  padding-top: 25px;
  h1 {
    margin-bottom: 20px;
    + p {
      margin-bottom: 7px;
    }
  }
  button {
    margin-top: 25px;
  }
`;

const HeaderTextRequestAccess = styled(HeaderText)`
  font-size: 23px;
  margin-top: 10px;
`;

function RecoverPassword({
  checkIfUserHasAnAccount,
  alertMessage,
  returnToLoginPage,
}) {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(recoverAccountSchema),
  });

  if (alertMessage) {
    errors.email = {};
    errors.email.message = alertMessage;
  }

  return (
    <AuthFormWrapperRequestAccess>
      <AuthBackButton goBack={returnToLoginPage} />
      <HeaderTextRequestAccess>
        Recover your Revmo account
      </HeaderTextRequestAccess>
      <AuthText>
        Please provide the email address associated with your account
      </AuthText>
      <AuthForm>
        <WhiteLargeFormInputHooksForm
          fieldName="Email Address"
          register={register}
          error={errors}
          name="email"
          bold
        />
        <AuthButton onClick={handleSubmit(checkIfUserHasAnAccount)}>
          Find Account
        </AuthButton>
      </AuthForm>
    </AuthFormWrapperRequestAccess>
  );
}

export default RecoverPassword;
