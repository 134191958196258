import React, { useState } from "react";
import styled from "styled-components";

const SectionWrapper = styled.div`
  width: 100%;
  border-radius: 9px;
  box-shadow: 0px 0px 5px 1px rgba(152, 152, 152, 0.75);
  max-width: 1000px;
  overflow: hidden;
  padding: 2rem 0 2rem 0;
  margin: 4rem 0 4rem 0;
  @media (max-width: 870px) {
    width: 85%;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3,
  p {
    margin-left: 2rem;
  }
  p {
    margin-bottom: 2rem;
    font-size: 18px;
  }
  h3 {
    font-size: 30px;
  }
  @media (max-width: 870px) {
    flex-direction: column;
    align-items: center;
    div {
      width: 95%;
    }
  }
`;

const TabsInner = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 870px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Tab = styled.button`
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
  text-align: center;
  border-radius: 7px;
  margin-bottom: 1rem;
  cursor: pointer;
  opacity: 0.6;
  background: #f1f1f1;
  border: 0;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    opacity: 1;
    background-color: #337ab9;
    color: white;
  `}
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  width: 26vw;
  border: 2px solid #d9d9d9;
  border-radius: 7px;
  @media (min-width: 871px) {
    margin: 0 2rem 0 2rem;
  }
  ol {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    li {
      padding-bottom: 1rem;
    }
  }
  @media (max-width: 870px) {
    width: 95%;
  }
`;

const ButtonGroup = styled.div`
  width: 13vw;
  margin-left: 2rem;
  line-height: 1.5;
  overflow: auto;
  display: flex;
  flex-basis: 20%;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 871px) {
    min-height: 289px;
  }
  @media (max-width: 870px) {
    flex-flow: row wrap;
    gap: 10px;
    width: 100%;
    height: 100%;
  }
`;

const types = ["LinkedIn", "iPhone/Apple", "Gmail", "Yahoo", "Outlook"];

function TabGroup() {
  const [active, setActive] = useState(types[0]);
  return (
    <>
      <ButtonGroup>
        {types.map((type) => (
          <Tab
            key={type}
            active={active === type}
            onClick={() => setActive(type)}
          >
            {type}
          </Tab>
        ))}
      </ButtonGroup>
      <TabContent>
        {active === "LinkedIn" ? (
          <ol>
            <li>Sign in to LinkedIn and select the Me icon at the top</li>
            <li>Choose Settings & Privacy from the dropdown</li>
            <li>Click Data Privacy on the left rail</li>
            <li>
              Click How LinkedIn uses your data under Get a copy of your data
            </li>
            <li>Select Connections and Imported Contact</li>
            <li>Click Request archive</li>
          </ol>
        ) : active === "iPhone/Apple" ? (
          <ol>
            <li>Sign into your Apple iCloud Account</li>
            <li>Click Contacts</li>
            <li>Click Gear Icon in bottom-left corner</li>
            <li>Click Select All</li>
            <li>Select Export vCard</li>
          </ol>
        ) : active === "Gmail" ? (
          <ol>
            <li>Sign in to Gmail and click the contacts link</li>
            <li>Click the More dropdown in the top left</li>
            <li>Click Export</li>
            <li>
              Select the button next to "which contacts do you want to export?"
            </li>
            <li>Click the Outlook CSV format option</li>
            <li>Click Export and save the file.</li>
          </ol>
        ) : active === "Yahoo" ? (
          <ol>
            <li>Sign in to Yahoo Mail and click the Contacts tab.</li>
            <li>
              Click the Actions button and select Export All from the dropdown
              menu.
            </li>
            <li>Click the Outlook CSV format option.</li>
            <li>Click Export Now and save the file.</li>
          </ol>
        ) : active === "Outlook" ? (
          <ol>
            <li>Open Outlook</li>
            <li>
              Select Import and Export from the file menu and wait for the
              wizard to open.
            </li>
            <li>Select Export to a file and click Next.</li>
            <li>Select Comma separated values (Windows) and click Next.</li>
            <li>
              Select the contacts folder you'd like to export and click Next.
            </li>
            <li>Name and save your file, then then click Next.</li>
            <li>
              Make sure the checkbox next to Export is checked and click Next.
            </li>
          </ol>
        ) : (
          <></>
        )}
      </TabContent>
    </>
  );
}
// Usage
<TabGroup />;

const GettingStartedTabs = () => {
  return (
    <SectionWrapper>
      <TabsWrapper>
        <div>
          <h3>Contact Upload</h3>
          <p>Complete these steps to get the most out of Revmo!</p>
        </div>
        <TabsInner>
          <TabGroup />
        </TabsInner>
      </TabsWrapper>
    </SectionWrapper>
  );
};

export default GettingStartedTabs;
