import { useState, useRef } from "react";
import { postFileData } from "utils";
import { useModalReducer } from "containers/Contacts/hooks";
import { useAlert } from "hooks";

function useFileUpload(getUserContacts, jumpPage) {
  const fileUploadRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [failureMessage, setFailureMessage] = useState(null);
  const [fileForUpload, setFileForUpload] = useState(null);
  const [fileOrigin, setFileOrigin] = useState(null);
  const { addAlert } = useAlert();
  const { setUploadStatus, toggleAddMultipleContactsModal } = useModalReducer();

  const handleContactUploadsFromFile = (event) => {
    if (failureMessage) {
      setFailureMessage(null);
    }
    setLoading(true);
    setFileForUpload(event.target.files[0]);
    if (event.target?.files[0]?.name) {
      fileUploadRef.current = event.target.files[0].name;
    }
    setLoading(false);
  };

  const handleChangeCSVDropdown = (event, result) => {
    if (failureMessage) {
      setFailureMessage(null);
    }
    const { value } = result || event.target;
    setFileOrigin(value);
  };

  const submitUploadsFromFile = async () => {
    fileUploadRef.current = null;
    const formData = new FormData();
    addAlert("processing", "We're processing your contacts", "info");
    setLoading(true);

    const failedUpload = () => {
      setUploadStatus("failedCSVUpload");
      toggleAddMultipleContactsModal();
      addAlert("failed", "Some of your contacts haven't uploaded.", "error");
      setFailure(true);
      setLoading(false);
    };

    const csvUpload = async () => {
      const reader = new FileReader();
      formData.append("file", fileForUpload, fileForUpload.name);
      try {
        window.plausible("Contact Upload", { props: { method: "CSV" } });
        reader.onload = async () => {
          let response;
          if (fileOrigin) {
            response = await postFileData(
              `/contacts/upload/csv?csv_source=${fileOrigin}`,
              formData
            );
          } else {
            try {
              response = await postFileData(`/contacts/upload/csv`, formData);
            } catch (err) {
              console.log(err);
              if (
                err.detail ===
                "CSVSource Enum is incorrectly set, received <enum 'CsvSource'>"
              ) {
                setFailureMessage(
                  "It looks like the CSV you uploaded has no headers. Please add some column information and try again!"
                );
              }
              setFailure(true);
              addAlert("failed", "CSV uploaded has no headers", "error");
            }
          }
          let successMessage = response?.background_uploading
            ? "We have successfully received your contacts. Whilst you continue to browse the platform, your contacts will upload in the background."
            : "You successfully updated your Revmo network.";
          toggleAddMultipleContactsModal();
          await getUserContacts();
          setUploadStatus({ status: "Success", message: successMessage });
          if (failure === false) {
            addAlert("success", "We have uploaded your contacts.", "success");
          }
          jumpPage(1);
        };
        reader.readAsText(fileForUpload);
        setLoading(false);
      } catch (err) {
        failedUpload();
        setFailure(true);
        addAlert("failed", "Some of your contacts haven't uploaded", "error");
      }
    };

    const connectionsUpload = async () => {
      const reader = new FileReader();
      formData.append("connections_file", fileForUpload, fileForUpload.name);
      try {
        window.plausible("Contact Upload", { props: { method: "Cxns" } });
        reader.onload = async () => {
          await postFileData("/profile/linkedin-connections", formData);
          toggleAddMultipleContactsModal();
          let successMessage = "Success uploading LinkedIn connections.";
          setUploadStatus({ status: "Success", message: successMessage });
          await getUserContacts();
          jumpPage(1);
        };
        reader.readAsText(fileForUpload);
        setLoading(false);
      } catch (err) {
        failedUpload();
      }
    };

    try {
      if (fileForUpload.name.toLowerCase().includes("revmo.csv")) {
        await csvUpload();
      } else if (fileForUpload.name.toLowerCase().includes("csv")) {
        await connectionsUpload();
      } else {
        failedUpload();
      }
    } catch (err) {
      failedUpload();
      console.log(err);
    }
  };

  return {
    failure,
    setFailure,
    failureMessage,
    fileUploadRef,
    loading,
    handleContactUploadsFromFile,
    handleChangeCSVDropdown,
    submitUploadsFromFile,
  };
}

export default useFileUpload;
