import React from "react";
import styled from "styled-components";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 3rem 0;
  padding: 0;
  max-width: 500px;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%auto;
  align-items: stretch;
  justify-content: space-between;
  border-bottom: 1px solid rgb(175, 175, 175);
  padding: 2rem 0;
`;

const TableCell = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  flex-basis: 50%;
  max-width: 50%;
`;

const TableCellContent = styled(TableCell)`
  flex-basis: 66.6667%;
  max-width: 66.66667%;
`;

function Table({ content }) {
  return (
    <TableWrapper>
      {content.map((item, index) => {
        return (
          <TableRow key={index}>
            <TableCell>
              <h3>{item.title}</h3>
            </TableCell>
            <TableCellContent>
              <p>{item.text}</p>
            </TableCellContent>
          </TableRow>
        );
      })}
    </TableWrapper>
  );
}

export default Table;
