import React from "react";
import styled from "styled-components";
import { HeaderText, MidLargeSpacedText } from "components/Typography";

const PageHeaderOuter = styled.div`
  background: #f7f6f8;
  padding: 45px 20px 45px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageHeaderInner = styled.div`
  width: 100%;
  max-width: 1100px;
  p {
    max-width: 562px;
  }
  button {
    margin-top: 27px;
    margin-bottom: 29px;
  }
`;

function PageHeader({ titleText, aboutText, children }) {
  return (
    <PageHeaderOuter>
      <PageHeaderInner>
        <HeaderText>{titleText}</HeaderText>
        <MidLargeSpacedText>{aboutText}</MidLargeSpacedText>
        {children}
      </PageHeaderInner>
    </PageHeaderOuter>
  );
}

export default PageHeader;
