import { fetchData } from "./";
import history from "../history";

const getContactByUUID = async (contactUUID) => {
  const contact = await fetchData(`/profile/${contactUUID}`);
  history.push({
    pathname: "/profile/contact",
    state: { contact, search: true },
  });
};

export default getContactByUUID;
