import styled, { css } from "styled-components";
import { ErrorMessage } from "components/Typography";

export const AdvancedSearchWrapper = styled.div`
  ${ErrorMessage} {
    margin-top: -22px;
  }
`;

export const InputName = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: ${(props) => props.theme.textColor};
  margin-bottom: 7px;
  ${(props) => {
    if (props.disabled) {
      return `
        color: #74757e;
      `;
    }
  }}
`;

export const AdvancedSearchInputContainer = styled.div`
  flex: 1;
  input {
    padding-left: 6px;
    font-size: 12px;
    text-overflow: ellipsis;
    background: transparent;
    border: unset;
    ${(props) =>
      props.error &&
      css`
        border: 1px solid #f9007a;
      `}
    color: #7c919e;
    box-sizing: border-box;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    ::placeholder {
      font-weight: 500;
      font-size: 12px;
      color: #7c919e;
    }
    :focus {
      outline: none;
    }
    ${(props) =>
      props.selected &&
      css`
        color: white;
      `}
  }
  @media (max-width: 870px) {
    max-width: calc(50% - 10px);
    min-width: calc(50% - 10px);
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    margin-bottom: 20px;
    width: 100%;
    max-width: calc(100%);
    min-width: calc(100%);
  }
  ${(props) => {
    if (props.lessWidth) {
      return `
        max-width: calc(14.6% - 7px);
        min-width: unset;
      `;
    }
    if (props.outsideSearch) {
      return `
        background: white;
        max-width: none;
        width: 100%;
        position: relative;
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 13px;
          position: absolute;
          top: 13px;
          left: 19px;
          z-index: 10;
          color: #313237;
        }
        div {
          width: 100%;
          height: 100%;
          padding-left: 0;
          input {
            padding: 24px 0 0 22px;
            overflow: hidden;
            white-space: nowrap;
            height: 54px;
            background: white;
            position: relative;
            font-style: normal;
            font-size: 15px;
            color: #313237;
            ::placeholder {
              font-style: normal;
              font-size: 17px;
              color: #a5a5a5;
            }
          }
        }
        @media (max-width: 870px) {
          max-width: 100%;
        }
      `;
    }
  }}
`;

export const InputWrapper = styled.div`
  background: #fcfcfc;
  border: 1px solid ${(props) => props.theme.borderColor};
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  height: 35px;
  padding-left: 8px;
  align-items: center;
  display: flex;
  ${(props) =>
    props.selected &&
    css`
      background: ${props.theme.brandColor};
    `}/* ${(props) => {
    if (props.company) {
      return `
        margin-left: 0;
      `;
    }
  }} */
`;

export const InputWrapperTickbox = styled(InputWrapper)`
  padding-right: 10px;
`;

export { default as AdvancedSearchCustomDropdown } from "./AdvancedSearchCustomDropdown";
export { default as AdvancedSearchCompanySize } from "./AdvancedSearchCompanySize";
export { default as AdvancedSearchTickbox } from "./AdvancedSearchTickbox";
export { default as AdvancedSearchIndustryHierarchy } from "./AdvancedSearchIndustryHierarchy";
