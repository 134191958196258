import React, { useState } from "react";
import {
  HeaderText,
  MidLargeSpacedText,
  ExtraSmallBlackText,
  GreyLinkText,
} from "components/Typography";
import { Slide } from "react-awesome-reveal";
import { AuthButtonNoArrow } from "components/Button";
import { WhiteLargeFormInput } from "components/Input";
import PlacesAutocomplete from "components/PlacesAutocomplete";
import {
  OnboardingForm,
  HeaderContainer,
  FooterWrapper,
  InformationTitleContainerProfessionalData,
  OnboardingPreviousPage,
} from "components/Onboarding";
import { useResetFormData } from "../utils";
import { OnboardingProgress } from ".";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { professionalDataSchemaPart2 } from "../utils";

function OnboardingLocationData({
  onboardingFields,
  handleChangeOnboardingLocationData,
  prevPage,
  userAddress,
  setUserAddress,
  currentOnboardingPage,
  customFormErrorsHook,
  diallingCodesHook,
}) {
  const { handleSubmit, reset } = useForm({
    resolver: yupResolver(professionalDataSchemaPart2),
  });

  // load in loadOnboardingFields[personalData] with a useEffectHook
  const [locationError, setLocationError] = useState(false);

  useResetFormData(
    reset,
    onboardingFields,
    currentOnboardingPage,
    diallingCodesHook
  );
  //has a useEffectHook in it

  return (
    <Slide right>
      <HeaderContainer>
        <OnboardingProgress activeStep={2} />
        <HeaderText>Where are you located?</HeaderText>
        <MidLargeSpacedText>
          We'll use this to help you connect with others near you.
        </MidLargeSpacedText>
      </HeaderContainer>
      <InformationTitleContainerProfessionalData>
        <ExtraSmallBlackText>*Denotes required field</ExtraSmallBlackText>
      </InformationTitleContainerProfessionalData>
      <OnboardingForm
        onSubmit={handleSubmit(handleChangeOnboardingLocationData)}
      >
        <PlacesAutocomplete
          component={WhiteLargeFormInput}
          useCase="userPersonal"
          name="location"
          fieldName="Location*"
          placesAddress={userAddress}
          setPlacesAddress={setUserAddress}
          resetValue={userAddress.location}
          customFormErrorsHook={customFormErrorsHook}
          outsideSearch={true}
          error={locationError}
          setError={setLocationError}
        />
        <FooterWrapper>
          <AuthButtonNoArrow
            buttonText={"Continue"}
            padding={"11px 30px"}
            type="submit"
          />
          <OnboardingPreviousPage onClick={prevPage}>
            <GreyLinkText>Back</GreyLinkText>
          </OnboardingPreviousPage>
        </FooterWrapper>
      </OnboardingForm>
    </Slide>
  );
}

export default OnboardingLocationData;
