import { LandingFooter } from "components/LandingPage";
import { PageWrapperMaxContentWidth } from "components/Layout";
import { LandingNavbar } from "components/Navbar";
import { usePageTitle } from "hooks";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import sanityClient from "../../client";
import { ArticleOverview, PressHeader } from "./components";

const PageWrapperFullWidth = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  align-items: center;
`;

function PressSection() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "article"]{
        title,
        author,
        body,
        slug,
        blurb,
        publishedAt,
        url,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    } | order(publishedAt desc)`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  usePageTitle("Press | Revmo");
  return (
    <PageWrapperFullWidth>
      <LandingNavbar />
      <PressHeader />
      <PageWrapperMaxContentWidth>
        <ArticleOverview articles={allPostsData} />
      </PageWrapperMaxContentWidth>
      <LandingFooter position={"fixed"} />
    </PageWrapperFullWidth>
  );
}

export default PressSection;
