import React from "react";
import { LargeBoldText, MidLargeSpacedText } from "../Typography";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  AddContactsContent,
  ProfileModalContainerSuccess,
  SuccessModalHeader,
  SuccessIconAndTextWrapper,
} from "./index";
import { ButtonPrimaryWithArrow } from "../Button";
import closeIcon from "../../assets/icons/close-icon.svg";

export function FileTooLargeModal({ displayModal, setDisplayModal }) {
  return (
    <>
      {displayModal && (
        <ProfileModalPageWrapper>
          <DarkBlurredBackground onClick={() => setDisplayModal(null)} />
          <ProfileModalContainerSuccess>
            <CloseIconImage
              src={closeIcon}
              onClick={() => setDisplayModal(null)}
            />
            <AddContactsContent>
              <SuccessModalHeader>
                <SuccessIconAndTextWrapper>
                  <LargeBoldText>
                    Unfortunately, the image is too large
                  </LargeBoldText>
                </SuccessIconAndTextWrapper>
                <MidLargeSpacedText>
                  Please upload an image less than 3MB
                </MidLargeSpacedText>
              </SuccessModalHeader>
              <ButtonPrimaryWithArrow
                disabled={false}
                padding={"11px 27px"}
                buttonText={"Continue"}
                action={() => setDisplayModal(null)}
              />
            </AddContactsContent>
          </ProfileModalContainerSuccess>
        </ProfileModalPageWrapper>
      )}
    </>
  );
}
