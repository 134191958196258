import styled from "styled-components";

export const SettingsOptionsTypeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 44px;
  position: relative;
  p {
    margin-left: 33px;
    cursor: pointer;
    width: max-content;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SettingsSectionRowBorder = styled.div`
  border-top: 1px solid #e3e6e9;
  padding: 40px 0 45px 0;
`;
