import React from "react";
import { LargeBoldText, MidLargeSpacedText } from "../Typography";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  AddContactsContent,
  ProfileModalContainerSuccess,
} from "./index";
import { ButtonPrimaryWithArrow } from "../Button";
import closeIcon from "../../assets/icons/close-icon.svg";
import styled from "styled-components";
import tickGreen from "../../assets/icons/tick-green-flatter.svg";

const SuccessModalHeader = styled.div`
  margin-bottom: 40px;
`;

const SuccessIconAndTextWrapper = styled.div`
  display: flex;
  margin-bottom: 29px;
  align-items: center;
`;

const GreenCircleTickIcon = styled.div`
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border-radius: 100%;
  border: 3px solid #18bfa1;
  img {
    width: 21px;
    height: 15px;
  }
`;

export function FeedbackModal({ displayModal, setDisplayModal }) {
  return (
    <>
      {displayModal && (
        <ProfileModalPageWrapper>
          <DarkBlurredBackground onClick={() => setDisplayModal()} />
          <ProfileModalContainerSuccess>
            <CloseIconImage src={closeIcon} onClick={() => setDisplayModal()} />
            <AddContactsContent>
              <SuccessModalHeader>
                <SuccessIconAndTextWrapper>
                  <GreenCircleTickIcon>
                    <img src={tickGreen} />
                  </GreenCircleTickIcon>
                  <LargeBoldText>Thank you</LargeBoldText>
                </SuccessIconAndTextWrapper>
                <MidLargeSpacedText>
                  Your input has been successfully logged. We appreciate your
                  input and feedback
                </MidLargeSpacedText>
              </SuccessModalHeader>
              <ButtonPrimaryWithArrow
                disabled={false}
                padding={"11px 27px"}
                buttonText={"Continue"}
                action={() => setDisplayModal()}
              />
            </AddContactsContent>
          </ProfileModalContainerSuccess>
        </ProfileModalPageWrapper>
      )}
    </>
  );
}
