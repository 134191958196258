import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { WhiteLargeFormInputHooksForm } from "components/Input";
import { AuthButton } from "components/Button";
import {
  HeaderText,
  BlueLinkTextUnderlined,
  BlueLinkText,
  AuthText,
} from "components/Typography";
import { AuthForm, AuthFormWrapper } from "components/Auth";
import { useForm } from "react-hook-form";
import { referralCodeSchema } from "../utils";
import { yupResolver } from "@hookform/resolvers/yup";

const AuthFormWrapperCheckReferral = styled(AuthFormWrapper)`
  padding-bottom: 25px;
  h1 {
    margin-bottom: 20px;
    + p {
      margin-bottom: 5px;
    }
  }
`;

const GetReferralCodeRow = styled.div`
  margin-top: 15px;
  width: 100%;
  div {
    display: flex;
  }
  padding-bottom: 25px;
`;

function CheckReferralCode({
  checkReferralCode,
  getAccessCode,
  alertMessage,
  getLocation,
}) {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(referralCodeSchema),
    defaultValues: {
      referralCode: getLocation(),
    },
  });

  if (alertMessage) {
    errors.referralCode = {};
    errors.referralCode.message = alertMessage;
  }

  return (
    <AuthFormWrapperCheckReferral>
      <HeaderText>Sign up for Revmo</HeaderText>
      <AuthText>
        Please enter your Referral Code to sign up for the Revmo Beta.
      </AuthText>
      <AuthForm>
        <WhiteLargeFormInputHooksForm
          fieldName="Referral Code"
          register={register}
          error={errors}
          name="referralCode"
          bold
        />
        <AuthButton onClick={handleSubmit(checkReferralCode)}>
          Sign up
        </AuthButton>
      </AuthForm>
      <GetReferralCodeRow>
        <AuthText>If you do not have a Referral Code, you can</AuthText>
        <div>
          <AuthText>request one&nbsp;</AuthText>
          <BlueLinkTextUnderlined onClick={getAccessCode}>
            here
          </BlueLinkTextUnderlined>
        </div>
      </GetReferralCodeRow>
      <Link to="/login">
        <BlueLinkText>Already have an account? Log in</BlueLinkText>
      </Link>
    </AuthFormWrapperCheckReferral>
  );
}

export default CheckReferralCode;
