import React from "react";
import styled from "styled-components";
import { HeaderText, MidLargeSpacedText } from "components/Typography";
import { AuthButtonWithArrow } from "components/Button";
import PrivacyPolicyTerms from "components/PrivacyPolicyTerms";

const GreyHeaderPolicy = styled.div`
  padding: 0px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const GreyHeaderPolicyInner = styled.div`
  width: 100%;
  max-width: 1140px;
  padding: 0 20px;
`;

const PolicyBody = styled.div`
  align-self: center;
  position: relative;
  margin-top: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  div {
    width: 100%;
    div {
      box-shadow: 1px 1px 8px 2px rgba(155, 155, 155, 0.18) inset;
      max-height: 70vh;
      height: 65vh;
      width: 100%;
      overflow: hidden;
      background: white;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  button {
    div {
      height: unset;
      background: unset;
    }
  }
`;

const PrivacyPolicyFooterWrapper = styled.div`
  display: flex;
  max-width: 1100px;
  width: 100%;
  align-self: center;
  padding: 0;
  margin-top: 40px;
`;

function PrivacyPolicy({ handleSubmitOnboarding }) {
  return (
    <>
      <GreyHeaderPolicy>
        <GreyHeaderPolicyInner>
          <HeaderText>Privacy at Revmo</HeaderText>
          <MidLargeSpacedText>
            Please find our full privacy policy below:
          </MidLargeSpacedText>
        </GreyHeaderPolicyInner>
      </GreyHeaderPolicy>

      <PolicyBody>
        <PrivacyPolicyTerms />
        <PrivacyPolicyFooter handleSubmitOnboarding={handleSubmitOnboarding} />
      </PolicyBody>
    </>
  );
}

function PrivacyPolicyFooter({ handleSubmitOnboarding }) {
  return (
    <PrivacyPolicyFooterWrapper>
      <AuthButtonWithArrow
        buttonText="Accept"
        padding={"11px 30px"}
        disabled={false}
        action={() => handleSubmitOnboarding()}
      />
    </PrivacyPolicyFooterWrapper>
  );
}

export default PrivacyPolicy;
