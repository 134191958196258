import React from "react";
import styled from "styled-components";
import userImageWrapper from "../../assets/icons/user-image-wrapper.svg";

const UserImageWrapper = styled.div`
  height: 166px;
  width: 166px;
  ${(props) => {
    if (props.onboarding) {
      return `
      height: 120px;
      width: 120px;
      `;
    }
  }}
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 9px;
  @media (max-width: 480px) {
    height: 100px;
    width: 100px;
  }
  div {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #d6d5dc;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 70%;
      width: 100%;
      fill: white;
    }
  }
`;

const UserImageBlueBackground = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
`;

const UserProfileImage = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  display: flex;
  object-fit: cover;
`;

export function UserImageBlueBorder({ userProfileImage, onboarding }) {
  return (
    <UserImageWrapper onboarding={onboarding}>
      <UserImageBlueBackground src={userImageWrapper} />
      {userProfileImage ? (
        <UserProfileImage src={userProfileImage} />
      ) : (
        <div>
          <AnonymousUserSVG />
        </div>
      )}
    </UserImageWrapper>
  );
}

const AnonymousUserIconWrapper = styled.div`
  height: 45px;
  min-width: 45px;
  background: #d6d5dc;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-top: 2px;
    height: 32px;
    min-width: 32px;
    fill: white;
  }
`;

export function AnonymousUserSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148    C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962    c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216    h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40    c59.551,0,108,48.448,108,108S315.551,256,256,256z" />
        </g>
      </g>
    </svg>
  );
}

export function AnonymousUser() {
  return (
    <AnonymousUserIconWrapper>
      <AnonymousUserSVG />
    </AnonymousUserIconWrapper>
  );
}

const AnonymousUserIconWrapperSearch = styled(AnonymousUserIconWrapper)`
  margin-top: 6px;
`;

export function AnonymousUserSearch() {
  return (
    <AnonymousUserIconWrapperSearch>
      <AnonymousUserSVG />
    </AnonymousUserIconWrapperSearch>
  );
}

const AnonymousUserIconWrapperLarge = styled(AnonymousUserIconWrapper)`
  height: 56px;
  width: 56px;
  padding: unset;
  svg {
    height: 39px;
    width: 39px;
    fill: white;
  }
`;

export function AnonymousUserLarge() {
  return (
    <AnonymousUserIconWrapperLarge>
      <AnonymousUserSVG />
    </AnonymousUserIconWrapperLarge>
  );
}

const AnonymousUserIconWrapperRequestsTable = styled(AnonymousUserIconWrapper)`
  height: 60px;
  min-width: 60px;
  svg {
    height: 42px;
    width: 42px;
    fill: white;
  }
  @media (max-width: 480px) {
    height: 54px;
    min-width: 54px;
    svg {
      height: 38px;
      width: 38px;
    }
  }
`;

export function AnonymousUserRequestsTable() {
  return (
    <AnonymousUserIconWrapperRequestsTable>
      <AnonymousUserSVG />
    </AnonymousUserIconWrapperRequestsTable>
  );
}

export const AnonymousUserIconPersonCard = styled(AnonymousUserIconWrapper)`
  height: 8vw;
  min-width: 8vw;
  svg {
    margin-top: 0.8vw;
    height: 5.6vw;
    min-width: 5.6vw;
  }
  @media (max-width: 670px) {
    height: 56px;
    min-width: 56px;
    svg {
      height: 39px;
      min-width: 39px;
    }
  }
`;

export function AnonymousUserPersonCard() {
  return (
    <AnonymousUserIconPersonCard>
      <AnonymousUserSVG />
    </AnonymousUserIconPersonCard>
  );
}
