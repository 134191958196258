import React, { useMemo, useCallback, useEffect } from "react";
import { useReducer, createContext } from "react";
import { fetchData } from "utils";
import { Auth } from "aws-amplify";
import { revmoApiUrl } from "../config";

export const ProfileContext = createContext();

const initialState = {};

const CREATE_USER_PROFILE = "CREATE_USER_PROFILE";
const GET_USER_PROFILE = "GET_USER_PROFILE";
const UPDATE_USER_PROFILE = "UPDATED_USER_PROFILE";

export const ProfileProvider = ({ children }) => {
  const profileReducer = (state, action) => {
    switch (action.type) {
      case CREATE_USER_PROFILE: {
        return action.profile;
      }
      case GET_USER_PROFILE: {
        return action.profile;
      }
      case UPDATE_USER_PROFILE: {
        return action.profile;
      }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(profileReducer, initialState);

  const getUserProfile = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      if (!cognitoUser.attributes["custom:onboarding_completed"]) return null;
      const profile = await fetchData(`/profile/me`);
      profile.profile_image =
        revmoApiUrl + `/images/profile_images/${profile.uuid}`;
      if (profile) {
        dispatch({ type: GET_USER_PROFILE, profile });
        return profile;
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const createUserProfile = async (profile) => {
    dispatch({ type: CREATE_USER_PROFILE, profile });
  };

  const updateUserProfile = useCallback(async (profile) => {
    dispatch({ type: UPDATE_USER_PROFILE, profile });
  }, []);

  const values = useMemo(
    () => ({
      profile: state || {},
      dispatch,
      updateUserProfile,
      createUserProfile,
      getUserProfile,
    }),
    [state, getUserProfile, updateUserProfile]
  );

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  return (
    <ProfileContext.Provider value={values}>{children}</ProfileContext.Provider>
  );
};

export default ProfileProvider;
