import React, { useState } from "react";
import {
  OnboardingCompanyData,
  OnboardingCompanyAddContacts,
} from "./components";
import { OnboardingBody, InternalContainer } from "components/Onboarding";

function OnboardingCorporate() {
  const [currentOnboardingPage, setCurrentOnboardingPage] = useState(1);

  const handleChangeCompanyData = () => {
    setCurrentOnboardingPage(currentOnboardingPage + 1);
  };

  return (
    <>
      <OnboardingBody>
        <InternalContainer>
          {currentOnboardingPage === 1 && (
            <OnboardingCompanyData
              handleChangeCompanyData={handleChangeCompanyData}
            />
          )}
          {currentOnboardingPage === 2 && (
            <OnboardingCompanyAddContacts
              handleChangeCompanyData={handleChangeCompanyData}
            />
          )}
        </InternalContainer>
      </OnboardingBody>
    </>
  );
}

export default OnboardingCorporate;
