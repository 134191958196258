import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Button, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

// project imports
import { gridSpacing } from "containers/DashboardNew/theme";

// assets
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BlockTwoToneIcon from "@mui/icons-material/BlockTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { revmoApiUrl } from "../../config";

const UserContactCard = ({
  contact,
  index,
  displayEditPositionModal,
  deleteContact,
}) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Card
      sx={{
        m: 2,
        borderRadius: "10px",
        border: theme.palette.mode === "dark" ? "none" : "1px solid",
        borderColor: theme.palette.grey[100],
        boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
      }}
    >
      <CardHeader
        sx={{
          height: "100px",
          p: 2,
          overflow: "hidden",
          "& .MuiCardHeader-content": {
            overflow: "hidden",
          },
        }}
        disableTypography
        avatar={
          <Avatar
            aria-label="profile image"
            src={revmoApiUrl + `/images/profile_images/${contact.uuid}` ?? ""}
            sx={{ width: { sm: 60, xs: 50 }, height: { sm: 60, xs: 50 } }}
          />
        }
        title={
          <Typography gutterBottom variant="h6" component="div">
            {contact.first_name + " " + contact.last_name}
          </Typography>
        }
        subheader={
          <Typography
            variant="body2"
            component="div"
            sx={{ fontSize: "1.15rem" }}
            noWrap
          >
            {contact?.positions[0]?.role_description
              ? `${contact?.positions[0]?.role_description} @ ${contact?.positions[0]?.org?.org_name}`
              : ""}
          </Typography>
        }
      />
      <CardContent
        sx={{
          bgcolor: theme.palette.grey[100],
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: theme.palette.grey[300],
        }}
      >
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} sm={contact?.telephone_number ? 6 : 12}>
            <Grid container spacing={1} sx={{ p: 1 }}>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ fontSize: "1.15rem" }}
                >
                  Email
                </Typography>
                <Typography
                  variant="subtitle2"
                  align="center"
                  sx={{ fontSize: "1.15rem" }}
                >
                  {contact?.personal_email_addresses[0]?.email_address}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {contact?.telephone_number && (
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1} sx={{ p: 1 }}>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{ fontSize: "1.15rem" }}
                  >
                    Phone Number
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="center"
                    sx={{ fontSize: "1.15rem" }}
                  >
                    {contact?.telephone_number?.national_number}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions sx={{ p: 2 }}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  sx={{ width: "100%", minHeight: "40px" }}
                  onClick={() =>
                    history.push({
                      pathname: "/profile/contact",
                      state: { contact },
                    })
                  }
                  startIcon={<RemoveRedEyeIcon />}
                >
                  View
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ width: "100%", minHeight: "40px" }}
                  onClick={() => displayEditPositionModal(index, contact)}
                  startIcon={<EditTwoToneIcon />}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ width: "100%", minHeight: "40px" }}
                  onClick={() => deleteContact(contact.uuid, index)}
                  startIcon={<BlockTwoToneIcon />}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

UserContactCard.propTypes = {
  contact: PropTypes.object,
  index: PropTypes.number,
  displayEditPositionModal: PropTypes.func,
  deleteContact: PropTypes.func,
};

export default UserContactCard;
