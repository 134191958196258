import * as yup from "yup";

const addOrEditContactSchema = yup.object().shape(
  {
    firstName: yup.string().required("Please enter contact's first name"),
    lastName: yup.string().required("Please enter contact's last name"),
    company: yup
      .object()
      .required()
      .typeError("Please enter contact's place of work"),
    position: yup.string().required("Please enter contact's current role"),
    personalPhoneNumber: yup.string().matches(/[0-9]{0,14}$/, {
      message: "Please enter a valid international number",
      excludeEmptyString: true,
    }),
    companyPhoneNumber: yup.string().matches(/[0-9]{0,14}$/, {
      message: "Please enter a valid international number",
      excludeEmptyString: true,
    }),
    personalEmail: yup
      .string()
      .when("companyEmail", {
        is: (companyEmail) => !companyEmail || companyEmail.length === 0,
        then: yup.string().required("At least one email address is required"),
      })
      .matches(/^\S+@\S+\.\S+$/, {
        message: "Please enter a valid email address",
        excludeEmptyString: true,
      }),
    companyEmail: yup
      .string()
      .when("personalEmail", {
        is: (personalEmail) => !personalEmail || personalEmail.length === 0,
        then: yup.string().required("At least one email address is required"),
      })
      .matches(/^\S+@\S+\.\S+$/, {
        message: "Please enter a valid email address",
        excludeEmptyString: true,
      }),
  },
  [["companyEmail", "personalEmail"]]
);

export default addOrEditContactSchema;
