import React from "react";
import styled from "styled-components";
import { Alert, Fade } from "@mui/material";
import { useAlert } from "hooks";

export const AlertContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 80px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 50;
`;

function Alerts() {
  const { alertsList, closeAlert } = useAlert();

  return (
    <AlertContainer className="alert-container">
      {alertsList?.map((alert) => {
        return (
          <Fade key={alert?.id} in={true}>
            <Alert
              key={alert?.id}
              className="alert-list"
              severity={alert?.severity ?? "info"}
              onClose={() => {
                closeAlert(alert);
              }}
            >
              {alert?.text}
            </Alert>
          </Fade>
        );
      })}
    </AlertContainer>
  );
}

export default Alerts;
