import preval from "preval.macro";

const buildDate = preval`module.exports = new Date().toISOString()`;
console.info("Build timestamp", buildDate);
console.info("Configuration ID:", process.env.REACT_APP_DESC || "< not set >");

export const awsauthconfig = {
  region: process.env.REACT_APP_COGNITO_REGION,
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID, // 'us-east-2:' followed by GUID
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID, //'XX-XXXX-X_abcd1234',
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
  federationTarget: "COGNITO_USER_POOLS",
};

export const revmoApiUrl = process.env.REACT_APP_REVMO_API_URI;
export const stripeApiPublicKey = process.env.REACT_APP_STRIPE_API_PUBLIC_KEY;
