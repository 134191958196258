import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useUser } from "hooks";

const PrivateRoute = ({ path, exact, children }) => {
  let { user } = useUser();
  let restricted = false;
  let exactPath = exact ? exact : false;

  if (
    user &&
    user?.attributes &&
    !user?.attributes["custom:onboarding_completed"] &&
    path !== "/onboarding"
  ) {
    return <Redirect to="/onboarding" />;
  } else if (
    user &&
    user?.attributes &&
    user?.attributes["custom:onboarding_completed"] &&
    path === "/onboarding"
  ) {
    return <Redirect to="/" />;
  } else if (user === undefined) {
    return null;
  }

  return (
    <Route
      exact={exactPath}
      path={path}
      render={() => (!restricted && user ? children : <Redirect to="/" />)}
    />
  );
};

export default PrivateRoute;
