import React, { useState } from "react";
import { SmallBlackBoldText, ExtraSmallBlackText } from "../Typography";
import {
  ProfileModalPageWrapper,
  ProfileModalContainer,
  DarkBlurredBackground,
  CloseIconImage,
  EditProfileContent,
  LargeBoldTextEditProfile,
  AddNewPositionHeader,
  EditPositionModalInputWrapper,
} from "./index";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  WhiteLargeFormInputHooksForm,
  DateSelect,
  WhiteLargeFormInput,
} from "../Input";
import PlacesAutocomplete from "components/PlacesAutocomplete";
import { OrgsAutocomplete } from "components/Autocomplete";
import closeIcon from "../../assets/icons/close-icon.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addOrEditPositionDataSchema } from "containers/Profile/utils";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { months } from "utils";

export function EditPositionModal({
  setDisplayModal,
  job,
  name,
  index,
  handleSubmitUpdatePosition,
  diallingCodesHook,
  customFormErrorsHook,
}) {
  const [checked, setChecked] = useState(job?.is_active);
  const [contactAddress, setContactAddress] = useState({});

  const getMonthFromDefault = (attribute) => {
    if (job[attribute]) {
      const monthsFiltered = months.filter(function (el) {
        return el.value == job[attribute].slice(5, 7);
      });
      return monthsFiltered[0];
    }
    return null;
  };
  const userValues = {
    companyPhoneNumber: job.telephone_number
      ? parseInt(job.telephone_number.national_number)
      : null,
    position: job.role_description,
    location: job.location,
    company: job.org,
    email: job?.company_email_addresses
      ? job?.company_email_addresses[0]?.email_address
      : null,
    startMonth: getMonthFromDefault("start_date"),
    endMonth: getMonthFromDefault("end_date"),
    startYear: job?.start_date ? job?.start_date.slice(0, 4) : null,
    endYear: job?.end_date ? job?.end_date.slice(0, 4) : null,
  };

  const { control, register, handleSubmit, errors } = useForm({
    defaultValues: userValues,
    resolver: yupResolver(addOrEditPositionDataSchema),
  });

  const { diallingCode } = diallingCodesHook;

  const handleSelectIsCurrentRole = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <ProfileModalPageWrapper displayModal={true}>
        <DarkBlurredBackground
          onClick={() => {
            setDisplayModal(index);
          }}
        />
        <ProfileModalContainer>
          <CloseIconImage
            src={closeIcon}
            onClick={() => setDisplayModal(index)}
          />
          <EditProfileContent>
            <LargeBoldTextEditProfile>{name}</LargeBoldTextEditProfile>
            <AddNewPositionHeader>
              <SmallBlackBoldText>Position details</SmallBlackBoldText>
              <ExtraSmallBlackText>*Denotes required field</ExtraSmallBlackText>
            </AddNewPositionHeader>
            <EditPositionModalInputWrapper>
              <WhiteLargeFormInputHooksForm
                fieldName="Position*"
                name="position"
                error={errors}
                register={register}
              />
              <OrgsAutocomplete
                name="company"
                control={control}
                errors={errors}
              />
              <PlacesAutocomplete
                component={WhiteLargeFormInput}
                useCase="userProfessional"
                fieldName="Location"
                placesAddress={contactAddress}
                setPlacesAddress={setContactAddress}
                customFormErrorsHook={customFormErrorsHook}
                outsideSearch={true}
              />
              <WhiteLargeFormInputHooksForm
                fieldName="Email"
                name="email"
                error={errors}
                register={register}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    color="secondary"
                    size="medium"
                    onChange={handleSelectIsCurrentRole}
                  />
                }
                label="I am currently working in this role"
              />
              <DateSelect
                control={control}
                type={"start"}
                title={"Start Date"}
              />
              {!checked && (
                <DateSelect control={control} type={"end"} title="End date" />
              )}
            </EditPositionModalInputWrapper>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "100%" }}
              onClick={handleSubmit((data) => {
                handleSubmitUpdatePosition(
                  data,
                  job,
                  setDisplayModal,
                  diallingCode,
                  contactAddress
                );
              })}
              endIcon={<ArrowForwardIcon />}
            >
              Update Position
            </Button>
          </EditProfileContent>
        </ProfileModalContainer>
      </ProfileModalPageWrapper>
    </>
  );
}
