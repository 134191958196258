import { useState } from "react";

export const useUpdateRequests = () => {
  const [updateRequest, setShowUpdateRequest] = useState({
    showMenu: false,
    request: {},
  });

  const toggleUpdateRequestMenu = () => {
    setShowUpdateRequest({
      showMenu: false,
      request: {},
    });
  };

  const displayMenuToUpdateRequest = (request) => {
    setShowUpdateRequest({
      showMenu: true,
      request,
    });
  };

  return {
    displayMenuToUpdateRequest,
    updateRequest,
    toggleUpdateRequestMenu,
  };
};

export const findNumberOfRequests = (requests) => {
  let numberOfIntroRequestsAccepted = !requests?.COMPLETED
    ? 0
    : requests?.COMPLETED?.length;
  let numberOfIntroRequestsPending = !requests?.PENDING
    ? 0
    : requests?.PENDING?.length;
  let numberOfIntroRequestsDeclined = !requests?.REJECTED
    ? 0
    : requests?.REJECTED?.length;

  return {
    numberOfIntroRequestsAccepted,
    numberOfIntroRequestsPending,
    numberOfIntroRequestsDeclined,
  };
};
