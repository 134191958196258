import React from "react";
import { SimpleNav } from "components/Navbar";
import { useUser } from "hooks";
import styled from "styled-components";

const OnboardNavWrapper = styled.div`
  position: relative;
`;

const LogoutButton = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background: unset;
  border: unset;
  color: white;
  font-weight: bold;
  font-size: 17px;
  cursor: pointer;
`;

function OnboardingNav() {
  const { logout } = useUser();
  return (
    <OnboardNavWrapper>
      <SimpleNav />
      <LogoutButton onClick={logout}>Logout</LogoutButton>
    </OnboardNavWrapper>
  );
}

export default OnboardingNav;
