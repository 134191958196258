import csvtojson from "csvtojson";
import { formatManualContactUpload, putData, postData } from "utils";
export const checkIfFieldExists = (contactField, fieldName) => {
  if (contactField.length) {
    return contactField;
  } else {
    return fieldName;
  }
};

export const getNumberOfResults = (contactsAll) =>
  contactsAll?.verified?.length ? contactsAll.verified.length : null;

export const checkCSV = async (reader) => {
  try {
    const jsonArray = await csvtojson().fromString(reader.result);
    let error = false;
    [...jsonArray].forEach((row) => {
      if (
        row.first_name === "" ||
        row.last_name === "" ||
        row.org_1_name === "" ||
        row.org_1_role_1_description === ""
      ) {
        error = true;
      }
    });
    return error;
  } catch (err) {
    console.log(err);
  }
};

export const checkCareer = (field, career) => {
  const matchedField = career.filter((index) => {
    if (index[field]) {
      return index[field];
    }
  });
  return matchedField[0] ? matchedField[0][field] : null;
};

export const editSingleContact = async (
  data,
  contactAddress,
  contactDetails,
  diallingCode
) => {
  const contactData = formatManualContactUpload(
    data,
    contactAddress,
    diallingCode
  );
  await putData(
    `/contacts/${contactDetails.uuid}`,
    JSON.stringify(contactData[0])
  );
  return contactData;
};

export const checkIfUserIsSelected = (selectedContacts, contact) => {
  const selectedContactId = selectedContacts.filter((selectedContact) => {
    if (selectedContact.uuid === contact.uuid) {
      return selectedContact;
    }
  });
  if (selectedContactId.length) {
    return true;
  } else {
    return false;
  }
};

export const numberToSkip = (currentPage = 1, itemsPerPage = 10) => {
  if (currentPage === 1) {
    return 0;
  } else {
    return (currentPage - 1) * itemsPerPage;
  }
};
const createSingleContact = (contact) => {
  const { first_name, last_name, job_title, email, companies } = contact;
  const userContact = {
    first_name,
    last_name,
    positions: [],
  };
  const personal_emails = [];
  const work_emails = [];
  email.forEach((e) => {
    if (e.type == "email_personal") {
      personal_emails.push({ email_address: e.address });
    }
    if (e.type == "email_work") {
      work_emails.push({ email_address: e.address });
    }
  });
  userContact.personal_email_addresses = personal_emails;
  if (job_title) {
    let positions = [
      {
        org: { org_name: companies[0] },
        role_description: job_title,
        company_email_addresses: [work_emails[0]],
        role: [
          {
            role_name: job_title,
          },
        ],
      },
    ];
    userContact.positions = positions;
  }
  return userContact;
};
export const submitUserContactsFromCloudsponge = async (contacts, addAlert) => {
  try {
    const contactsWithoutNames = [];
    const formattedContactsWithNames = [];

    contacts.forEach((contact) => {
      const userContact = createSingleContact(contact);
      if (userContact.first_name && userContact.last_name) {
        formattedContactsWithNames.push(userContact);
      } else {
        contactsWithoutNames.push(
          userContact.personal_email_addresses[0].email_address
        );
      }
    });
    if (formattedContactsWithNames.length) {
      await postData(
        `/contacts/?batch_size=${formattedContactsWithNames.length}`,
        JSON.stringify(formattedContactsWithNames)
      );
      addAlert(
        "processing",
        "We are processing your contacts. Check back soon!",
        "info"
      );
      return { formattedContactsWithNames, contactsWithoutNames };
    } else if (
      !formattedContactsWithNames.length &&
      contactsWithoutNames.length
    ) {
      return { contactsWithoutNames, formattedContactsWithNames: [] };
    }
  } catch (err) {
    console.log(err);
    addAlert("failure", "We couldn't process your contacts", "error");
  }
};
