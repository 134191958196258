import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { LandingRevmoLogo } from "components/Assets";

const LandingFooterWrapper = styled.footer`
  border-top: 1px solid #efeffd;
  background-color: white;
  padding: 56px 130px;
  color: white;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  @media (max-width: 1099px) {
    padding: 56px 102px;
  }
  @media (max-width: 767px) {
    padding: 56px 35px;
  }
`;

const LandingRevmoLogoWrapper = styled.div`
  max-width: 125px;
  padding-right: 2rem;
  img {
    overflow: hidden;
    width: 100%;
  }
  @media (max-width: 870px) {
    width: 100%;
    display: flex;
    align-items: flex-start;
    img {
      width: 100%;
      max-height: 100%;
    }
  }
`;

const LandingFooterContent = styled.div`
  max-width: 1071px;
  min-height: 200px;
  width: 100%;
  @media (max-width: 767px) {
    max-width: 360px;
  }
  @media (max-width: 1099px) {
    min-height: unset;
    max-width: 895px;
  }
`;

const LandingFooterGrid = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 28px;
    padding: 0 13px;
  }
`;

const LandingFooterLinks = styled.div`
  display: grid;
  column-gap: 96px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1099px) {
    margin-bottom: 44px;
  }
  @media (max-width: 767px) {
    column-gap: 0;
    grid-template-columns: 1fr;
    margin: 75px 0;
    row-gap: 42px;
    width: 100%;
  }
`;

const LandingFooterLinkSection = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-weight: bold;
    font-family: Montserrat, sans-serif;
  }
  a {
    text-decoration: none;
    color: #1f1f30;
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    :hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 767px) {
    p {
      border-bottom: 1px solid #efeffd;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 28px;
      margin-bottom: 16px;
      padding-bottom: 10px;
    }
    a {
      line-height: 28px;
      margin-bottom: 16px;
      color: #000a33;
    }
  }
`;

const LandingFooter = ({ position }) => {
  return (
    <LandingFooterWrapper position={position}>
      <LandingFooterContent>
        <LandingFooterGrid>
          <LandingRevmoLogoWrapper>
            <Link to="/">
              <LandingRevmoLogo />
            </Link>
          </LandingRevmoLogoWrapper>
          <LandingFooterLinks>
            <LandingFooterLinkSection>
              <p>Accounts</p>
              <Link to="/sign-up">Get Started</Link>
              <Link to="/login">Login</Link>
              <Link
                to={{ pathname: "https://calendly.com/margot-57/30min" }}
                target="_blank"
              >
                Book a Demo
              </Link>
            </LandingFooterLinkSection>
            <LandingFooterLinkSection>
              <p>Support</p>
              <a href="mailto:support@revmo.app">Contact Us</a>
              <Link to="/support">Help Center</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </LandingFooterLinkSection>
            <LandingFooterLinkSection>
              <p>Company</p>
              <Link to="/">About</Link>
              <Link to="/updates">Product Updates</Link>
              <Link to="/blog">Blog</Link>
              <Link to="/careers">Careers</Link>
              <Link to="/press">Press</Link>
            </LandingFooterLinkSection>
          </LandingFooterLinks>
        </LandingFooterGrid>
      </LandingFooterContent>
    </LandingFooterWrapper>
  );
};

export default LandingFooter;
