import React from "react";
import styled from "styled-components";

import { useImageUpload } from "hooks";
import Button from "@mui/material/Button";
import {
  ProfileModalPageWrapper,
  ProfileModalContainer,
  DarkBlurredBackground,
  CloseIconImage,
  EditProfileContent,
  UserProfileImageContainer,
  LargeBoldTextEditProfile,
} from "./index";
import EditImage from "components/EditImage";
import { ErrorMessage } from "components/Typography";
import { AnimateButton } from "components/DashboardNew";

import { UserImageBlueBorder } from "../Assets/Image";
import closeIcon from "../../assets/icons/close-icon.svg";

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 15;
  position: absolute;
  left: 73%;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  @media (max-width: 480px) {
    left: 72%;
  }
`;

const StyledLabel = styled.label`
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.311111px;
  text-decoration-line: underline;
  color: #5374ff;
  cursor: pointer;
`;

const FileInput = styled.input`
  display: none;
`;

export function UpdateProfileImageModal({
  setDisplayModal,
  handleSubmitUpdateUserProfilePicture,
  profile,
}) {
  const {
    selectImageToUpload,
    loaded,
    setLoaded,
    fileInputRef,
    imageString,
    setImageString,
    clearImageUpload,
    fileIsTooLarge,
    setFileIsTooLarge,
  } = useImageUpload();

  const { first_name, last_name, profile_image } = profile;

  return (
    <>
      <EditImage
        loaded={loaded}
        setLoaded={setLoaded}
        imageString={imageString}
        setImageString={setImageString}
        clearImageUpload={clearImageUpload}
        setFileIsTooLarge={setFileIsTooLarge}
      />
      <ProfileModalPageWrapper displayModal={true}>
        <DarkBlurredBackground
          onClick={() => {
            setDisplayModal(false);
            setFileIsTooLarge(null);
            clearImageUpload();
          }}
        />
        <ProfileModalContainer>
          <CloseIconImage
            src={closeIcon}
            onClick={() => {
              setDisplayModal(false);
              setFileIsTooLarge(null);
            }}
          />
          <EditProfileContent>
            <UserProfileImageContainer>
              <UserImageBlueBorder
                userProfileImage={imageString ? imageString : profile_image}
              />
              <InputWrapper>
                <StyledLabel htmlFor="file" loaded={loaded}>
                  upload a file
                </StyledLabel>
                <FileInput
                  type="file"
                  id="file"
                  ref={fileInputRef}
                  name="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={selectImageToUpload}
                />
              </InputWrapper>
            </UserProfileImageContainer>
            <LargeBoldTextEditProfile>{`${first_name} ${last_name}`}</LargeBoldTextEditProfile>
            {fileIsTooLarge && (
              <ErrorMessage>
                The file you're trying to upload is too large. Please try a
                photo under 3MB.
              </ErrorMessage>
            )}
            <AnimateButton>
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  handleSubmitUpdateUserProfilePicture(
                    imageString,
                    setDisplayModal
                  )
                }
                sx={{ width: "100%" }}
              >
                Update Photo
              </Button>
            </AnimateButton>
          </EditProfileContent>
        </ProfileModalContainer>
      </ProfileModalPageWrapper>
    </>
  );
}
