import React from "react";
import { Link } from "react-router-dom";

// material-ui
import { Box, Grid, Typography } from "@mui/material";
import InsightsIcon from "@mui/icons-material/Insights";

const SearchNoResults = () => {
  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      justifyContent="center"
      sx={{ p: 2, paddingBottom: 4 }}
    >
      <Grid item lg={8} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <InsightsIcon sx={{ fontSize: 60 }} color="secondary" />
        </Box>
      </Grid>
      <Grid item lg={8} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h4">
            Sorry, your search didn't return any results
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={8} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "left",
          }}
        >
          <Typography>
            <ul>
              <li>
                Have you tried using different terms? I.e. software engineer vs.
                developer
              </li>
              <li>
                Double check the terms you have submitted. Can you change the
                spelling?
              </li>
              <li>Limit your search to 1 or 2 filters</li>
              <li>
                Add more contacts to the Revmo platform to improve your results
              </li>
              <li>Try another search!</li>
            </ul>
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={8} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="subtitle2">
            Not sure what's wrong? Submit a bug, visit our{" "}
            <Link to="/support" target="_blank">
              Help Center
            </Link>
            , or{" "}
            <Link
              to={{ pathname: "https://calendly.com/margot-57/30min" }}
              target="_blank"
            >
              Book a Demo
            </Link>
            .
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SearchNoResults;
