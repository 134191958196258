import { useState, useEffect } from "react";

export function useProfileImage(person) {
  const [image, setImage] = useState(person?.profile_image);
  const profileImage = async (url) => {
    try {
      let res = await fetch(url, {
        method: "GET",
      });
      if (res.redirected == true) {
        return url;
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  useEffect(() => {
    const getImage = async () => {
      if (person?.profile_image) {
        const imageString = await profileImage(person.profile_image);
        setImage(imageString);
      } else {
        if (person?.uuid) {
          const imageString = await profileImage(
            `https://qa.api.revmo.app/api/v2/images/profile_images/${person.uuid}`
          );
          setImage(imageString);
        }
      }
    };
    getImage();
  }, [person]);
  return image;
}
