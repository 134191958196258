import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// material-ui
import { Box, Grid, Typography } from "@mui/material";

// assets
import contactsEmptyState from "assets/images/contacts-empty-state.png";

const EmptyStateImage = styled.img`
  max-width: 100%;
`;

const SearchEmptyState = () => {
  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      justifyContent="center"
      sx={{ p: 2, paddingBottom: 4 }}
    >
      <Grid
        item
        xs={12}
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <EmptyStateImage src={contactsEmptyState} />
      </Grid>
      <Grid item lg={8} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h4">
            Use Revmo to search your extended network, and make new connections
            through warm, personal introductions
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={8} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="subtitle2">
            Not sure where to start? Visit our{" "}
            <Link to="/support" target="_blank">
              Help Center
            </Link>
            , or{" "}
            <Link
              to={{ pathname: "https://calendly.com/margot-57/30min" }}
              target="_blank"
            >
              Book a Demo
            </Link>
            .
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SearchEmptyState;
