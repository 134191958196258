import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { LandingRevmoLogo } from "../Assets";
import { LandingButton, LandingButtonSecondary } from "../Button";
import { NavList } from ".";
import closeIcon from "assets/icons/close-icon.svg";

const LandingMobileNavbarContainer = styled.div`
  margin: 0;
  z-index: 35;
  background: transparent;
  height: 80px;
  width: 100%;
  padding-left: 4vw;
  min-height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  a {
    font-family: "Montserrat", sans-serif !important;
  }
  padding: 0 2rem;
  @media (min-width: 991.9px) {
    display: none;
  }
`;

const LandingRevmoLogoWrapper = styled.div`
  width: 125px;
  display: flex;
  align-items: center;
  a > div > img {
    overflow: hidden;
    cursor: pointer;
    height: 25px;
  }
`;

const HamburgerMenuContainer = styled.div`
  width: 30px;
  height: 28px;
  cursor: pointer;
  display: none;
  margin-left: 29px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 1080px) {
    display: flex;
  }
`;

const BlackLine = styled.div`
  height: 3px;
  width: 100%;
  background: black;
  transition: transform 0.2s;
  ${(props) => {
    if (props.first && props.showMobileMenu) {
      return `
        transform: translateY(12.5px) rotate(134deg);
            `;
    } else if (props.second && props.showMobileMenu) {
      return `
        display: none;
            `;
    } else if (props.third && props.showMobileMenu) {
      return `
        transform: translateY(-12.5px) rotate(-135deg);
            `;
    }
  }}
`;

function HamburgerMenu({ setShowMobileMenu, showMobileMenu }) {
  return (
    <HamburgerMenuContainer onClick={() => setShowMobileMenu(!showMobileMenu)}>
      <BlackLine first showMobileMenu={showMobileMenu} />
      <BlackLine second showMobileMenu={showMobileMenu} />
      <BlackLine third showMobileMenu={showMobileMenu} />
    </HamburgerMenuContainer>
  );
}

export const LandingMobileNavbar = ({
  setShowMobileMenu,
  showMobileMenu,
  setShowDropdown,
  showDropdown,
  user,
}) => {
  return (
    <LandingMobileNavbarContainer>
      <LandingRevmoLogoWrapper>
        <Link to="/">
          <LandingRevmoLogo />
        </Link>
      </LandingRevmoLogoWrapper>
      <HamburgerMenu
        setShowMobileMenu={setShowMobileMenu}
        showMobileMenu={showMobileMenu}
      />
      <LandingMobileMenu
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
        setShowDropdown={setShowDropdown}
        showDropdown={showDropdown}
        user={user}
      />
    </LandingMobileNavbarContainer>
  );
};

const LandingMobileMenuWrapper = styled.div`
  z-index: 1000;
  padding: 0 1rem 1rem 0.5rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 100vw;
  display: flex;
  flex-direction: column;
  background: #fff;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease-out all;
  ${(props) => {
    if (props.showMobileMenu) {
      return `
        transform: translateX(-100vw);
      `;
    }
  }}
`;

const LandingMobileMenuTopContainer = styled.div`
  padding: 1.5rem 2.5rem;
  margin: 0 -0.5rem 1rem;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  img {
    height: 25px;
  }
`;

const LandingMobileMenuButtons = styled.div`
  box-shadow: 0 -10px 12px rgb(0 0 0 / 4%);
  padding: 1rem;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  div {
    padding-top: 1.5rem;
    margin: 0;
    justify-content: initial;
    display: grid;
    grid-gap: 1rem 2.4%;
    grid-template-columns: 1fr;
    position: relative;
    button {
      position: relative;
      flex-basis: 49%;
      margin: 0;
      width: 100%;
      line-height: 1.3;
    }
  }
  ${(props) => {
    if (!props.showMobileMenu) {
      return `
        position: relative;
      `;
    }
  }}
`;

export function LandingMobileMenu({
  showMobileMenu,
  setShowMobileMenu,
  setShowDropdown,
  showDropdown,
  user,
}) {
  return (
    <LandingMobileMenuWrapper showMobileMenu={showMobileMenu}>
      <LandingMobileMenuTopContainer>
        <LandingRevmoLogoWrapper>
          <Link
            to="/"
            onClick={() => {
              setShowMobileMenu(false);
            }}
          >
            <LandingRevmoLogo />
          </Link>
        </LandingRevmoLogoWrapper>
        <img
          onClick={() => {
            setShowMobileMenu(false);
          }}
          src={closeIcon}
          alt="close mobile menu"
        />
      </LandingMobileMenuTopContainer>
      <NavList
        mobile
        setShowMobileMenu={setShowMobileMenu}
        setShowDropdown={setShowDropdown}
        showDropdown={showDropdown}
        user={user}
      />
      <LandingMobileMenuButtons showMobileMenu={showMobileMenu}>
        <div>
          {user ? (
            <Link to="/">
              <LandingButton
                onClick={() => {
                  setShowMobileMenu(false);
                }}
              >
                Launch App
              </LandingButton>
            </Link>
          ) : (
            <>
              <Link to="/login">
                <LandingButtonSecondary
                  onClick={() => {
                    setShowMobileMenu(false);
                  }}
                >
                  Log in to my account
                </LandingButtonSecondary>
              </Link>
              <Link to="/sign-up">
                <LandingButton
                  onClick={() => {
                    setShowMobileMenu(false);
                  }}
                >
                  Sign up for Revmo
                </LandingButton>
              </Link>
            </>
          )}
        </div>
      </LandingMobileMenuButtons>
    </LandingMobileMenuWrapper>
  );
}

export default LandingMobileNavbar;
