import React, { useMemo, useState, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import debounce from "lodash/debounce";
import { fetchData } from "utils";
import CircularProgress from "@mui/material/CircularProgress";

const errorMessage =
  "We're unable to retrieve any companies. Please try again.";

export default function OrgsAutocomplete({ name, control, errors }) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const previousController = useRef();
  const [loading, setLoading] = useState(false);

  const getOrgs = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    setLoading(true);
    fetchData(`/orgs/?org_name=${searchTerm}`)
      .then(function (response) {
        return response;
      })
      .then(function (respJson) {
        setLoading(false);
        setOptions(respJson);
      })
      .catch(function (err) {
        console.error(err);
        setOptions([{ org_name: errorMessage }]);
        setLoading(false);
      });
  };

  const getOrgsDebounced = useMemo(
    () =>
      debounce((searchTerm) => {
        setOptions([]);
        getOrgs(searchTerm);
      }, 200),
    []
  );

  useEffect(() => {
    if (inputValue?.length > 2) {
      getOrgsDebounced(inputValue, (filteredOptions) => {
        setOptions(filteredOptions);
      });
    } else {
      setOptions([]);
    }
  }, [inputValue, getOrgsDebounced]);

  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          clearOnBlur
          disablePortal
          filterOptions={(options, params) => {
            const optionsAdd = [...options];

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.org_name
            );
            if (
              inputValue !== "" &&
              !isExisting &&
              options[0]?.org_name !== errorMessage
            ) {
              optionsAdd.push({
                inputValue,
                org_name: inputValue,
              });
            }
            return optionsAdd;
          }}
          freeSolo
          getOptionDisabled={(option) =>
            option === errorMessage || option?.org_name === errorMessage
          }
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.org_name;
          }}
          handleHomeEndKeys
          id="companies-autocomplete"
          includeInputInList
          loading={loading}
          // eslint-disable-next-line no-unused-vars
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              setValue({
                org_name: newValue,
              });
            } else if (newValue?.org_name === errorMessage) {
              setValue(null);
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setValue({
                org_name: newValue.inputValue,
              });
            } else {
              setValue(newValue);
            }
            onChange(newValue);
          }}
          onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
          options={options}
          renderOption={(props, option) => (
            <li {...props}>{option.org_name}</li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              label="Company*"
              error={!!errors?.company}
              helperText={errors?.company?.message}
              sx={{ backgroundColor: "#fff" }}
            />
          )}
          selectOnFocus
          sx={{ mb: "20px" }}
          value={value}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={null}
      name={name}
      control={control}
    />
  );
}

// create another component for search - on select this sets search options
export function OrgsAutocompleteSearch({
  searchOptions,
  setSearchOptions,
  errors,
}) {
  const value = searchOptions.company;
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const previousController = useRef();
  const [loading, setLoading] = useState(false);

  const getOrgs = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    setLoading(true);
    fetchData(`/orgs/?org_name=${searchTerm}`)
      .then(function (response) {
        return response;
      })
      .then(function (respJson) {
        setLoading(false);
        setOptions(respJson);
      })
      .catch(function (err) {
        console.error(err);
        setOptions([{ org_name: errorMessage }]);
        setLoading(false);
      });
  };

  const getOrgsDebounced = useMemo(
    () =>
      debounce((searchTerm) => {
        setOptions([]);
        getOrgs(searchTerm);
      }, 200),
    []
  );

  useEffect(() => {
    if (inputValue?.length > 2) {
      getOrgsDebounced(inputValue, (filteredOptions) => {
        setOptions(filteredOptions);
      });
    } else {
      setOptions([]);
    }
  }, [inputValue, getOrgsDebounced]);

  return (
    <Autocomplete
      clearOnBlur
      disablePortal
      filterOptions={(options, params) => {
        const optionsAdd = [...options];

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.org_name
        );
        if (
          inputValue !== "" &&
          !isExisting &&
          options[0]?.org_name !== errorMessage
        ) {
          optionsAdd.push({
            inputValue,
            org_name: inputValue,
          });
        }
        return optionsAdd;
      }}
      freeSolo
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.org_name;
      }}
      handleHomeEndKeys
      id="companies-autocomplete-search"
      includeInputInList
      loading={loading}
      // eslint-disable-next-line no-unused-vars
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setSearchOptions({
            ...searchOptions,
            company: newValue,
          });
        } else if (newValue?.org_name === errorMessage) {
          setSearchOptions({ ...searchOptions, company: { org_name: "" } });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setSearchOptions({
            ...searchOptions,
            company: newValue.inputValue,
          });
        } else if (newValue?.org_name) {
          setSearchOptions({
            ...searchOptions,
            company: newValue.org_name,
          });
        } else {
          setSearchOptions({
            ...searchOptions,
            company: "",
          });
        }
      }}
      onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
      options={options}
      renderOption={(props, option) => <li {...props}>{option.org_name}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label="Company Name"
          error={!!errors?.company}
          helperText={errors?.company?.message}
          sx={{ backgroundColor: "#fff" }}
        />
      )}
      selectOnFocus
      value={value}
    />
  );
}
