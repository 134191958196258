import { useState } from "react";
import { useContact, useUser, useProfile } from "hooks";

const useCognitoAuth = (state) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [loginState, setLoginState] = useState(state);
  const { login } = useUser();
  const { getUserProfile } = useProfile();
  const { getContacts } = useContact();

  const customErrorMessages = {
    "User does not exist.": "You have entered an invalid username",
    "Incorrect username or password.":
      "You have entered an invalid username or password",
    "Password attempts exceeded.": "You’ve exceeded the maximum login attempts",
    "An account with the given email already exists.":
      "An account with the given email already exists.",
    "Username/client id combination not found.":
      "Email address can not be found",
    "Invalid verification code provided, please try again.":
      "Invalid verification code provided, please try again.",
    "Attempt limit exceeded, please try after some time.":
      "Attempt limit exceeded, please try after some time.",
    "User is not confirmed.":
      "Sign up was never completed. Please contact us and we will reset your email address",
    "Confirmation code cannot be empty": "Please enter your confirmation code",
  };

  const logInUser = async (data) => {
    let { password, email } = data;
    email = email.toLowerCase();
    try {
      await login(email, password);
      await getUserProfile();
      await getContacts();
    } catch (error) {
      console.log(error);
      setAlertMessage(customErrorMessages[error.message]);
    }
  };

  return {
    logInUser,
    alertMessage,
    setAlertMessage,
    loginState,
    setLoginState,
    customErrorMessages,
  };
};

export default useCognitoAuth;
