import React from "react";
import { Switch, withRouter, Route } from "react-router-dom";
import {
  PrivateRoute,
  PrivateRouteOnboarding,
  PublicRoute,
} from "./components/Route";
import { Auth } from "aws-amplify";
import {
  AboutUs,
  Blog,
  Careers,
  Contact,
  Contacts,
  Enterprise,
  GettingStarted,
  LandingPage,
  Login,
  MyRequests,
  Onboarding,
  Organisations,
  PressSection,
  PrivacyPolicy,
  ProductUpdates,
  Profile,
  RouteNotFound,
  Search,
  Signup,
  Support,
} from "./containers";
import { JobDescription } from "containers/Careers/components";
import { GlobalStyle } from "./styles/global";
import { awsauthconfig } from "./config";
import OnboardingCorporate from "containers/OnboardingCorporate";
import { UpdatesPost } from "containers/ProductUpdates/components";
import { Home, SettingsNew } from "containers/DashboardNew";
import MainLayout from "components/DashboardNew/Layout/MainLayout";
import Post from "components/Post";

Auth.configure(awsauthconfig);

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <Switch>
        <PublicRoute exact path="/" component={LandingPage} />
        <PublicRoute exact path="/enterprise" component={Enterprise} />
        <PublicRoute exact path="/about" component={AboutUs} />
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/login/reset-password" component={Login} />
        <PublicRoute exact path="/sign-up" component={Signup} />
        <PublicRoute exact path="/sign-up/era" component={Signup} />
        <PublicRoute exact path="/sign-up/sass-nyc" component={Signup} />
        <PrivateRouteOnboarding
          exact
          path="/onboarding"
          component={Onboarding}
        />
        <PrivateRouteOnboarding
          exact
          path="/onboarding/company"
          component={OnboardingCorporate}
        />
        <PrivateRoute
          exact
          path={[
            "/dashboard",
            "/profile",
            "/profile/contact",
            "/contacts",
            "/network",
            "/organisations",
            "/search",
            "/introductions",
            "/settings",
          ]}
        >
          <MainLayout>
            <Route path="/dashboard" component={Home} />
            <Route path="/search" component={Search} />
            <Route path="/contacts" component={Contacts} />
            <Route path="/organisations" component={Organisations} />
            <Route path="/introductions" component={MyRequests} />
            <Route path="/settings" component={SettingsNew} />
            <Route path="/profile" component={Profile} />
            <Route path="/network" component={Contacts} />
          </MainLayout>
        </PrivateRoute>
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/getting-started" component={GettingStarted} />
        <Route exact path="/press" component={PressSection} />
        <Route path="/about" component={AboutUs} />
        <Route exact path="/support" component={Support} />
        <Route path="/support/:slug" component={Post} />
        <Route exact path="/updates" component={ProductUpdates} />
        <Route path="/updates/:slug" component={UpdatesPost} />
        <Route exact path="/blog" component={Blog} />
        <Route path="/blog/:slug" component={Post} />
        <Route exact path="/careers" component={Careers} />
        <Route path="/careers/:slug" component={JobDescription} />
        <Route component={RouteNotFound} />
      </Switch>
    </div>
  );
}

export default withRouter(App);
