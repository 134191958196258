import React from "react";
import { Link } from "react-router-dom";

// material-ui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SearchOffTwoToneIcon from "@mui/icons-material/SearchOffTwoTone";

const SearchUserErrorMessage = ({ error }) => {
  return (
    <>
      {error?.detail === "Exceeded max search" ? (
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
          sx={{ p: 2, paddingBottom: 4 }}
        >
          <Grid item lg={8} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography variant="h4">
                Hey, you've exceeded your free weekly search limit
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={8} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography sx={{ p: 2 }}>
                <Link to="/settings">Subscribe</Link> to unleash the full power
                of making connections through Revmo and enjoy unlimited
                searches.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
          sx={{ p: 2, paddingBottom: 4 }}
        >
          <Grid item lg={8} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <SearchOffTwoToneIcon sx={{ fontSize: 60 }} color="secondary" />
            </Box>
          </Grid>
          <Grid item lg={8} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography variant="h4">
                Sorry, your search didn't return any results
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={8} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              <Typography>
                {typeof error.detail === "string"
                  ? error.detail
                  : "Looks like there was an error with your search. You can either try again, refresh or report a bug."}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SearchUserErrorMessage;
