import React from "react";
import styled from "styled-components";
import { AuthFormWrapper } from "components/Auth";
import { HeaderText, MidLargeSpacedText } from "components/Typography";
import revmoLogo from "assets/images/RevmoLogo.png";

const AuthFormWrapperThanks = styled(AuthFormWrapper)`
  position: relative;
  padding-bottom: 90px;
  padding-top: 80px;
  h1 {
    margin-bottom: 44px;
    margin-top: 25px;
  }
`;

const RevmoLogo = styled.img`
  position: absolute;
  width: 220px;
  top: -70px;
  left: -19px;
`;

const MidLargeSpacedTextLineHeight = styled(MidLargeSpacedText)`
  line-height: 35px;
`;

function ThankYouForRegistering() {
  return (
    <AuthFormWrapperThanks>
      <HeaderText>You're on the List</HeaderText>
      <MidLargeSpacedTextLineHeight>
        Thank you for signing up for the Revmo beta. We'll notify you as soon as
        it's your turn on the waitlist.
      </MidLargeSpacedTextLineHeight>
      <RevmoLogo src={revmoLogo} />
    </AuthFormWrapperThanks>
  );
}

export default ThankYouForRegistering;
