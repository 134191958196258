import * as yup from "yup";
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { loadOnboardingFields } from "state/sessionStorage";
import { postData } from "utils";

export const personalDataSchema = yup.object().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  //eslint-disable-next-line
  personalPhoneNumber: yup.string().matches(/[0-9]{0,14}$/, {
    message: "Please enter a valid international number",
    excludeEmptyString: true,
  }),
});

export const professionalDataSchema = yup.object().shape({
  company: yup
    .object()
    .required()
    .typeError("Please enter the name of your company"),
  position: yup.string().required("Please enter your role title"),
  companyEmail: yup
    .string()
    .email("Looks like this isn't a valid email")
    .required("Please enter your company email"),
});

export const professionalDataSchemaPart2 = yup.object().shape({
  //eslint-disable-next-line
  companyPhoneNumber: yup.string().matches(/[0-9]{0,14}$/, {
    message: "Please enter a valid international number",
    excludeEmptyString: true,
  }),
});

export const capitalize = (s) => {
  if (typeof s !== "string") return s;
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const updateUserData = (
  onboardingFields,
  setOnboardingFields,
  data,
  diallingCode
) => {
  if (data.personalPhoneNumber) {
    diallingCode.personalPhoneNumber.nationalNumber = data.personalPhoneNumber;
  }
  if (data.companyPhoneNumber) {
    diallingCode.companyPhoneNumber.nationalNumber = data.companyPhoneNumber;
  }
  if (data.company) {
    data.company = { org_name: data.company.org_name };
  }
  const formattedOnboardingValues = {
    ...onboardingFields,
    ...data,
    ...diallingCode,
  };
  setOnboardingFields(formattedOnboardingValues);
  return formattedOnboardingValues;
};

export const formatUserObject = (
  onboardingFields,
  userAddress,
  user,
  toggleStateRef
) => {
  const { firstName, lastName, company, position, profileImage, companyEmail } =
    onboardingFields;

  const { current } = toggleStateRef;
  const { personalLatLng } = userAddress;

  const userOnboardingProfile = {
    first_name: capitalize(firstName),
    last_name: capitalize(lastName),
    login_email_address: user.attributes.email,
    email_addresses: [
      {
        email_address: user.attributes.email,
      },
      {
        email_address: companyEmail,
      },
    ],
    positions: [
      {
        is_active: current,
        role_description: position,
        company_email_addresses: [{ email_address: companyEmail }],
        org: company,
        role: [
          {
            role_name: position,
          },
        ],
      },
    ],
    profile_image: profileImage,
    personal_primary_location: {
      lookup_point: {
        coordinates: [personalLatLng.lng, personalLatLng.lat],
      },
    },
  };

  return userOnboardingProfile;
};

export const createProfile = async (userOnboardingData, user) => {
  try {
    await postData(`/profile/`, JSON.stringify(userOnboardingData));
    await Auth.updateUserAttributes(user, {
      "custom:onboarding_completed": "true",
    });
    sessionStorage.clear();
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateProfileState = (userOnboardingData, createUserProfile) => {
  createUserProfile(userOnboardingData);
};

export const useResetFormData = (
  reset,
  onboardingFields,
  currentPage,
  diallingCodesHook
) => {
  useEffect(() => {
    const { personalData, professionalData } = loadOnboardingFields();
    if (personalData?.personalPhoneNumber?.nationalNumber) {
      // check personalDataNumber is different to the data in diallingCodesHook
      // turn into reusable function for both
      diallingCodesHook.setDiallingCode({
        ...diallingCodesHook.diallingCode,
        personalPhoneNumber: personalData.personalPhoneNumber,
      });
    }
    if (professionalData?.companyPhoneNumber?.nationalNumber) {
      // check personalDataNumber is different to the data in diallingCodesHook
      diallingCodesHook.setDiallingCode({
        ...diallingCodesHook.diallingCode,
        companyPhoneNumber: professionalData.companyPhoneNumber,
      });
    }

    let resetValues;
    if (currentPage === 1) {
      resetValues = {
        ...personalData,
        personalPhoneNumber: personalData?.personalPhoneNumber?.nationalNumber,
      };
    } else {
      resetValues = {
        ...professionalData,
        companyPhoneNumber:
          professionalData?.companyPhoneNumber?.nationalNumber,
      };
    }

    reset(resetValues);
  }, [onboardingFields, reset, currentPage, diallingCodesHook]);
};
