import React from "react";
import styled from "styled-components";
import {
  MidLargeSpacedText,
  MidLargeBoldTitleText,
  SmallBlackBoldText,
} from "components/Typography";
import Switch from "react-switch";

const SettingSectionRowIntroductionPreferences = styled.div`
  padding-top: 41px;
  padding-bottom: 47px;
  border-top: 1px solid #e3e6e9;
  border-bottom: 1px solid #e3e6e9;
`;

const ToggleContainer = styled.div`
  display: flex;
  padding-top: 33px;
  p {
    margin-right: 24px;
  }
`;

function IntroductionPreferences({
  allowUsersToRequestIntroduction,
  setAllowUsersToRequestIntroduction,
}) {
  return (
    <SettingSectionRowIntroductionPreferences>
      <MidLargeBoldTitleText>Introduction Preferences</MidLargeBoldTitleText>
      <MidLargeSpacedText>
        Choose if you only want to allow users in your network to request
        introductions.
      </MidLargeSpacedText>
      <ToggleContainer>
        <SmallBlackBoldText>On</SmallBlackBoldText>
        <Switch
          checkedIcon={false}
          uncheckedIcon={false}
          checked={allowUsersToRequestIntroduction}
          height={32}
          width={68}
          onColor="#18BFA1"
          onChange={() =>
            setAllowUsersToRequestIntroduction(!allowUsersToRequestIntroduction)
          }
        />
      </ToggleContainer>
    </SettingSectionRowIntroductionPreferences>
  );
}

export default IntroductionPreferences;
