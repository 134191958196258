import PropTypes from "prop-types";
import React, { useState } from "react";
import { Auth } from "aws-amplify";

// material-ui
import { useTheme } from "@mui/material/styles";
import { CardContent, Grid, Tab, Tabs, Typography } from "@mui/material";

// project imports
import UserProfile from "./UserProfile";
import ChangePassword from "./ChangePassword";
import { MainCard } from "components/DashboardNew";
import { gridSpacing } from "../theme";
import { useUser, useProfile, useAlert } from "hooks";
import { formatProfile } from "containers/Settings/utils";
import { putData } from "utils";

// assets
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";

// tabs
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// tabs option
const tabsOption = [
  {
    label: "User Profile",
    icon: <PersonOutlineTwoToneIcon />,
    caption: "Profile Settings",
  },
  {
    label: "Change Password",
    icon: <VpnKeyTwoToneIcon />,
    caption: "Update Profile Security",
  },
];

// ==============================|| PROFILE 2 ||============================== //

const SettingsNew = () => {
  const theme = useTheme();
  const { user } = useUser();
  const { profile, updateUserProfile } = useProfile();
  const { addAlert } = useAlert();

  const [updatePasswordStatus, setUpdatePasswordStatus] = useState({
    status: null,
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmitUpdatePassword = async (data) => {
    try {
      const { oldPassword, newPassword, confirmPassword } = data;
      if (oldPassword === newPassword) {
        setUpdatePasswordStatus({
          status: "Error",
          message: "Your previous password is not valid as a new password.",
        });
      } else if (newPassword !== confirmPassword) {
        setUpdatePasswordStatus({
          status: "Error",
          message: "Passwords must match",
        });
      } else {
        await Auth.changePassword(user, oldPassword, newPassword);
        setUpdatePasswordStatus({
          status: "Password has been updated",
          message: "Your password has been successfully updated",
        });
        addAlert(
          "success",
          "Your password has been sucessfully changed.",
          "success"
        );
      }
    } catch (err) {
      const errorMessages = {
        InvalidParameterException: "Password must be at least 6 characters",
        NotAuthorizedException: "Incorrect username or password.",
      };
      setUpdatePasswordStatus({
        status: "Failed",
        message: errorMessages[err.code],
      });
    }
  };

  const handleSubmitUpdatePersonalInfo = async (data) => {
    const formattedProfileData = formatProfile(data);
    const updatedProfile = { ...profile, ...formattedProfileData };
    try {
      await putData(`/profile/`, JSON.stringify(updatedProfile));
      updateUserProfile(updatedProfile);
      addAlert(
        "success",
        "Your profile has been sucessfully updated.",
        "success"
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard title="Account Settings" content={false}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} lg={4}>
              <CardContent>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  orientation="vertical"
                  variant="scrollable"
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      borderBottom: "none",
                    },
                    "& button": {
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.grey[600]
                          : theme.palette.grey[600],
                      minHeight: "auto",
                      minWidth: "100%",
                      py: 1.5,
                      px: 2,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      textAlign: "left",
                      justifyContent: "flex-start",
                      borderRadius: `8px`,
                    },
                    "& button.Mui-selected": {
                      color: theme.palette.primary.main,
                      background:
                        theme.palette.mode === "dark"
                          ? theme.palette.dark.main
                          : theme.palette.grey[50],
                    },
                    "& button > svg": {
                      marginBottom: "0px !important",
                      marginRight: 1.25,
                      marginTop: 1.25,
                      height: 20,
                      width: 20,
                    },
                    "& button > div > span": {
                      display: "block",
                    },
                    "& > div > span": {
                      display: "none",
                    },
                  }}
                >
                  {tabsOption.map((tab, index) => (
                    <Tab
                      key={index}
                      icon={tab.icon}
                      label={
                        <Grid container direction="column">
                          <Typography variant="subtitle1" color="inherit">
                            {tab.label}
                          </Typography>
                          <Typography
                            component="div"
                            variant="caption"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {tab.caption}
                          </Typography>
                        </Grid>
                      }
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </CardContent>
            </Grid>
            <Grid item xs={12} lg={8}>
              <CardContent
                sx={{
                  borderLeft: "1px solid",
                  borderColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.background.default
                      : theme.palette.grey[200],
                  height: "100%",
                }}
              >
                <TabPanel value={value} index={0}>
                  <UserProfile
                    profile={profile}
                    handleSubmitUpdatePersonalInfo={
                      handleSubmitUpdatePersonalInfo
                    }
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ChangePassword
                    handleSubmitUpdatePassword={handleSubmitUpdatePassword}
                    updatePasswordStatus={updatePasswordStatus}
                  />
                </TabPanel>
              </CardContent>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default SettingsNew;
