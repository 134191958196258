import React, { useState } from "react";
import { PathScoreDisplayOuter, PathScoreDisplayInner } from "../Score";
import styled from "styled-components";
import { AnonymousUserPersonCard } from "../Assets/Image";
import { SkeletonPulse } from "components/Skeleton";
import { getContactByUUID } from "utils";

export const PeopleCardContainer = styled.div`
  background: #f7f7f8;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-top: 20px;
  min-width: 265px;
  margin-right: 12px;
  position: relative;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 1126px) {
    min-width: calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-right: unset;
    ${(props) => {
      if (props.index > 1) {
        return `
          margin-top: 20px;
        `;
      } else {
        return `
          margin-top: 0;
        `;
      }
    }}
  }
  @media (max-width: 670px) {
    min-width: 345px;

    ${(props) => {
      if (props.index > 0) {
        return `
          margin-top: 20px;
        `;
      } else {
        return `
          margin-top: 0;
        `;
      }
    }}
  }

  @media (max-width: 480px) {
    min-width: 335px;
    width: 335px;
  }
  @media (max-width: 321px) {
    min-width: 300px;
  }
`;

export const PeopleCardSectionFirst = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 7px 15px 13px 12px;
  @media (max-width: 1126px) {
    padding: 15px 17px 15px 17px;
    height: 11.5vw;
  }
  @media (max-width: 871px) {
    height: 12.5vw;
  }
  @media (max-width: 670px) {
    height: unset;
    padding: 7px 15px 13px 12px;
  }
`;

export const PeopleCardSectionSecond = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 1126px) {
    height: 9vw;
  }
  @media (max-width: 871px) and (min-width: 670px) {
    height: 7vw;
  }
  @media (max-width: 670px) {
    height: 54px;
  }
`;

export const PeopleCardSectionThird = styled.div`
  display: flex;
  padding: 11px 15px 6px 15px;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1126px) {
    justify-content: start;
    padding: 18px 16px;
  }
  background: white;
`;

const UserImage = styled.img`
  height: 60px;
  min-width: 60px;
  border-radius: 100%;
  @media (max-width: 1126px) {
    height: 8vw;
    min-width: 8vw;
  }
  @media (max-width: 670px) {
    height: 56px;
    min-width: 56px;
  }
`;

export const VerticalContainer = styled.div`
  width: 100%;
  align-self: flex-start;
  margin-top: 3px;
  max-width: 100%;
  overflow: hidden;
`;

export const HorizontalFlexContainer = styled.div`
  display: flex;
  ${(props) => {
    if (props.first) {
      return `
        justify-content: space-between;
        width: 100%;
            `;
    }
  }}
`;

export const HorizontalFlexContainerFirst = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HalfWidthContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 51px;
  padding: 6px 0;
  border-top: 1px solid #e3e6e9;
  border-bottom: 1px solid #e3e6e9;
  @media (max-width: 1126px) {
    height: 9vw;
  }
  @media (max-width: 871px) {
    height: 7vw;
  }
  @media (max-width: 670px) {
    height: 54px;
  }
`;

const HalfWidthContainerRevmoScore = styled(HalfWidthContainer)`
  border-right: 1px solid #e3e6e9;
`;
const HalfWidthContainerPathScore = styled(HalfWidthContainer)`
  padding-right: 16px;
  padding-left: 16px;
`;

export const PeopleCardSectionFourth = styled.div`
  display: flex;
  padding: 14px 0;
  background: white;
  justify-content: center;

  div {
    :first-child {
      justify-content: center;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 1126px) {
    padding: 26px 0;
    height: 8vw;
  }
  @media (max-width: 670px) {
    height: 60px;
  }
`;

export const ScoreText = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #2c2948;
  margin-bottom: 0;
`;

export const ScoreTextBlue = styled(ScoreText)`
  color: ${(props) => props.theme.brandColor};
`;

const ScoreTextScoreType = styled(ScoreText)`
  font-size: 10px;
  line-height: 12px;
`;

export const IntroButton = styled.button`
  height: 31px;
  width: 117px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: unset;
  border-radius: 5px;
  background: #5374ff;
  font-weight: bold;
  color: #ffffff;
  ${(props) => {
    if (props.color === "white") {
      return `
        color: ${(props) => props.theme.textColor};
        background: white;
        border: 1px solid #E3E6E9;
      `;
    } else if (props.color === "grey") {
      return `
        background: #B3BCC6;
        color: white;
      `;
    } else if (props.color === "green") {
      return `
        color: white;
        background: ${props.theme.acceptedColor};
      `;
    } else if (props.color === "blue") {
      return `
        background: ${props.theme.brandColor};
        color: white;
      `;
    } else if (props.color === "muted") {
      return `
        background: #FFFFFF;
        color: #B3BCC6;
        border: 1px solid #E3E6E9;
        cursor: not-allowed;
      `;
    } else if (props.color === "navy") {
      return `
        background: #98acff;
        color: white;
      `;
    } else if (props.color === "secondary") {
      return `
        background: #66abd9;
        border: solid;
        background: white;
        color: #5374FF;
        border-color: #5374FF;
      `;
    }
  }}
  ${(props) => {
    if (props.size === "full") {
      return `
        width: 252px;
        margin-left: 18px;
      `;
    }
  }}
`;

export const UserDetailsText = styled.p`
  font-size: 15px;
  margin: 0;
  margin-left: 10px;
  color: #494661;
  line-height: 18px;
  font-weight: bold;
  @media (max-width: 670px) {
    margin-left: 8px;
    font-size: 15px;
    line-height: 18px;
  }
`;

export const UserDetailsPositionText = styled.p`
  font-weight: normal;
  color: ${(props) => props.theme.textColor};
  margin-top: 4px;
  margin-left: 10px;
  font-size: 12px;
  margin-bottom: 5px;
  line-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 255px;
  @media (max-width: 670px) {
    font-size: 12px;
    line-height: 14px;
    margin: 3px 0 3px 8px;
  }
`;

const UserNameAndShowIndustryButton = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  flex: 1;
`;

const ShowMorePopUpWrapper = styled.div`
  position: absolute;
  z-index: 10;
  height: max-content;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  background: #f7f6f8;
  right: -13px;
  width: max-content;
  pointer-events: none;
  top: 27px;
  box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.15);
  p:first-child {
    font-weight: bold;
    padding-bottom: 2px;
  }
  ${(props) => {
    if (props.showMorePopUp) {
      return `
        opacity: 1;
      `;
    }
  }}
`;

function ShowMorePopUp({ industries, showMorePopUp, setShowIndustriesPopUp }) {
  return (
    <ShowMorePopUpWrapper
      showMorePopUp={showMorePopUp}
      onClick={() => setShowIndustriesPopUp(false)}
    >
      <p>Industries</p>
      {industries.map((industry) => {
        return <p key={industry}>{industry}</p>;
      })}
    </ShowMorePopUpWrapper>
  );
}

const ShowMoreEllipsis = styled.span`
  font-size: 24px;
  margin-left: 10px;
  position: absolute;
  letter-spacing: 1px;
  color: #5374ff;
  cursor: pointer;
  right: 0px;
  top: -13px;
`;

export function Person({
  index,
  person,
  viewIntroductionPathwayToNetworkMember,
  showAddContactModal,
}) {
  const {
    first_name,
    last_name,
    career,
    path_score,
    revmo_score,
    profile_image,
    has_path,
    is_contact,
  } = person;

  const [showIndustriesPopUp, setShowIndustriesPopUp] = useState(false);

  let industries = [];
  if (career.length) {
    career.forEach((job) => {
      if (job.industry) {
        industries = [...industries, ...job.industry];
      }
    });
  }

  return (
    <PeopleCardContainer index={index}>
      <ShowMorePopUp
        setShowIndustriesPopUp={setShowIndustriesPopUp}
        showMorePopUp={showIndustriesPopUp}
        industries={industries}
      />
      <PeopleCardSectionFirst>
        {profile_image ? (
          <UserImage src={person.profile_image} />
        ) : (
          <AnonymousUserPersonCard />
        )}
        <VerticalContainer>
          <HorizontalFlexContainerFirst>
            <UserNameAndShowIndustryButton>
              <UserDetailsText>{`${first_name} ${last_name}`}</UserDetailsText>
              {industries[0] && (
                <>
                  <ShowMoreEllipsis
                    onClick={() => setShowIndustriesPopUp(!showIndustriesPopUp)}
                  >
                    ...
                  </ShowMoreEllipsis>
                </>
              )}
            </UserNameAndShowIndustryButton>
          </HorizontalFlexContainerFirst>
          {career[0] && (
            <>
              <UserDetailsPositionText>
                {career[0].role_desc}
              </UserDetailsPositionText>
              <UserDetailsPositionText>
                @ {career[0].org_name}
              </UserDetailsPositionText>
            </>
          )}
        </VerticalContainer>
      </PeopleCardSectionFirst>
      <PeopleCardSectionSecond>
        <HalfWidthContainerRevmoScore>
          <HorizontalFlexContainer>
            <ScoreText>{revmo_score}</ScoreText>
            <ScoreTextBlue>&nbsp; / 800</ScoreTextBlue>
          </HorizontalFlexContainer>
          <ScoreTextScoreType>REVMO SCORE</ScoreTextScoreType>
        </HalfWidthContainerRevmoScore>
        <HalfWidthContainerPathScore>
          <ScoreText>{path_score}%</ScoreText>
          <PathScoreDisplayOuter>
            <PathScoreDisplayInner score={path_score} />
          </PathScoreDisplayOuter>
          <ScoreTextScoreType>PATH SCORE</ScoreTextScoreType>
        </HalfWidthContainerPathScore>
      </PeopleCardSectionSecond>
      <PeopleCardSectionFourth>
        <PersonCardButtons
          path={has_path}
          person={person}
          viewIntroductionPathwayToNetworkMember={
            viewIntroductionPathwayToNetworkMember
          }
          showAddContactModal={showAddContactModal}
          index={index}
          is_contact={is_contact}
        />
      </PeopleCardSectionFourth>
    </PeopleCardContainer>
  );
}

const CardButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  button:first-of-type {
    margin-right: 18px;
  }
  div:first-of-type {
    margin-right: 18px;
  }
`;

function PersonCardButtons({
  path,
  person,
  viewIntroductionPathwayToNetworkMember,
  showAddContactModal,
  index,
  is_contact,
}) {
  return (
    <div>
      {is_contact ? (
        <CardButtonWrapper>
          <IntroButton
            color={"blue"}
            size={"full"}
            onClick={async () => getContactByUUID(person.uuid)}
          >
            View Profile
          </IntroButton>
        </CardButtonWrapper>
      ) : (
        <CardButtonWrapper>
          <IntroButton
            onClick={() => {
              viewIntroductionPathwayToNetworkMember(person, path);
            }}
            color={path ? "blue" : "muted"}
          >
            Request Intro
          </IntroButton>
          <IntroButton
            color={"secondary"}
            onClick={() => {
              !is_contact.length && showAddContactModal(index, person);
            }}
          >
            Add Contact
          </IntroButton>
        </CardButtonWrapper>
      )}
    </div>
  );
}

const PersonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-radius: 8px;
  @media (max-width: 1126px) {
    align-items: center;
    flex-wrap: wrap;
    width: 90vw;
  }
  @media (max-width: 871px) {
    justify-content: space-around;
  }
`;

export function PeopleYouMightWantToConnectWith({
  people,
  viewIntroductionPathwayToNetworkMember,
  showAddContactModal,
}) {
  return (
    <>
      <PersonContainer>
        {people.map((person, index) => {
          return (
            <Person
              index={index}
              key={person.uuid}
              person={person}
              viewIntroductionPathwayToNetworkMember={
                viewIntroductionPathwayToNetworkMember
              }
              showAddContactModal={showAddContactModal}
            />
          );
        })}
      </PersonContainer>
    </>
  );
}

// Skeleton Elements
const TextSkeleton = styled(SkeletonPulse)`
  height: 17px;
  width: 80%;
  min-width: 60px;
  width: ${(props) => (props.width ? props.width : "90%")};
`;

const ButtonSkeleton = styled(SkeletonPulse)`
  height: 32px;
  width: 120px;
  padding: 0 10px;
  border-radius: 5px;
  margin: 0 5px;
  border: unset;
`;

const ImageSkeleton = styled(SkeletonPulse)`
  height: 56px;
  min-width: 56px;
  max-width: 56px;
  border-radius: 100%;
`;

export function PeopleYouMightWantToConnectWithSkeleton() {
  return (
    <>
      <PersonContainer>
        <PeopleCardContainer index={0}>
          <PeopleCardSectionFirst>
            <ImageSkeleton />
            <VerticalContainer>
              <HorizontalFlexContainerFirst>
                <UserDetailsText>
                  <TextSkeleton />
                </UserDetailsText>
              </HorizontalFlexContainerFirst>
              <UserDetailsPositionText>
                <TextSkeleton width={"60%"} />
              </UserDetailsPositionText>
            </VerticalContainer>
          </PeopleCardSectionFirst>
          <PeopleCardSectionSecond>
            <HalfWidthContainerRevmoScore>
              <HorizontalFlexContainer></HorizontalFlexContainer>
            </HalfWidthContainerRevmoScore>
            <HalfWidthContainerPathScore></HalfWidthContainerPathScore>
          </PeopleCardSectionSecond>
          <PeopleCardSectionFourth>
            <CardButtonWrapper>
              <ButtonSkeleton />
              <ButtonSkeleton />
            </CardButtonWrapper>
          </PeopleCardSectionFourth>
        </PeopleCardContainer>
      </PersonContainer>
    </>
  );
}
