import { postData } from "./";
import { formatManualContactUpload } from "./";

const createSingleContact = async (data, contactAddress, diallingCode) => {
  try {
    const contactData = formatManualContactUpload(
      data,
      contactAddress,
      diallingCode
    );
    await postData("/contacts/", JSON.stringify(contactData));
  } catch (err) {
    console.log(err);
  }
};

export default createSingleContact;
