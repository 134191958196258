import React from "react";
import styled from "styled-components";
import GettingStartedTabs from "./GettingStartedTabs";

const ContactUploadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContactUploadText = styled.div`
  max-width: 850px;
  h2 {
    font-size: 40px;
    text-align: center;
    line-height: 1.2em;
    font-weight: bold;
    font-style: normal;
    padding-bottom: 2vh;
  }
  p,
  ol,
  li {
    text-align: left;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    line-height: 1.2em;
  }
  li {
    padding-bottom: 2rem;
  }
  @media (max-width: 870px) {
    width: 85%;
  }
`;

const ContactUpload = () => {
  return (
    <ContactUploadWrapper>
      <ContactUploadText>
        <h2>Uploading Your Contacts From Popular Services</h2>
        <p>There are three ways to add your contacts to Revmo:</p>
        <ol>
          <li>
            <strong>Upload them to your Revmo account:</strong> Follow the
            prompts on Revmo or go to your Settings and upload an XLS/CSV file
          </li>
          <li>
            <strong>Send a secure dropbox link:</strong> Send a secure dropbox
            link (with an expiry date) to secureupload@revmo.app
          </li>
          <li>
            <strong>Forward your contacts:</strong> Send your XLS/CSV (or Google
            Sheets/VCFs) to secureupload@revmo.app
          </li>
        </ol>
        <p>
          If you’re having trouble uploading your contacts, or creating a
          contacts file, email us at support@revmo.app and we’ll happily assist
          you.
        </p>
      </ContactUploadText>
      <GettingStartedTabs />
    </ContactUploadWrapper>
  );
};

export default ContactUpload;
