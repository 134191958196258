import React from "react";
import styled from "styled-components";
import { PageWrapperFullWidth } from "../Layout";
import { SimpleNav } from "../Navbar";

export const RouteNotFoundCard = styled.div`
  margin-top: 150px;
  padding: 30px 50px;
  width: max-content;
  border: 1px solid #d8d7e8;
  background: #f7f7f8;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  p {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const RouteNotFoundBody = styled.div`
  display: flex;
  justify-content: center;
`;

export function RouteNotFoundUserIsLoggedIn() {
  return (
    <PageWrapperFullWidth>
      <RouteNotFoundCard>
        <p>404 page not found</p>
      </RouteNotFoundCard>
    </PageWrapperFullWidth>
  );
}

export function RouteNotFoundNoUser() {
  return (
    <>
      <SimpleNav />
      <RouteNotFoundBody>
        <RouteNotFoundCard>
          <p>404 page not found</p>
        </RouteNotFoundCard>
      </RouteNotFoundBody>
    </>
  );
}
