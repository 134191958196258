import React from "react";
import { LargeBoldText, MidLargeSpacedText } from "../Typography";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  AddContactsContent,
  ProfileModalContainerSuccess,
} from "./index";
import { ButtonPrimaryWithArrow } from "../Button";
import closeIcon from "../../assets/icons/close-icon.svg";
import styled from "styled-components";

const ErrorModalHeader = styled.div`
  margin-bottom: 40px;
`;

const ErrorIconAndTextWrapper = styled.div`
  display: flex;
  margin-bottom: 29px;
  align-items: center;
`;

export function ErrorModal({ displayModal, setDisplayModal, useCase }) {
  const messages = {
    manualUpload:
      "We encountered an issue adding the contact to your network. Please try again later or contact us at support@revmo.app",
    csvUpload:
      "We detected a formatting issue with your uploaded contacts. Feel free to use our secure upload feature instead by sending your contacts file (CSV) to secureuploads@revmo.app",
  };
  return (
    <>
      {displayModal && (
        <ProfileModalPageWrapper>
          <DarkBlurredBackground onClick={() => setDisplayModal(null)} />
          <ProfileModalContainerSuccess>
            <CloseIconImage
              src={closeIcon}
              onClick={() => setDisplayModal(false)}
            />
            <AddContactsContent>
              <ErrorModalHeader>
                <ErrorIconAndTextWrapper>
                  <LargeBoldText>An error has occurred</LargeBoldText>
                </ErrorIconAndTextWrapper>
                <MidLargeSpacedText>{messages[useCase]}</MidLargeSpacedText>
              </ErrorModalHeader>
              <ButtonPrimaryWithArrow
                disabled={false}
                padding={"11px 27px"}
                buttonText={"Continue"}
                action={() => setDisplayModal(null)}
              />
            </AddContactsContent>
          </ProfileModalContainerSuccess>
        </ProfileModalPageWrapper>
      )}
    </>
  );
}
