import React from "react";
import styled from "styled-components";
import { SectionWrapper } from "../Layout";
import { IntroductionSection } from ".";
import screenshot1 from "assets/images/screenshot1.png";
import screenshot2 from "assets/images/screenshot2.png";
import screenshot3 from "assets/images/screenshot3.png";

const HowDoesItWorkWrapper = styled(SectionWrapper)`
  padding: calc(40vmax / 10) 4vw calc(40vmax / 10) 4vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

function HowDoesItWork() {
  const content = [
    {
      title: "Add your contacts to Revmo",
      text: "Add contacts directly from search, through CSV, or email. Quick and easy!",
      image: screenshot1,
    },
    {
      title: "Search your extended network",
      text: "Search for specific people, profiles, organisations or apply your own criteria. Revmo will find and quantify the best paths.",
      image: screenshot2,
    },
    {
      title: "Request introductions",
      text: "Select the people you'd like to meet. Your connection can make the introduction instantly or offline.",
      image: screenshot3,
    },
  ];
  return (
    <SectionWrapper>
      <HowDoesItWorkWrapper>
        <h1>How Does Revmo Work?</h1>
        <IntroductionSection content={content} />
      </HowDoesItWorkWrapper>
    </SectionWrapper>
  );
}

export default HowDoesItWork;
