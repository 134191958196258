import { Auth } from "aws-amplify";
import * as yup from "yup";

export const registerUser = async (email, password, setAlertMessage) => {
  try {
    setAlertMessage("");
    return await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
      },
    });
  } catch (error) {
    setAlertMessage(error.message);
    throw error;
  }
};

export const confirmUser = async (
  username,
  confirmCode,
  setAlertMessage,
  customErrorMessages
) => {
  try {
    const userHasBeenCreated = await Auth.confirmSignUp(username, confirmCode);
    if (userHasBeenCreated === "SUCCESS") {
      setAlertMessage("");
      return true;
    }
  } catch (error) {
    if (error.name === "InvalidParameterException") {
      setAlertMessage("Please enter a valid code");
    } else {
      setAlertMessage(customErrorMessages[error.message]);
    }
  }
};

export const registerUserSchema = yup.object({
  email: yup
    .string()
    .email("Email is not properly formatted")
    .required("Please enter your email"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Please make sure your passwords match"
    ),
  privacyPolicy: yup.bool().oneOf([true], "You must accept our privacy policy"),
});

export const referralCodeSchema = yup.object({
  referralCode: yup.string().required("Please enter your referral code"),
});

export const joinWaitlistSchema = yup.object({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter a valid email address"),
  company: yup.string().required("Please enter the name of your place of work"),
});

export const resendConfirmationCode = async (userEmail) => {
  try {
    await Auth.resendSignUp(userEmail);
  } catch (err) {
    console.log(err);
  }
};
