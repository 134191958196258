import React from "react";
import styled from "styled-components";
import {
  MidLargeSpacedText,
  MidLargeBoldTitleText,
  SmallBlackBoldText,
} from "components/Typography";
import { SettingsOptionsTypeContainer } from "../../Layout";
import { SelectedOptions } from "..";

const SettingsContactOptionsFrequency = styled.div`
  display: flex;
  width: 100%;
  max-width: 682px;
  align-items: center;
  justify-content: space-between;
  margin-top: 71px;
  position: relative;
  padding-bottom: 28px;
`;

const GreyOptionSelectLine = styled.div`
  left: 15px;
  right: 20px;
  top: 3px;
  height: 6px;
  background: #e3e6e9;
  position: absolute;
`;

function ContactFrequency({
  contactTypePreference,
  updateContactTypePreference,
  contactFrequency,
  setContactFrequency,
}) {
  const { email, sms, doNotContact } = contactTypePreference;
  return (
    <>
      <MidLargeBoldTitleText>Contact Frequency</MidLargeBoldTitleText>
      <MidLargeSpacedText>
        How many times a week should Revmo contact you about new connections?
      </MidLargeSpacedText>
      <SettingsOptionsTypeContainer>
        <SelectedOptions
          fieldName={"Email"}
          setState={updateContactTypePreference}
          optionName={"email"}
          currentlySelected={email}
        />
        <SelectedOptions
          fieldName={"SMS"}
          setState={updateContactTypePreference}
          optionName={"sms"}
          currentlySelected={sms}
        />
        <SelectedOptions
          fieldName={"Do not contact me"}
          setState={updateContactTypePreference}
          optionName={"doNotContact"}
          currentlySelected={doNotContact}
        />
      </SettingsOptionsTypeContainer>
      <SettingsContactOptionsFrequency>
        <GreyOptionSelectLine />
        <ContactFrequencyFormInput
          contactFrequency={contactFrequency}
          setContactFrequency={setContactFrequency}
          value={1}
        />
        <ContactFrequencyFormInput
          contactFrequency={contactFrequency}
          setContactFrequency={setContactFrequency}
          value={5}
        />
        <ContactFrequencyFormInput
          contactFrequency={contactFrequency}
          setContactFrequency={setContactFrequency}
          value={10}
        />
        <ContactFrequencyFormInput
          contactFrequency={contactFrequency}
          setContactFrequency={setContactFrequency}
          value={20}
        />
        <ContactFrequencyFormInput
          contactFrequency={contactFrequency}
          setContactFrequency={setContactFrequency}
          value={"No limit"}
        />
      </SettingsContactOptionsFrequency>
    </>
  );
}

const SettingsOptionsFrequencySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 49px;
  justify-content: space-between;
  width: 33px;
  p {
    width: max-content;
  }
`;

const GreyCircle = styled.div`
  background: #e3e6e9;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  z-index: 10;
  cursor: pointer;
  ${(props) => {
    if (props.selected) {
      return `
        height: 22px;
        width: 22px;
        background: #18BFA1;
        transform: translateY(-5px);
        `;
    }
  }}
`;

function ContactFrequencyFormInput({
  contactFrequency,
  setContactFrequency,
  value,
}) {
  return (
    <SettingsOptionsFrequencySection>
      <GreyCircle
        selected={contactFrequency === value}
        onClick={() => setContactFrequency(value)}
      />
      <SmallBlackBoldText>{value}</SmallBlackBoldText>
    </SettingsOptionsFrequencySection>
  );
}

export default ContactFrequency;
