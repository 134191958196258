import React from "react";
import { AuthButton } from "components/Button";
import { HeaderText } from "components/Typography";
import { WhiteLargeFormInputHooksForm } from "components/Input";
import {
  AuthFormWrapperBackButton,
  AuthForm,
  AuthBackButton,
} from "components/Auth";
import { setNewPasswordSchema } from "../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

function SetNewPassword({
  resetPassword,
  alertMessage,
  returnToCheckPasswordForAccount,
}) {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(setNewPasswordSchema),
  });

  if (alertMessage.includes("code")) {
    errors.confirmationCode = {};
    errors.confirmationCode.message = alertMessage;
  } else {
    errors.password = {};
    errors.password.message = alertMessage;
  }

  return (
    <AuthFormWrapperBackButton>
      <AuthBackButton goBack={returnToCheckPasswordForAccount} />
      <HeaderText>Select your new password</HeaderText>
      <AuthForm>
        <WhiteLargeFormInputHooksForm
          fieldName="Confirmation Code"
          register={register}
          error={errors}
          name="confirmationCode"
          bold
        />
        <WhiteLargeFormInputHooksForm
          fieldName="Password"
          register={register}
          error={errors}
          name="password"
          bold
          type="password"
        />
        <WhiteLargeFormInputHooksForm
          fieldName="Confirm Password"
          register={register}
          error={errors}
          name="confirmPassword"
          bold
          type="password"
        />
        <AuthButton onClick={handleSubmit(resetPassword)}>
          Reset Password
        </AuthButton>
      </AuthForm>
    </AuthFormWrapperBackButton>
  );
}

export default SetNewPassword;
