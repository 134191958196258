import React from "react";
import styled from "styled-components";
import { SkeletonPulse } from "components/Skeleton";
import {
  LargeBoldText,
  LargeBoldTextLight,
  MidSizedText,
  BlueLinkTextUnderlined,
} from "components/Typography";
import blackLogoName from "assets/icons/revmo-logo-name.svg";
import blackLogoIcon from "assets/icons/revmo-logo-icon.svg";
import { UserImageBlueBorder } from "components/Assets/Image";
import blueWhiteDiagonal from "assets/images/blue-white-diagonal.svg";

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 8px 7px 0 7px;
  box-sizing: border-box;
  min-height: max-content;
`;

const IconContainer = styled.div`
  margin-bottom: 25px;
`;

const ScoreDisplayGraphic = styled.div`
  width: 151px;
  min-height: 41px;
  margin-top: 11px;
  display: flex;
  background: #0069ff;
  border-radius: 10px;
  border: 3px solid #0069ff;
  overflow: hidden;
  position: relative;
`;

const ScoreDisplayUnit = styled.div`
  width: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => {
    if (props.right) {
      return `
       background: white;
       flex-grow: 1;
      `;
    }
  }}
`;

const WhiteDiagonalSquare = styled.img`
  position: absolute;
  left: 66px;
  height: 45px;
  width: 12px;
  top: 0;
`;

const SideBarBlackLogoIcon = styled.img`
  height: 24px;
  width: 28px;
  margin-right: 10px;
`;

const SideBarBlackLogoName = styled.img`
  width: 87px;
  height: 20px;
`;

const SkeletonLine = styled(SkeletonPulse)`
  width: 5.5em;
  border-radius: 5px;
`;

const SkeletonLinePosition = styled(SkeletonLine)`
  width: 210px;
  height: 18px;
`;

const SkeletonLineScoreDisplay = styled(SkeletonLine)`
  width: 151px;
  min-height: 39px;
  height: 39px;
  margin-top: 10px;
`;

const SkeletonLineUserImage = styled(SkeletonLine)`
  min-height: 166px;
  height: 166px;
  width: 166px;
  border-radius: 100%;
  margin-bottom: 9px;
  @media (max-width: 480px) {
    height: 100px;
    min-height: 100px;
    width: 100px;
  }
`;

const SkeletonLineUserName = styled(SkeletonLine)`
  height: 20px;
  width: 150px;
`;

const UserAnalyticsWrapper = styled.div`
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const UserAnalyticsOutlinePrimary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f6f8;
  border-radius: 8px;
  padding: 10px;
  @media (max-width: 767px) {
    width: 271px;
  }
  @media (max-width: 480px) {
    width: 100vw;
    border-radius: unset;
    padding: 15px;
  }
  ${(props) => {
    if (props.onboarding) {
      return `
       background: none;
       @media (max-width: 480px) {
         width: 100%;
       }
      `;
    }
  }}
`;

const UserAnalyticsPersonalDataContainer = styled.aside`
  background: white;
  text-align: center;
  overflow-y: scroll;
  width: 261px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  padding: 16px 0 18px 0;
  z-index: 30;
  top: 0;
  ::-webkit-scrollbar {
    display: none;
  }
  transition: right 0.3s ease;
  ${(props) => {
    if (props.showHiddenSideNav) {
      return `
          top: 0px;
        `;
    } else {
      return `
          right: -281px;
        `;
    }
  }}
  @media (max-width: 480px) {
    width: 100%;
  }
`;

function UserAnalytics({
  userAnalytics,
  userContact,
  profile,
  setShowUpdateProfileImageModal,
  onboarding,
}) {
  return (
    <UserAnalyticsWrapper>
      <UserAnalyticsPersonalData
        result={userAnalytics}
        profile={profile}
        setShowUpdateProfileImageModal={setShowUpdateProfileImageModal}
        userContact={userContact}
        onboarding={onboarding}
      />
    </UserAnalyticsWrapper>
  );
}

function UserAnalyticsPersonalData({
  profile,
  setShowUpdateProfileImageModal,
  userContact,
  onboarding,
}) {
  const { profile_image, first_name, last_name, revmo_network_score } = profile;

  let career;
  if (profile?.positions) {
    career = [...profile.positions].sort((a, b) => {
      return b.seq_no - a.seq_no;
    });
  }

  return (
    <UserAnalyticsOutlinePrimary onboarding={onboarding}>
      <UserAnalyticsPersonalDataContainer>
        <>
          {!onboarding && (
            <LogoContainer>
              <IconContainer>
                <SideBarBlackLogoIcon src={blackLogoIcon} />
                <SideBarBlackLogoName src={blackLogoName} />
              </IconContainer>
            </LogoContainer>
          )}
          {profile?.first_name ? (
            <UserImageBlueBorder
              userProfileImage={profile_image}
              onboarding={onboarding}
            />
          ) : (
            <SkeletonLineUserImage />
          )}
          <LargeBoldText>
            {profile?.first_name ? (
              `${first_name} ${last_name}`
            ) : (
              <SkeletonLineUserName />
            )}
          </LargeBoldText>
          <span>
            {first_name ? (
              <MidSizedText>
                <strong>{career[0]?.role_desc}</strong>&nbsp;
                {career[0]?.org_name}
              </MidSizedText>
            ) : (
              <SkeletonLinePosition />
            )}
          </span>
          {profile?.first_name ? (
            <ScoreDisplayGraphic>
              <ScoreDisplayUnit>
                <LargeBoldTextLight>
                  {revmo_network_score || 458}
                </LargeBoldTextLight>
              </ScoreDisplayUnit>
              <WhiteDiagonalSquare src={blueWhiteDiagonal} />
              <ScoreDisplayUnit right>
                <LargeBoldText>800</LargeBoldText>
              </ScoreDisplayUnit>
            </ScoreDisplayGraphic>
          ) : (
            <SkeletonLineScoreDisplay />
          )}
          {!onboarding && !userContact && (
            <BlueLinkTextUnderlined
              onClick={() => setShowUpdateProfileImageModal(true)}
            >
              Edit
            </BlueLinkTextUnderlined>
          )}
        </>
      </UserAnalyticsPersonalDataContainer>
    </UserAnalyticsOutlinePrimary>
  );
}

export default UserAnalytics;
