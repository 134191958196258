import React, { useState, useEffect, memo } from "react";
import { RequestIntroductionPanel } from "components/RequestIntroductionPanel";
import {
  usePagination,
  useIntroductionRequest,
  useProfile,
  useInternationalDiallingCodes,
} from "hooks";
import Grid from "@mui/material/Grid";
import { SearchResultsTableNew, SearchHeader } from "./components";
import { createSingleContact } from "utils";
import { PageWrapper } from "components/Layout";
import { updateSinglePageSearch, searchForMatches } from "./utils";
import { useDisplayOptions, useCustomFormErrors } from "../../hooks";
import {
  SuccessModal,
  ErrorModal,
  AddOrEditContactModal,
} from "components/Modal";
import { useRecoilValue } from "recoil";
import { walkthroughSearchResults } from "state/walkthrough";
import { walkthroughState } from "state/recoil/walkthrough";
import * as _ from "lodash";

function Search(props) {
  const { profile } = useProfile();
  const [searchResultsAll, setSearchResultsAll] = useState({});
  const defaultSearchState = {
    people: [],
    results: null,
    user_count: null,
    loading: false,
  };
  const [searchResultSinglePage, setSearchResultSinglePage] =
    useState(defaultSearchState);
  const MemoizedSearchResultTable = memo(SearchResultsTableNew);
  const introductionRequestHook = useIntroductionRequest();
  const [selectModalToDisplay, setSelectModalToDisplay] = useState(null);
  const [knownContactAddress, setKnownContactAddress] = useState({});
  const [knownContactDetails, setKnownContactDetails] = useState({});
  const [searchRequestHistory, setSearchRequestHistory] = useState(
    props.location.state
  );
  const [error, setError] = useState("");
  const customFormErrorsHook = useCustomFormErrors();

  const { nextPage, prevPage, jumpPage, currentPage, maxPage } = usePagination(
    searchResultSinglePage?.results,
    10
  );

  const resetSearch = () => {
    setSearchResultSinglePage(defaultSearchState);
  };

  const diallingCodesHook = useInternationalDiallingCodes();
  const { diallingCode } = diallingCodesHook;

  const { displayOptions, toggleDisplayOption } = useDisplayOptions(
    searchResultSinglePage?.people?.length
  );

  const walkthrough = useRecoilValue(walkthroughState);

  useEffect(() => {
    const fetchSearchResults = async () => {
      const searchResults = await searchForMatches(
        searchRequestHistory,
        setError
      );
      setSearchResultsAll({
        ...searchResultsAll,
        people: searchResults,
        loading: false,
        results: searchResults.length,
      });
    };
    if (searchRequestHistory) {
      setSearchResultSinglePage({ ...searchResultSinglePage, loading: true });
      // check for history in the instantiation of state
      fetchSearchResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequestHistory]);

  useEffect(() => {
    if (_.isEqual(props.location.state, searchRequestHistory)) {
      return;
    } else if (props.location.state) {
      // Should only occur when pressing back
      setSearchRequestHistory(props.location.state);
      jumpPage(1);
      setSearchResultSinglePage({ ...searchResultSinglePage, loading: true });
    } else {
      setSearchRequestHistory(props.location.state);
      setSearchResultSinglePage({
        people: [],
        results: null,
        user_count: null,
        loading: false,
      });
    }
  }, [
    jumpPage,
    props.location.state,
    searchRequestHistory,
    searchResultSinglePage,
  ]);

  useEffect(() => {
    if (walkthrough) {
      setSearchResultSinglePage({
        ...walkthroughSearchResults,
        loading: false,
      });
    }
  }, [walkthrough]);

  useEffect(() => {
    //The purpose of this useEffect hooks is pagination
    const singlePageResults = async () => {
      const results = await updateSinglePageSearch(
        searchResultsAll,
        currentPage
      );
      // turn searchRequestHistory into an atom
      setSearchResultSinglePage({ ...results, loading: false });
    };
    if (searchResultsAll?.people) {
      singlePageResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchResultsAll]);

  const handleSubmitSearchRequest = async (
    data,
    searchOptions,
    searchAddress
  ) => {
    jumpPage(1);
    try {
      const { person } = data;
      let updatedSearchOptions = {
        ...searchOptions,
        person,
      };
      if (_.isEqual(updatedSearchOptions, searchRequestHistory)) {
        return;
      }

      if (searchAddress.location && !searchAddress.professionalLatLng) {
        delete updatedSearchOptions.location;
      } else if (!searchAddress.location) {
        delete updatedSearchOptions.location;
      }

      const location = {
        pathname: "/search",
        state: updatedSearchOptions,
      };
      props.history.push(location);
    } catch (err) {
      console.log(err);
      setSearchResultSinglePage({ ...searchResultSinglePage, loading: false });
    }
  };

  const handleSubmitManuallyAddNewContact = async (data, contactAddress) => {
    try {
      await createSingleContact(data, contactAddress, diallingCode);
      setSelectModalToDisplay("Success");
    } catch (err) {
      console.log(err);
    }
  };

  const showAddContactModal = (index, contact) => {
    setSelectModalToDisplay("Add contact");
    setKnownContactDetails(contact);
  };

  const closeAddOrEditContactModal = () => {
    setSelectModalToDisplay(null);
    customFormErrorsHook.clearCustomFormErrors();
  };

  return (
    <PageWrapper>
      <RequestIntroductionPanel
        introductionRequestHook={introductionRequestHook}
        useCase={searchRequestHistory?.introductionUseCase}
        profile={profile}
        walkthrough={walkthrough}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SearchHeader
            searchRequestHistory={searchRequestHistory}
            handleSubmitSearchRequest={handleSubmitSearchRequest}
            historyProps={props.location.state}
            profile={profile}
            setError={setError}
            resetSearch={resetSearch}
          />
        </Grid>
        <Grid item xs={12} sx={{ paddingBottom: 2 }}>
          <MemoizedSearchResultTable
            nextPage={nextPage}
            prevPage={prevPage}
            jumpPage={jumpPage}
            currentPage={currentPage}
            maxPage={maxPage}
            searchResult={searchResultSinglePage}
            numberOfSearchResults={searchResultsAll?.results}
            viewIntroductionPathwayToNetworkMember={
              introductionRequestHook.viewIntroductionPathwayToNetworkMember
            }
            searchRequestHistory={searchRequestHistory}
            displayOptions={displayOptions}
            toggleDisplayOption={toggleDisplayOption}
            showAddContactModal={showAddContactModal}
            error={error}
          />
          {selectModalToDisplay === "Add contact" && (
            <AddOrEditContactModal
              setDisplayModal={closeAddOrEditContactModal}
              handleSubmitManuallyAddOrEditNewContact={
                handleSubmitManuallyAddNewContact
              }
              contactAddress={knownContactAddress}
              setContactAddress={setKnownContactAddress}
              contactDetails={knownContactDetails}
              diallingCodesHook={diallingCodesHook}
              customFormErrorsHook={customFormErrorsHook}
            />
          )}
          <SuccessModal
            displayModal={selectModalToDisplay === "Success"}
            setDisplayModal={setSelectModalToDisplay}
            message="You successfully updated your Revmo network."
          />
          <ErrorModal
            displayModal={selectModalToDisplay === "Failed"}
            setDisplayModal={setSelectModalToDisplay}
            useCase={"manualUpload"}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}

export default Search;
