import React from "react";
import {
  MidLargeBoldTitleText,
  MidLargeSpacedText,
} from "components/Typography";
import { SelectedOptions } from "../";
import {
  SettingsSectionRowBorder,
  SettingsOptionsTypeContainer,
} from "../../Layout";

function TrustedContactInteractions({
  updateContactInteractions,
  contactInteractions,
}) {
  return (
    <SettingsSectionRowBorder>
      <MidLargeBoldTitleText>
        Trusted Contact Interactions
      </MidLargeBoldTitleText>
      <MidLargeSpacedText>
        Choose if trusted contacts on Revmo can contact you. Ths happens when an
        introduction or interaction can be made.
      </MidLargeSpacedText>
      <SettingsOptionsTypeContainer>
        <SelectedOptions
          fieldName={"Yes"}
          setState={updateContactInteractions}
          optionName={"yes"}
          currentlySelected={contactInteractions === "yes"}
        />
        <SelectedOptions
          fieldName={"A little"}
          setState={updateContactInteractions}
          optionName={"a little"}
          currentlySelected={contactInteractions === "a little"}
        />
        <SelectedOptions
          fieldName={"No"}
          setState={updateContactInteractions}
          optionName={"no"}
          currentlySelected={contactInteractions === "no"}
        />
      </SettingsOptionsTypeContainer>
    </SettingsSectionRowBorder>
  );
}

export default TrustedContactInteractions;
