import React, { useReducer, useEffect } from "react";
import styled from "styled-components";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { INITIAL_STATE, walkthroughReducer } from "state/walkthrough";
import {
  walkthroughState,
  showRequestIntroPanelState,
} from "state/recoil/walkthrough";

const HiddenBeacon = styled.div`
  display: none;
`;

// Define the Walkthrough component
const Walkthrough = () => {
  const [tourState, dispatch] = useReducer(walkthroughReducer, INITIAL_STATE);
  const [walkthrough, setWalkthrough] = useRecoilState(walkthroughState);
  const [showRequestIntroPanel, setShowRequestIntroPanel] = useRecoilState(
    showRequestIntroPanelState
  );

  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    if (walkthrough) {
      if (location.pathname == "/dashboard") {
        dispatch({ type: "START" });
      } else if (location.pathname == "/search") {
        dispatch({ type: "SEARCH" });
      } else if (location.pathname == "/introductions") {
        dispatch({ type: "CHECK_REQUESTS" });
      }
    }
  }, [walkthrough, location]);

  const callback = async (data) => {
    const { action, index, type, status } = data;
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });
      setWalkthrough(false);
      setShowRequestIntroPanel(false);
    } else if (
      index == 1 &&
      type === EVENTS.STEP_AFTER &&
      action === ACTIONS.NEXT
    ) {
      history.push("/search");
    } else if (
      index == 4 &&
      action === ACTIONS.NEXT &&
      type === EVENTS.STEP_AFTER &&
      !showRequestIntroPanel
    ) {
      history.push("/introductions");
    } else if (
      index == 8 &&
      action === ACTIONS.NEXT &&
      type === EVENTS.STEP_AFTER
    ) {
      setShowRequestIntroPanel(false);
      history.push("/introductions");
    } else if (index == 2 && action === ACTIONS.PREV) {
      history.push("/dashboard");
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={true}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
          options: {
            primaryColor: "#0069ff",
            zIndex: 5000,
          },
          buttonBack: {
            marginRight: 10,
          },
          overlay: { height: "100%" },
        }}
        locale={{
          last: "End tour",
        }}
        scrollToFirstStep={true}
        scrollOffset={100}
        beaconComponent={HiddenBeacon}
      />
    </>
  );
};

export default Walkthrough;
