import React from "react";
import { SearchFormInput } from "components/Input";
import { OrgsAutocompleteSearch } from "components/Autocomplete/OrgsAutocomplete";
import { RoleAutocomplete } from "components/Autocomplete";
import Grid from "@mui/material/Grid";
import { gridSpacing } from "containers/DashboardNew/theme";
import Box from "@mui/material/Box";

function SearchForm({ register, advancedSearchHook }) {
  const { searchOptions, setSearchOptions } = advancedSearchHook;

  return (
    <Box mb={3}>
      <Grid container spacing={gridSpacing} justifyContent="center">
        <Grid item xs={12} md={4}>
          <SearchFormInput
            fieldName="Person"
            placeholder="Ex. Art Vandelay"
            name="person"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <OrgsAutocompleteSearch
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RoleAutocomplete
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
export default SearchForm;
