import * as yup from "yup";

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email address is not properly formatted")
    .required("Please enter your email address"),
  password: yup.string().required("Please enter your password"),
});

export default loginSchema;
