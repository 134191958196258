import React from "react";
import styled from "styled-components";
import { SectionWrapperDark } from "containers/LandingPage/Layout";
import { ArrowSection } from "components/LandingPage";

const ProblemsWeSolveContentWrapper = styled.div`
  padding: calc(40vmax / 10) 4vw calc(40vmax / 10) 4vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1,
  h2,
  h4,
  p {
    color: white;
  }
  h1 {
    padding-bottom: 1rem;
  }
`;

function ProblemsWeSolve() {
  const content = [
    {
      title: "Are you wasting money on leads you cannot reach?",
      text: "Companies spend billions a year on relationship enablement and lead generation platforms that are not reaching the right people. Billions of cold emails and spam messages are not yielding results.",
    },
    {
      title:
        "Are you losing business because the right people aren't involved?",
      text: "Organisations are complex - teams sit apart and use their own CRM systems.  Your colleague might know the person you need to close your deal or raise your capital - and you don't even know it!",
    },
    {
      title: "Desire to acquire?",
      text: "Relationship centred businesses have big plans for acquisitions but are not prepared to integrate the relaitonships that come along with them.",
    },
  ];
  return (
    <SectionWrapperDark>
      <ProblemsWeSolveContentWrapper>
        <h1>What can we fix for you?</h1>
        <ArrowSection content={content} />
      </ProblemsWeSolveContentWrapper>
    </SectionWrapperDark>
  );
}

export default ProblemsWeSolve;
