import React from "react";
import { SmallBlackBoldText, ExtraSmallBlackText } from "../Typography";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  EditProfileContent,
  AddNewPositionHeader,
  ProfileModalContainerAddPosition,
} from "./index";
import { ButtonPrimaryWithArrow } from "../Button";
import closeIcon from "../../assets/icons/close-icon.svg";

export function UpdateRequestStatus({ displayModal, setDisplayModal }) {
  return (
    <>
      {displayModal && (
        <ProfileModalPageWrapper>
          <DarkBlurredBackground onClick={() => setDisplayModal(false)} />
          <ProfileModalContainerAddPosition>
            <CloseIconImage
              src={closeIcon}
              onClick={() => setDisplayModal(false)}
            />
            <EditProfileContent>
              <AddNewPositionHeader>
                <SmallBlackBoldText>Position details</SmallBlackBoldText>
                <ExtraSmallBlackText>
                  *Denotes required field
                </ExtraSmallBlackText>
              </AddNewPositionHeader>

              <ButtonPrimaryWithArrow
                buttonText={"Update"}
                padding={"11px 30px"}
              />
            </EditProfileContent>
          </ProfileModalContainerAddPosition>
        </ProfileModalPageWrapper>
      )}
    </>
  );
}
