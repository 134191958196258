import React from "react";
import styled from "styled-components";
import revmoPageHeader from "assets/images/revmo-page-header.png";

const HeaderContainer = styled.div`
  height: 260px;
  width: 100%;
  background: linear-gradient(rgb(220 236 246 / 0%), rgb(220 236 246 / 0%)),
    url(${revmoPageHeader}) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
`;

const TextContainer = styled.div`
  max-width: 700px;
  h1,
  a {
    color: #337ab9;
  }
  span {
    line-height: 1.5;
    font-weight: 600;
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif;
  }
  h1 {
    font-size: 2.8rem;
    background-color: #000a33;
    color: white;
    width: fit-content;
    span {
      font-size: 2.8rem;
      line-height: normal;
    }
  }
`;

const BlueContainer = styled.div`
  background-color: #ffffff5c;
  padding: 0.2em 3em 0.2em 0.2em;
`;

const DarkBlueContainer = styled.div`
  margin-bottom: 1rem;
  width: fit-content;
  background-color: #000a33;
  padding: 0.1em 1em 0.1em 0.1em;
`;

function PressHeader() {
  return (
    <HeaderContainer>
      <TextContainer>
        <DarkBlueContainer>
          <h1>
            Press Centre<span style={{ color: "#4dd9f2" }}>.</span>
          </h1>
        </DarkBlueContainer>
        <BlueContainer>
          <span>The latest press releases from Revmo.</span>
          <br />
          <span>
            Any questions? Contact our team:{" "}
            <a href="mailto:press@revmo.app">press@revmo.app</a>
          </span>
        </BlueContainer>
      </TextContainer>
    </HeaderContainer>
  );
}

export default PressHeader;
