import React from "react";
import {
  HeaderText,
  MidLargeSpacedText,
  SmallBlackBoldText,
  SmallGreenText,
  ExtraSmallBlackText,
} from "components/Typography";
import { ButtonPrimaryWithArrow } from "components/Button";
import { WhiteLargeFormInputHooksForm } from "components/Input";
import {
  OnboardingForm,
  FooterWrapper,
  InformationTitleContainerProfessionalData,
  HeaderContainerMarginTop,
} from "components/Onboarding";
import { useForm } from "react-hook-form";

// search contacts in use ReferAFriend
// Add contacts to a recoilState

function OnboardingAddContacts({ handleChangeCompanyData }) {
  const { register, errors, watch, handleSubmit } = useForm();

  const { firstName, lastName } = watch();
  const disabled = !firstName?.length || !lastName?.length;

  return (
    <>
      <HeaderContainerMarginTop>
        <HeaderText>Add contacts to your company</HeaderText>
        <SmallGreenText>2 / 3</SmallGreenText>
      </HeaderContainerMarginTop>
      <MidLargeSpacedText>
        Search your contacts below, and add them to your company on the Revmo
        network. If the individual is already part of Revmo, they will receive
        an invite and their account will come under your subscription. If the
        person has never used Revmo, we will share a sign up link.
      </MidLargeSpacedText>
      <InformationTitleContainerProfessionalData>
        <SmallBlackBoldText>Company members</SmallBlackBoldText>
        <ExtraSmallBlackText>*Denotes required field</ExtraSmallBlackText>
      </InformationTitleContainerProfessionalData>
      <OnboardingForm>
        <WhiteLargeFormInputHooksForm
          name="firstName"
          fieldName={"First Name"}
          error={errors}
          register={register}
        />
        <WhiteLargeFormInputHooksForm
          fieldName="Last Name"
          register={register}
          error={errors}
          name="lastName"
        />
      </OnboardingForm>
      <FooterWrapper>
        <ButtonPrimaryWithArrow
          action={handleSubmit(handleChangeCompanyData)}
          buttonText={"Next"}
          disabled={disabled}
          padding={"11px 30px"}
        />
      </FooterWrapper>
    </>
  );
}

export default OnboardingAddContacts;
