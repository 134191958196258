import React from "react";

// material-ui
import { Grid, Typography } from "@mui/material";

// project imports
import { MainCard } from "components/DashboardNew";
import { borderRadius } from "containers/DashboardNew/theme";

export default function SearchMainCard({
  loading,
  title,
  currentPage,
  maxPage,
  error,
  children,
}) {
  function renderPageNumber(loading, maxPage, currentPage, error) {
    if (loading && !error) {
      return `Searching Revmo...`;
    } else if (error || maxPage === 0) {
      return ``;
    } else {
      return `Showing page ${currentPage} of ${maxPage} `;
    }
  }

  return (
    <MainCard
      title={
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              {renderPageNumber(loading, maxPage, currentPage, error)}
            </Typography>
          </Grid>
        </Grid>
      }
      content={false}
      sx={{
        borderRadius: { borderRadius },
        border: "none",
        marginBottom: 2,
        ["@media (max-width:1200px)"]: {
          // eslint-disable-line no-useless-computed-key
          boxShadow: "none",
        },
      }}
    >
      {children}
    </MainCard>
  );
}
