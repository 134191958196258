import React from "react";
import styled from "styled-components";
import { MidSizedGreyTextSpaced } from "components/Typography";
import { UseCaseDropdown } from "components/Dropdown";

const UseCaseDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 870px) {
    align-items: flex-start;
  }
  p {
    @media (max-width: 870px) {
      margin: 0 0 10px 0;
      width: unset;
    }
  }
`;

const DropdownErrorText = styled.p`
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.311111px;
  color: red;
  text-decoration: none;
  margin-top: 10px;
`;

function RequestIntroductionUseCaseDropdown({
  handleChange,
  useCase,
  introductionRequestHasBeenSent,
  showError,
}) {
  return (
    <UseCaseDropdownWrapper className="walkthrough-step-7">
      {!introductionRequestHasBeenSent && (
        <MidSizedGreyTextSpaced>
          Please specify the reason for the introduction:
        </MidSizedGreyTextSpaced>
      )}
      <UseCaseDropdown
        handleChange={handleChange}
        useCase={useCase}
        introductionRequestHasBeenSent={introductionRequestHasBeenSent}
        showError={showError}
      />
      {showError ? (
        <DropdownErrorText>You must specify a reason.</DropdownErrorText>
      ) : (
        <></>
      )}
    </UseCaseDropdownWrapper>
  );
}

export default RequestIntroductionUseCaseDropdown;
