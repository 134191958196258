import React from "react";
import styled from "styled-components";
import { FilterIconGrey } from "components/Assets/Icon";
import { DownwardChevron } from "components/Assets";
import {
  DropdownWrapper,
  DropdownContainer,
  CustomOption,
} from "components/AdvancedSearch/components/AdvancedSearchCustomOptions";
import { useToggleDropdown } from "hooks";
import { getTagName, getTagUUIDs } from "../utils";

const TagFilterWrapper = styled.div`
  width: 184px;
  margin-left: 25px;
  @media (max-width: 870px) {
    width: unset;
    flex: 1;
    margin-left: unset;
  }
  @media (max-width: 480px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;

`;

const TagFilterHeaderText = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #313237;
  margin-bottom: 7px;
`;

const TagFilter = styled.div`
  padding-left: 9px;
  position: relative;
  font-size: 12px;
  cursor: pointer;
  text-overflow: ellipsis;
  background: #fcfcfc;
  border: 1px solid #e3e6e9;
  box-sizing: border-box;
  max-width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  height: 35px;
  width: 100%;
  position: relative;
  ${(props) => {
    if (props.selected) {
      return `
        background: ${props.theme.brandColor};
        p {
          color: white;
        }
        svg {
          path {
            fill: white;
          }
        }
      `;
    }
  }}
`;

const TagFilterInternalText = styled.p`
  color: #7c919e;
  font-weight: 500;
  font-size: 12px;
  margin: 0 20px 0 6px;
  text-overflow: ellipsis;
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
`;

const TagFilterInner = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  ${(props) => {
    if (props.dropdownIsOpen) {
      return `
        pointer-events: none;
      `;
    }
  }}
`;

function SearchTagFilter({ contactAndOrgsTagHook }) {
  const { allTags, toggleSearchFilter, searchFilter, selectTag } =
    contactAndOrgsTagHook;

  return (
    <>
      <TagFilterWrapper>
        <TagFilterHeaderText>Select a tag</TagFilterHeaderText>
        <TagFilter selected={searchFilter.tags.length}>
          <TagFilterInner
            dropdownIsOpen={searchFilter.showSearchFilter}
            onClick={() => toggleSearchFilter()}
          />
          <FilterIconGrey />
          <TagFilterInternalText>
            {searchFilter.tags.length
              ? getTagName(searchFilter.tags).join(", ")
              : "Filter"}
          </TagFilterInternalText>
          <DownwardChevron />
          {searchFilter.showSearchFilter && allTags[0] && (
            <CustomFilterDropdown
              searchFilter={searchFilter}
              allTags={allTags}
              toggleSearchFilter={toggleSearchFilter}
              selectTag={selectTag}
            />
          )}
        </TagFilter>
      </TagFilterWrapper>
    </>
  );
}

function CustomFilterDropdown({
  allTags,
  toggleSearchFilter,
  selectTag,
  searchFilter,
}) {
  const { dropdownRef } = useToggleDropdown(toggleSearchFilter);
  const { tags } = searchFilter;
  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownContainer>
        {allTags.map((tag, index) => {
          return (
            <CustomOption
              key={index}
              onClick={() => selectTag({ name: tag.name, uuid: tag.uuid })}
              selected={getTagUUIDs(tags).includes(tag.uuid)}
              last={index === allTags.length - 1 ? true : false}
            >
              {tag.name}
            </CustomOption>
          );
        })}
      </DropdownContainer>
    </DropdownWrapper>
  );
}

export default SearchTagFilter;
