import React from "react";
import styled from "styled-components";
import verifiedUserIcon from "../../assets/icons/verified-user.svg";

const SelectContentRowWrapperFullWidth = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px #efefef;
  width: 100vw;
`;

const SelectContentRowOptions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 653px;
`;

const SelectContentRowWrapperMaxWidth = styled.div`
  display: flex;
  padding: 37px 20px 0px 20px;
  width: 100%;
  max-width: 1132px;
  @media (max-width: 480px) {
    padding: 19px 19px 0 19px;
  }
`;

const SelectContentRowWrapperMaxWidthNetwork = styled(
  SelectContentRowWrapperMaxWidth
)`
  @media (max-width: 321px) {
    padding: 19px 8px 0 8px;
  }
`;

const VerifiedUserIcon = styled.img`
  height: 26px;
  width: 23px;
  margin-right: 10px;
  @media (max-width: 480px) {
    display: none;
  }
`;

const SelectContentRowOptionsSettings = styled(SelectContentRowOptions)`
  width: 372px;
`;

export function NetworkSelectContentRow({ children }) {
  return (
    <SelectContentRowWrapperFullWidth>
      <SelectContentRowWrapperMaxWidthNetwork>
        <VerifiedUserIcon src={verifiedUserIcon} />
        <SelectContentRowOptions>{children}</SelectContentRowOptions>
      </SelectContentRowWrapperMaxWidthNetwork>
    </SelectContentRowWrapperFullWidth>
  );
}

export function SettingsSelectContentRow({ children }) {
  return (
    <SelectContentRowWrapperFullWidth>
      <SelectContentRowWrapperMaxWidth>
        <SelectContentRowOptionsSettings>
          {children}
        </SelectContentRowOptionsSettings>
      </SelectContentRowWrapperMaxWidth>
    </SelectContentRowWrapperFullWidth>
  );
}
