import React from "react";
import styled from "styled-components";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 23px 49px 0 49px;
  @media (max-width: 480px) {
    padding: 23px 15px 0 15px;
  }
`;

const AlertContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  ${(props) => {
    if (props.hideAlert) {
      return `
        display: none;
      `;
    }
  }}
`;

function RequestIntroductionPanelHeader({
  introductionRequestHasBeenSent,
  introductionRequestError,
}) {
  return (
    <>
      <HeaderBody>
        <AlertContainer hideAlert={!introductionRequestHasBeenSent}>
          <Alert severity="success" sx={{ width: "100%" }}>
            <AlertTitle>Success</AlertTitle>
            Your connection request was sent! Your path will receive a
            notification to effortlessly make the introduction. They can also
            opt to reject the request or make the request offline. You can
            always choose another path to reach your desired connection.
          </Alert>
        </AlertContainer>
        <AlertContainer
          hideAlert={introductionRequestHasBeenSent || introductionRequestError}
        >
          <Alert severity="info" sx={{ width: "100%" }}>
            <AlertTitle>Picking the best path for you</AlertTitle>
            Revmo finds the optimal contact to help you make your desired
            connection. This person has the highest likelihood of success in
            introducing you, and will receive a notification from Revmo to
            effortlessly make the introduction.
          </Alert>
        </AlertContainer>
        <AlertContainer hideAlert={!introductionRequestError}>
          <Alert severity="error" sx={{ width: "100%" }}>
            <AlertTitle>Request Error</AlertTitle>
            It looks like there was an error with this introduction request.
            Either try again, or try a different request.
          </Alert>
        </AlertContainer>
      </HeaderBody>
    </>
  );
}

export default RequestIntroductionPanelHeader;
