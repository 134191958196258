import React, { useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Button, Grid, Menu, MenuItem, Pagination } from "@mui/material";

// project imports
import {
  SearchMainCard,
  SearchResultsLoading,
  SearchUserErrorMessage,
  SearchResultsCards,
} from ".";
import { SearchResultsList } from "components/DashboardNew/List";
import SearchEmptyState from "./SearchEmptyState";

// assets
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import SearchNoResults from "./SearchNoResults";

function SearchResultsTableNew({
  nextPage,
  prevPage,
  jumpPage,
  currentPage,
  maxPage,
  searchResult,
  numberOfSearchResults,
  viewIntroductionPathwayToNetworkMember,
  searchRequestHistory,
  showAddContactModal,
  error,
}) {
  // Local state variables
  const theme = useTheme();

  const options = ["10 Rows", "20 Rows", "30 Rows"];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event, value) => {
    jumpPage(value);
  };

  return (
    <>
      <SearchMainCard
        loading={searchResult.loading}
        title="Search Results"
        currentPage={currentPage}
        maxPage={maxPage}
        error={error}
      >
        {searchResult?.loading && !error && <SearchResultsLoading />}
        {error && <SearchUserErrorMessage error={error} />}
        {numberOfSearchResults === 0 && !searchResult?.loading && !error && (
          <SearchNoResults />
        )}
        {searchResult?.results === null &&
          searchResult.loading == false &&
          !error && <SearchEmptyState />}
        {!!searchResult?.people?.length && !searchResult?.loading && (
          <>
            <SearchResultsList
              nextPage={nextPage}
              prevPage={prevPage}
              jumpPage={jumpPage}
              currentPage={currentPage}
              maxPage={maxPage}
              searchResult={searchResult}
              viewIntroductionPathwayToNetworkMember={
                viewIntroductionPathwayToNetworkMember
              }
              searchRequestHistory={searchRequestHistory}
              showAddContactModal={showAddContactModal}
              error={error}
            />
            <SearchResultsCards
              nextPage={nextPage}
              prevPage={prevPage}
              jumpPage={jumpPage}
              currentPage={currentPage}
              maxPage={maxPage}
              searchResult={searchResult}
              viewIntroductionPathwayToNetworkMember={
                viewIntroductionPathwayToNetworkMember
              }
              searchRequestHistory={searchRequestHistory}
              showAddContactModal={showAddContactModal}
              error={error}
            />
            <Grid item xs={12} sx={{ p: 3 }}>
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Pagination
                    count={maxPage}
                    color="primary"
                    showFirstButton
                    showLastButton
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </Grid>
                <Grid item>
                  <Button
                    size="large"
                    sx={{ color: theme.palette.grey[900] }}
                    color="secondary"
                    endIcon={<ExpandMoreRoundedIcon />}
                    onClick={handleClick}
                  >
                    {options[selectedIndex]}
                  </Button>
                  <Menu
                    id="menu-user-list-style1"
                    anchorEl={anchorEl}
                    // keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </SearchMainCard>
    </>
  );
}

export default SearchResultsTableNew;
