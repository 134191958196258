import React from "react";
import { Controller, useForm } from "react-hook-form";

// material-ui
import { Button, Grid, TextField, Stack } from "@mui/material";
import { AnimateButton } from "components/DashboardNew";
// project imports
import { gridSpacing } from "../theme";

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const UserProfile = ({ profile, handleSubmitUpdatePersonalInfo }) => {
  const { control, handleSubmit } = useForm();

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} sm={6} key={"first_name"}>
        <Controller
          name={"first_name"}
          control={control}
          render={({ onChange, value }) => (
            <TextField
              onChange={onChange}
              value={value}
              label={"First Name"}
              fullWidth
              defaultValue={profile?.first_name ?? ""}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} key={"last_name"}>
        <Controller
          name={"last_name"}
          control={control}
          render={({ onChange, value }) => (
            <TextField
              onChange={onChange}
              value={value}
              label={"Last Name"}
              fullWidth
              defaultValue={profile?.last_name ?? ""}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} key={"email_address"}>
        <TextField
          fullWidth
          disabled
          label="Email Address"
          defaultValue={profile?.login_email_address}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Stack direction="row">
          <AnimateButton>
            <Button
              variant="contained"
              size="large"
              // type="submit"
              onClick={handleSubmit(handleSubmitUpdatePersonalInfo)}
            >
              Update Profile
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default UserProfile;
