import { LandingFooter, LandingPageContainer } from "components/LandingPage";
import { LandingNavbar } from "components/Navbar";
import { usePageTitle } from "hooks";
import React from "react";
import { UpdatesHero, UpdatesListItems } from "./components";

function ProductUpdates() {
  usePageTitle("Updates | Revmo");
  return (
    <>
      <LandingPageContainer>
        <LandingNavbar />
        <UpdatesHero />
        <UpdatesListItems />
      </LandingPageContainer>
      <LandingFooter />
    </>
  );
}

export default ProductUpdates;
