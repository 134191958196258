import React from "react";
import { GridThreeSection } from "components/LandingPage";

function HowDoesItWorkEnterprise() {
  const content = {
    title: "How does it work?",
    cards: [
      {
        title: "Universal Data",
      },
      {
        title: "Federated Search",
      },
      {
        title: "Smart Weighting",
      },
    ],
  };
  return <GridThreeSection content={content} />;
}

export default HowDoesItWorkEnterprise;
