import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

function PositionTableSkeleton() {
  const theme = useTheme();

  return (
    <TableContainer>
      <Table
        sx={{
          "& td": {
            whiteSpace: "nowrap",
          },
          "& td:first-child": {
            pl: 0,
          },
          "& td:last-child": {
            pr: 0,
          },
          "& tbody tr:last-child  td": {
            borderBottom: "none",
          },
          [theme.breakpoints.down("xl")]: {
            "& tr:not(:last-child)": {
              borderBottom: "1px solid",
              borderBottomColor:
                theme.palette.mode === "dark"
                  ? "rgb(132, 146, 196, .2)"
                  : "rgba(224, 224, 224, 1)",
            },
            "& td": {
              display: "inline-block",
              borderBottom: "none",
              pl: 0,
            },
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                width: {
                  xs: "70%",
                  xl: "33%",
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell
              sx={{
                width: {
                  xs: "0%",
                  xl: "33%",
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="text" />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell
              sx={{
                width: {
                  xs: "25%",
                  md: "100%",
                  lg: "25%",
                },
              }}
            >
              <Grid
                container
                spacing={2}
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
              >
                <Grid item>
                  <Skeleton variant="circular" width={30} height={30} />
                </Grid>
                <Grid item>
                  <Skeleton variant="circular" width={30} height={30} />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PositionTableSkeleton;
