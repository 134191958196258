import React from "react";
import { JobInfoTableContainer } from "../Layout";
import { EditPositionModal } from "components/Modal";
import { ToggleWithHook } from "components/Button";
import { ToggleProfile } from "components/Button/Toggle";
import { PositionTableRow } from "./index";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import { useInternationalDiallingCodes } from "hooks";

const ProfileInfoWrapper = styled.div`
  background: white;
`;

function GroupTable({
  index,
  job,
  handleSubmitUpdatePosition,
  profile,
  displayOption,
  toggleDisplayOption,
  userContact,
}) {
  const { email_address, location, org_name, role_desc, telephone_number } =
    job;

  const diallingCodesHook = useInternationalDiallingCodes();

  return (
    <ProfileInfoWrapper>
      {!userContact && (
        <EditPositionModal
          setDisplayModal={() => toggleDisplayOption(index, "displayModal")}
          index={index}
          job={job}
          displayModal={displayOption?.displayModal}
          handleSubmitUpdatePosition={handleSubmitUpdatePosition}
          diallingCodesHook={diallingCodesHook}
        />
      )}
      <DragDropContext>
        <JobInfoTableContainer>
          <PositionTableRow
            content={role_desc}
            fieldName="Role"
          ></PositionTableRow>
          <PositionTableRow content={org_name} fieldName="Company" />
          <PositionTableRow content={location} fieldName="Location" />
          <PositionTableRow content={email_address} fieldName="Email" />
          <PositionTableRow
            content={telephone_number?.telephone_number_idn}
            fieldName="Phone number"
          />
          <ToggleWithHook
            component={ToggleProfile}
            index={index}
            profile={profile}
            disabled={userContact}
          />
        </JobInfoTableContainer>
      </DragDropContext>
    </ProfileInfoWrapper>
  );
}

export default GroupTable;
