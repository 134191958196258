import React, { useEffect, useState } from "react";
import sanityClient from "../../../client";
import { Container996 } from "components/Post/Layout";
import BlogItem from "./BlogItem";

function BlogListItems() {
  const [crmData, setCRMData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'post'] 
              {
              title,
              slug,
              publishedAt,
              body[]{
                ..., 
                asset->{
                  ...,
                  "_key": _id
                }
              },
              mainImage{
                asset->{  
                _id,
                url
              }
            }
          }`
      )
      .then((data) => setCRMData(data))
      .catch(console.error);
  }, []);

  return (
    <Container996>
      {crmData &&
        crmData.map((post, index) => {
          return <BlogItem post={post} key={index} />;
        })}
    </Container996>
  );
}

export default BlogListItems;
