import { postData } from "utils";
import { useState } from "react";

export const updateSinglePageSearch = async (searchResultsAll, currentPage) => {
  let updatedSearchData = { ...searchResultsAll };
  updatedSearchData.people = [
    ...updatedSearchData.people.slice((currentPage - 1) * 10, currentPage * 10),
  ];
  return updatedSearchData;
};

export const searchForMatches = async (searchOptions, setError) => {
  try {
    setError("");
    const {
      company,
      companySize,
      includeContactsInSearch,
      industries,
      location,
      person,
      position,
      revmoScore,
      searchRadius,
      includePreviousRolesInSearch,
    } = searchOptions;

    const revmoScoreToNum = {
      "1 - 200": 0,
      "201 - 400": 1,
      "401 - 600": 2,
      "601 - 800": 3,
    };

    const companySizeToNum = [1, 10, 50, 100, 250, 500, 1000, 5000, 10001];

    const radiusOptionsToNum = {
      "Within 1 mile": 1,
      "Within 5 miles": 5,
      "Within 10 miles": 10,
      "Within 20 miles": 20,
      "Within 40 miles": 40,
      "Less than 100 miles": 100,
    };

    const mapFieldToNum = (map, selectedFields) => {
      return selectedFields.map((field) => {
        return map[field];
      });
    };

    const formattedSearchOptions = {
      role_name: position,
      industries: industries.name,
      first_name: person.split(" ")[0],
      last_name: person.substring(person.split(" ")[0].length + 1),
      org_name: company,
      revmo_score: mapFieldToNum(revmoScoreToNum, revmoScore.name),
      include_contacts: includeContactsInSearch,
      limit: 500,
      is_active: !includePreviousRolesInSearch,
      skip: 0,
      distance: mapFieldToNum(radiusOptionsToNum, searchRadius.name)[0],
    };

    // If searching by company size, only include bounds if they are not the max/min size
    if (companySize.name[0] !== 0) {
      formattedSearchOptions.org_size_min =
        companySizeToNum[companySize.name[0]];
    }
    if (companySize.name[1] !== 8) {
      formattedSearchOptions.org_size_max =
        companySizeToNum[companySize.name[1]];
    }

    if (location) {
      let formattedLocation = {
        lookup_point: location,
      };
      formattedSearchOptions.locations = [formattedLocation];
    }

    const searchResults = await postData(
      "/search/",
      JSON.stringify(formattedSearchOptions)
    );
    // TODO
    // setUsageData((prevState) => ({
    //   ...prevState,
    //   search_count: searchResults.user_search_count
    // }))
    return searchResults;
  } catch (err) {
    console.error(err);
    setError(err);
  }
};

export const getTagUUIDs = (tags) => {
  return tags.map((tag) => {
    return tag.uuid;
  });
};

export const getTagName = (tags) => {
  return tags.map((tag) => {
    return tag.name;
  });
};

export const useContactAndOrgTags = () => {
  const [allTags, setAllTags] = useState([]);
  const [searchFilter, setSearchFilter] = useState({
    showSearchFilter: false,
    tags: [],
  });

  const toggleSearchFilter = () =>
    setSearchFilter({
      showSearchFilter: !searchFilter.showSearchFilter,
      tags: searchFilter.tags,
    });

  const selectTag = (tag) => {
    let tags = [...searchFilter.tags];
    let tagIndex = getTagUUIDs(tags).indexOf(tag.uuid);
    if (tagIndex > -1) {
      tags = [...tags.slice(0, tagIndex), ...tags.slice(tagIndex + 1)];
    } else {
      tags = [...tags, tag];
    }

    setSearchFilter({ ...searchFilter, tags: tags });
  };

  return {
    allTags,
    selectTag,
    setAllTags,
    searchFilter,
    toggleSearchFilter,
  };
};
