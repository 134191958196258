import React, { useEffect, useState } from "react";
import { LocationIcon } from "../Assets";
import { useRecoilState } from "recoil";
import { searchAddressState } from "state";
import styled from "styled-components";
import {
  AdvancedSearchCompanySize,
  AdvancedSearchCustomDropdown,
  AdvancedSearchIndustryHierarchy,
  AdvancedSearchTickbox,
} from "./components";
import { SearchFormInputLocation } from "components/Input";
import PlacesAutocomplete from "components/PlacesAutocomplete";
import { industryListQuery } from "state";
import { useRecoilValueLoadable } from "recoil";

const AdvancedSearchWrapperFirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  @media (max-width: 870px) {
    flex-wrap: wrap;
  }
`;

const AdvancedSearchWrapper = styled.div``;

const AdvancedSearchSecondRow = styled.div`
  display: flex;
  margin-top: 30px;
  @media (max-width: 870px) {
    flex-direction: column;
    margin-top: 0;
  }
  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

const AdvancedSearchIndustryWrapper = styled(AdvancedSearchSecondRow)`
  @media (max-width: 870px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;

export function AdvancedSearch({ advancedSearchHook, children, register }) {
  const {
    showAdvancedSearch,
    searchOptions,
    setSearchOptions,
    searchPage,
    dashboard,
    formatDefaultText,
    addOrRemoveSearchCriteria,
    openAndCloseDropdowns,
    closeAllDropdownOnPageBodyClick,
    toggleIncludeContactsInSearch,
    toggleIncludePreviousRolesInSearch,
  } = advancedSearchHook;

  const [searchAddress, setSearchAddress] = useRecoilState(searchAddressState);
  const industryOptionsLoadable = useRecoilValueLoadable(industryListQuery);
  const [industryOptions, setIndustryOptions] = useState([]);

  const radiusOptions = [
    "Within 1 mile",
    "Within 5 miles",
    "Within 10 miles",
    "Within 20 miles",
    "Within 40 miles",
    "Within 100 miles",
  ];

  useEffect(() => {
    switch (industryOptionsLoadable.state) {
      case "hasValue":
        setIndustryOptions(industryOptionsLoadable.contents);
    }
  }, [industryOptionsLoadable.contents, industryOptionsLoadable.state]);

  return (
    <AdvancedSearchWrapper>
      <AdvancedSearchWrapperFirstRow
        showAdvancedSearch={showAdvancedSearch}
        searchPage={searchPage}
        dashboard={dashboard}
      >
        <PlacesAutocomplete
          fieldName="Location"
          component={SearchFormInputLocation}
          useCase="userProfessional"
          setPlacesAddress={setSearchAddress}
          setSearchOptions={advancedSearchHook.setSearchOptions}
          searchOptions={advancedSearchHook.searchOptions}
          resetValue={searchAddress.location}
          register={register}
          optionIcon={LocationIcon}
        />
        <AdvancedSearchCustomDropdown
          searchOptions={searchOptions}
          listOfOptions={radiusOptions}
          searchCriteriaName={"searchRadius"}
          addOrRemoveSearchCriteria={addOrRemoveSearchCriteria}
          formatDefaultText={formatDefaultText}
          searchPage={searchPage}
          openAndCloseDropdowns={openAndCloseDropdowns}
          closeAllDropdownOnPageBodyClick={closeAllDropdownOnPageBodyClick}
          disabled={!searchAddress?.city?.length}
        />
        <AdvancedSearchTickbox
          toggleIncludeInSearch={toggleIncludePreviousRolesInSearch}
          searchOptions={searchOptions}
          headerText="Include previous roles"
          roles={true}
        />
        <AdvancedSearchTickbox
          toggleIncludeInSearch={toggleIncludeContactsInSearch}
          searchOptions={searchOptions}
          headerText="Personal Contacts"
          lessWidth={true}
        />
      </AdvancedSearchWrapperFirstRow>
      <AdvancedSearchWrapperFirstRow>
        <AdvancedSearchCompanySize
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
        />
      </AdvancedSearchWrapperFirstRow>
      <AdvancedSearchIndustryWrapper>
        <AdvancedSearchIndustryHierarchy
          searchOptions={searchOptions}
          listOfOptions={industryOptions}
          searchCriteriaName={"industries"}
          addOrRemoveSearchCriteria={addOrRemoveSearchCriteria}
          formatDefaultText={formatDefaultText}
          searchPage={searchPage}
        />
      </AdvancedSearchIndustryWrapper>
      <AdvancedSearchSecondRow>{children}</AdvancedSearchSecondRow>
    </AdvancedSearchWrapper>
  );
}
