import styled from "styled-components";

export const HeaderText = styled.h1`
  font-size: 28px;
  line-height: 33px;
  margin-bottom: ${(props) => props.marginBottom}px;
  width: max-content;
  color: ${(props) => props.theme.titleTextColor};
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 23px;
  }
`;

export const HeaderTextSearch = styled(HeaderText)`
  @media (min-width: 500px) {
    width: 650px;
  }
`;

export const ExtraLargeBoldText = styled.p`
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 0;
  color: #ffffff;
`;

export const LargeBoldText = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: ${(props) => props.theme.textColor};
  margin: 0;
  text-align: left;
`;

export const LargeBoldTextLight = styled(LargeBoldText)`
  color: white;
`;

export const LargeWhiteText = styled.p`
  font-size: 22px;
  line-height: 50px;
  color: #ffffff;
`;

export const LargeBoldTextLeftAligned = styled(LargeBoldText)`
  text-align: left;
`;

export const MidLargeSpacedText = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.4px;
  color: ${(props) => props.theme.textColor};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  strong {
    font-weight: bold;
  }
  @media (max-width: 480px) {
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.355556px;
  }
`;

export const MidLargeSpacedTextMarginTop = styled(MidLargeSpacedText)`
  margin-top: 10px;
`;

export const AuthText = styled(MidLargeSpacedText)`
  font-size: 14px;
`;

export const MidLargeSpacedTextBlue = styled(MidLargeSpacedText)`
  color: ${(props) => props.theme.brandColor};
`;

export const MidLargeSpacedTextGrey = styled(MidLargeSpacedText)`
  color: #7c919e;
`;

export const MidLargeSpacedTextWhite = styled(MidLargeSpacedText)`
  color: white;
`;

export const MidLargeBoldText = styled.p`
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: ${(props) => props.theme.textColor};
  margin: 0;
  padding-bottom: 11px;
  cursor: pointer;
  ${(props) => {
    if (props.underline) {
      return `
        border-bottom: 4px solid ${props.theme.brandColor};
      `;
    }
  }}
  @media (max-width: 480px) {
    font-size: 13px;
    line-height: 26px;
    padding-bottom: 9px;
  }
`;

export const MidLargeBoldTitleText = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 26px;
  letter-spacing: 0.422222px;
  color: #313237;
`;

export const MidBlackBoldText = styled.p`
  font-size: 15px;
  line-height: 18px;
  font-weight: bold;
  color: #494661;
`;

export const MidBlueBoldText = styled(MidBlackBoldText)`
  color: ${(props) => props.theme.brandColor};
`;

export const MidSizedText = styled.p`
  display: inline;
  font-size: 15px;
  line-height: 18px;
  color: ${(props) => props.theme.textColor};
`;

export const MidSizedGreyTextBold = styled(MidSizedText)`
  font-weight: bold;
`;

export const MidSizedGreyTextSpaced = styled.p`
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.311111px;
  color: #7c919e;
  text-decoration: none;
`;

export const MidSizedTextSpaced = styled(MidSizedGreyTextSpaced)`
  color: ${(props) => props.theme.textColor};
  font-size: 13px;
`;

export const MidSizedTextSpacedRed = styled(MidSizedGreyTextSpaced)`
  color: #f9007a;
`;

export const MidSizedTextSpacedUnderline = styled(MidSizedTextSpaced)`
  text-decoration: underline;
`;

export const BlueLinkText = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.311111px;
  color: #5374ff;
  cursor: pointer;
`;

export const GreyLinkText = styled(BlueLinkText)`
  color: rgb(55, 53, 47);
  :hover {
    text-decoration: underline;
  }
`;

export const BlueLinkTextUnderlined = styled(BlueLinkText)`
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.311111px;
  text-decoration-line: underline;
  color: #5374ff;
  cursor: pointer;
`;

export const BlueLinkTextUnderlinedBanner = styled(BlueLinkTextUnderlined)`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  @media (max-width: 480px) {
    font-size: 10px;
    line-height: 13px;
    text-shadow: unset;
  }
`;

export const BlueLinkTextSmall = styled(BlueLinkTextUnderlined)`
  font-size: 10px;
  line-height: 12px;
  width: max-content;
  margin: 0;
  cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};
`;

export const BlueLinkTextAdapative = styled(BlueLinkText)`
  @media (max-width: 480px) {
    font-size: 12px;
  }
  @media (max-width: 321px) {
    font-size: 10px;
  }
`;

export const VerySmallSpacedText = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 10.8px;
  line-height: 16px;
  letter-spacing: 0.24px;
  color: ${(props) => props.theme.textColor};
`;

export const SmallBlackNormalText = styled.p`
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.311111px;
  color: ${(props) => props.theme.textColor};
  margin: 0;
  opacity: ${(props) => props.opacity};
`;

export const SmallBlackNormalTextUnderlined = styled(SmallBlackNormalText)`
  text-decoration: underline;
`;

export const SmallBlackBoldText = styled(SmallBlackNormalText)`
  font-weight: bold;
`;

export const SmallBlackBodyText = styled.p`
  font-size: 10px;
  line-height: 12px;
  color: #494661;
`;

export const SmallBlackBodyTextEllipsis = styled(SmallBlackBodyText)`
  max-width: 215px;
  width: 18vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ExtraSmallBlackBoldTextUpperCase = styled.p`
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #2c2948;
  text-transform: uppercase;
`;

export const ExtraSmallBlackText = styled.p`
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.266667px;
  color: ${(props) => props.theme.textColor};
`;

export const ExtraSmallBlackTextEllipsis = styled(ExtraSmallBlackText)`
  max-width: 215px;
  width: 18vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ExtraSmallBlackTextUnderline = styled(ExtraSmallBlackText)`
  text-decoration: underline;
`;

export const ExtraSmallBlackTextUnderlineLogin = styled(
  ExtraSmallBlackTextUnderline
)`
  position: relative;
  bottom: 14px;
  font-weight: normal;
  cursor: pointer;
`;

export const ExtraSmallText = styled.p`
  font-weight: 300;
  font-size: 10.8px;
  line-height: 16px;
  letter-spacing: 0.24px;
  color: ${(props) => props.theme.textColor};
`;

export const VariableColourSubTitleText = styled.p`
  color: ${(props) => (props.color ? props.color : "#7c919e")};
  font-size: 18px;
  line-height: 21px;
`;

export const SmallBodyText = styled.p`
  font-size: 10px;
  line-height: 12px;
  color: #91a2af;
`;

export const SmallWhiteText = styled.p`
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
  width: max-content;
  margin: 0;
`;

export const SmallGreenText = styled.p`
  color: ${(props) => props.theme.acceptedColor};
  font-size: 14px;
  line-height: 26px;
  font-weight: bold;
  margin: 0;
  letter-spacing: 0.311111px;
  @media (max-width: 480px) {
    font-size: 13px;
    line-height: 15px;
  }
`;

export const LocationText = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: #494661;
  margin-top: 4px;
  margin-bottom: 7px;
  @media (max-width: 1126px) and (min-width: 635px) {
    line-height: 24px;
    margin-top: 7px;
    margin-bottom: 9px;
    font-size: 16px;
  }
`;

export const IntroCountText = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: ${(props) => props.theme.textColor};
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;

export const CurrentlyShowingText = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #9fa2b4;
  letter-spacing: 0.4px;
  margin-right: 15px;
  margin-bottom: 5px;
  ${(props) => {
    if (props.boldAndBlue) {
      return `
      font-weight: bold;
      color: ${props.theme.brandColor};
    `;
    }
  }}
  @media (max-width: 767px) {
    margin-right: 5px;
  }
`;

export const TableTextIntroData = styled.p`
  margin-bottom: 1px;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #2c2948;
  @media (max-width: 1126px) {
    ::after {
      content: " ";
      white-space: pre;
    }
    @media (max-width: 480px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const TableTextIntroReason = styled.p`
  margin-bottom: 1px;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #91a2af;
  margin: 0;
  width: max-content;
  color: ${(props) => props.theme.textColor};
`;

export const TableTextName = styled.p`
  margin-bottom: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  color: #494661;
  margin-bottom: 3px;
  color: ${(props) => props.theme.textColor};
`;

export const TableTextJob = styled.p`
  margin-bottom: 0;
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.theme.textColor};
`;

export const TableTextLocation = styled.p`
  margin-bottom: 0;
  font-size: 10px;
  color: #494661;
`;

export const TableTextAnonymousUser = styled.p`
  color: ${(props) => props.theme.textColor};
  margin-bottom: 0;
  font-size: 11px;
  line-height: 18px;
  text-align: center;
`;

export const ContactUsTitleText = styled.h1`
  font-size: 56px;
  text-transform: capitalize;
  margin-bottom: 0;
  line-height: 84px;
`;

export const ContactUsSubText = styled.h4`
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  max-width: 90%;
`;

export const DisplayCardText = styled.p`
  color: ${(props) => props.theme.textColor};
  font-size: 16px;
`;

export const InfoTextBoxText = styled.p`
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.textColor};
  margin: 0;
`;

export const InfoTextBoxTitle = styled(InfoTextBoxText)`
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 14px;
`;

export const ErrorMessage = styled.p`
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.266667px;
  color: #e40079;
`;

export const ErrorMessageNoMargin = styled(ErrorMessage)`
  margin: 0;
  align-self: flex-start;
`;

export const InitialsText = styled(SmallWhiteText)`
  font-size: 16px;
`;
