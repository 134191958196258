import React from "react";
import styled from "styled-components";
import { Accordion } from "components/LandingPage";

const CareersInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const CareersValues = () => {
  const values = [
    {
      question: "Security",
      answer:
        "Our users’ data belongs to them, not us. Though Revmo requires contact and relationship data to run, all on-platform and connected user data is private by default, permissioned by the user on a granular and case-by-case basis, and never viewed by Revmo or our employees. We will always use the highest standards in encryption and security to safeguard and segment user data, while routinely auditing our processes to ensure optimal data safety.",
    },
    {
      question: "Speed",
      answer:
        "Revmo condenses hours of networking work and workflows into a single button press. This gives professionals time back to do more, while quickly scaling their networking pipeline in a short stretch of time.",
    },
    {
      question: "Professionalism",
      answer:
        "Revmo is made for hardworking professionals, by hardworking professionals. We created Revmo as a toolset to help realize our own network efficiencies where such solutions did not exist previously. We know what the modern professional needs in the office and beyond, and we will always strive to provide and update an optimal product with equally optimal results.",
    },
    {
      question: "Efficiency",
      answer:
        "Revmo finds the shortest, most natural way to connect a professional to their desired connection. Our platform takes the minutiae and grunt work out of finding the right person, while utilizing all available data to provide only optimal results by finding connections where other solutions could not. Simply put, Revmo provides the easiest path to whomever you’re looking for.",
    },
    {
      question: "Humanity",
      answer:
        "Revmo’s professionals make natural, meaningful connections. There are no cold, random reachouts on our platform. Professionals and those they connect with get a personalized experience each time they make a connection. The connections on and through Revmo are human connections — the same you would get with personalized, thought-out emails. We just happen to do them infinitely faster than everyone else.",
    },
  ];
  return (
    <CareersInformationWrapper>
      <h2>Our values are central to all that we do.</h2>
      <Accordion data={values} />
    </CareersInformationWrapper>
  );
};

export default CareersValues;
