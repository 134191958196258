import React, { useEffect } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import SuggestionsTable from "./SuggestionsTable";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { clearSearchAddressState } from "state";
import {
  AdvancedSearchInputContainer,
  InputName,
} from "components/AdvancedSearch/components";
import { ErrorMessage } from "../Typography";

const AutocompleteInputWrapper = styled.div`
  position: relative;
`;

const ErrorMessageWrapper = styled.div`
  ${ErrorMessage} {
    margin-top: -22px;
  }
`;

const Suggestions = styled.ul`
  background: white;
  border: 1px solid #e3e6e9;
  position: absolute;
  z-index: 100;
  top: 100%;
  margin-top: 4px;
  width: 270px;
  padding: 10px 20px;
  list-style-type: none;
  li {
    padding: 10px 0;
  }
`;

function PlacesAutocomplete({
  component: Component,
  useCase,
  placesAddress,
  setPlacesAddress,
  resetValue,
  customFormErrorsHook,
  setSearchOptions,
  searchOptions,
  optionIcon,
  outsideSearch,
  fieldName,
  name,
  error,
  setError,
}) {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions:
      useCase === "userProfessional" || useCase === "contacts"
        ? { types: ["(cities)"] }
        : {},
    debounce: 300,
  });

  const [clearSearchAddress, setClearSearchAddress] = useRecoilState(
    clearSearchAddressState
  );

  useEffect(() => {
    if (clearSearchAddress) {
      setValue(resetValue);
      setClearSearchAddress(!clearSearchAddress);
    }
  }, [clearSearchAddress, setClearSearchAddress, setValue, resetValue]);

  const getUsersPersonalAddress = async (updatedPlacesAddress, addressData) => {
    const formattedAddress = addressData[0].formatted_address;
    updatedPlacesAddress.location = formattedAddress;
    updatedPlacesAddress.personalLatLng = await getLatLng(addressData[0]);
    setPlacesAddress({ ...updatedPlacesAddress });
    setValue(formattedAddress, false);
  };

  const getUsersWorkAddress = async (updatedPlacesAddress, addressData) => {
    const formattedAddress = addressData[0].formatted_address;
    updatedPlacesAddress.location = formattedAddress;
    updatedPlacesAddress.professionalLatLng = await getLatLng(addressData[0]);
    await getLatLng(addressData[0]);
    setValue(formattedAddress, false);
    setPlacesAddress(updatedPlacesAddress);
    customFormErrorsHook?.clearCustomFormErrors();
  };

  const handleSelect =
    ({ description }) =>
    async () => {
      const googleMapDataMapping = {
        postal_town: "city",
        country: "country",
        postal_code: "postCode",
        administrative_area_level_1: "city",
        route: "address2",
      };
      clearSuggestions();

      const updatedPlacesAddress = { ...placesAddress };
      const addressData = await getGeocode({ address: description });
      addressData[0].address_components.forEach((component) => {
        const mappedLocationName = googleMapDataMapping[component.types[0]];
        if (mappedLocationName && !updatedPlacesAddress[mappedLocationName]) {
          updatedPlacesAddress[mappedLocationName] = component.long_name;
        }
      });
      const coordinates = await getLatLng(addressData[0]);
      setSearchOptions
        ? setSearchOptions({
            ...searchOptions,
            location: {
              coordinates: [coordinates.lng, coordinates.lat],
              type: "Point",
            },
          })
        : null;

      if (useCase === "userPersonal") {
        await getUsersPersonalAddress(updatedPlacesAddress, addressData);
      } else if (useCase === "userProfessional" || useCase === "contacts") {
        await getUsersWorkAddress(updatedPlacesAddress, addressData);
      }
    };

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
    if (outsideSearch && setError) {
      if (!placesAddress?.location.length) {
        setError("Please enter a valid city");
      } else {
        setError(false);
      }
    }
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    if (useCase === "userPersonal" && e.target.value.length) {
      setPlacesAddress({ address1: e.target.value });
    } else if (
      useCase === "userProfessional" ||
      (useCase === "contacts" && e.target.value.length)
    ) {
      setPlacesAddress({ ...placesAddress, location: e.target.value });
      if (setError) {
        setError(false);
      }
    } else if (!e.target.value.length) {
      setPlacesAddress({});
    }
    setValue(e.target.value);
  };

  return (
    <>
      <AdvancedSearchInputContainer outsideSearch={outsideSearch} error={error}>
        {fieldName ? (
          <InputName>{fieldName}</InputName>
        ) : (
          <InputName>Location</InputName>
        )}
        <AutocompleteInputWrapper ref={ref}>
          <Component
            value={value || resetValue}
            name={name}
            handleChange={handleInput}
            optionIcon={optionIcon}
          />
          {/* We can use the "status" to decide whether we should display the dropdown or not */}
          {status === "OK" && (
            <Suggestions>
              <SuggestionsTable data={data} handleSelect={handleSelect} />
            </Suggestions>
          )}
        </AutocompleteInputWrapper>
      </AdvancedSearchInputContainer>
      {error && (
        <ErrorMessageWrapper>
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorMessageWrapper>
      )}
    </>
  );
}

export default PlacesAutocomplete;

// turn into Custom Hook and HOC
