import React from "react";
import styled from "styled-components";
import { ButtonPrimary } from "./";
import arrowWhite from "../../assets/icons/arrow-white.svg";

const ButtonPrimaryContentContainer = styled.div`
  min-width: 76px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  img {
    margin-left: 8px;
    width: 12px;
    height: 11px;
  }
`;

const SearchBarIcon = styled.img`
  pointer-events: none;
`;

export function ButtonPrimaryWithArrow({
  buttonText,
  disabled,
  action,
  padding,
  border,
}) {
  return (
    <ButtonPrimary
      disabled={disabled}
      onClick={async () => {
        action ? await action() : null;
      }}
      padding={padding}
      border={border}
    >
      <ButtonPrimaryContentContainer>
        <p>{buttonText}</p>
        <SearchBarIcon src={arrowWhite} />
      </ButtonPrimaryContentContainer>
    </ButtonPrimary>
  );
}
