import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { fetchData } from "utils";
import CircularProgress from "@mui/material/CircularProgress";

export default function RoleAutocomplete({ searchOptions, setSearchOptions }) {
  const value = searchOptions.position;
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;
  function sortArray(roles) {
    roles.forEach((role) => {
      if (role.depth === 1) {
        var childRoles = roles.filter(function (el) {
          return el.role_level_1 === role.role_name;
        });
        const groupby = `${role.role_name} (${childRoles.length - 1})`;
        role.groupby = groupby;
        childRoles.forEach((role) => {
          role.groupby = groupby;
        });
      }
    });
    return roles;
  }
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        const getData = async () => {
          try {
            const response = await fetchData(
              `/roles/?role_taxonomy=REVMO_SIMPLE&mode=FLAT`
            );
            const sortedArray = sortArray(response);
            setOptions(sortedArray);
          } catch (err) {
            console.log(err);
          }
        };
        getData();
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      groupBy={(option) => option.groupby}
      loading={loading}
      isOptionEqualToValue={(option, value) =>
        option.role_name === value.role_name
      }
      value={value}
      onChange={(event, value) => {
        const newValue = value ?? { role_name: "" };
        setSearchOptions({
          ...searchOptions,
          position: newValue.role_name,
        });
      }}
      getOptionLabel={(option) =>
        option.role_name ? option.role_name : option.length ? option : ""
      }
      renderInput={(params) => (
        <TextField
          label="Title or Role"
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        ></TextField>
      )}
    />
  );
}
