import React, { Suspense } from "react";
import ErrorBoundary from "components/ErrorBoundary";
import RequestStatusTable from "components/RequestStatusTable";
import styled from "styled-components";
import { NoRequestsFound, RequestTablesSkeleton } from ".";
import { NoRequestsLoaded } from "./NoRequestsFound";
import { useRecoilValue } from "recoil";
import {
  introductionRequestsState,
  userHasRequestHistoryState,
} from "state/recoil/requests";

const RequestTablesWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
`;

function RequestTables() {
  return (
    <>
      <ErrorBoundary fallback={<NoRequestsLoaded />}>
        <Suspense fallback={<RequestTablesSkeleton />}>
          <RequestTablesSection />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

function RequestTablesSection() {
  const userHasRequestHistory = useRecoilValue(
    userHasRequestHistoryState
  ).userHasRequestHistory;
  const requests = useRecoilValue(introductionRequestsState);

  return (
    <>
      {userHasRequestHistory == true ? (
        <RequestTablesWrapper className="walkthrough-step-12">
          {requests?.COMPLETED[0] && (
            <RequestStatusTable requestType="COMPLETED" requests={requests} />
          )}
          {requests?.PENDING[0] && (
            <RequestStatusTable requestType="PENDING" requests={requests} />
          )}
          {requests?.REJECTED[0] && (
            <RequestStatusTable requestType="REJECTED" requests={requests} />
          )}
        </RequestTablesWrapper>
      ) : (
        <NoRequestsFound />
      )}
    </>
  );
}

export default RequestTables;
