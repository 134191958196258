import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

// material-ui
import { Box, Button, Grid, Typography } from "@mui/material";

// project imports
import { AnimateButton } from "components/DashboardNew";

// assets
import contactsEmptyState from "assets/images/contacts-empty-state.png";

const EmptyStateImage = styled.img`
  max-width: 100%;
`;

const ContactEmptyState = ({ toggleAddContactsModal }) => {
  let location = useLocation();

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid
        item
        xs={12}
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <EmptyStateImage src={contactsEmptyState} />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h4">Start building your network</Typography>
          <Typography sx={{ p: 2 }}>
            Before you can make introductions, you must upload your contacts
          </Typography>
          <AnimateButton>
            {location.pathname === "/contacts" ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={toggleAddContactsModal}
              >
                Add Contacts
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to={{ pathname: "/contacts", state: { addContacts: true } }}
              >
                Add Contacts
              </Button>
            )}
          </AnimateButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactEmptyState;
