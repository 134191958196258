import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  LargeBoldText,
  SmallBlackBoldText,
  ExtraSmallBlackText,
} from "../Typography";
import closeIcon from "../../assets/icons/close-icon.svg";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  AddContactsContent,
  ProfileModalContainerAddOrEditContact,
  AddOrEditContactPersonalDetailsWrapper,
  AddOrEditContactPositionDetailsWrapper,
  HeaderAndExplainerTextContainerSingleContact,
  FormTypeHeader,
  FormTypeHeaderAddContact,
} from "./index";
import { DateSelect, WhiteLargeFormInputHooksForm } from "../Input";
import { OrgsAutocomplete } from "components/Autocomplete";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addOrEditContactSchema } from "utils";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { months } from "utils";

const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RequiredFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  p {
    margin: 0;
  }
  @media (max-width: 870px) {
    align-items: flex-start;
    -webkit-align-items: flex-start;
  }
`;

export function AddOrEditContactModal({
  setDisplayModal,
  handleSubmitManuallyAddOrEditNewContact,
  contactDetails,
  action,
}) {
  const [contactAddress] = useState({});
  const [checked, setChecked] = useState(
    contactDetails?.positions?.length
      ? contactDetails?.positions[0]?.is_active
      : true
  );

  const getMonthFromDefault = (attribute) => {
    if (contactDetails?.positions[0][attribute]) {
      const monthsFiltered = months.filter(function (el) {
        return el.value == contactDetails?.positions[0][attribute].slice(5, 7);
      });
      return monthsFiltered[0];
    }
    return null;
  };

  const userValues = {
    firstName: contactDetails?.first_name,
    lastName: contactDetails?.last_name,
  };

  if (contactDetails?.uuid) {
    userValues.personUuid = contactDetails.uuid;
  }

  if (contactDetails?.personal_email_addresses?.length) {
    userValues.personalEmail =
      contactDetails?.personal_email_addresses[0]?.email_address;
  }

  if (contactDetails?.positions?.length) {
    userValues.company = contactDetails?.positions[0]?.org;
    userValues.position = contactDetails?.positions[0]?.role_description;
    userValues.companyEmail = contactDetails?.positions[0]
      ?.company_email_addresses?.length
      ? contactDetails?.positions[0]?.company_email_addresses[0]?.email_address
      : "";
    (userValues.startYear = contactDetails?.positions[0]?.start_date
      ? contactDetails?.positions[0]?.start_date.slice(0, 4)
      : null),
      (userValues.endYear = contactDetails?.positions[0]?.end_date
        ? contactDetails?.positions[0]?.end_date.slice(0, 4)
        : null),
      (userValues.startMonth = getMonthFromDefault("start_date"));
    userValues.endMonth = getMonthFromDefault("end_date");
  }

  const { control, register, handleSubmit, errors, trigger } = useForm({
    defaultValues: userValues,
    resolver: yupResolver(addOrEditContactSchema),
  });

  const handleSelectIsCurrentRole = (event) => {
    setChecked(event.target.checked);
  };

  register("personUuid", { value: contactDetails?.uuid });

  return (
    <ProfileModalPageWrapper>
      <DarkBlurredBackground onClick={() => setDisplayModal(false)} />
      <ProfileModalContainerAddOrEditContact>
        <CloseIconImage
          src={closeIcon}
          onClick={async () => {
            setDisplayModal(false);
          }}
        />
        <AddContactsContent>
          <HeaderAndExplainerTextContainerSingleContact>
            {action == "edit" ? (
              <LargeBoldText>Edit Contact</LargeBoldText>
            ) : (
              <LargeBoldText>Add Contact</LargeBoldText>
            )}
          </HeaderAndExplainerTextContainerSingleContact>
          <FormTypeHeaderAddContact>
            <SmallBlackBoldText>Personal details</SmallBlackBoldText>
            <RequiredFieldWrapper>
              <ExtraSmallBlackText>*Required</ExtraSmallBlackText>
            </RequiredFieldWrapper>
          </FormTypeHeaderAddContact>
          <AddOrEditContactPersonalDetailsWrapper>
            <WhiteLargeFormInputHooksForm
              fieldName="First Name*"
              name="firstName"
              error={errors}
              register={register}
              onBlur={() => {
                trigger("firstName");
              }}
            />
            <WhiteLargeFormInputHooksForm
              fieldName="Last Name*"
              name="lastName"
              error={errors}
              register={register}
              onBlur={() => {
                trigger("lastName");
              }}
            />
            <WhiteLargeFormInputHooksForm
              fieldName="Personal Email"
              name="personalEmail"
              error={errors}
              register={register}
              onBlur={() => {
                trigger("personalEmail");
              }}
            />
          </AddOrEditContactPersonalDetailsWrapper>
          <FormTypeHeader>
            <SmallBlackBoldText>Position</SmallBlackBoldText>
          </FormTypeHeader>
          <AddOrEditContactPositionDetailsWrapper>
            <OrgsAutocomplete
              name="company"
              control={control}
              errors={errors}
              onBlur={() => {
                trigger("company");
              }}
            />
            <WhiteLargeFormInputHooksForm
              fieldName="Position*"
              name="position"
              error={errors}
              register={register}
              onBlur={() => {
                trigger("position");
              }}
            />
            <WhiteLargeFormInputHooksForm
              fieldName="Work Email"
              name="companyEmail"
              error={errors}
              register={register}
              onBlur={() => {
                trigger("companyEmail");
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  color="secondary"
                  size="medium"
                  onChange={handleSelectIsCurrentRole}
                />
              }
              label="This contact is currently working in this role"
            />
            <DateSelect control={control} type={"start"} title={"Start Date"} />
            {!checked && (
              <DateSelect control={control} type={"end"} title="End date" />
            )}

            <input {...register("personUuid")} type="hidden" />

            <Typography variant="caption">
              We require at least one email address so that this contact is able
              to receive introduction requests.{" "}
              <Link
                to="/support/i-found-a-contact-i-know-in-revmo-how-can-i-add-them-to-my-network"
                target="_blank"
              >
                Learn more in our Help Center.
              </Link>
            </Typography>
          </AddOrEditContactPositionDetailsWrapper>
          <ModalFooterWrapper>
            {action == "edit" ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit((data) =>
                  handleSubmitManuallyAddOrEditNewContact(
                    data,
                    contactAddress,
                    action
                  )
                )}
              >
                Edit Contact
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit((data) =>
                  handleSubmitManuallyAddOrEditNewContact(data, contactAddress)
                )}
              >
                Add Contact
              </Button>
            )}
          </ModalFooterWrapper>
        </AddContactsContent>
      </ProfileModalContainerAddOrEditContact>
    </ProfileModalPageWrapper>
  );
}
