import React from "react";
import styled from "styled-components";
import { SectionWrapper, ContentWrapper } from "containers/LandingPage/Layout";
import { Table } from "components/LandingPage";

const FAQHeader = styled.div`
  text-align: center;
`;

export function KeyFeatures() {
  const content = [
    {
      title: "Discovery",
      text: "Who does my organisation know? I can map across all networks.",
    },
    {
      title: "Universal Integration",
      text: "Integrates with personal, professional, proprietry and public data.",
    },
    {
      title: "Collaboration",
      text: "Request introductions from colleagues.",
    },
  ];

  return (
    <SectionWrapper>
      <ContentWrapper>
        <FAQHeader>
          <h2>Key Features</h2>
        </FAQHeader>
        <Table content={content} />
      </ContentWrapper>
    </SectionWrapper>
  );
}

export default KeyFeatures;

// To learn more about our offering for enterprise, please write to [TBU@revmo.app → will got to Margot]
