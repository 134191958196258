import React, { useState } from "react";
import styled from "styled-components";
import blueArrow from "assets/icons/arrow-curved-to-right.svg";
import greenTick from "assets/icons/tick-green.svg";
import blueTick from "assets/icons/tick-blue.svg";
import {
  TableTextName,
  TableTextJob,
  TableTextLocation,
  TableTextAnonymousUser,
} from "components/Typography";
import { TickboxContainer } from "components/Input";
import { PathScoreDisplayOuter, PathScoreDisplayInner } from "components/Score";
import { AnonymousUserLarge } from "../../Assets/Image";
import { SkeletonPulse } from "../../Skeleton";
import IntermediaryContext from "./RequestIntroductionIntermediaryContext";
import { Stack } from "@mui/material";
import {
  RequestRowWrapper,
  ContactTypeTextWrapper,
  ContactTypeTextWrapperIntroduceTo,
  ContactTypeTextWrapperDesiredConnection,
  RequestedConnectionContainer,
  PathChanceContainer,
  DesiredConnectionContainer,
  CheckBoxContainer,
  RowSectionWrap,
  RowSectionWrapFlexGrow,
} from "../Layout";
import { useProfileImage } from "hooks/useProfileImage";

const ContactTypeText = styled.p`
  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
  color: ${(props) => (props.blue ? "#5374FF" : "#7c919e")};
  display: flex;
  align-items: center;
  justify-content: center;
  height: min-content;
  margin-left: ${(props) => (props.blue ? "11px" : "0")};
`;

const UserImage = styled.img`
  height: 56px;
  width: 56px;
  border-radius: 100%;
`;

const UserImageWrapper = styled.div`
  margin-right: 10px;
`;

const ScoreText = styled.p`
  ${(props) => {
    if (props.score) {
      return `
      font-size: 15px;
      line-height: 18px;
      font-weight: bold;
      color: #2C2948;
      margin-bottom: 1px;
      `;
    } else {
      return `
      font-size: 11px;
      color: #2C2948;
      margin-top: 5px;
      `;
    }
  }}
`;

const SentBox = styled.div`
  background: #f2fffc;
  border: 1px solid ${(props) => props.theme.acceptedColor};
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0 8px;
  padding: 7px 9px;
  ${(props) => {
    if (props.hide) {
      return `
        display: none;
      `;
    }
  }}
  p {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: ${(props) => props.theme.acceptedColor};
  }
`;

function RequestIntroductionTableRow({
  personYouWouldLikeToViewIntroductionPathwayTo,
  userHasConfirmedTheyWouldLikeAnIntroductionRequest,
  introductionRequestHasBeenSent,
  confirmIntroductionRequest,
  peopleWhoCanIntroduceYou,
}) {
  if (!peopleWhoCanIntroduceYou.length) {
    return <SkeletonRow />;
  }

  return (
    <>
      <RequestRowWrapper className="walkthrough-step-8">
        <RowSectionWrap>
          <PersonWhoCanIntroYouRow
            userHasConfirmedTheyWouldLikeAnIntroductionRequest={
              userHasConfirmedTheyWouldLikeAnIntroductionRequest
            }
            confirmIntroductionRequest={confirmIntroductionRequest}
            peopleWhoCanIntroduceYou={peopleWhoCanIntroduceYou}
            introductionRequestHasBeenSent={introductionRequestHasBeenSent}
          />
        </RowSectionWrap>
        <RowSectionWrapFlexGrow>
          <PersonYouDesireAnIntroTo
            introductionRequestHasBeenSent={introductionRequestHasBeenSent}
            personYouWouldLikeToViewIntroductionPathwayTo={
              personYouWouldLikeToViewIntroductionPathwayTo
            }
          />
        </RowSectionWrapFlexGrow>
      </RequestRowWrapper>
    </>
  );
}

function CheckBox({
  introductionRequestHasBeenSent,
  handleOnChange,
  index,
  checkedState,
}) {
  return (
    <CheckBoxContainer>
      <TickboxContainer
        hide={introductionRequestHasBeenSent}
        onClick={() => {
          handleOnChange(index);
        }}
      >
        {checkedState[index] && <img src={greenTick} alt="" />}
      </TickboxContainer>
      {checkedState[index] && (
        <SentBox hide={!introductionRequestHasBeenSent}>
          <p>Sent</p>
        </SentBox>
      )}
    </CheckBoxContainer>
  );
}

export default RequestIntroductionTableRow;

const PathImage = styled.img`
  height: 15px;
  width: 15px;
`;

function PathType({ path }) {
  if (path === "best path") {
    return (
      <ContactTypeText>
        ( <PathImage src={blueTick} />
        &nbsp;best path )
      </ContactTypeText>
    );
  } else {
    return (
      <ContactTypeText>
        ( <PathImage src={blueArrow} />
        &nbsp;{path.numberOfAlternatives} alternate paths available )
      </ContactTypeText>
    );
  }
}

function PersonWhoCanIntroYouRow({
  introductionRequestHasBeenSent,
  userHasConfirmedTheyWouldLikeAnIntroductionRequest,
  confirmIntroductionRequest,
  peopleWhoCanIntroduceYou,
}) {
  let length = peopleWhoCanIntroduceYou.length;
  const [checkedState, setCheckedState] = useState(
    new Array(length).fill(false)
  );

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) => {
      if ((index === position) | (item === true)) {
        return !item;
      } else {
        if (item === false) {
          return item;
        }
      }
    });
    setCheckedState(updatedCheckedState);
    confirmIntroductionRequest(updatedCheckedState);
  };

  peopleWhoCanIntroduceYou.sort(
    (a, b) =>
      b.from_intermediary_contexts.length - a.from_intermediary_contexts.length
  );
  return (
    <Stack direction="column" spacing={2}>
      {peopleWhoCanIntroduceYou
        .slice(0, 5)
        .map((personWhoCanIntroYou, index) => {
          return (
            <Stack key={index} direction="row">
              <CheckBox
                introductionRequestHasBeenSent={introductionRequestHasBeenSent}
                userHasConfirmedTheyWouldLikeAnIntroductionRequest={
                  userHasConfirmedTheyWouldLikeAnIntroductionRequest
                }
                confirmIntroductionRequest={confirmIntroductionRequest}
                indexArray={index}
                personWhoCanIntroYou={personWhoCanIntroYou}
                handleOnChange={handleOnChange}
                checkedState={checkedState}
                index={index}
              />
              <PersonWhoCanIntroYou
                personWhoCanIntroduceYou={personWhoCanIntroYou}
                introductionRequestHasBeenSent={introductionRequestHasBeenSent}
                index={index}
              ></PersonWhoCanIntroYou>
              <PathChance
                introductionRequestHasBeenSent={introductionRequestHasBeenSent}
                chance={peopleWhoCanIntroduceYou[index]?.probability}
                index={index}
              />
            </Stack>
          );
        })}
    </Stack>
  );
}
function AskText({ index }) {
  {
    return (
      index == 0 && (
        <>
          <ContactTypeText>Ask &nbsp;</ContactTypeText>
          <PathType path="best path" />
        </>
      )
    );
  }
}
function PersonWhoCanIntroYou({
  personWhoCanIntroduceYou,
  introductionRequestHasBeenSent,
  index,
}) {
  return (
    <RowSectionWrapFlexGrow>
      <RequestedConnectionContainer>
        <ContactTypeTextWrapper
          introductionRequestHasBeenSent={introductionRequestHasBeenSent}
        >
          <AskText index={index} />
        </ContactTypeTextWrapper>
        <PersonInvolvedInIntroductionProcess
          person={personWhoCanIntroduceYou}
        />
        <IntermediaryContext
          personYouWouldLikeToViewIntroductionPathwayToContext={
            personWhoCanIntroduceYou
          }
        />
      </RequestedConnectionContainer>
    </RowSectionWrapFlexGrow>
  );
}

function PersonYouDesireAnIntroTo({
  introductionRequestHasBeenSent,
  personYouWouldLikeToViewIntroductionPathwayTo,
}) {
  return (
    <DesiredConnectionContainer>
      <ContactTypeTextWrapperDesiredConnection
        introductionRequestHasBeenSent={introductionRequestHasBeenSent}
      >
        <ContactTypeText>Desired connection</ContactTypeText>
      </ContactTypeTextWrapperDesiredConnection>
      <PersonInvolvedInIntroductionProcess
        person={personYouWouldLikeToViewIntroductionPathwayTo}
      />
    </DesiredConnectionContainer>
  );
}
function IntroducePercentageText({ index, introductionRequestHasBeenSent }) {
  return (
    index == 0 && (
      <ContactTypeTextWrapperIntroduceTo
        introductionRequestHasBeenSent={introductionRequestHasBeenSent}
      >
        <ContactTypeText>to introduce you to</ContactTypeText>
      </ContactTypeTextWrapperIntroduceTo>
    )
  );
}
function PathChance({ introductionRequestHasBeenSent, chance, index }) {
  const score = Math.round(chance * 100);
  return (
    <PathChanceContainer>
      <IntroducePercentageText
        index={index}
        introductionRequestHasBeenSent={introductionRequestHasBeenSent}
      ></IntroducePercentageText>
      <ScoreText score>{score}%</ScoreText>
      <PathScoreDisplayOuter>
        <PathScoreDisplayInner score={score} />
      </PathScoreDisplayOuter>
      <ScoreText>Chance to succeed</ScoreText>
    </PathChanceContainer>
  );
}

function PersonInvolvedInIntroductionProcess({ person }) {
  const { positions, first_name, last_name } = person;
  const image = useProfileImage(person);
  return (
    <>
      <UserImageWrapper>
        {image ? <UserImage src={image} /> : <AnonymousUserLarge />}
      </UserImageWrapper>
      <div>
        {positions ? (
          <>
            <TableTextName>{`${first_name} ${last_name}`}</TableTextName>
            <span>
              <TableTextJob>{positions[0]?.role_description}</TableTextJob>
              <TableTextJob color="#5374FF">
                {!!positions.length && " @ "}
              </TableTextJob>
              <TableTextJob>{positions[0]?.org?.org_name}</TableTextJob>
            </span>
            <TableTextLocation>{positions[0]?.location}</TableTextLocation>
          </>
        ) : (
          <TableTextAnonymousUser>Anonymous User</TableTextAnonymousUser>
        )}
      </div>
    </>
  );
}

const CheckBoxSkeleton = styled(SkeletonPulse)`
  height: 15px;
  width: 15px;
  border-radius: 3px;
`;

const AnonymousTextSkeleton = styled(SkeletonPulse)`
  width: 100%;
  height: 15px;
`;

const TextSkeletonWrapper = styled.div`
  width: 100%;
  @media (max-width: 870px) {
    max-width: 250px;
  }
`;

const SkeletonUser = styled(SkeletonPulse)`
  height: 56px;
  width: 56px;
  min-width: 56px;
  border-radius: 100%;
`;

const SkeletonScore = styled(SkeletonPulse)`
  width: 100%;
  height: 9px;
`;

function SkeletonRow() {
  return (
    <RequestRowWrapper>
      <RowSectionWrap>
        <CheckBoxContainer>
          <CheckBoxSkeleton />
        </CheckBoxContainer>
        <RequestedConnectionContainer>
          <UserImageWrapper>
            <SkeletonUser />
          </UserImageWrapper>
          <TextSkeletonWrapper>
            <AnonymousTextSkeleton />
            <AnonymousTextSkeleton />
          </TextSkeletonWrapper>
        </RequestedConnectionContainer>
      </RowSectionWrap>
      <RowSectionWrapFlexGrow>
        <PathChanceContainer>
          <SkeletonScore />
        </PathChanceContainer>
        <RequestedConnectionContainer>
          <UserImageWrapper>
            <SkeletonUser />
          </UserImageWrapper>
          <TextSkeletonWrapper>
            <AnonymousTextSkeleton />
            <AnonymousTextSkeleton />
          </TextSkeletonWrapper>
        </RequestedConnectionContainer>
      </RowSectionWrapFlexGrow>
    </RequestRowWrapper>
  );
}
