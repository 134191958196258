import history from "../../../history";

export const handleSubmitDashboardSearch = (
  data,
  openAndCloseDropdowns,
  searchAddress
) => {
  const searchResults = openAndCloseDropdowns();
  searchResults.position = data.position;
  searchResults.person = data.person;

  if (searchAddress?.professionalLatLng) {
    const { lat, lng } = searchAddress.professionalLatLng;
    searchResults.locationString = searchAddress.location;
    searchResults.location = {
      coordinates: [lng, lat],
      type: "Point",
    };
  }

  history.push({ pathname: "/search", state: searchResults });
};
