import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// material-ui
import { Box, Button, Grid, Typography } from "@mui/material";

// project imports
import { AnimateButton } from "components/DashboardNew";

// assets
import contactsErrorImage from "assets/images/loading-error.png";

const EmptyStateImage = styled.img`
  max-width: 30%;
  margin-top: 1rem;
  @media (max-width: 870px) {
    max-width: 80%;
  }
`;

const ContactErrorState = () => {
  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid
        item
        xs={12}
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <EmptyStateImage src={contactsErrorImage} />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h4">
            Oops, there was an error loading your contacts
          </Typography>
          <Typography sx={{ p: 2 }}>
            Don't worry, Revmo is ready to help, so send us an email at
            support@revmo.app, or try refreshing this page.
          </Typography>
          <AnimateButton>
            <Button
              sx={{ "&:hover": { color: "white" } }}
              variant="contained"
              color="secondary"
              component={Link}
              to={{ pathname: "https://forms.gle/G6dvheyaTwhmwPFQA" }}
              target={"_blank"}
            >
              Contact Support
            </Button>
          </AnimateButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactErrorState;
