import React, {
  useMemo,
  useCallback,
  useEffect,
  useReducer,
  createContext,
} from "react";
import { Auth } from "aws-amplify";
import { fetchData, deleteData, formatManualContactUpload } from "utils";
import { submitUserContactsFromCloudsponge } from "containers/Contacts/utils";
import { useAlert } from "hooks";

export const ContactsContext = createContext();

/**
 * Immutable enum for the possible states of contacts.
 * @readonly
 * @enum {string}
 */
const Mode = Object.freeze({
  idle: "idle",
  loading: "loading",
  loaded: "loaded",
  noResults: "noResults",
  error: "error",
});

const initialContactState = {
  /** Handles the status of getting contacts and the UI display */
  mode: Mode.idle,
  /** The total number of contacts in someone's network */
  numberOfNodes: 0,
  /** The current set of contact results returned by the API. */
  contactsAll: [],
  currentPage: 1,
  itemsPerPage: 10,
  searchName: "",
  cloudspongeData: {
    showModal: false,
    contactsWithNames: [],
    contactsWithoutNames: [],
  },
};

const SET_MODE = "SET_MODE";
const CREATE_CONTACT = "CREATE_CONTACT";
const SET_CONTACTS = "SET_NETWORK_CONTACTS";
const GET_CONTACT = "GET_CONTACTS";
const UPDATE_CONTACT = "UPDATE_CONTACT";
const DELETE_CONTACT = "DELETE_CONTACT";
const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";
const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_SEARCH_NAME = "SET_SEARCH_NAME";
const SHOW_CLOUDSPONGE_MODAL = "SHOW_CLOUDSPONGE_MODAL";
const CLOSE_CLOUDSPONGE_MODAL = "CLOSE_CLOUDSPONGE_MODAL";

export const ContactProvider = ({ children }) => {
  const contactReducer = (state, action) => {
    switch (action.type) {
      case SET_MODE:
        return { ...state, mode: action.payload.mode };
      case CREATE_CONTACT:
        return {
          ...state,
          contactsAll: [...state.contactsAll, action.payload.contact],
          numberOfNodes: (state.numberOfNodes += 1),
        };
      case GET_CONTACT: {
        return action.contacts;
      }
      case SET_CONTACTS:
        return {
          ...state,
          contactsAll: action.payload.allContacts,
          numberOfNodes: action.payload.numberOfNodes,
          mode: action.payload.mode,
        };
      case UPDATE_CONTACT: {
        return action.contacts;
      }
      case DELETE_CONTACT:
        if (state.contactsAll.length > 1) {
          return {
            ...state,
            contactsAll: [
              ...state.contactsAll.slice(0, action.index),
              ...state.contactsAll.slice(action.index + 1),
            ],
            numberOfNodes: state.numberOfNodes - 1,
          };
        } else {
          return {
            ...state,
            contactsAll: [],
          };
        }
      case SET_SEARCH_NAME:
        return {
          ...state,
          searchName: action.payload.searchName,
        };
      case SHOW_CLOUDSPONGE_MODAL:
        return {
          ...state,
          cloudspongeData: {
            showModal: true,
            contactsWithoutNames: action.payload.contactsWithoutNames,
            contactsWithNames: action.payload.contactsWithNames.length
              ? action.payload.contactsWithNames
              : [],
          },
        };
      case CLOSE_CLOUDSPONGE_MODAL:
        return {
          ...state,
          cloudspongeData: {
            showModal: false,
            contactsWithoutNames: [],
            contactsWithNames: [],
          },
        };
      case SET_ITEMS_PER_PAGE:
        return {
          ...state,
          itemsPerPage: action.payload,
        };
      case SET_CURRENT_PAGE:
        return {
          ...state,
          currentPage: action.payload,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(contactReducer, initialContactState);
  const { addAlert } = useAlert();

  /**
   * This function is used to get the list of user contacts, either with or without a search term
   */
  const getContacts = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      if (!cognitoUser.attributes["custom:onboarding_completed"]) return null;
      dispatch({ type: SET_MODE, payload: { mode: Mode.loading } });
      const contacts = await fetchData(
        `/contacts/?skip=${
          (state.currentPage - 1) * state.itemsPerPage
        }&limit=${state.itemsPerPage}&filter_by=${state.searchName}`
      );
      const allContacts = contacts.revmo_contacts ?? [];
      if (allContacts.length) {
        const payload = {
          allContacts,
          numberOfNodes: contacts.total_contacts,
          mode: "loaded",
        };
        return dispatch({ type: SET_CONTACTS, payload });
      } else {
        dispatch({ type: SET_MODE, payload: { mode: Mode.noResults } });
      }
    } catch (err) {
      dispatch({ type: SET_MODE, payload: { mode: Mode.error } });
      if (err === "The user is not authenticated") {
        dispatch({ type: SET_MODE, payload: { mode: Mode.noResults } });
      }
      console.log(err);
    }
  }, [state.currentPage, state.itemsPerPage, state.searchName]);

  /**
   * @param {string} searchName - Search name to filter by.
   */
  const setSearchName = useCallback((searchName) => {
    dispatch({ type: SET_SEARCH_NAME, payload: { searchName: searchName } });
  }, []);

  const addContactToNetwork = useCallback((data, contactAddress) => {
    const formattedContact = formatManualContactUpload(data, contactAddress)[0];
    dispatch({
      type: CREATE_CONTACT,
      payload: {
        contact: formattedContact,
      },
    });
  }, []);

  const deleteContactFromNetwork = useCallback(
    async (uuid, index) => {
      await deleteData(`/contacts/${uuid}`);
      dispatch({ type: DELETE_CONTACT, index });
      if (state.numberOfNodes > 10) {
        getContacts();
      }
      addAlert(
        "success",
        "Contact successfully deleted from network",
        "success"
      );
    },
    [addAlert, getContacts, state.numberOfNodes]
  );

  const setItemsPerPage = (itemsPerPage) => {
    dispatch({ type: SET_ITEMS_PER_PAGE, payload: itemsPerPage });
  };

  const setCurrentPage = (page) => {
    dispatch({ type: SET_CURRENT_PAGE, payload: Math.min(page) });
  };

  const submitAndAddContactsFromCloudsponge = useCallback(
    async (contacts) => {
      const newContacts = await submitUserContactsFromCloudsponge(
        contacts,
        addAlert
      );
      const { formattedContactsWithNames, contactsWithoutNames } = newContacts;
      if (formattedContactsWithNames.length && !contactsWithoutNames.length) {
        getContacts();
      } else if (contactsWithoutNames.length) {
        dispatch({
          type: SHOW_CLOUDSPONGE_MODAL,
          payload: {
            contactsWithNames: formattedContactsWithNames,
            contactsWithoutNames: contactsWithoutNames,
          },
        });
      }
    },
    [addAlert, getContacts]
  );

  const closeCloudspongeModal = () => {
    dispatch({ type: CLOSE_CLOUDSPONGE_MODAL });
  };

  const values = useMemo(
    () => ({
      contactsContextState: state || {},
      dispatch,
      getContacts,
      deleteContactFromNetwork,
      setItemsPerPage,
      setCurrentPage,
      setSearchName,
      closeCloudspongeModal,
      addContactToNetwork,
      submitAndAddContactsFromCloudsponge,
    }),
    [
      state,
      getContacts,
      deleteContactFromNetwork,
      setSearchName,
      addContactToNetwork,
      submitAndAddContactsFromCloudsponge,
    ]
  );

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  return (
    <ContactsContext.Provider value={values}>
      {children}
    </ContactsContext.Provider>
  );
};
