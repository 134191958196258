import React from "react";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import { DownwardChevron } from "components/Assets";
import { InputName } from "./index";
import AdvancedSearchCustomOptions from "./AdvancedSearchCustomOptions";

const SelectWrapper = styled.div`
  height: max-content;
  flex: 1;
  order: ${(props) => props.order};
  :last-child {
    margin-right: unset;
  }
  @media (max-width: 870px) {
    max-width: calc(50% - 10px);
    min-width: calc(50% - 10px);
    margin-bottom: 20px;
    :nth-child(even) {
      margin-right: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 480px) {
    max-width: 100%;
    min-width: 100%;
  }
  ${(props) => {
    if (props.lessWidth) {
      return `
        max-width: calc(14.6% - 7px);
      `;
    }
    if (props.disabled) {
      return `
        cursor: not-allowed;
      `;
    }
  }}
`;

const CustomSelect = styled.div`
  padding-left: ${(props) => (props.noIcon ? "10px" : "9px")};
  font-size: 12px;
  cursor: pointer;
  text-overflow: ellipsis;
  background: #fcfcfc;
  border: 1px solid ${(props) => props.theme.borderColor};
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 5px;
  height: 35px;
  width: 100%;
  p {
    color: #7c919e;
    font-weight: 500;
    font-size: 12px;
    margin: 0 20px 0 6px;
    text-overflow: ellipsis;
    max-width: 75%;
    white-space: nowrap;
    overflow: hidden;
  }
  ${(props) => {
    if (props.selected) {
      return `
      background: ${props.theme.brandColor};
      p {
        color: white;
      }
      `;
    }
    if (props.disabled) {
      return `
        cursor: not-allowed;
      `;
    }
  }}
`;

const CustomSelectInner = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  ${(props) => {
    if (props.dropdownIsOpen || props.disabled) {
      return `
        pointer-events: none;
      `;
    }
  }}
`;

function AdvancedSearchCustomDropdown({
  searchOptions,
  listOfOptions,
  searchCriteriaName,
  formatDefaultText,
  addOrRemoveSearchCriteria,
  searchPage,
  optionIcon: OptionIcon,
  openAndCloseDropdowns,
  closeAllDropdownOnPageBodyClick,
  order,
  lessWidth,
  disabled,
}) {
  const correctNames = {
    industries: "Industry",
    history: "History",
    company: "Company",
    revmoScore: "Revmo Score",
    companySize: "Company Size",
    showCompanies: "Show Companies",
    searchRadius: "Search Distance",
  };

  return (
    <Tooltip
      title={
        disabled == false
          ? ""
          : "To select search distance, please choose a location"
      }
    >
      <SelectWrapper lessWidth={lessWidth} order={order} disabled={disabled}>
        <InputName disabled={disabled}>
          {" "}
          {correctNames[searchCriteriaName]}
        </InputName>
        <CustomSelect
          searchPage={searchPage}
          searchCriteriaName={searchCriteriaName}
          selected={searchOptions[searchCriteriaName].name.length}
          showDropdown={searchOptions[searchCriteriaName].showDropdown}
          disabled={disabled}
        >
          <CustomSelectInner
            dropdownIsOpen={searchOptions[searchCriteriaName].showDropdown}
            onClick={() =>
              !disabled && openAndCloseDropdowns(searchCriteriaName)
            }
            disabled={disabled}
          />
          {OptionIcon && (
            <OptionIcon
              selected={searchOptions[searchCriteriaName].name.length}
            />
          )}
          <p>
            {searchOptions[searchCriteriaName].name.length
              ? formatDefaultText(
                  searchOptions[searchCriteriaName].name,
                  searchCriteriaName
                )
              : searchCriteriaName === "industries"
              ? `Select ${correctNames[searchCriteriaName]}`
              : listOfOptions[0]}
          </p>
          <DownwardChevron
            selected={searchOptions[searchCriteriaName].name.length}
          />
          {searchOptions[searchCriteriaName].showDropdown && (
            <AdvancedSearchCustomOptions
              searchOptions={searchOptions}
              searchCriteriaName={searchCriteriaName}
              addOrRemoveSearchCriteria={addOrRemoveSearchCriteria}
              listOfOptions={listOfOptions}
              closeAllDropdownOnPageBodyClick={closeAllDropdownOnPageBodyClick}
            />
          )}
        </CustomSelect>
      </SelectWrapper>
    </Tooltip>
  );
}

export default AdvancedSearchCustomDropdown;
