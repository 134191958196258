import { useState } from "react";

function useImageUpload() {
  const [loaded, setLoaded] = useState(false);
  const [imageString, setImageString] = useState("");
  const [fileIsTooLarge, setFileIsTooLarge] = useState(null);

  const selectImageToUpload = (event) => {
    const fileReference = event.target.files[0];
    const reader = new FileReader();
    if (fileReference) {
      reader.readAsDataURL(fileReference);
    }

    const { size, type } = formatBytes(fileReference.size);
    if (type > 2 || (type === 2 && size >= 3)) {
      setFileIsTooLarge(true);
    } else {
      reader.onload = (event) => {
        setImageString(event.target.result);
      };
      setLoaded(true);
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return {
      size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
      type: i,
    };
  }

  const clearImageUpload = () => {
    setImageString("");
    setLoaded(false);
  };

  return {
    selectImageToUpload,
    loaded,
    setLoaded,
    imageString,
    setImageString,
    clearImageUpload,
    fileIsTooLarge,
    setFileIsTooLarge,
  };
}

export default useImageUpload;
