import React from "react";
import styled from "styled-components";

const CardContainer = styled.a`
  margin-bottom: 1.5rem;
  overflow: hidden;
  flex: 0 0 100%;
  border: 1px solid #cdd4d9;
  border-radius: 0.5rem;
  @media (min-width: 768px) {
    flex: 0 0 80%;
  }
  @media (min-width: 1024px) {
    flex: 0 0 70%;
  }
  :hover {
    div {
      h3 {
        color: #337ab9;
      }
    }
  }
`;

const CardWrapper = styled.div`
  padding: 2rem;
  text-decoration: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  span {
    color: #8495a2;
  }
  h3 {
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: 500;
    flex-grow: 2;
  }
  p:first-of-type {
    font-weight: normal;
  }
  @media (min-width: 768px) {
    padding: 3rem;
  }
  @media (min-width: 1024px) {
    padding: 3rem;
  }
`;

const CardImage = styled.img`
  max-width: 100%;
`;

function ArticleCard({ article }) {
  return (
    <CardContainer href={article.url} target="_blank">
      <CardImage src={article?.mainImage?.asset?.url} />
      <CardWrapper>
        <span>{article.author}</span>
        <h3>{article.title}</h3>
        <p>{article.blurb}</p>
        <p>{article.publishedAt}</p>
      </CardWrapper>
    </CardContainer>
  );
}

export default ArticleCard;
