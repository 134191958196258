import React from "react";
import { useHistory } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material";
import {
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

// project imports
import { Avatar } from "components/DashboardNew";
import { AnonymousUser } from "components/Assets/Image";

// assets
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockTwoToneIcon from "@mui/icons-material/BlockTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

function UserContactsList({
  contactsAll,
  toggleAddOrEditSingleContactModal,
  diallingCodesHook,
  deleteContact,
  currentPage,
  itemsPerPage,
}) {
  const theme = useTheme();
  const history = useHistory();

  const displayEditPositionModal = (index, contact) => {
    let diallingCodeState = {};
    if (contact?.positions[0]?.telephone_number?.country_code) {
      diallingCodeState.companyPhoneNumber =
        contact?.positions[0].telephone_number;
    }
    if (contact?.telephone_number?.country_code) {
      diallingCodeState.personalPhoneNumber = contact?.telephone_number;
    }
    if (Object.keys(diallingCodeState).length) {
      diallingCodesHook.setDiallingCodeToLiveData(diallingCodeState);
    }
    toggleAddOrEditSingleContactModal("edit", index, contact);
  };

  return (
    <TableContainer sx={{ display: { xs: "none", md: "block" } }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 3 }}>#</TableCell>
            <TableCell>
              <Typography align="left" variant="subtitle2">
                Contact Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography align="center" variant="subtitle2">
                Position
              </Typography>
            </TableCell>
            <TableCell>
              <Typography align="center" variant="subtitle2">
                Company
              </Typography>
            </TableCell>
            <TableCell align="center" sx={{ pr: 3 }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contactsAll &&
            contactsAll.map((contact, index) => (
              <TableRow hover key={index}>
                <TableCell sx={{ pl: 3 }}>
                  {(currentPage - 1) * itemsPerPage + index + 1}
                </TableCell>
                <TableCell>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      {contact?.profile_image ? (
                        <Avatar
                          alt="User profile image"
                          src={contact.profile_image}
                        />
                      ) : (
                        <AnonymousUser />
                      )}
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Typography
                        align="left"
                        variant="subtitle1"
                        component="div"
                      >
                        {`${contact.first_name} ${contact.last_name}`}{" "}
                        {contact.status === "Active" && (
                          <CheckCircleIcon
                            sx={{
                              color: "success.dark",
                              width: 14,
                              height: 14,
                            }}
                          />
                        )}
                      </Typography>
                      <Typography align="left" variant="subtitle2" noWrap>
                        {contact.personal_email_addresses[0]?.email_address}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography align="center" variant="subtitle2">
                    {contact?.positions[0]?.role_description}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography align="center" variant="subtitle2">
                    {contact?.positions[0]?.org?.org_name}
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ pr: 3 }}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Tooltip placement="top" title="View">
                      <IconButton
                        color="primary"
                        aria-label="view"
                        size="large"
                        onClick={() =>
                          history.push({
                            pathname: "/profile/contact",
                            state: { contact },
                          })
                        }
                      >
                        <RemoveRedEyeIcon sx={{ fontSize: "1.1rem" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip placement="top" title="Edit">
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        size="large"
                        onClick={() => displayEditPositionModal(index, contact)}
                      >
                        <EditTwoToneIcon sx={{ fontSize: "1.1rem" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip placement="top" title="Delete">
                      <IconButton
                        color="primary"
                        sx={{
                          color: theme.palette.tertiary.dark,
                          borderColor: theme.palette.tertiary.main,
                          "&:hover ": {
                            background: theme.palette.tertiary.light,
                          },
                        }}
                        size="large"
                        onClick={() => deleteContact(contact.uuid, index)}
                      >
                        <BlockTwoToneIcon sx={{ fontSize: "1.1rem" }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UserContactsList;
