import React from "react";
import styled from "styled-components";

const LandingScreenshotWrapper = styled.div`
  width: 100%;
  padding: 40px 0 40px 0;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  text-align: center;
  div {
    max-width: 1080px;
  }
  h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    color: white;
  }
  p {
    font-size: 25px;
    font-weight: normal;
    color: white;
    margin-left: 10%;
    margin-right: 10%;
  }
  @media (min-width: 871px) {
  }
  @media (max-width: 500px) {
    text-align: center;
    h2 {
      max-width: 80%;
    }
    h4 {
      max-width: 75%;
    }
    img {
      max-width: 95%;
    }
  }
  @media (max-width: 870px) {
    text-align: center;
    h2 {
      max-width: 80%;
    }
    h4 {
      max-width: 75%;
    }
    img {
      max-width: 85%;
    }
  }
`;

const GettingStartedSection = () => {
  return (
    <LandingScreenshotWrapper>
      <div>
        <p>
          <strong>Revmo</strong> is a privacy-first networking platform where
          professionals easily make automated personalized introductions across
          comprehensive networks.
        </p>
        <p>
          We’re thrilled to have you join the initial group of Revmo users and
          help shape the experience of our brand-new, privacy-first,
          professional networking platform.
        </p>
        <p>
          Your feedback will be extremely valuable during this beta. Please
          reach out at <strong>beta@revmo.app</strong> with any feedback,
          questions, concerns, or simply just to nominate others for beta
          access.
        </p>
      </div>
    </LandingScreenshotWrapper>
  );
};

export default GettingStartedSection;
