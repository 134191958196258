import React from "react";
import { MidLargeBoldText } from "components/Typography";
import { SettingsSelectContentRow } from "components/Menu";
import { PageHeader } from "components/Layout";

function SettingsHeader({ setSettingsType, settingsType }) {
  const aboutText =
    "Fine-tune your account settings to adjust notifications, search options, and more.";
  return (
    <>
      <PageHeader titleText="Settings and Privacy" aboutText={aboutText} />
      <SettingsSelectContentRow>
        <MidLargeBoldText
          onClick={() => setSettingsType("Account settings")}
          underline={settingsType === "Account settings"}
        >
          Account settings
        </MidLargeBoldText>
      </SettingsSelectContentRow>
    </>
  );
}

export default SettingsHeader;
