import React, { useState } from "react";
import { HeaderText } from "components/Typography";
import styled from "styled-components";
import Cropper from "react-easy-crop";
import { Slider } from "react-semantic-ui-range";
import { DarkBlurredBackground } from "components/Modal";
import { getCroppedImg } from "./utils";
import { AuthButtonNoArrow } from "components/Button";
import "semantic-ui-css/semantic.min.css";
import closeIcon from "assets/icons/close-icon.svg";

const EditImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.loaded ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2001;
  .reactEasyCrop {
    &_Container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: unset;
      width: 720px;
      height: 720px;
    }
    &_Image {
      height: 378px;
    }
  }
`;

const EditImageModal = styled.div`
  background: black;
  z-index: 50;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  @media (max-width: 480px) {
    transform: scale(0.5);
  }
  h1 {
    margin-bottom: 0;
  }
`;

const EditImageHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: #f7f6f8;
  padding-left: 20px;
  display: flex;
  align-items: center;
  z-index: 55;
  justify-content: space-between;
`;

const EditImageSliderWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  height: 80px;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 500px;
  }
  .semantic_ui_range_inner {
    div:first-child {
      background-color: #b4b4b4 !important;
    }
  }
`;

const EditImageFooter = styled.div`
  width: 100%;
  height: 80px;
  background: #f7f6f8;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 20px;
  align-items: center;
  button {
    margin-top: 0;
  }
`;

const CloseModalButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  cursor: pointer;
  img {
    height: 25px;
  }
`;

function EditImage({
  imageString,
  setImageString,
  loaded,
  setLoaded,
  clearImageUpload,
  setFileIsTooLarge,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [edittedImage, setEdittedImage] = useState(imageString);

  const onCropComplete = async (_, croppedAreaPixels) => {
    setEdittedImage(await getCroppedImg(imageString, croppedAreaPixels));
  };

  const submitUpdatedImage = (edittedImage) => {
    setImageString(edittedImage);
    setLoaded(false);
    setFileIsTooLarge(null);
  };

  const settings = {
    start: 1,
    min: 1,
    max: 2,
    step: 0.1,
    onChange: (zoom) => {
      setZoom(zoom);
    },
  };

  return (
    <>
      <EditImageWrapper loaded={loaded}>
        <DarkBlurredBackground />
        <EditImageModal>
          <EditImageHeader>
            <HeaderText>Edit Photo</HeaderText>
            <CloseModalButtonWrapper onClick={clearImageUpload}>
              <img src={closeIcon} />
            </CloseModalButtonWrapper>
          </EditImageHeader>
          <Cropper
            image={imageString}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape={"round"}
            maxZoom={2} //Set maxZoom to prevent slider out of range
          />
          <EditImageSliderWrapper>
            <Slider value={zoom} color="red" settings={settings} />
          </EditImageSliderWrapper>
          <EditImageFooter>
            <AuthButtonNoArrow
              disabled={false}
              padding={"11px 27px"}
              buttonText={"Save Photo"}
              action={() => {
                submitUpdatedImage(edittedImage);
              }}
            />
          </EditImageFooter>
        </EditImageModal>
      </EditImageWrapper>
    </>
  );
}

export default EditImage;
