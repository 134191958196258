import React from "react";
import { useUser } from "hooks";
import {
  RouteNotFoundUserIsLoggedIn,
  RouteNotFoundNoUser,
} from "../components/Route";

function RouteNotFound() {
  const { user } = useUser();

  return (
    <>{user ? <RouteNotFoundUserIsLoggedIn /> : <RouteNotFoundNoUser />}</>
  );
}

export default RouteNotFound;
