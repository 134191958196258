import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { searchAddressState, clearSearchAddressState } from "state";
import { searchesRemaining } from "state/settings";
import styled from "styled-components";
import { SearchButtonSearchForm } from "components/Button";
import Button from "@mui/material/Button";
import arrowWhite from "assets/icons/arrow-white.svg";
import { SearchForm } from "../components";
import {
  HeaderTextSearch,
  BlueLinkTextUnderlined,
} from "components/Typography";
import { AdvancedSearch } from "components/AdvancedSearch";
import { useForm } from "react-hook-form";
import { useAdvancedSearch } from "hooks";
import { useContactAndOrgTags } from "../utils";

const SearchHeaderWrapper = styled.div`
  padding: 40px 0 34px 0;
  position: relative;
  width: 100%;
  h1 {
    margin: 0;
    margin-bottom: 28px;
  }
  button {
    margin-right: 0;
  }
  @media (max-width: 480px) {
    padding-bottom: 0;
  }
`;

const PrimarySearchFields = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 690px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const HeaderRemainingSearchesText = styled.p`
  color: grey;
  margin-top: 15px;
  font-size: 14px;
  a {
    text-decoration: none;
    font-weight: 300;
  }
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const GreyHeaderBackgroundSearch = styled.div`
  height: 382px;
  top: 50px;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  background: #f7f6f8;
  @media (max-width: 870px) {
    height: 685px;
  }
  @media (max-width: 480px) {
    height: 894px;
  }
`;

const SearchBarIcon = styled.img`
  pointer-events: none;
`;

const SearchHeaderSecondRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  button {
    margin-right: 22px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 870px) {
    button {
      margin-right: 12px;
      order: 1;
      img {
        order: 2;
      }
    }
    p {
      order: 2;
    }
  }
  @media (max-width: 480px) {
    ${BlueLinkTextUnderlined} {
      margin-top: 10px;
    }
  }
`;

function SearchHeader({
  searchRequestHistory,
  handleSubmitSearchRequest,
  setError,
  resetSearch,
}) {
  const userValues = {
    position: searchRequestHistory?.position,
    person: searchRequestHistory?.person,
  };

  const [searchAddress, setSearchAddress] = useRecoilState(searchAddressState);
  const [, setClearSearchAddress] = useRecoilState(clearSearchAddressState);

  const { register, handleSubmit, watch, reset } = useForm({
    defaultValues: userValues,
  });

  const advancedSearchHook = useAdvancedSearch(searchRequestHistory);
  const disabled = advancedSearchHook.disableForm(watch());
  const contactAndOrgsTagHook = useContactAndOrgTags();

  const usageData = useRecoilValueLoadable(searchesRemaining);
  const showUsageData = usageData.state === "hasValue";
  const searches = usageData.contents;

  const resetValues = () => {
    reset({
      position: "",
      person: "",
    });
    setSearchAddress({
      location: "",
      error: "",
    });
    setClearSearchAddress(true);
    resetSearch();
    // clear location state without triggering rerender
    window.history.replaceState({}, document.title);
    advancedSearchHook.resetAdvancedSearch();
    setError("");
  };

  return (
    <>
      <SearchHeaderWrapper>
        <HeaderTextSearch>Search</HeaderTextSearch>
        <form
          onSubmit={handleSubmit((data) =>
            handleSubmitSearchRequest(
              { ...data, tags: contactAndOrgsTagHook.searchFilter.tags },
              advancedSearchHook.searchOptions,
              searchAddress
            )
          )}
        >
          <PrimarySearchFields>
            <SearchForm
              register={register}
              advancedSearchHook={advancedSearchHook}
            />
          </PrimarySearchFields>
          <AdvancedSearch
            register={register}
            advancedSearchHook={advancedSearchHook}
          >
            <SearchHeaderSecondRow>
              <SearchButtonSearchForm type="submit" disabled={disabled}>
                <p>SEARCH REVMO</p>
                <SearchBarIcon searchButton src={arrowWhite} />
              </SearchButtonSearchForm>
              <Button variant="outlined" onClick={resetValues}>
                Clear
              </Button>
            </SearchHeaderSecondRow>
          </AdvancedSearch>
          {showUsageData && (
            <HeaderRemainingSearchesText>
              As a free tier account user, you have{" "}
              {searches?.search_count ?? "0"} searches remaining.{" "}
              <Link to="/settings">Please upgrade here.</Link>
            </HeaderRemainingSearchesText>
          )}
        </form>
      </SearchHeaderWrapper>
    </>
  );
}

export default SearchHeader;
