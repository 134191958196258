import React from "react";
import styled from "styled-components";
import { SectionWrapper, ContentWrapper } from "../Layout";
import { Accordion } from "components/LandingPage";

const FAQHeader = styled.div`
  text-align: center;
`;

const data = [
  {
    question: "What is Revmo?",
    answer:
      "Revmo is a platform that enables professionals to make connections that matter. Utilizing every meaningful input source — CRM systems, contact lists, social/professional networks, and countless other sources — our professional and enterprise users can make meaningful professional connections in an instant.",
  },
  {
    question: "For whom is Revmo intended?",
    answer:
      "If your job requires a “Rolodex,” Revmo is for you.  Founders, business development professionals, sales teams and recruiters can connect with the right people through Revmo.  For businesses and larger enterprises, please see our enterprise page and contact ",
    email: "support@revmo.app",
  },
  {
    question: "Do I have to upload my contacts to use Revmo?",
    answer:
      "Uploading your contacts enables Revmo to map your network and identify the broader universe you can reach. The more you put in, the more you get out!",
  },
  {
    question: "Do the people I want to meet have to be on Revmo?",
    answer:
      "The people you are looking to meet do not have to be on Revmo to be reached. With Revmo, everyone is within reach.",
  },
  {
    question:
      "What if I don’t want to make an introduction? Or what if the connector says no to my request?",
    answer:
      "Revmo handles redirection and rejection for you gently, and discreetly. If the recipient of the request cannot, or will not make the introduction, Revmo finds the next best path.",
  },
  {
    question: "How secure is your platform?",
    answer:
      "Privacy and respect for your data are at the core of our business. Nobody can see your data - neither Revmo nor other users - unless you want them to.  Privacy settings are entirely customizable and all data in our platform is encrypted to higher than industry standards.",
  },
];

function FAQ() {
  return (
    <SectionWrapper>
      <ContentWrapper>
        <FAQHeader>
          <h2>FAQ</h2>
          <p>
            If we haven't answered your question below, drop us a note at
            <a href="mailto:support@revmo.app"> support@revmo.app</a>.
          </p>
        </FAQHeader>
        <Accordion data={data} />
      </ContentWrapper>
    </SectionWrapper>
  );
}

export default FAQ;
