import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useUser } from "hooks";
import { SimpleNav } from "components/Navbar";
import {
  HeaderText,
  MidLargeSpacedText,
  BlueLinkText,
} from "components/Typography";

const ContactUsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  min-height: calc(100vh - 50px);
  padding: 110px 20px 153px 20px;
  height: 100%;
`;

const ContactUsPageWrapperCentered = styled.div`
  max-width: 540px;
  width: 100%;
  h1 {
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 8px;
    font-size: 18px;
    @media (max-width: 480px) {
      margin-bottom: 0;
    }
  }
  a {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    @media (max-width: 480px) {
      font-size: 15px;
      line-height: 27px;
      letter-spacing: 0.355556px;
    }
  }
`;

const ContactUsTextWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  p {
    margin-top: 4px;
    margin-bottom: 0;
  }
`;

function ContactUs() {
  const { user } = useUser();
  return (
    <>
      {!user && <SimpleNav />}
      <ContactUsPageWrapper>
        <ContactUsPageWrapperCentered>
          <HeaderText>Contact us</HeaderText>

          <MidLargeSpacedText>
            Any questions or queries, please contact us at:
          </MidLargeSpacedText>
          <ContactUsTextWrapper>
            <BlueLinkText as="a" href="mailto:support@revmo.app">
              support@revmo.app
            </BlueLinkText>
            <MidLargeSpacedText>&nbsp;or head to the</MidLargeSpacedText>
            <Link to="/support" target={"_blank"}>
              <BlueLinkText>&nbsp;Revmo helpdesk</BlueLinkText>
            </Link>
          </ContactUsTextWrapper>
        </ContactUsPageWrapperCentered>
      </ContactUsPageWrapper>
    </>
  );
}

export default ContactUs;
