import * as yup from "yup";
import { deleteData, postData, putData } from "utils";
/* eslint-disable */

export const addOrEditPositionDataSchema = yup.object().shape({
  company: yup
    .object()
    .required()
    .typeError("Please enter the name of your company"),
  position: yup.string().required("Please enter your position"),
  phoneNumber: yup.string().matches(/[0-9]{0,14}$/, {
    message: "Please enter a valid international number",
    excludeEmptyString: true,
  }),
  email: yup.string().email("Hmm... That doesn't look like a valid email"),
});

export const updateUserCareers = (career, updatedJob) => {
  const updatedCareer = career.map((role) =>
    updatedJob.uuid === role.uuid ? updatedJob : role
  );
  return updatedCareer;
};

/**
 * Format the org object returned by the OrgAutocomplete component
 * @param {Object} company
 * @returns {Object} org - the org associated with the position
 * @returns {string} org.org_name - the org name
 */
const formatUserOrg = (company) => {
  let org = {};
  if (company.uuid) {
    org = company;
  } else if (company.inputValue) {
    org = { org_name: company.inputValue };
  } else {
    org = {};
  }
  return org;
};

export const updateUserPosition = (
  profile,
  updateUserProfile,
  getUserProfile
) => {
  return async (
    data,
    job,
    setDisplayModal,
    diallingCode,
    contactAddress,
    addAlert
  ) => {
    const {
      uuid,
      company,
      email,
      location,
      position,
      companyPhoneNumber,
      startYear,
      startMonth,
      endYear,
      endMonth,
    } = data;
    let updatedJob = { ...job };
    updatedJob.org = formatUserOrg(company);
    updatedJob.role_description = position;
    updatedJob.company_email_addresses = email
      ? [{ email_address: email }]
      : [];
    updatedJob.is_active = endMonth ? false : true;
    updatedJob.start_date = startMonth
      ? `${startYear}-${startMonth.value}-01`
      : null;
    updatedJob.end_date = endMonth ? `${endYear}-${endMonth.value}-01` : null;

    if (!uuid) {
      const newProfile = await getUserProfile();
      const newPosition = await newProfile.positions.filter((role) => {
        return (
          role.role_description === job.role_description &&
          role.org.org_name === job.org.org_name
        );
      });
      updatedJob.uuid = newPosition[0].uuid;
    }
    delete updatedJob.role;

    if (companyPhoneNumber) {
      updatedJob.telephone_number = {
        country_code: diallingCode?.companyPhoneNumber?.countryCode,
        national_number: companyPhoneNumber,
        region_code: diallingCode.companyPhoneNumber.regionCode.toUpperCase(),
      };
    } else {
      updatedJob.telephone_number = null;
    }

    if (contactAddress && contactAddress?.professionalLatLng?.lng) {
      const lookupPointCoords = [
        contactAddress?.professionalLatLng?.lng,
        contactAddress?.professionalLatLng?.lat,
      ];
      updatedJob.org.additional_locations = [
        { lookup_point: { coordinates: lookupPointCoords } },
      ];
    }
    const updatedCareer = updateUserCareers(profile?.positions, updatedJob);
    const updatedUserProfile = { ...profile, positions: updatedCareer };
    try {
      const careersHaveBeenUpdated = await putData(
        `/profile/positions/`,
        JSON.stringify(updatedJob)
      );
      updateUserProfile(updatedUserProfile);
      setDisplayModal(false);
    } catch (err) {
      addAlert(
        "positionError",
        "We were unable to update this role. Please try again later.",
        "error"
      );
      setDisplayModal(false);
    }
  };
};

export const updateUserProfilePicture = (profile, updateUserProfile) => {
  return async (imageString, setDisplayModal) => {
    try {
      let profileImage = imageString?.length
        ? imageString
        : profile.profile_image;
      let updatedProfile = { ...profile };
      if (profileImage?.length) {
        updatedProfile.profile_image = profileImage;
        await putData(`/profile/`, JSON.stringify(updatedProfile));
        setDisplayModal(false);
        updateUserProfile(updatedProfile);
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const createAndUpdatePosition = async (
  data,
  contactAddress,
  profile,
  getUserProfile
) => {
  const {
    company,
    email,
    position,
    startMonth,
    startYear,
    endMonth,
    endYear,
    isActive,
  } = data;

  let newJob = {};
  newJob.org = formatUserOrg(company);
  newJob.company_email_addresses = email ? [{ email_address: email }] : [];
  newJob.role_description = position;
  newJob.role = [{ role_name: position }];
  newJob.is_active = isActive;
  newJob.start_date = startMonth ? `${startYear}-${startMonth.value}-01` : null;
  newJob.end_date = endMonth ? `${endYear}-${endMonth.value}-01` : null;

  if (contactAddress.professionalLatLng) {
    const lookupPointCoords = [
      contactAddress.professionalLatLng.lng,
      contactAddress.professionalLatLng.lat,
    ];
    newJob.primary_location = {
      lookup_point: { coordinates: lookupPointCoords },
    };
  }

  const updatedProfile = { ...profile };
  updatedProfile.positions = [newJob, ...updatedProfile.positions];

  await postData(`/profile/positions/`, JSON.stringify({ ...newJob }));
  await getUserProfile();

  return updatedProfile;
};

export const deleteAndUpdateUserRoles = async (
  profile,
  roleUUID,
  job,
  getUserProfile
) => {
  try {
    let updatedPositions = [];
    if (!roleUUID) {
      const updatedProfile = await getUserProfile();
      const positionToDelete = await updatedProfile.positions.filter((role) => {
        return (
          role.role_description === job.role_description &&
          role.org.org_name === job.org.org_name
        );
      })[0];
      updatedPositions = updatedProfile.positions.filter((role) => {
        return role.uuid != positionToDelete.uuid;
      });
      deleteData(`/profile/positions/${positionToDelete.uuid}`);
    } else {
      updatedPositions = profile.positions.filter((role) => {
        return role.uuid != roleUUID;
      });
      deleteData(`/profile/positions/${roleUUID}`);
    }
    const updatedProfile = { ...profile, positions: updatedPositions };
    return updatedProfile;
  } catch (err) {
    console.log(err);
  }
};

export const sortPositions = (profile) => {
  if (profile?.positions) {
    return [...profile.positions].sort((a, b) => {
      return b.seq_no - a.seq_no;
    });
  }
};
