import React, { useEffect, useState } from "react";
import sanityClient from "../../../client";
import { Container996 } from "components/Post/Layout";
import UpdatesItem from "./UpdatesItem";

function UpdatesListItems() {
  const [crmData, setCRMData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'update'] | order(publishedAt desc)
              {
              title,
              slug,
              publishedAt,
              body,
              mainImage{
                asset->{
                _id,
                url
              }
            }
          }`
      )
      .then((data) => setCRMData(data))
      .catch(console.error);
  }, []);

  return (
    <Container996>
      {crmData &&
        crmData.map((post, index) => {
          return <UpdatesItem post={post} key={index} />;
        })}
    </Container996>
  );
}

export default UpdatesListItems;
