import styled from "styled-components";
import heroBackground from "assets/images/hero-background.png";

export const CareersPageWrapper = styled.div`
  background: radial-gradient(
      50% 125.2% at 100% 100%,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    ),
    url(${heroBackground});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1,
  h2,
  h3,
  h4,
  p,
  a {
    font-family: Montserrat, "sans-serif";
  }
`;

export const RolesWrapper = styled.div`
  max-width: 840px;
  width: 100%;
  margin: 20px 20px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 6px 10px -11px rgb(49 71 95 / 30%);
`;

export const JobWrapper = styled.div`
  background-color: #ffffff;
  max-width: 840px;
  width: 100%;
  margin: 20px 20px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 6px 10px -11px rgb(49 71 95 / 30%);
  a > button {
    margin: 15px;
  }
`;
