import React from "react";
import { RequestCards } from "components/Card";
import { TableAndCardContainer } from "./Layout";
import { PageWrapper } from "components/Layout";
import { MyRequestsHeader, RequestTables } from "./components";
import { UpdateRequestStatus } from "components/Modal";
import { useUpdateRequests } from "./utils";

function MyRequests() {
  const updateRequestHook = useUpdateRequests();

  return (
    <PageWrapper>
      <MyRequestsHeader />
      <TableAndCardContainer className="walkthrough-step-11">
        <RequestCards myRequestsPage />
        <RequestTables />
      </TableAndCardContainer>
      <UpdateRequestStatus
        displayModal={updateRequestHook.updateRequest.showMenu}
        setDisplayModal={updateRequestHook.toggleUpdateRequestMenu}
      />
    </PageWrapper>
  );
}

export default MyRequests;
