import range from "lodash/range";

export const industryOptions = [
  "Accounting",
  "Banking",
  "Biotechnology",
  "Civic & Social Organisation",
  "Computer & Network Security",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "E-learning",
  "Education Management",
  "Entertainment",
  "Financial Services",
  "Food & Beverages",
  "Government & Administration",
  "Health, Wellness & Fitness",
  "Higher Education",
  "Hospital & Healthcare",
  "Hospitality",
  "Information Services",
  "Information Technology & Services",
  "Insurance",
  "Internet",
  "Investment Banking",
  "Investment Management",
  "Law Practice",
  "Legal Services",
  "Management Consulting",
  "Marketing & Advertising",
  "Music",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Media",
  "Pharmaceuticals",
  "Public Relations & Communications",
  "Publishing",
  "Real Estate",
  "Research",
  "Retail",
  "Sports",
  "Staffing & Recruiting",
  "Telecommunications",
  "Think Tanks",
  "Venture Capital and Private Equity",
  "Wine & Spirits",
];

export const newIndustryOptions = [
  { id: 1, label: "Accounting" },
  { id: 2, label: "Banking" },
  { id: 3, label: "Biotechnology" },
  { id: 4, label: "Civic & Social Organisation" },
  { id: 5, label: "Computer & Network Security" },
  { id: 6, label: "Computer Hardware" },
  { id: 7, label: "Computer Networking" },
  { id: 8, label: "Computer Software" },
  { id: 9, label: "E-learning" },
  { id: 10, label: "Education Management" },
  { id: 11, label: "Entertainment" },
  { id: 12, label: "Financial Services" },
  { id: 13, label: "Food & Beverages" },
  { id: 14, label: "Government & Administration" },
  { id: 15, label: "Health, Wellness & Fitness" },
  { id: 16, label: "Higher Education" },
  { id: 17, label: "Hospital & Healthcare" },
  { id: 18, label: "Hospitality" },
  { id: 19, label: "Information Services" },
  { id: 20, label: "Information Technology & Services" },
  { id: 21, label: "Insurance" },
  { id: 22, label: "Internet" },
  { id: 23, label: "Investment Banking" },
  { id: 24, label: "Investment Management" },
  { id: 25, label: "Law Practice" },
  { id: 26, label: "Legal Services" },
  { id: 27, label: "Management Consulting" },
  { id: 28, label: "Marketing & Advertising" },
  { id: 29, label: "Music" },
  { id: 30, label: "Nonprofit Organization Management" },
  { id: 31, label: "Oil & Energy" },
  { id: 32, label: "Online Media" },
  { id: 33, label: "Pharmaceuticals" },
  { id: 34, label: "Public Relations & Communications" },
  { id: 35, label: "Publishing" },
  { id: 36, label: "Real Estate" },
  { id: 37, label: "Research" },
  { id: 38, label: "Retail" },
  { id: 39, label: "Sports" },
  { id: 40, label: "Staffing & Recruiting" },
  { id: 41, label: "Telecommunications" },
  { id: 42, label: "Think Tanks" },
  { id: 43, label: "Venture Capital and Private Equity" },
  { id: 44, label: "Wine & Spirits" },
];

export const companySizeOptions = [
  1, 10, 50, 100, 250, 500, 1000, 5000, 10000, 10001,
];

export const companyTypeOptions = [
  "Public company",
  "Self employed",
  "Government agency",
  "Non-profit",
  "Self owner",
  "Privately held",
  "Partnership",
];

export const months = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const years = range(2022, 1922, -1).map((item) => item.toString());
