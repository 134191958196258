import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { alertsState } from "state/alerts";

export default function useAlert() {
  const [alertsList, setAlertsList] = useRecoilState(alertsState);

  function removeItemAtIndex(arr, index) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  }

  /**
   * @param {string} id - Unique identifier for this alert.
   * @param {string} text - Body text to display in the alert.
   * @param {('error'|'warning'|'info'|'success')} severity - Severity changes the colour of the alert.
   * @param {number} timeout - Length of time before the alert disappears.
   */
  const addAlert = useCallback(
    (id, text, severity, timeout = 5000) => {
      const dismissAlert = (alertToDismiss) => {
        const index = alertsList.findIndex(
          (listItem) => listItem === alertToDismiss
        );
        const newList = removeItemAtIndex(alertsList, index);
        setAlertsList(newList);
      };
      const newAlert = { id, text, severity, timeout };
      setAlertsList((currentState) => [...currentState, newAlert]);

      if (newAlert?.timeout) {
        console.log(newAlert?.timeout);
        setTimeout(() => {
          dismissAlert(newAlert);
        }, newAlert?.timeout);
      }
    },
    [alertsList, setAlertsList]
  );

  const closeAlert = (alertToDismiss) => {
    const index = alertsList.findIndex(
      (listItem) => listItem === alertToDismiss
    );
    const newList = removeItemAtIndex(alertsList, index);
    setAlertsList(newList);
  };

  return { alertsList, addAlert, closeAlert };
}
