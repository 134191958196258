import { useEffect, useRef } from "react";

const useToggleDropdown = (setState) => {
  const dropdownRef = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (!dropdownRef.current.contains(e.target)) {
        setState(false);
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [setState]);

  return { dropdownRef };
};

export default useToggleDropdown;
