import React from "react";
import styled from "styled-components";
import { SmallBlackBoldText } from "components/Typography";

const OptionType = styled.div`
  display: flex;
  align-items: center;
  margin-right: 63px;
  @media (max-width: 480px) {
    margin-bottom: 23px;
  }
`;

const GreenCircle = styled.div`
  background: #18bfa1;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  display: ${(props) => (props.isSelected ? "block" : "none")}'
`;

function SelectedOptions({
  fieldName,
  optionName,
  currentlySelected,
  setState,
}) {
  return (
    <OptionType onClick={() => setState(optionName)}>
      <GreenCircle isSelected={currentlySelected} />
      <SmallBlackBoldText>{fieldName}</SmallBlackBoldText>
    </OptionType>
  );
}

export default SelectedOptions;
