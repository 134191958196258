import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { Link } from "react-router-dom";
import arrowBlue from "assets/icons/arrow-blue.svg";
import {
  DividerSmallMargin,
  PostItemWrapper,
  PostItemDate,
  PostItemPreviewText,
  PostItemLinkText,
  PostItemImage,
} from "components/Post/Layout";

function UpdatesItem({ post }) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return (
    <>
      <PostItemWrapper>
        <PostItemImage src={post?.mainImage?.asset.url} />
        <PostItemDate>
          {new Date(post?.publishedAt).toLocaleDateString("en-US", options)}
        </PostItemDate>
        <Link to={`updates/${post?.slug.current}`}>
          <h2>{post?.title}</h2>
          <PostItemPreviewText>
            <BlockContent blocks={post?.body} />
          </PostItemPreviewText>
          <PostItemLinkText>
            <p>Read more</p>
            <img src={arrowBlue} />
          </PostItemLinkText>
        </Link>
        <DividerSmallMargin />
      </PostItemWrapper>
    </>
  );
}

export default UpdatesItem;
