import React from "react";
import styled from "styled-components";
import { TableTextIntroData } from "components/Typography";
import crossSignOrange from "assets/icons/cross-sign-orange.svg";
import introducedByIcon from "assets/icons/introduced-by-icon.svg";
import clockIcon from "assets/icons/clock-icon.svg";
import {
  TableHeaderText,
  RequestStatusTableColumnIntroduction,
  IntroducedByIcon,
} from ".";

const ContactTypeAndStatusIconAndText = styled.div`
  display: flex;
  margin-bottom: 4px;
  @media (max-width: 870px) {
    margin-left: 14px;
    margin-bottom: 0;
  }
`;

const DateAndReasonWrapper = styled.div`
  @media (max-width: 1126px) {
    display: flex;
  }
  @media (max-width: 870px) {
    margin-left: 76.5px;
    justify-content: space-between;
    align-items: flex-end;
  }
  @media (max-width: 480px) {
    margin-left: 62.5px;
  }
`;

export const RequestTableTextRequestType = styled.p`
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #2c2948;
  @media (max-width: 871px) {
    margin-left: 62.5px;
  }
  @media (max-width: 480px) {
    margin-left: 48.5px;
  }
`;

export const RequestTableTextRequestTypeBold = styled(
  RequestTableTextRequestType
)`
  font-weight: bold;
`;

function ContactTypeAndStatus({ requestType, request, index }) {
  const { request_created } = request;
  let formattedDate;
  if (request_created) {
    formattedDate = new Date(request_created)
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, "/");
  } else {
    //eslint-disable-next-line
    formattedDate = new Date().toISOString().slice(0, 10).replace(/\-/g, "/");
  }

  return (
    <RequestStatusTableColumnIntroduction>
      {requestType === "COMPLETED" && (
        <>
          <TableHeaderText index={index}>INTRODUCED YOU BY</TableHeaderText>
          <div>
            <ContactTypeAndStatusIconAndText>
              <IntroducedByIcon src={introducedByIcon} lessMargin />
              <RequestTableTextRequestTypeBold>
                Email
              </RequestTableTextRequestTypeBold>
            </ContactTypeAndStatusIconAndText>
            <DateAndReasonWrapper>
              <TableTextIntroData>{`On ${formattedDate}`}</TableTextIntroData>
            </DateAndReasonWrapper>
          </div>
        </>
      )}
      {requestType === "PENDING" && (
        <>
          <TableHeaderText index={index}>TO INTRODUCE YOU</TableHeaderText>
          <div>
            <ContactTypeAndStatusIconAndText>
              <IntroducedByIcon src={clockIcon} lessMargin />
              <RequestTableTextRequestType>
                Requested
              </RequestTableTextRequestType>
            </ContactTypeAndStatusIconAndText>
            <DateAndReasonWrapper>
              <TableTextIntroData>{`On ${formattedDate}`}</TableTextIntroData>
            </DateAndReasonWrapper>
          </div>
        </>
      )}
      {requestType === "REJECTED" && (
        <>
          <TableHeaderText index={index}>INTRODUCED YOU BY</TableHeaderText>
          <div>
            <ContactTypeAndStatusIconAndText>
              <IntroducedByIcon src={crossSignOrange} lessMargin />
              <RequestTableTextRequestType>Denied</RequestTableTextRequestType>
            </ContactTypeAndStatusIconAndText>
            <DateAndReasonWrapper>
              <TableTextIntroData>{`On ${formattedDate}`}</TableTextIntroData>
            </DateAndReasonWrapper>
          </div>
        </>
      )}
    </RequestStatusTableColumnIntroduction>
  );
}

export default ContactTypeAndStatus;
