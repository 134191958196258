import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { LandingRevmoLogo } from "components/Assets";
import { NavList } from ".";
import { LandingButton } from "../Button";
import { LandingMobileNavbar, LandingNavbarDropdown } from ".";
import { useUser } from "hooks";
import { NavListContainer, NavListItem } from "./NavList";

const LandingNavbarContainer = styled.div`
  margin: 0;
  z-index: 35;
  background: transparent;
  height: 80px;
  width: 100%;
  padding: 0;
  min-height: auto;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  a {
    font-family: "Montserrat", sans-serif !important;
  }
  @media only screen and (min-width: 768px) {
    padding: 0 4vw;
  }
  @media (max-width: 991.9px) {
    display: none;
  }
  @media (max-width: 992.9px) and (orientation: portrait) {
    padding: 0 2rem;
  }
  @media (max-width: 992.9px) and (min-width: 768px) {
    height: 80px;
  }
  @media only screen and (max-width: 1200px) {
    a {
      font-size: 1rem;
    }
  }

  ${(props) => {
    if (props.absolute) {
      return `
            position: absolute;
          `;
    }
  }}
`;

const LandingNavbarContent = styled.div`
  @media screen and (min-width: 992px) {
    max-width: 92rem;
    padding: 0;
  }
  width: 100%;
  height: 100%;
  @media (min-width: 1200px) {
    padding-left: 4vw;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const LandingButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  a {
    display: flex;
    align-items: center;
  }
`;

const LandingRevmoLogoWrapper = styled.div`
  max-width: 125px;
  padding-right: 2rem;
  img {
    overflow: hidden;
  }
  @media (max-width: 870px) {
    max-width: 35%;
    display: flex;
    align-items: center;
    img {
      max-height: 100%;
    }
  }
`;

export const LandingNavbar = ({ absolute }) => {
  const { user } = useUser();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <>
      <LandingNavbarContainer absolute={absolute}>
        <LandingNavbarContent>
          <LandingRevmoLogoWrapper>
            <Link to="/">
              <LandingRevmoLogo />
            </Link>
          </LandingRevmoLogoWrapper>
          <NavList
            setShowMobileMenu={setShowMobileMenu}
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
            user={user}
          />
          {user ? (
            <LandingButtonWrapper>
              <Link to="/">
                <LandingButton>LAUNCH APP</LandingButton>
              </Link>
            </LandingButtonWrapper>
          ) : (
            <LandingButtonWrapper>
              <NavListContainer>
                <ul>
                  <NavListItem>
                    <Link to="/login">Login</Link>
                  </NavListItem>
                  <NavListItem>
                    <Link to="/sign-up">Create account</Link>
                  </NavListItem>
                </ul>
              </NavListContainer>
              <Link
                to={{ pathname: "https://calendly.com/margot-57/30min" }}
                target="_blank"
              >
                <LandingButton>BOOK A DEMO</LandingButton>
              </Link>
            </LandingButtonWrapper>
          )}
        </LandingNavbarContent>
      </LandingNavbarContainer>
      <LandingNavbarDropdown
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
      />
      <LandingMobileNavbar
        setShowMobileMenu={setShowMobileMenu}
        showMobileMenu={showMobileMenu}
        setShowDropdown={setShowDropdown}
        showDropdown={showDropdown}
        user={user}
      />
    </>
  );
};

export default LandingNavbar;
