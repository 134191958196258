import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Link, useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { CardContent, Grid } from "@mui/material";

import { useSetRecoilState } from "recoil";
import { walkthroughState } from "state/recoil/walkthrough";
import { saveHasUserCompletedOnboarding } from "state/localStorage";

const steps = [
  {
    label: "Take a Tour",
    description: `Taking a tour of Revmo is the first step to unlocking your potential connections.`,
  },
  {
    label: "Upload Contacts",
    description:
      "Uploading contacts allows you to search your extended network.",
  },
  {
    label: "Search Revmo",
    description: `Try out different search terms to find your next connection.`,
  },
  {
    label: "Request an Introduction",
    description: `It's time to extend your network and be introduced!`,
  },
  {
    label: "Add Career Information",
    description: `This allows us to improve your search results and widen your network.`,
  },
  {
    label: "Done!",
    description: `Continue exploring the platform and making warm introductions.`,
  },
];

const StyledCard = styled(Card)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? theme.palette.dark.main
      : theme.palette.secondary.light,
  marginBottom: "22px",
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "157px",
    height: "157px",
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.dark
        : theme.palette.secondary.main,
    borderRadius: "50%",
    top: "-105px",
    right: "-96px",
  },
}));

export default function OnboardingChecklist() {
  const [activeStep, setActiveStep] = React.useState(0);
  const setWalkthroughState = useSetRecoilState(walkthroughState);
  const [displayChecklist, setDisplayChecklist] = useState(true);

  let history = useHistory();

  const handleStart = () => {
    history.push("/dashboard");
    setWalkthroughState(true);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setWalkthroughState(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    saveHasUserCompletedOnboarding(true);
    setDisplayChecklist("none");
  };

  return (
    <Grid container display={displayChecklist}>
      <Grid item xs={12}>
        <StyledCard>
          <CardContent sx={{ p: 2 }}>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      optional={
                        index === 5 ? (
                          <Typography variant="caption">Last step</Typography>
                        ) : null
                      }
                      onClick={() => setActiveStep(index)}
                      sx={{
                        cursor: "pointer",
                        borderRadius: "8px",
                        "&:hover": {
                          backgroundColor: "#d4d4d521",
                        },
                      }}
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      <Box sx={{ mb: 1 }}>
                        <Box sx={{ display: "flex" }}>
                          {index === 0 && (
                            <Button
                              variant="contained"
                              onClick={handleStart}
                              sx={{ mt: 0.1, mr: 0.1 }}
                            >
                              Let's go
                            </Button>
                          )}
                          {index === 1 && (
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 0.1, mr: 0.1 }}
                              component={Link}
                              to={{
                                pathname: "/contacts",
                                state: { addContacts: true },
                              }}
                            >
                              Continue
                            </Button>
                          )}
                          {index === 2 && (
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 0.1, mr: 0.1 }}
                              component={Link}
                              to="/search"
                            >
                              Continue
                            </Button>
                          )}
                          {index === 4 && (
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 0.1, mr: 0.1 }}
                              component={Link}
                              to="/profile"
                            >
                              Continue
                            </Button>
                          )}
                          {index !== 0 &&
                            index !== 1 &&
                            index !== 2 &&
                            index !== 4 && (
                              <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 0.1, mr: 0.1 }}
                              >
                                {index === steps.length - 1
                                  ? "Finish"
                                  : "Continue"}
                              </Button>
                            )}
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 0.1, mr: 0.1 }}
                          >
                            Back
                          </Button>
                        </Box>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                    Finish & Close
                  </Button>
                </Paper>
              )}
            </Box>
          </CardContent>
        </StyledCard>
      </Grid>
    </Grid>
  );
}
