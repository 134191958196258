import React, { useState } from "react";
import PropTypes from "prop-types";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  FormHelperText,
  OutlinedInput,
  Popper,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import { AnimateButton, InputLabel, Transitions } from "../../";
import { newIndustryOptions } from "utils/companyDataSets";

// assets
import { shouldForwardProp } from "@mui/system";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background:
        theme.palette.mode === "dark" ? theme.palette.dark[800] : "#fff",
    },
  })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.secondary.light,
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.secondary.dark,
    "&:hover": {
      background:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.main
          : theme.palette.secondary.dark,
      color:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.light
          : theme.palette.secondary.light,
    },
  })
);

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
  const theme = useTheme();

  return (
    <OutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Search"
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon
            stroke={1.5}
            size="1rem"
            color={theme.palette.grey[500]}
          />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <ButtonBase sx={{ borderRadius: "12px" }}>
            <HeaderAvatarStyle variant="rounded">
              <TuneIcon stroke={1.5} size="1.3rem" />
            </HeaderAvatarStyle>
          </ButtonBase>
          <Box sx={{ ml: 2 }}>
            <ButtonBase sx={{ borderRadius: "12px" }}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  background:
                    theme.palette.mode === "dark"
                      ? theme.palette.dark.main
                      : theme.palette.orange.light,
                  color: theme.palette.orange.dark,
                  "&:hover": {
                    background: theme.palette.orange.dark,
                    color: theme.palette.orange.light,
                  },
                }}
                {...bindToggle(popupState)}
              >
                <CloseIcon stroke={1.5} size="1.3rem" />
              </Avatar>
            </ButtonBase>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
  const theme = useTheme();
  const [value, setValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box sx={{ ml: 2 }}>
                <ButtonBase sx={{ borderRadius: "12px" }}>
                  <HeaderAvatarStyle
                    variant="rounded"
                    {...bindToggle(popupState)}
                  >
                    <SearchIcon stroke={1.5} size="1.2rem" />
                  </HeaderAvatarStyle>
                </ButtonBase>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions
                      type="zoom"
                      {...TransitionProps}
                      sx={{ transformOrigin: "center left" }}
                    >
                      <Card
                        sx={{
                          background:
                            theme.palette.mode === "dark"
                              ? theme.palette.dark[900]
                              : "#fff",
                          [theme.breakpoints.down("sm")]: {
                            border: 0,
                            boxShadow: "none",
                          },
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs>
                              <MobileSearch
                                value={value}
                                setValue={setValue}
                                popupState={popupState}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <OutlineInputStyle
          id="input-search-header"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon
                stroke={1.5}
                size="1rem"
                color={theme.palette.grey[500]}
              />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <ButtonBase sx={{ borderRadius: "12px" }} onClick={handleClick}>
                <HeaderAvatarStyle variant="rounded">
                  <TuneIcon stroke={1.5} size="1.3rem" />
                </HeaderAvatarStyle>
              </ButtonBase>
            </InputAdornment>
          }
          aria-describedby="search-helper-text"
          inputProps={{ "aria-label": "weight" }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h4" sx={{ p: 2 }}>
                Advanced Search
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ p: 2 }}>Clear | Cancel</Typography>
                <AnimateButton>
                  <Button variant="contained" type="submit" color="secondary">
                    Search
                  </Button>
                </AnimateButton>
              </Box>
            </Box>
            <Grid container spacing={2} alignItems="center" sx={{ p: 2 }}>
              <Grid item xs={12} lg={4} md={4}>
                <InputLabel>Person Name</InputLabel>
                <TextField fullWidth placeholder="Enter person name" />
                <FormHelperText>Please enter a name</FormHelperText>
              </Grid>
              <Grid item xs={12} lg={4} md={4}>
                <InputLabel>Company</InputLabel>
                <TextField fullWidth placeholder="Enter company name" />
                <FormHelperText>Please enter a company name</FormHelperText>
              </Grid>
              <Grid item xs={12} lg={4} md={4}>
                <InputLabel>Title or Role</InputLabel>
                <TextField fullWidth placeholder="Enter role" />
                <FormHelperText>Please enter a role</FormHelperText>
              </Grid>
              <Grid item xs={12} lg={4} md={4}>
                <InputLabel>Industry</InputLabel>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <Autocomplete
                      multiple
                      options={newIndustryOptions}
                      getOptionLabel={(option) => option.label}
                      defaultValue={[
                        newIndustryOptions[0],
                        newIndustryOptions[4],
                      ]}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Grid>
                <FormHelperText>Please select industries</FormHelperText>
              </Grid>
              <Grid item xs={12} lg={4} md={4}>
                <InputLabel>Location</InputLabel>
                <TextField fullWidth placeholder="Select a location" />
                <FormHelperText>Please enter a location</FormHelperText>
              </Grid>
              <Grid item xs={12} lg={2} md={2}>
                <InputLabel>Include Previous Roles</InputLabel>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Include in results"
                />
                <FormHelperText>Search previous roles</FormHelperText>
              </Grid>
              <Grid item xs={12} lg={2} md={2}>
                <InputLabel>Personal Contacts</InputLabel>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Include in results"
                />
                <FormHelperText>Show personal contacts</FormHelperText>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default SearchSection;
