import React from "react";
import styled from "styled-components";
import { RevmoLogoName } from "../Assets";

const SimpleNavWrapper = styled.div`
  height: 50px;
  background: ${(props) => props.theme.brandColor};
  display: flex;
  align-items: center;
  padding-right: 23px;
`;

function SimpleNav() {
  return (
    <SimpleNavWrapper>
      <RevmoLogoName />
    </SimpleNavWrapper>
  );
}

export default SimpleNav;
