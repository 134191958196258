import styled from "styled-components";

export const ContentWrapper = styled.div`
  padding: calc(40vmax / 10) 4vw calc(40vmax / 10) 4vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const AboutUsHeader = styled.header`
  position: relative;
  padding-top: 5.3125rem;
  padding-bottom: 5.3125rem;
  @media screen and (max-width: 991px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 767px) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
`;

export const AboutUsContainer = styled.div`
  position: relative;
  margin-right: auto;
  margin-left: auto;
  max-width: 1248px;
  padding: 4rem 5.625rem 5.25rem;
  padding-bottom: 0;
  @media screen and (max-width: 479px) {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
`;
export const HeroAbout = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

export const HeroAboutText = styled.div`
  max-width: 31.25rem;
  opacity: 0.75;
  font-weight: 500;
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-size: 1.25rem;
  }
  @media screen and (max-width: 767px) {
    h1,
    h2 {
      font-size: 2.25rem;
    }
  }
`;

export const HeroAboutHeader = styled.h1`
  font-size: 3rem;
  margin-right: 10px;
  max-width: 30rem;
  font-family: "Montserrat", sans-serif;
`;

export const BiosSection = styled.div`
  padding-top: 5.3125rem;
  padding-bottom: 5.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const BiosContainer = styled.div`
  max-width: 1024px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
`;

export const BiosHeader = styled.h1`
  text-align: center;
`;
