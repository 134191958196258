import React from "react";
import {
  HeaderText,
  MidLargeSpacedText,
  MidLargeSpacedTextBlue,
} from "../../../components/Typography";
import { AuthButtonNoArrow } from "components/Button";
import {
  HeaderContainerPersonalData,
  FooterWrapper,
} from "components/Onboarding";
import { Slide } from "react-awesome-reveal";

function OnboardingError({ handleSubmit }) {
  return (
    <Slide>
      <HeaderContainerPersonalData>
        <HeaderText>Oops!</HeaderText>
        <MidLargeSpacedText>
          There seems to have been an issue getting you signed up.
        </MidLargeSpacedText>
        <MidLargeSpacedText>
          We suggest reaching out to our support team at{" "}
          <MidLargeSpacedTextBlue as="a" href="mailto:support@revmo.app">
            support@revmo.app.
          </MidLargeSpacedTextBlue>
          <MidLargeSpacedText>
            Otherwise, feel free to give our onboarding another go.
          </MidLargeSpacedText>
        </MidLargeSpacedText>
      </HeaderContainerPersonalData>
      <FooterWrapper>
        <AuthButtonNoArrow
          buttonText="Try again"
          action={() => handleSubmit()}
          padding={"11px 30px"}
          type="submit"
        />
      </FooterWrapper>
    </Slide>
  );
}

export default OnboardingError;
