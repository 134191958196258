import React, { useEffect, useState } from "react";
import sanityClient from "../../../client";
import BlockContent from "@sanity/block-content-to-react";
import {
  AboutUsHeader,
  AboutUsContainer,
  HeroAbout,
  HeroAboutText,
  HeroAboutHeader,
} from "../Layout";

function AboutUsHero() {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'page' && title == 'About'] 
            {
            title,
            tagline,
            content,
            mainImage{
              asset->{
              _id,
              url
            }
          }
        }`
      )
      .then((data) => setAboutData(data[0]))
      .catch(console.error);
  }, []);

  return (
    <AboutUsHeader>
      <AboutUsContainer>
        {aboutData && (
          <HeroAbout>
            <HeroAboutHeader>{aboutData.tagline}</HeroAboutHeader>
            <HeroAboutText>
              <BlockContent blocks={aboutData.content} />
            </HeroAboutText>
          </HeroAbout>
        )}
      </AboutUsContainer>
    </AboutUsHeader>
  );
}

export default AboutUsHero;
