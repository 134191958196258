import React from "react";
import styled from "styled-components";
import {
  LargeBoldText,
  MidLargeSpacedText,
  MidSizedText,
} from "../../Typography";
import closeIcon from "assets/icons/close-icon.svg";
import {
  ProfileModalPageWrapper,
  DarkBlurredBackground,
  CloseIconImage,
  ProfileModalContainerAddContacts,
  AddContactsContent,
  HeaderAndExplainerTextContainerTags,
} from "../index";
import {
  ExistingTagsWrapper,
  AskIfTheUserWouldLikeToSelectAnExistingTag,
  ExistingTag,
} from "./index";
import { useForm } from "react-hook-form";
import { ButtonPrimaryWithArrow } from "components/Button";

const ViewTagButtons = styled.div`
  display: flex;
  margin-top: 20px;
  button {
    margin-top: 10px;
    height: 50px;
    margin-right: 10px;
    margin-bottom: 7px;
  }
`;

export function AddOrViewTagsModal({
  setDisplayModal,
  contact,
  tagContactOrOrgHook,
  allTags,
  setAllTags,
  org,
}) {
  const { first_name, last_name } = contact;
  const {
    addTagToContact,
    deleteContactTag,
    contactTag,
    editTag,
    addAdditionalTag,
    toggleAddAdditionalTag,
  } = tagContactOrOrgHook;

  const { register, errors, watch, handleSubmit } = useForm();
  const { otherTags } = watch();

  const disableOtherTag = !otherTags?.length;

  const addExistingTagToContact = async (data) => {
    const otherTags = JSON.parse(data.otherTags);
    const tagInfo = {
      name: otherTags.name,
      setting: otherTags.settings,
    };
    if (org) {
      contact.uuid = contact.org_uuid;
    }
    await addTagToContact(tagInfo, contact, org);
  };

  const deleteAndRemoveFromAllTags = async (tag, contact) => {
    const filteredTags = allTags.filter((tag) => {
      return tag.name !== tag.name;
    });
    setAllTags([...filteredTags]);
    await deleteContactTag(tag, contact);
  };

  const checkIfContactHasAllTags = () => {
    const flattenedTagNames = contactTag.map((tag) => tag.name);
    let contactHasAllTags = true;
    allTags.forEach((tag) => {
      if (!flattenedTagNames.includes(tag.name)) {
        contactHasAllTags = false;
      }
    });
    return contactHasAllTags;
  };

  const allExistingTagsBelongToContact = checkIfContactHasAllTags();

  return (
    <>
      <ProfileModalPageWrapper>
        <DarkBlurredBackground onClick={setDisplayModal} />
        <ProfileModalContainerAddContacts>
          <CloseIconImage src={closeIcon} onClick={setDisplayModal} />
          <AddContactsContent>
            <HeaderAndExplainerTextContainerTags>
              <LargeBoldText>Tags</LargeBoldText>
              {!contactTag[0] || addAdditionalTag ? (
                <>
                  <MidLargeSpacedText>
                    Tags help us to understand your relationship with an
                    individual or organisation. Tags can be used to create
                    groups, such as 'Alumni' or 'Trusted', for example. Using
                    tags, you can refine your searches, and filter contacts.
                  </MidLargeSpacedText>
                  <MidLargeSpacedText>
                    Please read our FAQ to find out more, or click on an option
                    below.
                  </MidLargeSpacedText>
                  <MidLargeSpacedText>
                    Select from the list of tags below, to help us identify your
                    relationship with {first_name} {last_name}.
                  </MidLargeSpacedText>
                </>
              ) : (
                <MidLargeSpacedText>
                  The following tags are associated with {first_name}{" "}
                  {last_name}.
                </MidLargeSpacedText>
              )}
            </HeaderAndExplainerTextContainerTags>
            {(addAdditionalTag || !contactTag[0]) && (
              <>
                <AddExistingTagsDropdown
                  allTags={allTags}
                  editTag={editTag}
                  register={register}
                  errors={errors}
                  allExistingTagsBelongToContact={
                    allExistingTagsBelongToContact
                  }
                  contactTag={contactTag}
                  handleSubmit={handleSubmit}
                  addExistingTagToContact={addExistingTagToContact}
                  disableOtherTag={disableOtherTag}
                ></AddExistingTagsDropdown>
                <ViewTagButtons>
                  {allTags[0] && !allExistingTagsBelongToContact && (
                    <ButtonPrimaryWithArrow
                      buttonText="Add existing tag"
                      action={handleSubmit(addExistingTagToContact)}
                      disabled={disableOtherTag}
                    />
                  )}
                </ViewTagButtons>
              </>
            )}
            <ViewExistingTags
              contactTags={contactTag}
              contact={contact}
              deleteContactTag={deleteAndRemoveFromAllTags}
              buttonText="Add new tag"
              toggleAddAdditionalTag={toggleAddAdditionalTag}
              addAdditionalTag={addAdditionalTag}
              org={org}
            />
          </AddContactsContent>
        </ProfileModalContainerAddContacts>
      </ProfileModalPageWrapper>
    </>
  );
}

function ViewExistingTags({
  contactTags,
  contact,
  deleteContactTag,
  toggleAddAdditionalTag,
  addAdditionalTag,
  org,
}) {
  return (
    <>
      {contactTags[0] && !addAdditionalTag && (
        <TagsCardWrapper>
          <ExistingTag
            contactTags={contactTags}
            contact={contact}
            deleteContactTag={deleteContactTag}
            org={org}
          />
          <ButtonPrimaryWithArrow
            buttonText="Add new tag"
            action={toggleAddAdditionalTag}
          />
        </TagsCardWrapper>
      )}
    </>
  );
}

const TagsCardWrapper = styled.div`
  button {
    margin-top: 35px;
  }
`;

function AddExistingTagsDropdown({
  allTags,
  register,
  errors,
  setMessage,
  allExistingTagsBelongToContact,
  contactTag,
}) {
  if (allExistingTagsBelongToContact) {
    return null;
  }

  return (
    <>
      {allTags[0] && (
        <>
          <ExistingTagsWrapper>
            <MidSizedText>
              Select a tag from the list below. You will be able to sort and
              filter your contacts by tag.
            </MidSizedText>
            <AskIfTheUserWouldLikeToSelectAnExistingTag
              register={register}
              error={errors}
              setMessage={setMessage}
              allTags={allTags}
              contactTag={contactTag}
            />
          </ExistingTagsWrapper>
        </>
      )}
    </>
  );
}

export default AddOrViewTagsModal;
