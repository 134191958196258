import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";

// material-ui
import { Button, Grid, Stack, TextField, Typography } from "@mui/material";

// project imports
import { AnimateButton } from "components/DashboardNew";
import { gridSpacing } from "../theme";

// ==============================|| PROFILE 2 - CHANGE PASSWORD ||============================== //

const ChangePassword = ({
  handleSubmitUpdatePassword,
  updatePasswordStatus,
}) => {
  const theme = useTheme();
  const { control, handleSubmit } = useForm();

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} sm={6}>
        <Controller
          name={"oldPassword"}
          control={control}
          render={({ onChange, value }) => (
            <TextField
              onChange={onChange}
              value={value}
              label={"Current Password"}
              type="password"
              fullWidth
              defaultValue=""
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} />
      <Grid item xs={12} sm={6}>
        <Controller
          name={"newPassword"}
          control={control}
          render={({ onChange, value }) => (
            <TextField
              onChange={onChange}
              value={value}
              label={"New Password"}
              type="password"
              fullWidth
              defaultValue=""
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name={"confirmPassword"}
          control={control}
          render={({ onChange, value }) => (
            <TextField
              onChange={onChange}
              value={value}
              label={"Confirm Password"}
              type="password"
              fullWidth
              defaultValue=""
            />
          )}
        />
      </Grid>
      {updatePasswordStatus && (
        <Grid item xs={12} sm={12}>
          {updatePasswordStatus.status === "Error" ? (
            <Typography variant="subtitle1" color={theme.palette.warning}>
              {updatePasswordStatus.message}
            </Typography>
          ) : (
            <Typography variant="subtitle1" color="inherit">
              {updatePasswordStatus.message}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <Stack direction="row">
          <AnimateButton>
            <Button
              variant="outlined"
              size="large"
              onClick={handleSubmit(handleSubmitUpdatePassword)}
            >
              Change Password
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
