import React from "react";
import styled from "styled-components";
import ArticleCard from "./ArticleCard";

const ArticleOverviewWrapper = styled.div`
  padding: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

function ArticleOverview({ articles }) {
  return (
    <ArticleOverviewWrapper>
      {articles?.map((article, index) => {
        return <ArticleCard key={index} article={article} />;
      })}
    </ArticleOverviewWrapper>
  );
}

export default ArticleOverview;
