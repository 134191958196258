import React from "react";
import styled from "styled-components";
import {
  PersonInvolvedInIntro,
  ContactTypeAndStatus,
  PersonYouWishToBeIntroducedTo,
  CallToAction,
} from ".";

const MultiColumnWrapper = styled.div`
  display: flex;
  @media (max-width: 1126px) {
    padding: 32px 17px 22px 26px;
    background: #f9fafb;
  }
  @media (max-width: 870px) {
    flex-direction: column;
    padding: 40px 20px 0px 20px;
  }
`;

const MultiColumnWrapperSecond = styled(MultiColumnWrapper)`
  flex: 1;
  @media (max-width: 870px) {
    flex-direction: column;
    padding: 28px 20px 30px 20px;
  }
`;

const RequestStatusTableRowContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 11px;
  @media (max-width: 1126px) {
    flex-direction: column;
    margin-bottom: 29px;
  }
  @media (max-width: 870px) {
    border-top: 1px solid #e3e6e9;
    border-bottom: 1px solid #e3e6e9;
  }
`;

function RequestStatusTableRow({ request, index, requestType }) {
  return (
    <RequestStatusTableRowContainer>
      <MultiColumnWrapper>
        <PersonInvolvedInIntro
          person={request.intermediary_person_node}
          column={"introduced by"}
          index={index}
          requestType={requestType}
        />
        <ContactTypeAndStatus
          requestType={requestType}
          request={request}
          index={index}
        />
      </MultiColumnWrapper>
      <MultiColumnWrapperSecond>
        <PersonYouWishToBeIntroducedTo
          person={request.target_person_node}
          column={"introduced to"}
          index={index}
          request={request}
          requestType={requestType}
        />
        <CallToAction index={index} requestType={requestType} />
      </MultiColumnWrapperSecond>
    </RequestStatusTableRowContainer>
  );
}

export default RequestStatusTableRow;
