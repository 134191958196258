import React from "react";
import { styled } from "@mui/material/styles";

import { Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { InputName } from "./index";

const CheckboxStyled = styled(Checkbox)(() => ({
  padding: "5px",
  "&.MuiCheckbox-root": {
    color: "#0069ff",
  },
  "&.MuiCheckbox-colorSecondary": {
    "&.Mui-checked": {
      color: "#0069ff",
    },
  },
}));

function AdvancedSearchTreeView({
  addOrRemoveSearchCriteria,
  searchOptions,
  searchCriteriaName,
  listOfOptions,
}) {
  const industriesOptions = {
    uuid: "industries",
    name: "Select industries...",
    sub_industry: listOfOptions.length
      ? listOfOptions
      : [{ name: "Loading..." }],
  };

  //id is id of node clicked
  function getChildById(node, uuid) {
    let array = [];

    //returns an array of nodes ids: clicked node id and all children node ids
    function getAllChild(nodes) {
      if (nodes === null) return [];
      if (nodes.uuid !== "industries") {
        array.push(nodes);
      }
      return array;
    }

    //returns the node object that was selected
    function getNodeByUuid(nodes, uuid) {
      if (nodes.uuid === uuid) {
        return nodes;
      } else if (Array.isArray(nodes.sub_industry)) {
        let result = null;
        nodes.sub_industry.forEach((node) => {
          if (getNodeByUuid(node, uuid)) {
            result = getNodeByUuid(node, uuid);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeByUuid(node, uuid));
  }

  function getOnChange(checked, nodes) {
    //gets all freshly selected or unselected nodes
    const allNode = getChildById(industriesOptions, nodes.uuid);
    //combines newly selected nodes with existing selection
    //or filters out newly deselected nodes from existing selection
    let array = checked
      ? [...searchOptions[searchCriteriaName].name, ...allNode]
      : searchOptions[searchCriteriaName].name.filter(
          (value) => !allNode.includes(value)
        );

    addOrRemoveSearchCriteria(
      searchOptions[searchCriteriaName].name,
      array,
      searchCriteriaName
    );
  }

  const RenderTreeWithCheckboxes = (nodes) => {
    return (
      <TreeItem
        key={nodes.uuid}
        nodeId={nodes.uuid}
        sx={{
          div: {
            "&.Mui-selected": {
              backgroundColor: "transparent",
            },
          },
        }}
        label={
          <FormControlLabel
            control={
              <CheckboxStyled
                checked={searchOptions[searchCriteriaName].name.some(
                  (item) => item.uuid === nodes.uuid
                )}
                onChange={(event) =>
                  getOnChange(event.currentTarget.checked, nodes)
                }
                onClick={(e) => e.stopPropagation()}
              />
            }
            label={<>{nodes.name}</>}
            key={nodes.uuid}
            sx={{
              backgroundColor: "transparent",
              span: {
                color: "#7c919e",
                fontWeight: 500,
                fontSize: "12px",
              },
            }}
          />
        }
      >
        {Array.isArray(nodes.sub_industry)
          ? nodes.sub_industry.map((node) => RenderTreeWithCheckboxes(node))
          : null}
      </TreeItem>
    );
  };

  return (
    <TreeView
      aria-label="industries"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{
        maxHeight: 350,
        flexGrow: 1,
        maxWidth: "100%",
        overflowY: "auto",
        border: "1px solid #e3e6e9",
        backgroundColor: "#fcfcfc",
      }}
    >
      {RenderTreeWithCheckboxes(industriesOptions)}
    </TreeView>
  );
}

export default function AdvancedSearchIndustryHierarchy({
  searchOptions,
  listOfOptions,
  searchCriteriaName,
  addOrRemoveSearchCriteria,
}) {
  return (
    <Grid
      container
      direction="column"
      spacing={2}
      sx={{ ml: 0, width: "100%" }}
    >
      <InputName>Industries</InputName>
      <AdvancedSearchTreeView
        addOrRemoveSearchCriteria={addOrRemoveSearchCriteria}
        searchOptions={searchOptions}
        listOfOptions={listOfOptions}
        searchCriteriaName={searchCriteriaName}
      />
    </Grid>
  );
}
