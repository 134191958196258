import React, { useEffect, useState } from "react";

// material-ui
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

// assets
import { mdTheme } from "containers/DashboardNew/theme";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Walkthrough from "components/Walkthrough";
import Alerts from "components/Alert";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ children }) => {
  const [open, setOpen] = useState(true);
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(true);
  //eslint-disable-next-line
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    //   userData();
  }, []);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const toggleAdvancedSearch = () => {
    setAdvancedSearchOpen(!advancedSearchOpen);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Walkthrough />
        <Header
          toggleDrawer={toggleDrawer}
          toggleAdvancedSearch={toggleAdvancedSearch}
        />

        <Sidebar drawerOpen={open} drawerToggle={toggleDrawer} />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Alerts />
            {children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default MainLayout;
