import React from "react";
import user1 from "assets/images/user1.png";
import user3 from "assets/images/user3.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BookTwoToneIcon from "@mui/icons-material/BookTwoTone";
import HelpCenterTwoToneIcon from "@mui/icons-material/HelpCenterTwoTone";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import PersonSearchTwoToneIcon from "@mui/icons-material/PersonSearchTwoTone";
import InsightsTwoToneIcon from "@mui/icons-material/InsightsTwoTone";
import PeopleTwoToneIcon from "@mui/icons-material/PeopleTwoTone";

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropdownLinkItem = styled.div`
  display: flex;
  align-items: flex-start;
  p {
    font-weight: 600;
    margin-bottom: 0;
  }
  :hover {
    text-decoration: none;
    p {
      color: #0069ff;
    }
  }
  svg {
    width: 35px;
    height: auto;
    margin-right: 13px;
  }
  @media (min-width: 992px) {
    font-size: 14px;
    color: #3c3f40;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 40px;
    transition: color 0.1s ease-in-out;
  }
`;

const DropdownLinkItemContentText = styled.span`
  @media (min-width: 992px) {
    font-size: 14px;
    color: #b1b2b3;
    line-height: 21px;
  }
`;

const DropdownLinkItemContent = styled.div``;

const TOUR_STEPS = [
  {
    target: "body",
    disableBeacon: true,
    content: (
      <>
        <h2>Welcome to Revmo!</h2>
        <p>On your dashboard, you can do the following:</p>
        <OptionsContainer>
          <DropdownLinkItem>
            <PersonSearchTwoToneIcon style={{ color: "#0069FF" }} />
            <DropdownLinkItemContent>
              <p>Search Revmo</p>
              <DropdownLinkItemContentText>
                Use Revmo's innovative person search to find new connections{" "}
              </DropdownLinkItemContentText>
            </DropdownLinkItemContent>
          </DropdownLinkItem>
          <DropdownLinkItem>
            <PeopleTwoToneIcon
              style={{
                color: "#0069FF",
                fontWeight: "700",
                fontFamily: "Montserrat",
              }}
            />
            <DropdownLinkItemContent>
              <p>Manage Contacts</p>
              <DropdownLinkItemContentText>
                Add, edit and delete your contacts to ensure we can help you
                find the relevant people{" "}
              </DropdownLinkItemContentText>
            </DropdownLinkItemContent>
          </DropdownLinkItem>
          <DropdownLinkItem>
            <InsightsTwoToneIcon
              style={{
                color: "#0069FF",
                fontWeight: "700",
                fontFamily: "Montserrat",
              }}
            />
            <DropdownLinkItemContent>
              <p>View Metrics</p>
              <DropdownLinkItemContentText>
                See your overall Revmo usage statistics.{" "}
              </DropdownLinkItemContentText>
            </DropdownLinkItemContent>
          </DropdownLinkItem>
        </OptionsContainer>
      </>
    ),
    styles: {
      options: {
        arrowColor: "transparent",
      },
    },
    placement: "center",
  },
  {
    target: ".walkthrough-step-2",
    content:
      'Here, you can search by person or company for potential connections. Try typing in "Revmo".',
    spotlightClicks: true,
    hideBackButton: true,
  },
  {
    target: "body",
    placement: "center",
    title: "This is your search page",
    content:
      "We've gone ahead and put in some sample data - let's take a look.",
    disableBeacon: true,
    hideBackButton: true,
  },
  {
    target: ".walkthrough-step-4",
    content: "You have 2 results, and 1 contact at Revmo - hi, Ross!",
  },
  {
    target: ".walkthrough-step-5",
    content:
      "It looks like you have a connection path with Rachel, why don't you Request an Intro?",
    spotlightClicks: true,
  },
  {
    target: ".walkthrough-step-7",
    content:
      "When requesting an introduction, you can specify why you'd like to be introduced to Rachel.",
    placement: "bottom",
    spotlightClicks: true,
    disableOverlayClose: true,
  },
  {
    target: ".walkthrough-step-8",
    content:
      "You have one path - this user is your contact, Ross. Let's choose them.",
    spotlightClicks: true,
  },
  {
    target: ".walkthrough-step-9",
    content: "The text sent to your contact is completely customisable.",
    spotlightClicks: true,
  },
  {
    target: ".walkthrough-step-10",
    content: "We're ready to confirm our Intro Request. Let's go!",
    spotlightClicks: true,
  },
  {
    target: "body",
    placement: "center",
    content:
      "The introductions page is where you can track the status of your requests, and see your new connections!",
    spotlightClicks: true,
    hideBackButton: true,
  },
  {
    target: "body",
    placement: "center",
    content: (
      <>
        <h2>You're on your way!</h2>
        <p>
          Thanks for taking the tour. Check out these additional resources to
          learn more about Revmo:
        </p>
        <OptionsContainer>
          <Link to="/blog">
            <DropdownLinkItem>
              <BookTwoToneIcon style={{ color: "#0069FF" }} />
              <DropdownLinkItemContent>
                <p>Blog</p>
                <DropdownLinkItemContentText>
                  News, stories and latest thinking on networking, data and more{" "}
                </DropdownLinkItemContentText>
              </DropdownLinkItemContent>
            </DropdownLinkItem>
          </Link>
          <Link to="/updates">
            <DropdownLinkItem>
              <CategoryTwoToneIcon
                style={{
                  color: "#0069FF",
                  fontWeight: "700",
                  fontFamily: "Montserrat",
                }}
              />
              <DropdownLinkItemContent>
                <p>Product Updates</p>
                <DropdownLinkItemContentText>
                  Get updates on our product, best practices and tips{" "}
                </DropdownLinkItemContentText>
              </DropdownLinkItemContent>
            </DropdownLinkItem>
          </Link>
          <Link to="/support" target="_blank">
            <DropdownLinkItem>
              <HelpCenterTwoToneIcon
                style={{
                  color: "#0069FF",
                  fontWeight: "700",
                  fontFamily: "Montserrat",
                }}
              />
              <DropdownLinkItemContent>
                <p>Help Center</p>
                <DropdownLinkItemContentText>
                  Read our Help Center articles for any guidance you may need.{" "}
                </DropdownLinkItemContentText>
              </DropdownLinkItemContent>
            </DropdownLinkItem>
          </Link>
        </OptionsContainer>
      </>
    ),
    styles: {
      options: {
        arrowColor: "transparent",
      },
    },
  },
];

export const walkthroughSearchResults = {
  people: [
    {
      addresses: null,
      positions: [
        {
          org: {
            org_uuid: "17a9dc14-b89c-4d20-b777-d5874091bad3",
            org_name: "Revmo",
          },
          company_size: null,
          role_description: "Developer",
        },
      ],
      dist: 0,
      first_name: "Ross",
      has_path: false,
      is_contact: "4a00e2ca-a5fa-4c97-884b-622b0b79051a",
      last_name: "(Example Contact)",
      middle_name: null,
      profile_image: user1,
      relationship_strength: null,
      probability: 1,
      revmo_score: 774,
      revmo_score_band: null,
      suffix: null,
      telephone_number: null,
      uuid: "d725c5ad-a151-4801-8ee1-3d64e187f750",
    },
    {
      addresses: null,
      positions: [
        {
          org: {
            org_name: "Revmo",
            org_uuid: "17a9dc14-b89c-4d20-b777-d5874091bad3",
          },
          company_size: null,
          role_description: "Customer Success",
        },
      ],
      contact_has_email: false,
      dist: 0,
      email_address: null,
      first_name: "Rachel",
      has_path: true,
      is_contact: "",
      last_name: "(Example Connection)",
      linkedin: null,
      middle_name: null,
      prefix: null,
      profile_image: user3,
      relationship_strength: null,
      revmo_score: 714,
      revmo_score_band: null,
      suffix: null,
      telephone_number: null,
      uuid: "ee7429c9-081b-411c-aadb-3a615caa2492",
    },
  ],
  results: 2,
  user_search_count: 0,
};

// Define our state
export const INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

// Set up the reducer function
export const walkthroughReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true, stepIndex: 0 };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "SEARCH":
      return { ...state, run: true, ...action.payload, stepIndex: 2 };
    case "REQUEST_INTRO":
      return { ...state, run: true, ...action.payload, stepIndex: 5 };
    case "SEND_INTRO":
      return { ...state, run: true, ...action.payload, stepIndex: 8 };
    case "CHECK_REQUESTS":
      return { ...state, run: true, ...action.payload, stepIndex: 9 };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};
