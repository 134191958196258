import React from "react";

// material-ui
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Skeleton from "@mui/material/Skeleton";

// third party imports
import times from "lodash/times";

export default function SearchResultsLoading() {
  return (
    <>
      <LinearProgress />
      <Box sx={{ display: "flex", alignItems: "center", margin: 1 }}>
        {times(2, (index) => {
          return (
            <Box sx={{ width: "100%", margin: 1 }} key={index}>
              <Skeleton width="100%">
                <Typography>.</Typography>
              </Skeleton>
            </Box>
          );
        })}
      </Box>
      <Box>
        {times(5, (index) => {
          return (
            <Box
              sx={{ display: "flex", alignItems: "center", margin: 1 }}
              key={index}
            >
              <Box sx={{ margin: 1 }}>
                <Skeleton variant="circular">
                  <Avatar />
                </Skeleton>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Skeleton width="42.5%">
                  <Typography>.</Typography>
                </Skeleton>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
