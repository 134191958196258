import React, { useState, useEffect } from "react";
import { fetchData } from "utils";

// material-ui
import {
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  ButtonBase,
} from "@mui/material";

// project imports
import { ContactEmptyState } from ".";

// assets
import LocationCityIcon from "@mui/icons-material/LocationCity";

// ==============================|| USER LIST 1 ||============================== //
const HeaderAvatarStyle = styled(
  Avatar,
  {}
)(({ theme }) => ({
  borderRadius: "50%",
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background:
    theme.palette.mode === "dark"
      ? theme.palette.dark.main
      : theme.palette.secondary.light,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.secondary.main
      : theme.palette.secondary.dark,
}));

const OrgList = ({
  orgResultsPerPage,
  setTotalOrgsInNetwork,
  amountOfOrgsToSkip,
}) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const response = await fetchData(
        `/organisations/user/?skip=${amountOfOrgsToSkip}&limit=${orgResultsPerPage}`
      );
      setData(response?.results);
      setTotalOrgsInNetwork(response?.total_count);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, [orgResultsPerPage, amountOfOrgsToSkip]);

  return (
    <TableContainer>
      {data?.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>Organisation Name</TableCell>
              <TableCell>Industry</TableCell>
              <TableCell># Connections</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow hover key={index}>
                  <TableCell sx={{ pl: 3 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <ButtonBase sx={{ borderRadius: "30px" }}>
                          <HeaderAvatarStyle variant="rounded">
                            <LocationCityIcon stroke={1.5} size="1.3rem" />
                          </HeaderAvatarStyle>
                        </ButtonBase>
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <Typography
                          align="left"
                          variant="subtitle1"
                          component="div"
                        >
                          {`${row.organisation.org_name}`}{" "}
                        </Typography>
                        <Typography align="left" variant="subtitle2" noWrap>
                          {row.organisation?.locations}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>{row.organisation?.industry}</TableCell>
                  <TableCell>{row.num_connections}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        <ContactEmptyState />
      )}
    </TableContainer>
  );
};

export default OrgList;
