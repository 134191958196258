import { atom, selector } from "recoil";
import { fetchData } from "utils";

export const introductionRequestsStateAll = selector({
  key: "introductionRequestsStateAll",
  get: async ({ get }) => {
    try {
      const dateLimit = get(introductionDateLimit);
      const response = await fetchData(`/intros/?date_limit=${dateLimit}`);
      return response;
    } catch (error) {
      console.error(
        `Introduction Requests State - get intros ERROR: \n${error}`
      );
      return {
        STARTED: [],
        PENDING: [],
        ACCEPTED: [],
        REJECTED: [],
        CANCELLED: [],
        COMPLETED: [],
      };
    }
  },
});

export const introductionRequestsState = atom({
  key: "introductionRequestsState",
  default: introductionRequestsStateAll,
});

export const userHasRequestHistoryState = selector({
  key: "userHasRequestHistoryState",
  get: ({ get }) => {
    let userHasRequestHistory = false;
    const requests = get(introductionRequestsState);
    if (
      requests?.ACCEPTED.length ||
      requests?.CANCELLED.length ||
      requests?.COMPLETED.length ||
      requests?.PENDING.length ||
      requests?.REJECTED.length ||
      requests?.STARTED.length
    ) {
      userHasRequestHistory = true;
    }
    return {
      userHasRequestHistory,
    };
  },
});

export const introductionRequestsTotal = selector({
  key: "introductionRequestsTotalState",
  get: ({ get }) => {
    let totalUserRequests = 0;
    const requests = get(introductionRequestsState);
    Object.keys(requests).map(function (key) {
      totalUserRequests += requests[key].length;
    });

    return {
      totalUserRequests,
    };
  },
});

export const introductionDateLimit = atom({
  key: "introductionDateLimit",
  default: 15,
});
