import React, { useEffect, useState } from "react";
import sanityClient from "../../../client";
import { BiosHeader, BiosSection, BiosContainer } from "../Layout";
import { Bio } from ".";

function AboutUsBios() {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'person' && (role match "COO*" || role match "Board*")] | order(_createdAt asc)
            {
            name,
            role,
            bio,
            image{
              asset->{
              _id,
              url
            }
          }
        }`
      )
      .then((data) => setAboutData(data))
      .catch(console.error);
  }, []);

  return (
    <BiosSection>
      <BiosHeader>Meet Our Founding Team</BiosHeader>
      <BiosContainer>
        {aboutData &&
          aboutData.map((person, index) => {
            return (
              <Bio
                name={person.name}
                title={person.role}
                bio={person.bio}
                photo={person.image}
                key={index}
              />
            );
          })}
      </BiosContainer>
    </BiosSection>
  );
}

export default AboutUsBios;
