import React from "react";
import styled from "styled-components";
import {
  MidLargeSpacedText,
  MidLargeBoldTitleText,
  ExtraSmallText,
} from "components/Typography";
import chevronBlack from "assets/icons/chevron-black.svg";

const SettingSectionRowReferralPreferences = styled.div`
  padding-top: 67px;
  padding-bottom: 16px;
  border-top: 1px solid #e3e6e9;
`;

const RefferalSelectWrapper = styled.div`
  position: relative;
  max-width: 562px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
`;

const GreySelect = styled.select`
  padding: 16px 22px 14px 20px;
  width: 100%;
  background: #fcfcfc;
  border: 1px solid #e3e6e9;
  box-sizing: border-box;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0.377778px;
  color: #313237;
`;

const ReferralPreferenceChevron = styled.img`
  position: absolute;
  height: 4.2px;
  width: 8px;
  right: 22px;
`;

function ReferralPreferences({ referralPreference, setReferralPreference }) {
  return (
    <SettingSectionRowReferralPreferences>
      <MidLargeBoldTitleText>Referral Preferences</MidLargeBoldTitleText>
      <MidLargeSpacedText>
        Choose if you want to be visible or hidden when acting as an
        intermediary for connection.
      </MidLargeSpacedText>
      <RefferalSelectWrapper>
        <ReferralPreferenceChevron src={chevronBlack} />
        <GreySelect
          onChange={(event) => setReferralPreference(event.target.value)}
        >
          <option value="Hidden for all Revmo contacts">
            Hidden for all Revmo contacts
          </option>
          <option value="Visible for trusted contacts">
            Visible for trusted contacts
          </option>
          <option value="Visible for people at my company">
            Visible for people at my company
          </option>
          <option value="Visible for all contacts">
            Visible for all contacts
          </option>
          <option value="Visible for all of Revmo*">
            Visible for all of Revmo*
          </option>
        </GreySelect>
      </RefferalSelectWrapper>
      {referralPreference === "Visible for all of Revmo*" && (
        <ExtraSmallText>
          *This will allow anyone on Revmo to see you as the intermediary
          between all contacts. Be sure to...
        </ExtraSmallText>
      )}
    </SettingSectionRowReferralPreferences>
  );
}

export default ReferralPreferences;
