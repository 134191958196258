import React from "react";
import styled from "styled-components";
import {
  MidLargeSpacedText,
  MidLargeBoldTitleText,
} from "components/Typography";
import { SkeletonPulse } from "components/Skeleton";

const AccountLevelRowPreferences = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid #e3e6e9;
  margin-bottom: 45px;
  button {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const UserHasSubscriptionTextWrapper = styled.div`
  p:first-child {
    margin-bottom: 10px;
  }
`;

function Payment({ profile }) {
  const userDataIsLoading = profile === undefined;

  return (
    <>
      {userDataIsLoading ? (
        <PaymentSkeleton />
      ) : (
        <AccountLevelRowPreferences>
          <MidLargeBoldTitleText>Account Type</MidLargeBoldTitleText>
          <MidLargeSpacedText>
            Account type determines feature access and control over your
            settings.
          </MidLargeSpacedText>
        </AccountLevelRowPreferences>
      )}
    </>
  );
}

const SkeletonPulsePaymentType = styled(SkeletonPulse)`
  max-width: 635px;
  width: 100%;
  height: 54px;
  margin-top: 32px;
  margin-bottom: 22px;
  border-radius: 5px;
`;

const SkeletonPulseTextLarger = styled(SkeletonPulse)`
  height: 20px;
  max-width: 400px;
  width: 100%;
  margin-bottom: 10px;
`;

function PaymentSkeleton() {
  return (
    <AccountLevelRowPreferences>
      <MidLargeBoldTitleText>Account Type</MidLargeBoldTitleText>
      <MidLargeSpacedText>
        Account type determines feature access and control over your settings.
      </MidLargeSpacedText>
      <SkeletonPulsePaymentType />
      <UserHasSubscriptionTextWrapper>
        <SkeletonPulseTextLarger />
      </UserHasSubscriptionTextWrapper>
    </AccountLevelRowPreferences>
  );
}

export default Payment;
